@charset "utf-8";

@import "../src/ui-bootstrap.less";

// Override some bootstrap styles as we use an older version of bootstrap
// compared to angular-ui-bootstrap
.gh-typeahead .dropdown-menu,
.popover.fade.in {
  display: block;
}

@import "_variables.less";

@import "nucleo/nucleo-outline.less";
@import "nucleo-mini/nucleo-mini.less";
@import "./../../../wwwroot/fe/fonts/gh-font/style.less";

@import (inline) "node_modules/quill/dist/quill.core.css";
@import (inline) "node_modules/quill/dist/quill.snow.css";
@import (inline) "node_modules/quill-mention/dist/quill.mention.min.css";

@import "common/border.less";
@import "common/positioning.less";
@import "common/displaying.less";
@import "common/font-coloring.less";
@import "common/bg-coloring.less";
@import "common/box-model.less";
@import "common/font-formatting.less";
@import "common/twitter-emoji.less";
@import "common/icons.less";
@import "common/forms.less";
@import "common/forms-v2.less";
@import "common/grid.less";
@import "common/opacity.less";
@import "common/line-height.less";

@import "_line_icons.less";
@import "axiforma.less";
@import "login-font.less";
@import "screens/login.less";
@import "_buttons.less";
@import "_modals.less";
@import "_forms.less";
@import "_bootstrap-tweaks.less";
@import "body.less";
@import "body.hide-left-nav.less";
@import "screens/permissions.less";
@import "screens/color-scale.less";
@import "../src/delighted/survey.less";
@import "../src/feature-banner/views/feature-banner.less";

// SHARED
@import "../src/shared/components/shared-components.module.less";

// ONBOARDING CHECKLIST
@import "../src/onboarding/components/add-kr-label.less";

// WELCOME MODAL
@import "../src/welcome-modal/components/welcome-modal-v1/welcome-modal-v1.less";

// GRID
@import "screens/grid/grid.less";

// to do: move to permissions module - https://gtmhub.atlassian.net/browse/GVS-13832
@import "components/permissions-selector.less";
@import "components/select.less";
@import "components/timeframe.less";
@import "components/alert.less";
@import "components/note.less";
@import "components/buttons.less";
@import "components/modals.less";
@import "components/session-selector.less";
@import "components/session-tree-selector.less";
@import "components/badge.less";
@import "components/checkbox-list.less";
@import "components/radio-list.less";
@import "components/assignee-selector.less";
@import "components/invite-user.less";
@import "components/tags.less";
@import "components/datepicker.less";
@import "components/layout.less";
@import "components/box.less";
@import "components/dropdown.less";
@import "components/two-colums-screen.less";
@import "components/single-item-view.less";
@import "components/card.less";
@import "components/tooltip.less";

// MODULES
@import "../src/login/less/user-deactivated.less";
@import "../src/home/home.less";
@import "../src/attachments/attachments.module.less";
@import "../src/assignees/module.less";
@import "../src/multi-account/module.less";
@import "../src/user-profile/user-profile.module.less";
@import "../src/edition-plan-change/edition-plan-change.module.less";
@import "../src/configuration/configuration-core.module.less";
@import "../src/notifications/notifications.module.less";

grammarly-btn {
  display: none;
}

.or {
  color: @brand-grey;
  margin-bottom: 8px;
}

.link-accent {
  color: @blue-80-v2;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: @blue-100-v2;
    text-decoration: underline;
  }

  &:focus {
    border-bottom: 1px solid @blue-100-v2;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover,
    &:active,
    &:focus {
      color: @royal-90;
    }
  }
}

.link-underline {
  text-decoration: underline;
}

#login-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: lighten(desaturate(@brand-dark, 15%), 71%);

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #f1f3f8;
  }
}

#login-panel {
  #login-transfer {
    text-align: center;
    margin-top: 20px;
    color: fade(#fff, 80%);
    font-size: 18px;
    font-weight: @light;

    a {
      color: #c3c4ff;
      text-decoration: underline;
      font-weight: @bold;
    }
  }
}

.master-container {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;

  navigation {
    flex-shrink: 0;
    min-width: 0;
  }

  main {
    overflow: auto;
    width: 100%;
  }
}

.form-error {
  color: @brand-danger;
  font-weight: @light;
  margin: 5px 0;
  font-size: 13px;
  letter-spacing: 0.04em;
}

.active {
  .typeahead-assignee {
    background: @black-10;
    cursor: pointer;
  }
}

.typeahead-assignee {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 20px 8px 10px;
  .transition(0.1s);
  max-width: 250px;

  h5 {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: @brand-dark;
    margin-left: 5px;
  }

  &.awarded {
    opacity: 0.5;
    cursor: no-drop;
  }

  &.role {
    .icon {
      position: absolute;
      left: 10px;
      top: 7px;
      width: 20px;
      border-radius: 20px;
      height: 20px;
      background: @brand-border;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
    }
  }
}

.close {
  position: fixed;
  margin-left: 80%;
  float: right;
  z-index: 1;
  font-size: 35px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}

.container {
  width: 100%;
}

.alert-small {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: @regular;
  line-height: 18px;
}

#select-username {
  .topicon {
    font-size: 60px;
    color: #ddd;
  }

  p {
    font-size: 16px;
  }
}

table.details {
  width: 100%;
  border-top: 1px solid fade(#fff, 5%);
  border-collapse: separate;
  font-weight: @light;

  tr {
    border: 0;

    td {
      border: 0;
      border-top: 0;
      border-bottom: 1px solid fade(#fff, 5%);
      padding: 10px;

      &.lead {
        font-size: 18px;
        line-height: 30px;

        .icon {
          opacity: 0.2;
          font-size: 30px;
          margin-top: 15px;
        }
      }

      &.actions {
        text-align: right;
        font-size: 13px;
        text-transform: uppercase;

        a {
          display: inline-block;
          margin: 0 15px;
        }
      }
    }

    &:hover {
      td {
        background: fade(#fff, 2%);
      }
    }
  }
}

.empty-module {
  text-align: center;
  max-width: 800px;
  position: relative;
  margin: 0 auto;

  h1 {
    clear: both;
    line-height: 1.3em;
    font-size: 2em;
    font-weight: @regular;
    display: block;
    color: @brand-dark;
  }

  p {
    font-size: 1.6em;
    font-weight: @light;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 26px;
    color: @brand-subtle;
  }

  .main {
    font-size: 120px;
    color: fade(@brand-dark, 5%);
  }

  .row {
    margin-top: 60px;
  }
}

html {
  min-height: 100%;
}

p {
  font-weight: @regular;
}

h1 {
  font-size: 40px;
  font-weight: @thin;

  & + h4 {
    color: #888;
    font-weight: @light;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  &.list-style-inside {
    list-style: inside;
  }
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

.mt {
  margin-top: 40px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: @s / 2;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.dn {
  display: none;
}

.ib {
  display: inline-block;
}

main {
  & > [ui-view] {
    min-height: 100%;
    position: relative;
  }
}

.skip-link-focus {
  &:focus {
    box-shadow: 0 0 0 1px @blue-80-v3 inset;
  }
}

main.new-layout {
  overflow: hidden;

  & > [ui-view],
  .new-layout-page {
    height: 100vh;
  }

  .new-layout-sub {
    height: calc(100vh - var(--top-nav-bar-height));
  }

  article.new-layout-sub {
    height: 100%;
  }

  & > [ui-view],
  .new-layout-sub,
  .new-layout-page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      "header"
      "article"
      "footer";

    & > article {
      overflow: auto;
    }

    & > header {
      top-nav-bar.legacy {
        position: unset;
      }
    }

    & > footer {
      border-top: 1px solid #eee;
      padding: 20px;
      background-color: #fff;
    }
  }
}

.placeholder.well {
  text-align: center;

  div {
    top: 50%;
    position: relative;
    margin-top: -130px;

    h5 {
      font-size: 24px;
      opacity: 0.3;
      font-weight: @light;
      margin-top: 40px;
    }

    h6 {
      opacity: 0.3;
    }

    .icon {
      font-size: 80px;
      opacity: 0.1;
    }
  }
}

.initial {
  margin-top: 40px;

  p.lead {
    font-size: 18px;
    line-height: 30px;
    margin-top: 40px;
    opacity: 0.3;
  }

  h3 {
    font-size: 35px;
    margin-bottom: 40px;
    font-weight: @thin;
    color: #fff;
  }
}

.vertical {
  .nav {
    width: 285px;
    border: 0;
    padding-right: 40px;
    float: left;
    padding-top: 15px;

    li {
      width: 100%;

      a {
        width: 100%;
        font-weight: @light;
        color: #000;
        border: 0;

        &:hover {
          background: none;
          color: #666;
          border: 0;
        }
      }

      &.active {
        a {
          color: #fff;
          background: @brand-dark;
          border-radius: 4px;
          border: 0;
          margin: 0;
        }
      }
    }
  }

  .tab-content {
    margin-left: 285px;
    width: auto;
    padding-left: 40px;
    position: relative;
  }
}

.horizontal {
  margin-top: 0px;
  border-left: 0;
  font-weight: @regular;
  margin-bottom: 30px;

  ul.nav {
    border: 0;

    li,
    li:focus,
    li:active {
      border: 0;
      padding: 0;
      font-size: 12px;

      a,
      a:focus,
      a:active {
        color: @brand-dark;
        font-weight: @medium;
        border: 0;
        font-size: 14px;
        margin-right: 0;
        padding: 10px;
        border-bottom: 3px solid @brand-light;
        text-transform: capitalize;

        &:hover {
          background: none;
          border-bottom: 3px solid @brand-grey;
        }

        &:focus {
          background: none;
        }
      }

      &.active {
        border: 0;
        background: none;

        a,
        a:focus,
        a:active {
          border: 0;
          background: none;
          border-bottom: 3px solid @brand-primary;

          &:hover {
            border: 0;
            border-bottom: 3px solid @brand-primary;
          }
        }
      }
    }
  }
}

.link {
  color: @brand-primary;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid @brand-primary;
  }

  &.danger {
    color: @brand-danger;

    &:hover {
      border-bottom: 1px solid @brand-danger;
    }
  }

  &.info {
    color: @brand-info;

    &:hover {
      border-color: @brand-info;
    }
  }

  &.classic {
    color: @brand-jira;

    &:hover {
      border-color: @brand-jira;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.card {
  &.small {
    dropdown-actions-menu {
      position: absolute;
      top: 10px;
      right: 5px;
    }
  }
}

.tiles {
  padding: 0;
  margin: 0;
  list-style: none;

  .tile {
    width: 170px;
    height: 240px;
    background: #fff;
    border: 1px solid #ddd;
    margin-right: 16px;
    margin-bottom: 20px;
    float: left;
    padding: 20px;
    font-size: 15px;
    text-align: center;
    font-weight: @light;

    .icon {
      margin: 20px 0 30px 0;
      font-size: 60px;
      display: block;
      width: 100%;
      color: #ddd;
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 10px;
    }

    &:hover {
      color: #777;
      border-color: @brand-primary;
      cursor: pointer;

      img {
        opacity: 0.6;
      }

      .icon {
        color: @brand-primary;
      }
    }
  }
}

.row.lead {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid fade(#fff, 10%);
}

.secondary-info {
  opacity: 0.6;
}

.pre-wrap {
  white-space: pre-wrap;
}

.gh-clickable {
  cursor: pointer;
}

.gh-capitalize-first {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.top-left-absolute-indicator {
  position: absolute;
  top: 20px;
  left: 20px;
}

@tooltip-max-width: 290px 400px;

each(@tooltip-max-width, {
  .tooltip-max-width-@{value} {
    max-width: @value;

    .tooltip-inner {
      max-width: @value;
    }
  }
});

.tooltip-p-14px {
  .tooltip-inner {
    padding: 14px;
  }
}

.t-align-l-tooltip {
  .tooltip-inner {
    text-align: left;
  }
}

.ink-80-tooltip {
  .tooltip-inner {
    background-color: @ink-80-v2;
    box-shadow: 0px 4px 12px rgba(63, 87, 110, 0.2);
    border-radius: 4px;
    color: @brand-white;
    word-break: normal;
  }

  &.tooltip {
    &.in {
      opacity: 1 !important;
    }

    &.left {
      .tooltip-arrow {
        border-left-color: @ink-80-v2;
      }
    }

    &.right {
      .tooltip-arrow {
        border-right-color: @ink-80-v2;
      }
    }

    &.bottom {
      .tooltip-arrow {
        border-bottom-color: @ink-80-v2;
      }
    }

    &.top {
      .tooltip-arrow {
        border-top-color: @ink-80-v2;
      }
    }
  }
}

.ink-80-popover {
  background-color: @ink-80-v2;
  box-shadow: 0px 4px 12px rgba(63, 87, 110, 0.2);
  border-radius: 4px;
  .popover-content {
    color: @brand-white;
    word-break: break-word;
    font-size: 12px;
    padding: 3px 8px;
  }

  &.popover {
    &.left {
      .arrow {
        &:after {
          border-left-color: @ink-80-v2;
        }
      }
    }

    &.right {
      .arrow {
        &:after {
          border-right-color: @ink-80-v2;
        }
      }
    }

    &.bottom {
      .arrow {
        &:after {
          border-bottom-color: @ink-80-v2;
        }
      }
    }

    &.top {
      .arrow {
        &:after {
          border-top-color: @ink-80-v2;
        }
      }
    }
  }
}

.gh-vcenter {
  display: flex;
  align-items: center;
}

.loading {
  display: inline-block;
  width: 20px;
}

.fsp {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 300px;
}

@import "print.less";
@import "../src/shared/smartbanner/smartbanner.less";
