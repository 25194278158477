tree-selector {
  visibility: hidden; // this is override once the tree is build
  position: absolute;
  margin-top: 2px;
  width: 100%;
  max-height: 400px;
  padding: 10px 14px;
  overflow-y: auto;
  background-color: @brand-white;
  border-radius: 4px;
  border: none;
  box-shadow: 0 4px 10px 3px rgba(63, 87, 110, 0.2);
  color: @list-filter-color;
  z-index: 2;

  @media all and (max-height: 750px) {
    max-height: 500px;
  }

  @media all and (max-height: 650px) {
    max-height: 400px;
  }

  .branch {
    &.first {
      > .leading-node {
        margin-top: 0px;
      }
    }

    &.top-separator {
      margin-top: 11px;
      border-top: 1px solid @grey-10;
    }

    .leading-node {
      .all-sub-sessions {
        display: none;
        position: absolute;
        right: 19px;
        padding: 0 15px;

        cursor: pointer;

        color: @blue-80-v3;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 1) 90%
        );

        span.limit-reached {
          cursor: auto;
          color: @ink-20-v3;
        }
      }

      &:hover {
        .all-sub-sessions {
          display: initial;
        }
      }

      &:extend(.centered-vert);
      font-family:
        SF Pro Text,
        sans-serif;
      margin-top: 10px;
      line-height: 20px;
      color: @ink-100;

      &.non-selectable {
        pointer-events: none;
        opacity: 0.5;
        margin: 2px;
      }

      .expand-collapse {
        color: @ink-50-v2;

        &:not(.empty) {
          cursor: pointer;
        }

        &.empty {
          &.medium {
            height: 16px;
            max-width: 16px;
            min-width: 16px;
          }
        }
      }

      ui-checkbox.limit-reached,
      label.limit-reached {
        &:active {
          pointer-events: none;
        }
      }

      grid-filters-tag-avatar {
        margin-left: 8px;
      }

      label {
        all: unset;

        &.title {
          margin-left: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: @ink-100;
          cursor: pointer;

          &:hover {
            color: @ink-80;
          }
        }
      }
    }

    .sub-tree {
      margin-left: 7px;
    }
  }
}
#session-tree-selector tree-selector {
  .branch.create-goal-custom-selector {
    overflow: hidden;

    .leading-node {
      padding-left: 0px;
      overflow: visible;
      z-index: 0;

      label {
        position: relative;
        overflow: visible;

        &:before,
        &:after {
          content: "";
          display: block;
          height: 36px;
          width: 650%;
          left: -200px;
          top: -8px;
          position: absolute;
        }
        &:before {
          z-index: 1;
        }
        &:after {
          z-index: -1;
        }
      }

      &:hover,
      &:focus {
        label:after {
          background: @blue-10-v3;
        }
      }
      &.active {
        label:after {
          background: @blue-80-v3;
        }
      }
    }

    .sub-tree {
      padding-left: 13px;
    }
  }
}
