.preview-image-modal {
  .modal-dialog {
    display: table;
    width: auto;
    height: auto;

    .modal-content {
      min-height: auto;
      text-align: center;
      padding: 20px;

      .error-message {
        margin: 20px;
        color: @brand-danger;
      }
    }
  }
}
