gh-dropdown-button {
  .dropdown {
    display: inline-block;

    .dropdown-menu {
      li[gh-dropdown-button-menu-item] {
        &:last-of-type {
          button.dropdown-item {
            border-bottom: none;
          }
        }

        button.dropdown-item {
          display: flex;
          width: 100%;
          height: 100%;
          padding: 10px 20px 10px 15px;
          background: @brand-white;
          border: none;
          border-bottom: 1px solid @brand-border;

          &:hover,
          &:active {
            cursor: pointer;
            background: @blue-1;
          }

          &:focus {
            box-shadow:
              0px 0px 0px 1px @brand-white,
              inset 0px 0px 0px 1px @blue-80-v3;
            border-radius: 4px;
          }

          &:disabled {
            cursor: not-allowed;

            .context,
            img {
              opacity: 0.4;
            }

            &:hover {
              background: @brand-white;
            }
          }

          .context {
            display: flex;
            flex-direction: column;
            text-align: left;

            .headline {
              color: @ink-90;
              font-weight: @medium;
              font-size: 15px;

              .gh-font-icon-external-link {
                margin-left: 5px;
                font-size: 12px;
                font-weight: @regular;
              }
            }

            .description {
              margin-top: 2px;
              line-height: 16px;
              font-size: 13px;
              color: @black-80;
            }
          }

          &.with-image {
            img {
              height: 32px;
              width: 32px;
            }

            .leading-emoji {
              font-size: 15px;
            }

            .context {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
