@import "../../../../less/_variables.less";

disabled-edit-user-names {
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;

    h2 {
      font-size: 27px;
      font-style: normal;
      font-weight: @mediumbold;
      line-height: 34px;
      letter-spacing: 0px;
      padding: 5px 0;
    }

    .disabled-profile-info {
      color: #3f444d;
      background-color: #eef0f4;
      padding: 9px 14px;
      margin: 14px 14px 0 14px;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 15px;
    }

    .email {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #536178;
    }
  }
}
