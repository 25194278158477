.user-invitation-form {
  h2 {
    font-weight: @light;
    font-size: 18px;
    line-height: 26px;
  }

  .form-group {
    margin: 0;
  }

  .gh-f-title {
    color: @ink-100-v3;
    letter-spacing: 0.005em;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 16px;
  }

  .gh-modal-header {
    background: @brand-white;
    padding: 22px 40px 0px 40px;
  }

  .gh-f-body {
    .input-label-message {
      line-height: 20px;
      color: @ink-40-v3;
      margin-bottom: 8px;
    }

    .problematic-emails {
      background: @red-20-v2;
      margin-top: 8px;
      border-radius: 8px;
      padding: 12px;

      .gh-font-icon-caution {
        color: @red-80-v2;
        font-size: 18px;
        margin: 2px 8px 0 0;
      }
    }

    .email-separator {
      border-top: 1px solid @grey-10-v3;
      margin: 16px 0;
    }

    .form-actions {
      button:disabled {
        background: @grey-60-v2;
        color: @brand-white;
        cursor: not-allowed;
      }
    }

    .main-form-data {
      margin: 16px 0px;
      padding-right: 10px;
      border-top: 1px solid @grey-10-v3;
      border-bottom: 1px solid @grey-10-v3;

      .users-form-data {
        ul.single-user-form-data,
        ul.unified-form-data {
          button.gh-input-select {
            .dropdown-menu {
              left: -1px;

              ul {
                margin-top: 2px;
                width: 400px;

                li {
                  padding: 10px;
                  font:
                    14px "Helvetica Neue",
                    Helvetica,
                    Arial,
                    sans-serif;
                  font-size: 14px;
                  color: @ink-100;
                  cursor: pointer;
                  white-space: nowrap;
                  overflow: hidden;
                  border: none;

                  &:hover {
                    background-color: @brand-white;
                  }
                }
              }
            }
          }

          .tags-input-container {
            .autocomplete {
              margin: 2px 0 0 -15px;
              width: 400px;
            }
          }

          .roles-input-container,
          .users-input-container {
            .autocomplete {
              width: 400px;
            }
          }

          .gh-input-select {
            height: 38px;
            border: 1px solid @grey-60-v2;
            box-shadow: 0px 1px 1px rgba(90, 127, 158, 0.1);
            font-size: 14px;

            &.readonly {
              cursor: default;
              pointer-events: none;
              background: @grey-20-v2;
              color: @ink-100-v3;
            }

            &.open {
              border: 1px solid @blue-20-v3;
              box-shadow: rgb(90 127 158 / 10%) 0px 1px 1px;
            }

            [class^="gh-font-icon-"] {
              position: absolute;
              right: 10px;
            }
          }

          .gh-input-text {
            width: 400px;
            height: 38px;
            border: 1px solid @grey-60-v2;
            background: @grey-20-v2;
            font-size: 14px;
          }

          .gh-siv-row-name,
          label {
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            color: @ink-70-v2;
            margin-top: 10px;
          }

          label {
            width: 140px;
          }

          > li {
            margin-bottom: 16px;

            .gh-siv-row-content {
              min-width: 400px;
              flex: inherit;
              margin-left: 9px;

              .tags-input-container .host .tags {
                width: 400px;
              }
            }

            textarea {
              min-width: 400px;
            }

            .gh-siv-row-content .tags-input-container .host .tags {
              display: flex;
              align-items: center;
              margin: 0 0 0 -14px;
              padding: 0;
            }

            .tags {
              min-height: 38px;

              border: 1px solid @grey-60-v2;
              box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);

              &:before {
                content: "";
              }

              .input {
                margin: 4px;
                &::-webkit-input-placeholder {
                  color: @ink-20-v3;
                }
              }

              &.focused {
                border: 2px solid @blue-20-v3;
                box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);

                .input {
                  margin: 3px;
                }
              }
            }
          }
        }
      }
    }
  }

  button.gh-font-icon-trash-bin,
  button.gh-font-icon-make-copy {
    border: none;
    background-color: @brand-white;
    color: @ink-50-v2;

    &:hover,
    &.btn.copy:hover {
      color: @ink-40-v3;
    }

    &.btn.copy:active {
      box-shadow: none;
    }

    &:before,
    &.btn.copy:hover {
      font-size: 16px;
    }
  }

  .gh-fg {
    border-bottom: none;
    padding: 0 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
      font-weight: 400;
      margin-bottom: 0px;

      &.bold-checkbox-label {
        font-weight: 500;
      }
    }
  }

  .gh-fa {
    margin-top: 0;
  }

  .invitation {
    margin-top: 30px;
    padding-right: 80px;
    float: left;
    border-top: 1px solid @brand-border;
    border-bottom: 1px solid @brand-border;
    position: relative;

    &:last-of-type {
      margin-bottom: 20px;
    }

    &.hideBorder {
      border-top: none;
    }

    .col-md-3 {
      padding: 0;
      display: flex;
      width: 190px;

      .gh-select-toggle {
        &:before {
          top: 14px;
        }
      }
    }

    .email {
      display: inline-block;
      width: 100%;
    }

    .gh-fg {
      margin-right: 10px;
    }

    &.sending {
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: @brand-white;
        opacity: 0.5;
        z-index: 2;
      }
    }

    &.sent {
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "Invitation sent.";
        background: @brand-white;
        line-height: 60px;
        z-index: 2;
        text-align: center;
        font-size: 18px;
        font-weight: @light;
      }
    }
  }

  .remove {
    width: 20px;
    height: 20px;
    color: @brand-white;
    background: fade(@brand-subtle, 50%);
    border-radius: 10px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    line-height: 21px;
    margin-top: 10px;
    margin-right: 10px;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .help-block {
    position: absolute;
    top: 40px;
    text-align: center;

    font-size: 12px;
    letter-spacing: 0;
    font-weight: 400;
    margin-top: 5px;
    display: block;
    margin-bottom: 0;
    color: @brand-danger;
  }

  .invite-link {
    margin-top: 8px;
    margin-bottom: 10px;

    .link-accent {
      display: flex;
      margin-bottom: 30px;
    }

    .link-box {
      display: flex;
      width: inherit;

      div {
        display: flex;
      }
    }

    .users-invited-description {
      margin-top: 4px;
      margin-bottom: 30px;
      color: @ink-70-v2;
    }

    button.btn.copy {
      display: flex;
      height: 42px;
      background: @brand-white;
      color: @ink-50-v2;
      border-left: none;
      align-items: center;
      padding: 0;
      margin-left: 8px;
    }
  }

  input.gh-input-text {
    font-size: 1em;

    &:read-only {
      color: @ink-100-v2;
      background-color: @grey-5;
      border: 1px solid @grey-20;
      box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);
    }
  }

  .email-tags {
    .mr-9 {
      margin-right: 9px;
    }

    .active-b {
      border: 1px solid @ink-50;
      box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);
    }

    tags-input.emails-list-container {
      width: 100%;

      .clickable {
        cursor: pointer;
      }

      .autocomplete {
        padding: 0;
        border: 0;
        border-radius: 3px;
        box-shadow:
          0px 2px 15px rgba(9, 30, 66, 0.25),
          0px 0px 1px rgba(63, 87, 110, 0.31);
        width: 70%;

        .suggestion-list {
          border-radius: 3px;

          .suggestion-item {
            ng-include {
              .centered-vert;
              height: 23px;
              font-size: 14px;
              color: @ink-100;

              span {
                .ellipsis;
              }

              em {
                font: inherit;
                background: transparent;
                .fw-700;
              }
            }

            &.selected {
              background: @black-10;

              em {
                color: initial;
                background-color: transparent;
              }
            }

            &.disabled {
              opacity: 0.6;
              cursor: not-allowed;
            }

            span.disabled {
              margin-left: 4px;
              text-transform: lowercase;
            }
          }
        }

        .actions {
          border-top: 1px solid @ink-20;
          border-bottom: 1px solid @ink-20;

          li {
            .fw-500;
            padding: 5px 10px;
            color: @blue-100;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .host {
        margin-top: 0;
        margin-bottom: 0;

        .tags {
          min-height: 38px;
          border: 1px solid @grey-60-v2;
          box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);
          border-radius: 4px;
          font-size: 14px;
          color: @ink-90;

          &:hover {
            .active-b;
          }

          .input {
            margin: 4px;
            &::-webkit-input-placeholder {
              color: @ink-20-v3;
            }
          }

          .tag-list {
            .tag-item {
              margin-top: 4px;
              padding-right: 12px;
              border: 0;
              border-radius: 20px;
              background: @black-10;
              line-height: 24px;
              font-size: 14px;
              color: @ink-100;

              white-space: nowrap;
              text-overflow: ellipsis;

              .remove-button {
                margin: 4px 0 0 5px;
              }

              ng-include {
                .d-flex;

                .gh-font-icon-close-medium {
                  display: none;
                }
              }

              &.already-exists {
                background: @red-20-v2;
              }

              &.already-in-list {
                background: #aed2fe;
              }
            }
          }

          &.focused {
            border: 2px solid @blue-20-v3;
            box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);

            .input {
              margin: 3px;
            }

            .tag-list {
              .tag-item {
                margin: 3px 2px;

                ng-include {
                  .gh-font-icon-close-medium {
                    display: initial;
                    color: @ink-80;
                    margin-left: 8px;
                    align-self: center;

                    &:hover,
                    &:active,
                    &:focus {
                      color: @ink-90;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.errored-input {
        .tags {
          border: 1px solid @red-80-v2;

          &:hover,
          &.focused {
            border: 1px solid @red-80-v2;
          }
        }
      }
    }

    p {
      display: inline;
      color: @ink-40-v3;
    }
    .contact-sales-btn {
      display: inline;
      color: @blue-80-v2;
      border: none;
      background: none;
      &:hover,
      &:active,
      &:focus {
        color: @blue-100-v2;
        text-decoration: underline;
      }
    }
  }

  cf-user-picker tags-input.users-input-container {
    margin-left: -14px;

    .host {
      margin-top: 12px;
    }
  }
}
