@steps-to-generate: 5;
@step-size: 10px;

.generate-box-model(@selector, @property) {
  .generate-box-model-loop(@steps) when (@steps >= 0) {
    .generate-box-model-loop((@steps - 1));

    .@{selector}-@{steps} {
      @{property}: @steps* @step-size;
    }
  }

  .generate-box-model-loop(@steps-to-generate);
}

.generate-box-model(gh-m, margin);
.generate-box-model(gh-mt, margin-top);
.generate-box-model(gh-mr, margin-right);
.generate-box-model(gh-mb, margin-bottom);
.generate-box-model(gh-ml, margin-left);
.generate-box-model(gh-p, padding);
.generate-box-model(gh-pt, padding-top);
.generate-box-model(gh-pr, padding-right);
.generate-box-model(gh-pb, padding-bottom);
.generate-box-model(gh-pl, padding-left);

.bold {
  font-weight: bold;
}
