gh-dnd-list {
  .gh-dnd-list-item {
    &:last-child {
      .gh-siv-section-item {
        border-bottom-width: 0;
      }
    }

    .gh-siv-section-item {
      padding: 0;

      &:hover {
        .icon-wrapper {
          .contributor-icon {
            display: none;
          }

          .gh-font-icon-move {
            display: inline-flex;
          }
        }
      }

      &:last-child {
        border-bottom-width: 1px;
      }

      .icon-wrapper {
        padding: 12px 0;

        .gh-icon {
          width: 24px;
          height: 28px;
          border: 1px solid transparent;
          &:hover {
            cursor: grab;
          }

          &:target,
          &:active,
          &:focus {
            cursor: grabbing;
          }

          &:before {
            top: 5px;
            left: 3px;
          }
        }

        .gh-font-icon-move {
          display: none;
          border: 1px solid transparent;
          border-radius: 4px;
          width: 24px;
          height: 28px;
          font-size: 16px;

          &:hover {
            border-color: @grey-30;
            cursor: grab;
          }

          &:target,
          &:active,
          &:focus {
            cursor: grabbing;
          }

          &:before {
            top: 5px;
            left: 3px;
          }
        }
      }

      .content-wrapper {
        display: flex;
        padding: 12px 0;
        width: calc(100% - 34px);

        & > * {
          margin-right: 10px;
        }

        & > :last-child {
          margin-right: 0;
        }
      }

      .gh-font-icon-menu {
        border: 1px solid transparent;
        width: 24px;
        height: 28px;
        color: @ink-60-v3;
        margin-right: 10px;
        padding: 15px;

        &:hover {
          background-color: rgba(2, 20, 33, 0.1);
        }
      }

      .gh-ui-actions-dd.open {
        .gh-font-icon-menu {
          background: @grey-5;
        }
      }
    }

    &.hidden {
      display: none;
    }
  }

  .dndDragging {
    .gh-siv-section-item {
      .icon-wrapper {
        .gh-font-icon-move {
          border-color: transparent;

          &:hover {
            border-color: transparent;
          }
        }
      }
    }
  }

  // Prevent drag icon to appear outside the active element
  .no-pointer-events {
    pointer-events: none;
  }

  .dndDraggingSource {
    box-shadow: none;
    height: 1px;
    width: 100%;
    overflow: hidden;
    // display: none !important; The rule breaks the index calculation of the npm package
  }

  .dndDragging:not(.dndDraggingSource) {
    opacity: 1;
    border-radius: 4px;
    box-shadow:
      0px 5px 10px rgba(9, 30, 66, 0.25),
      0px 0px 1px rgba(63, 87, 110, 0.31);
    background: @blue-10-v3;

    pointer-events: all;
    cursor: grabbing;
    &:hover,
    &:focus,
    &:target,
    &:visited {
      cursor: grabbing;
    }
  }

  .dndPlaceholder {
    display: block;
    height: 60px;
    width: 100%;
    box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%237485A2FF' stroke-width='2' stroke-dasharray='7%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
    margin-top: 10px;
  }
}
