@import (less) "../../../../../node_modules/smartbanner.js/dist/smartbanner.css";

.smartbanner {
  position: fixed;
  height: 55px;
  z-index: 9998;
  -webkit-text-size-adjust: none;

  &.smartbanner--android,
  &.smartbanner--ios {
    background: #20354f;
    box-shadow: inset 0 5px 0 #20354f;

    .smartbanner__button {
      margin-right: 20px;
      top: 12px;
      right: 50px;
      color: #fff;
      min-width: unset;
      border-color: #fff;
      box-shadow: none;
      background: #fff;
      border-radius: 5px !important;
      padding: 0 10px !important;
    }

    .smartbanner__exit {
      left: initial;
      right: 40px;
      margin-right: 0;
      box-shadow: none;
      background: none;
      top: calc(50% - 7px);
      width: 17px;
      height: 17px;
      border-radius: 14px;
      color: #b1b1b3;
      font-family: "ArialRoundedMTBold", Arial;
      font-size: 20px;
      line-height: 17px;
      text-shadow: 0 1px 1px #000;
    }

    .smartbanner__exit::before,
    .smartbanner__exit::after {
      background: #fff;
      top: 3px;
      left: 8px;
      width: 2px;
      height: 11px;
    }

    .smartbanner__button__label {
      font-family: "SF Pro Text", sans-serif;
      background: #ffffff;
      border-radius: 1000px;
      color: #021421;
      width: 74px;
      height: 32px;
      padding: 0 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;

      &:hover {
        background: #ffffff;
      }
    }
  }
}

.smartbanner__info {
  top: -5px;
  left: 98px;
  font-weight: bold;
}

.smartbanner__info__title {
  font-size: 13px;
  color: #fff;
  font-weight: bold;
}

.smartbanner__info__author,
.smartbanner__info__price {
  font-size: 11px;
  line-height: 1.2em;
  color: #ccc;
  text-shadow: 0 1px 2px #000;
}

.smartbanner__icon {
  top: 9px;
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
