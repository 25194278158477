.gh-datepicker {
  min-width: 350px;

  * {
    outline: none;
    box-shadow: none;
  }

  table {
    min-width: 350px;

    td {
      border-right: 1px solid @brand-light;

      &:last-child {
        border-right: 0;
      }
    }

    th {
      padding: 2px 3px;

      small {
        font-weight: @regular;
      }
    }
  }

  .h6 {
    color: @brand-subtler;
  }

  .btn-default {
    border: none;
    padding: 6px 11px;
    font-size: 14px;
    color: @brand-dark;
    box-shadow: none;
    border-radius: 3px;

    &[disabled="disabled"] {
      opacity: 0.3;
    }

    &.active {
      background: @brand-light;
      color: @brand-dark;
      box-shadow: none;
      box-shadow: none;
    }

    &.btn-info {
      box-shadow: none;
      background: @brand-primary;
      border-radius: 0;
      box-shadow: none;

      &:hover,
      &.active {
        background: @brand-primary;
        span {
          color: #fff;
        }
      }
      span {
        color: #fff;
      }
    }
  }

  .btn-sm {
    padding: 6px 11px;
    font-size: 14px;
    color: @brand-dark;

    &:hover {
      background: @brand-light;
    }

    .text-muted {
      color: lighten(@brand-dark, 50%);
    }
  }
}

.datepicker.bright {
  * {
    outline: none;
  }

  table {
    width: 300px;

    .h6 {
      font-style: normal;
      font-size: 11px;
      opacity: 0.2;
    }

    small {
      text-transform: uppercase;
      font-weight: @regular;
      color: #ccc;
    }

    button {
      background: none;
      border: 0;
      border-radius: 0;
      padding: 8px 0;
      font-size: 12px;
      font-weight: @light;
      width: 100%;

      &:hover {
        color: #fff;
        background: fade(#fff, 5%);
      }

      strong {
        color: #000;
        font-weight: @light;
        font-size: 16px;
      }

      &.btn-default {
        color: #666;
        box-shadow: none;

        &:focus,
        &.focus {
          background: none;
        }

        &:hover {
          color: fade(@brand-dark, 50%);
          background: #f5f5f5;
        }

        &.active {
          background: #eee;
          color: @brand-dark;
        }

        &.btn-info {
          background: @brand-primary;
          color: #fff;
          box-shadow: none;

          .text-info {
            color: #fff;
          }
        }

        .text-muted {
          color: fade(@brand-dark, 40%);
        }

        .text-info {
          font-weight: @bold;
          color: @brand-dark;
        }
      }
    }
  }

  &.tight table {
    width: auto;
    display: block;
    padding: 15px;
    border: 1px solid #eee;

    tr {
      th {
        small {
          font-size: 10px;
        }
      }
    }

    button {
      font-weight: @regular;
      padding: 3px 7px;
      color: #000;

      &[disabled="disabled"] {
        opacity: 0.5;
        font-weight: @light;
      }
    }
  }

  &.wide {
    table {
      width: 420px;
    }
  }
}
