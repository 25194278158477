.form-dark {
  label {
    color: #fff;
  }

  .well {
    background: darken(@brand-dark, 9%);
    border: 1px solid lighten(@brand-dark, 3%);
  }

  hr {
    border-color: fade(#fff, 10%);
  }
  .form-control {
    background: @brand-dark;
    font-size: 17px;
    font-weight: @light;
    color: #fff;
    border-radius: 2px;
    border: 1px solid lighten(@brand-dark, 15%);

    &::-webkit-input-placeholder {
      color: fade(#fff, 20%);
      font-weight: @light;
    }
    &:-moz-placeholder {
      color: fade(#fff, 20%);
      font-weight: @light;
    }
    &::-moz-placeholder {
      color: fade(#fff, 20%);
      font-weight: @light;
    }
    &:-ms-input-placeholder {
      color: fade(#fff, 20%);
      font-weight: @light;
    }

    &:focus {
      border-color: lighten(@brand-dark, 35%);
    }
  }

  .input-group-addon + .form-control {
    padding-left: 10px;
  }

  .help-block {
    color: #fff;
    font-size: 15px;
    color: fade(#fff, 50%);
    font-weight: @light;
  }

  .error {
    color: @brand-danger;
    margin-top: 5px;
  }

  .input-group-addon {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: lighten(@brand-dark, 15%);
    border-color: lighten(@brand-dark, 15%);
    background: none;
    color: #fff;
    font-size: 24px;
  }
}

.form-group {
  label {
    font-weight: @light;
  }
}
