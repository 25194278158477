@font-face {
  font-family: "Axiforma-Bold";
  src: url("../../../wwwroot/fe/fonts/axiforma/axiforma-bold/font.woff2")
    format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma-Medium";
  src: url("../../../wwwroot/fe/fonts/axiforma/axiforma-medium/font.woff2")
    format("woff2");
  font-display: swap;
}
