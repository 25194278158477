/*------------------------
	font icons
-------------------------*/

.nc-icon-outline.travel_axe:before {
  content: "\eb28";
}

.nc-icon-outline.travel_backpack:before {
  content: "\eb29";
}

.nc-icon-outline.travel_bag:before {
  content: "\eb2a";
}

.nc-icon-outline.travel_barbecue:before {
  content: "\eb2b";
}

.nc-icon-outline.travel_beach-umbrella:before {
  content: "\eb2c";
}

.nc-icon-outline.travel_berlin:before {
  content: "\eb2d";
}

.nc-icon-outline.travel_binocular:before {
  content: "\eb2e";
}

.nc-icon-outline.travel_camper:before {
  content: "\eb2f";
}

.nc-icon-outline.travel_camping:before {
  content: "\eb30";
}

.nc-icon-outline.travel_castle:before {
  content: "\eb31";
}

.nc-icon-outline.travel_china:before {
  content: "\eb32";
}

.nc-icon-outline.travel_church:before {
  content: "\eb33";
}

.nc-icon-outline.travel_drink:before {
  content: "\eb34";
}

.nc-icon-outline.travel_explore:before {
  content: "\eb35";
}

.nc-icon-outline.travel_fire:before {
  content: "\eb36";
}

.nc-icon-outline.travel_hotel-bell:before {
  content: "\eb37";
}

.nc-icon-outline.travel_hotel-symbol:before {
  content: "\eb38";
}

.nc-icon-outline.travel_hotel:before {
  content: "\eb39";
}

.nc-icon-outline.travel_hut:before {
  content: "\eb3a";
}

.nc-icon-outline.travel_igloo:before {
  content: "\eb3b";
}

.nc-icon-outline.travel_info:before {
  content: "\eb3c";
}

.nc-icon-outline.travel_istanbul:before {
  content: "\eb3d";
}

.nc-icon-outline.travel_jellyfish:before {
  content: "\eb3e";
}

.nc-icon-outline.travel_lamp:before {
  content: "\eb3f";
}

.nc-icon-outline.travel_lighthouse:before {
  content: "\eb40";
}

.nc-icon-outline.travel_london:before {
  content: "\eb41";
}

.nc-icon-outline.travel_luggage:before {
  content: "\eb42";
}

.nc-icon-outline.travel_mosque:before {
  content: "\eb43";
}

.nc-icon-outline.travel_ny:before {
  content: "\eb44";
}

.nc-icon-outline.travel_octopus:before {
  content: "\eb45";
}

.nc-icon-outline.travel_paris-tower:before {
  content: "\eb46";
}

.nc-icon-outline.travel_passport:before {
  content: "\eb47";
}

.nc-icon-outline.travel_pickaxe:before {
  content: "\eb48";
}

.nc-icon-outline.travel_pool:before {
  content: "\eb49";
}

.nc-icon-outline.travel_pyramid:before {
  content: "\eb4a";
}

.nc-icon-outline.travel_rackets:before {
  content: "\eb4b";
}

.nc-icon-outline.travel_rio:before {
  content: "\eb4c";
}

.nc-icon-outline.travel_road-sign-left:before {
  content: "\eb4d";
}

.nc-icon-outline.travel_road-sign-right:before {
  content: "\eb4e";
}

.nc-icon-outline.travel_rome:before {
  content: "\eb4f";
}

.nc-icon-outline.travel_rowing:before {
  content: "\eb50";
}

.nc-icon-outline.travel_sea-mask:before {
  content: "\eb51";
}

.nc-icon-outline.travel_sf-bridge:before {
  content: "\eb52";
}

.nc-icon-outline.travel_shark:before {
  content: "\eb53";
}

.nc-icon-outline.travel_spa:before {
  content: "\eb54";
}

.nc-icon-outline.travel_sunglasses:before {
  content: "\eb55";
}

.nc-icon-outline.travel_surf:before {
  content: "\eb56";
}

.nc-icon-outline.travel_swimsuit:before {
  content: "\eb57";
}

.nc-icon-outline.travel_swimwear:before {
  content: "\eb58";
}

.nc-icon-outline.travel_swiss-knife:before {
  content: "\eb59";
}

.nc-icon-outline.travel_temple-02:before {
  content: "\eb5a";
}

.nc-icon-outline.travel_temple-25:before {
  content: "\eb5b";
}

.nc-icon-outline.travel_trolley:before {
  content: "\eb5c";
}

.nc-icon-outline.travel_white-house:before {
  content: "\eb5d";
}

.nc-icon-outline.travel_world:before {
  content: "\eb5e";
}

.nc-icon-outline.travel_worldmap:before {
  content: "\eb5f";
}

.nc-icon-outline.food_alcohol:before {
  content: "\eb60";
}

.nc-icon-outline.food_apple:before {
  content: "\eb61";
}

.nc-icon-outline.food_baby:before {
  content: "\eb62";
}

.nc-icon-outline.food_bacon:before {
  content: "\eb63";
}

.nc-icon-outline.food_baguette:before {
  content: "\eb64";
}

.nc-icon-outline.food_banana:before {
  content: "\eb65";
}

.nc-icon-outline.food_barbecue-02:before {
  content: "\eb66";
}

.nc-icon-outline.food_barbecue-15:before {
  content: "\eb67";
}

.nc-icon-outline.food_barbecue-tools:before {
  content: "\eb68";
}

.nc-icon-outline.food_beer-95:before {
  content: "\eb69";
}

.nc-icon-outline.food_beer-96:before {
  content: "\eb6a";
}

.nc-icon-outline.food_beverage:before {
  content: "\eb6b";
}

.nc-icon-outline.food_bottle-wine:before {
  content: "\eb6c";
}

.nc-icon-outline.food_bottle:before {
  content: "\eb6d";
}

.nc-icon-outline.food_bowl:before {
  content: "\eb6e";
}

.nc-icon-outline.food_bread:before {
  content: "\eb6f";
}

.nc-icon-outline.food_broccoli:before {
  content: "\eb70";
}

.nc-icon-outline.food_cake-13:before {
  content: "\eb71";
}

.nc-icon-outline.food_cake-100:before {
  content: "\eb72";
}

.nc-icon-outline.food_cake-slice:before {
  content: "\eb73";
}

.nc-icon-outline.food_candle:before {
  content: "\eb74";
}

.nc-icon-outline.food_candy:before {
  content: "\eb75";
}

.nc-icon-outline.food_carrot:before {
  content: "\eb76";
}

.nc-icon-outline.food_champagne:before {
  content: "\eb77";
}

.nc-icon-outline.food_cheese-24:before {
  content: "\eb78";
}

.nc-icon-outline.food_cheese-87:before {
  content: "\eb79";
}

.nc-icon-outline.food_cheeseburger:before {
  content: "\eb7a";
}

.nc-icon-outline.food_chef-hat:before {
  content: "\eb7b";
}

.nc-icon-outline.food_cherry:before {
  content: "\eb7c";
}

.nc-icon-outline.food_chicken:before {
  content: "\eb7d";
}

.nc-icon-outline.food_chili:before {
  content: "\eb7e";
}

.nc-icon-outline.food_chinese:before {
  content: "\eb7f";
}

.nc-icon-outline.food_chips:before {
  content: "\eb80";
}

.nc-icon-outline.food_chocolate:before {
  content: "\eb81";
}

.nc-icon-outline.food_cocktail:before {
  content: "\eb82";
}

.nc-icon-outline.food_coffe-long:before {
  content: "\eb83";
}

.nc-icon-outline.food_coffee-long:before {
  content: "\eb84";
}

.nc-icon-outline.food_coffee:before {
  content: "\eb85";
}

.nc-icon-outline.food_cookies:before {
  content: "\eb86";
}

.nc-icon-outline.food_course:before {
  content: "\eb87";
}

.nc-icon-outline.food_crab:before {
  content: "\eb88";
}

.nc-icon-outline.food_croissant:before {
  content: "\eb89";
}

.nc-icon-outline.food_cutlery-75:before {
  content: "\eb8a";
}

.nc-icon-outline.food_cutlery-76:before {
  content: "\eb8b";
}

.nc-icon-outline.food_cutlery-77:before {
  content: "\eb8c";
}

.nc-icon-outline.food_dishwasher:before {
  content: "\eb8d";
}

.nc-icon-outline.food_donut:before {
  content: "\eb8e";
}

.nc-icon-outline.food_drink:before {
  content: "\eb8f";
}

.nc-icon-outline.food_egg:before {
  content: "\eb90";
}

.nc-icon-outline.food_energy-drink:before {
  content: "\eb91";
}

.nc-icon-outline.food_fish:before {
  content: "\eb92";
}

.nc-icon-outline.food_fishbone:before {
  content: "\eb93";
}

.nc-icon-outline.food_fridge:before {
  content: "\eb94";
}

.nc-icon-outline.food_glass:before {
  content: "\eb95";
}

.nc-icon-outline.food_grape:before {
  content: "\eb96";
}

.nc-icon-outline.food_hob:before {
  content: "\eb97";
}

.nc-icon-outline.food_hot-dog:before {
  content: "\eb98";
}

.nc-icon-outline.food_ice-cream-22:before {
  content: "\eb99";
}

.nc-icon-outline.food_ice-cream-72:before {
  content: "\eb9a";
}

.nc-icon-outline.food_jam:before {
  content: "\eb9b";
}

.nc-icon-outline.food_kettle:before {
  content: "\eb9c";
}

.nc-icon-outline.food_kitchen-fan:before {
  content: "\eb9d";
}

.nc-icon-outline.food_knife:before {
  content: "\eb9e";
}

.nc-icon-outline.food_lemon-slice:before {
  content: "\eb9f";
}

.nc-icon-outline.food_lighter:before {
  content: "\eba0";
}

.nc-icon-outline.food_lobster:before {
  content: "\eba1";
}

.nc-icon-outline.food_matches:before {
  content: "\eba2";
}

.nc-icon-outline.food_measuring-cup:before {
  content: "\eba3";
}

.nc-icon-outline.food_meat-spit:before {
  content: "\eba4";
}

.nc-icon-outline.food_microwave:before {
  content: "\eba5";
}

.nc-icon-outline.food_milk:before {
  content: "\eba6";
}

.nc-icon-outline.food_moka:before {
  content: "\eba7";
}

.nc-icon-outline.food_muffin:before {
  content: "\eba8";
}

.nc-icon-outline.food_mug:before {
  content: "\eba9";
}

.nc-icon-outline.food_oven:before {
  content: "\ebaa";
}

.nc-icon-outline.food_pan:before {
  content: "\ebab";
}

.nc-icon-outline.food_pizza-slice:before {
  content: "\ebac";
}

.nc-icon-outline.food_pizza:before {
  content: "\ebad";
}

.nc-icon-outline.food_plate:before {
  content: "\ebae";
}

.nc-icon-outline.food_pot:before {
  content: "\ebaf";
}

.nc-icon-outline.food_prosciutto:before {
  content: "\ebb0";
}

.nc-icon-outline.food_recipe-book-46:before {
  content: "\ebb1";
}

.nc-icon-outline.food_recipe-book-47:before {
  content: "\ebb2";
}

.nc-icon-outline.food_rolling-pin:before {
  content: "\ebb3";
}

.nc-icon-outline.food_salt:before {
  content: "\ebb4";
}

.nc-icon-outline.food_sausage:before {
  content: "\ebb5";
}

.nc-icon-outline.food_scale:before {
  content: "\ebb6";
}

.nc-icon-outline.food_scotch:before {
  content: "\ebb7";
}

.nc-icon-outline.food_shrimp:before {
  content: "\ebb8";
}

.nc-icon-outline.food_steak:before {
  content: "\ebb9";
}

.nc-icon-outline.food_store:before {
  content: "\ebba";
}

.nc-icon-outline.food_strawberry:before {
  content: "\ebbb";
}

.nc-icon-outline.food_sushi:before {
  content: "\ebbc";
}

.nc-icon-outline.food_tacos:before {
  content: "\ebbd";
}

.nc-icon-outline.food_tea:before {
  content: "\ebbe";
}

.nc-icon-outline.food_temperature:before {
  content: "\ebbf";
}

.nc-icon-outline.food_vest-07:before {
  content: "\ebc0";
}

.nc-icon-outline.food_vest-31:before {
  content: "\ebc1";
}

.nc-icon-outline.food_watermelon:before {
  content: "\ebc2";
}

.nc-icon-outline.food_whisk:before {
  content: "\ebc3";
}

.nc-icon-outline.emoticons_alien:before {
  content: "\ebc4";
}

.nc-icon-outline.emoticons_angry-10:before {
  content: "\ebc5";
}

.nc-icon-outline.emoticons_angry-44:before {
  content: "\ebc6";
}

.nc-icon-outline.emoticons_big-eyes:before {
  content: "\ebc7";
}

.nc-icon-outline.emoticons_big-smile:before {
  content: "\ebc8";
}

.nc-icon-outline.emoticons_bigmouth:before {
  content: "\ebc9";
}

.nc-icon-outline.emoticons_bleah:before {
  content: "\ebca";
}

.nc-icon-outline.emoticons_blind:before {
  content: "\ebcb";
}

.nc-icon-outline.emoticons_bomb:before {
  content: "\ebcc";
}

.nc-icon-outline.emoticons_bored:before {
  content: "\ebcd";
}

.nc-icon-outline.emoticons_cake:before {
  content: "\ebce";
}

.nc-icon-outline.emoticons_cry-15:before {
  content: "\ebcf";
}

.nc-icon-outline.emoticons_cry-57:before {
  content: "\ebd0";
}

.nc-icon-outline.emoticons_cute:before {
  content: "\ebd1";
}

.nc-icon-outline.emoticons_devil:before {
  content: "\ebd2";
}

.nc-icon-outline.emoticons_disgusted:before {
  content: "\ebd3";
}

.nc-icon-outline.emoticons_fist:before {
  content: "\ebd4";
}

.nc-icon-outline.emoticons_ghost:before {
  content: "\ebd5";
}

.nc-icon-outline.emoticons_hannibal:before {
  content: "\ebd6";
}

.nc-icon-outline.emoticons_happy-sun:before {
  content: "\ebd7";
}

.nc-icon-outline.emoticons_kid:before {
  content: "\ebd8";
}

.nc-icon-outline.emoticons_kiss:before {
  content: "\ebd9";
}

.nc-icon-outline.emoticons_laugh-17:before {
  content: "\ebda";
}

.nc-icon-outline.emoticons_laugh-35:before {
  content: "\ebdb";
}

.nc-icon-outline.emoticons_like-no:before {
  content: "\ebdc";
}

.nc-icon-outline.emoticons_like:before {
  content: "\ebdd";
}

.nc-icon-outline.emoticons_mad-12:before {
  content: "\ebde";
}

.nc-icon-outline.emoticons_mad-58:before {
  content: "\ebdf";
}

.nc-icon-outline.emoticons_malicious:before {
  content: "\ebe0";
}

.nc-icon-outline.emoticons_manga-62:before {
  content: "\ebe1";
}

.nc-icon-outline.emoticons_manga-63:before {
  content: "\ebe2";
}

.nc-icon-outline.emoticons_monster:before {
  content: "\ebe3";
}

.nc-icon-outline.emoticons_nerd-22:before {
  content: "\ebe4";
}

.nc-icon-outline.emoticons_nerd-23:before {
  content: "\ebe5";
}

.nc-icon-outline.emoticons_ninja:before {
  content: "\ebe6";
}

.nc-icon-outline.emoticons_no-words:before {
  content: "\ebe7";
}

.nc-icon-outline.emoticons_parrot:before {
  content: "\ebe8";
}

.nc-icon-outline.emoticons_penguin:before {
  content: "\ebe9";
}

.nc-icon-outline.emoticons_pirate:before {
  content: "\ebea";
}

.nc-icon-outline.emoticons_poop:before {
  content: "\ebeb";
}

.nc-icon-outline.emoticons_puzzled:before {
  content: "\ebec";
}

.nc-icon-outline.emoticons_quite-happy:before {
  content: "\ebed";
}

.nc-icon-outline.emoticons_robot:before {
  content: "\ebee";
}

.nc-icon-outline.emoticons_rock:before {
  content: "\ebef";
}

.nc-icon-outline.emoticons_sad:before {
  content: "\ebf0";
}

.nc-icon-outline.emoticons_satisfied:before {
  content: "\ebf1";
}

.nc-icon-outline.emoticons_shark:before {
  content: "\ebf2";
}

.nc-icon-outline.emoticons_shy:before {
  content: "\ebf3";
}

.nc-icon-outline.emoticons_sick:before {
  content: "\ebf4";
}

.nc-icon-outline.emoticons_silly:before {
  content: "\ebf5";
}

.nc-icon-outline.emoticons_skull:before {
  content: "\ebf6";
}

.nc-icon-outline.emoticons_sleep:before {
  content: "\ebf7";
}

.nc-icon-outline.emoticons_sloth:before {
  content: "\ebf8";
}

.nc-icon-outline.emoticons_smart:before {
  content: "\ebf9";
}

.nc-icon-outline.emoticons_smile:before {
  content: "\ebfa";
}

.nc-icon-outline.emoticons_soldier:before {
  content: "\ebfb";
}

.nc-icon-outline.emoticons_speechless:before {
  content: "\ebfc";
}

.nc-icon-outline.emoticons_spiteful:before {
  content: "\ebfd";
}

.nc-icon-outline.emoticons_sunglasses-48:before {
  content: "\ebfe";
}

.nc-icon-outline.emoticons_sunglasses-49:before {
  content: "\ebff";
}

.nc-icon-outline.emoticons_surprise:before {
  content: "\ec00";
}

.nc-icon-outline.emoticons_upset-13:before {
  content: "\ec01";
}

.nc-icon-outline.emoticons_upset-14:before {
  content: "\ec02";
}

.nc-icon-outline.emoticons_virus:before {
  content: "\ec03";
}

.nc-icon-outline.emoticons_what:before {
  content: "\ec04";
}

.nc-icon-outline.emoticons_whiskers:before {
  content: "\ec05";
}

.nc-icon-outline.emoticons_wink-06:before {
  content: "\ec06";
}

.nc-icon-outline.emoticons_wink-11:before {
  content: "\ec07";
}

.nc-icon-outline.emoticons_wink-69:before {
  content: "\ec08";
}

.nc-icon-outline.weather_celsius:before {
  content: "\e600";
}

.nc-icon-outline.weather_cloud-13:before {
  content: "\e601";
}

.nc-icon-outline.weather_cloud-14:before {
  content: "\e602";
}

.nc-icon-outline.weather_cloud-drop:before {
  content: "\e603";
}

.nc-icon-outline.weather_cloud-fog-31:before {
  content: "\e604";
}

.nc-icon-outline.weather_cloud-fog-32:before {
  content: "\e605";
}

.nc-icon-outline.weather_cloud-hail:before {
  content: "\e606";
}

.nc-icon-outline.weather_cloud-light:before {
  content: "\e607";
}

.nc-icon-outline.weather_cloud-moon:before {
  content: "\e608";
}

.nc-icon-outline.weather_cloud-rain:before {
  content: "\e609";
}

.nc-icon-outline.weather_cloud-rainbow:before {
  content: "\e60a";
}

.nc-icon-outline.weather_cloud-snow-34:before {
  content: "\e60b";
}

.nc-icon-outline.weather_cloud-snow-42:before {
  content: "\e60c";
}

.nc-icon-outline.weather_cloud-sun-17:before {
  content: "\e60d";
}

.nc-icon-outline.weather_cloud-sun-19:before {
  content: "\e60e";
}

.nc-icon-outline.weather_compass:before {
  content: "\e60f";
}

.nc-icon-outline.weather_drop-12:before {
  content: "\e610";
}

.nc-icon-outline.weather_drop-15:before {
  content: "\e611";
}

.nc-icon-outline.weather_drops:before {
  content: "\e612";
}

.nc-icon-outline.weather_eclipse:before {
  content: "\e613";
}

.nc-icon-outline.weather_fahrenheit:before {
  content: "\e614";
}

.nc-icon-outline.weather_fog:before {
  content: "\e615";
}

.nc-icon-outline.weather_forecast:before {
  content: "\e616";
}

.nc-icon-outline.weather_hurricane-44:before {
  content: "\e617";
}

.nc-icon-outline.weather_hurricane-45:before {
  content: "\e618";
}

.nc-icon-outline.weather_moon-cloud-drop:before {
  content: "\e619";
}

.nc-icon-outline.weather_moon-cloud-fog:before {
  content: "\e61a";
}

.nc-icon-outline.weather_moon-cloud-hail:before {
  content: "\e61b";
}

.nc-icon-outline.weather_moon-cloud-light:before {
  content: "\e61c";
}

.nc-icon-outline.weather_moon-cloud-rain:before {
  content: "\e61d";
}

.nc-icon-outline.weather_moon-cloud-snow-61:before {
  content: "\e61e";
}

.nc-icon-outline.weather_moon-cloud-snow-62:before {
  content: "\e61f";
}

.nc-icon-outline.weather_moon-fog:before {
  content: "\e620";
}

.nc-icon-outline.weather_moon-full:before {
  content: "\e621";
}

.nc-icon-outline.weather_moon-stars:before {
  content: "\e622";
}

.nc-icon-outline.weather_moon:before {
  content: "\e623";
}

.nc-icon-outline.weather_rain-hail:before {
  content: "\e624";
}

.nc-icon-outline.weather_rain:before {
  content: "\e625";
}

.nc-icon-outline.weather_rainbow:before {
  content: "\e626";
}

.nc-icon-outline.weather_snow:before {
  content: "\e627";
}

.nc-icon-outline.weather_sun-cloud-drop:before {
  content: "\e628";
}

.nc-icon-outline.weather_sun-cloud-fog:before {
  content: "\e629";
}

.nc-icon-outline.weather_sun-cloud-hail:before {
  content: "\e62a";
}

.nc-icon-outline.weather_sun-cloud-light:before {
  content: "\e62b";
}

.nc-icon-outline.weather_sun-cloud-rain:before {
  content: "\e62c";
}

.nc-icon-outline.weather_sun-cloud-snow-54:before {
  content: "\e62d";
}

.nc-icon-outline.weather_sun-cloud-snow-55:before {
  content: "\e62e";
}

.nc-icon-outline.weather_sun-cloud:before {
  content: "\e62f";
}

.nc-icon-outline.weather_sun-fog-29:before {
  content: "\e630";
}

.nc-icon-outline.weather_sun-fog-30:before {
  content: "\e631";
}

.nc-icon-outline.weather_sun-fog-43:before {
  content: "\e632";
}

.nc-icon-outline.weather_sun:before {
  content: "\e633";
}

.nc-icon-outline.weather_wind:before {
  content: "\e634";
}

.nc-icon-outline.users_add-27:before {
  content: "\e635";
}

.nc-icon-outline.users_add-29:before {
  content: "\e636";
}

.nc-icon-outline.users_badge-13:before {
  content: "\e637";
}

.nc-icon-outline.users_badge-14:before {
  content: "\e638";
}

.nc-icon-outline.users_badge-15:before {
  content: "\e639";
}

.nc-icon-outline.users_circle-08:before {
  content: "\e63a";
}

.nc-icon-outline.users_circle-09:before {
  content: "\e63b";
}

.nc-icon-outline.users_circle-10:before {
  content: "\e63c";
}

.nc-icon-outline.users_contacts:before {
  content: "\e63d";
}

.nc-icon-outline.users_delete-28:before {
  content: "\e63e";
}

.nc-icon-outline.users_delete-30:before {
  content: "\e63f";
}

.nc-icon-outline.users_man-20:before {
  content: "\e640";
}

.nc-icon-outline.users_man-23:before {
  content: "\e641";
}

.nc-icon-outline.users_man-glasses:before {
  content: "\e642";
}

.nc-icon-outline.users_mobile-contact:before {
  content: "\e643";
}

.nc-icon-outline.users_multiple-11:before {
  content: "\e644";
}

.nc-icon-outline.users_multiple-19:before {
  content: "\e645";
}

.nc-icon-outline.users_network:before {
  content: "\e646";
}

.nc-icon-outline.users_parent:before {
  content: "\e647";
}

.nc-icon-outline.users_single-01:before {
  content: "\e648";
}

.nc-icon-outline.users_single-02:before {
  content: "\e649";
}

.nc-icon-outline.users_single-03:before {
  content: "\e64a";
}

.nc-icon-outline.users_single-04:before {
  content: "\e64b";
}

.nc-icon-outline.users_single-05:before {
  content: "\e64c";
}

.nc-icon-outline.users_single-body:before {
  content: "\e64d";
}

.nc-icon-outline.users_single-position:before {
  content: "\e64e";
}

.nc-icon-outline.users_square-31:before {
  content: "\e64f";
}

.nc-icon-outline.users_square-32:before {
  content: "\e650";
}

.nc-icon-outline.users_square-33:before {
  content: "\e651";
}

.nc-icon-outline.users_woman-21:before {
  content: "\e652";
}

.nc-icon-outline.users_woman-24:before {
  content: "\e653";
}

.nc-icon-outline.users_woman-25:before {
  content: "\e654";
}

.nc-icon-outline.users_woman-34:before {
  content: "\e655";
}

.nc-icon-outline.users_woman-35:before {
  content: "\e656";
}

.nc-icon-outline.users_woman-man:before {
  content: "\e657";
}

.nc-icon-outline.ui-1_analytics-88:before {
  content: "\e658";
}

.nc-icon-outline.ui-1_analytics-89:before {
  content: "\e659";
}

.nc-icon-outline.ui-1_attach-86:before {
  content: "\e65a";
}

.nc-icon-outline.ui-1_attach-87:before {
  content: "\e65b";
}

.nc-icon-outline.ui-1_bell-53:before {
  content: "\e65c";
}

.nc-icon-outline.ui-1_bell-54:before {
  content: "\e65d";
}

.nc-icon-outline.ui-1_bell-55:before {
  content: "\e65e";
}

.nc-icon-outline.ui-1_bold-add:before {
  content: "\e65f";
}

.nc-icon-outline.ui-1_bold-delete:before {
  content: "\e660";
}

.nc-icon-outline.ui-1_bold-remove:before {
  content: "\e661";
}

.nc-icon-outline.ui-1_bookmark-add:before {
  content: "\e662";
}

.nc-icon-outline.ui-1_bookmark-remove:before {
  content: "\e663";
}

.nc-icon-outline.ui-1_calendar-57:before {
  content: "\e664";
}

.nc-icon-outline.ui-1_calendar-60:before {
  content: "\e665";
}

.nc-icon-outline.ui-1_calendar-check-59:before {
  content: "\e666";
}

.nc-icon-outline.ui-1_calendar-check-62:before {
  content: "\e667";
}

.nc-icon-outline.ui-1_calendar-grid-58:before {
  content: "\e668";
}

.nc-icon-outline.ui-1_calendar-grid-61:before {
  content: "\e669";
}

.nc-icon-outline.ui-1_check-bold:before {
  content: "\e66a";
}

.nc-icon-outline.ui-1_check-circle-07:before {
  content: "\e66b";
}

.nc-icon-outline.ui-1_check-circle-08:before {
  content: "\e66c";
}

.nc-icon-outline.ui-1_check-curve:before {
  content: "\e66d";
}

.nc-icon-outline.ui-1_check-simple:before {
  content: "\e66e";
}

.nc-icon-outline.ui-1_check-small:before {
  content: "\e66f";
}

.nc-icon-outline.ui-1_check-square-09:before {
  content: "\e670";
}

.nc-icon-outline.ui-1_check-square-11:before {
  content: "\e671";
}

.nc-icon-outline.ui-1_check:before {
  content: "\e672";
}

.nc-icon-outline.ui-1_circle-add:before {
  content: "\e673";
}

.nc-icon-outline.ui-1_circle-bold-add:before {
  content: "\e674";
}

.nc-icon-outline.ui-1_circle-bold-remove:before {
  content: "\e675";
}

.nc-icon-outline.ui-1_circle-delete:before {
  content: "\e676";
}

.nc-icon-outline.ui-1_circle-remove:before {
  content: "\e677";
}

.nc-icon-outline.ui-1_dashboard-29:before {
  content: "\e678";
}

.nc-icon-outline.ui-1_dashboard-30:before {
  content: "\e679";
}

.nc-icon-outline.ui-1_dashboard-half:before {
  content: "\e67a";
}

.nc-icon-outline.ui-1_dashboard-level:before {
  content: "\e67b";
}

.nc-icon-outline.ui-1_database:before {
  content: "\e67c";
}

.nc-icon-outline.ui-1_drop:before {
  content: "\e67d";
}

.nc-icon-outline.ui-1_edit-71:before {
  content: "\e67e";
}

.nc-icon-outline.ui-1_edit-72:before {
  content: "\e67f";
}

.nc-icon-outline.ui-1_edit-73:before {
  content: "\e680";
}

.nc-icon-outline.ui-1_edit-74:before {
  content: "\e681";
}

.nc-icon-outline.ui-1_edit-75:before {
  content: "\e682";
}

.nc-icon-outline.ui-1_edit-76:before {
  content: "\e683";
}

.nc-icon-outline.ui-1_edit-77:before {
  content: "\e684";
}

.nc-icon-outline.ui-1_edit-78:before {
  content: "\e685";
}

.nc-icon-outline.ui-1_email-83:before {
  content: "\e686";
}

.nc-icon-outline.ui-1_email-84:before {
  content: "\e687";
}

.nc-icon-outline.ui-1_email-85:before {
  content: "\e688";
}

.nc-icon-outline.ui-1_eye-17:before {
  content: "\e689";
}

.nc-icon-outline.ui-1_eye-19:before {
  content: "\e68a";
}

.nc-icon-outline.ui-1_eye-ban-18:before {
  content: "\e68b";
}

.nc-icon-outline.ui-1_eye-ban-20:before {
  content: "\e68c";
}

.nc-icon-outline.ui-1_flame:before {
  content: "\e68d";
}

.nc-icon-outline.ui-1_home-51:before {
  content: "\e68e";
}

.nc-icon-outline.ui-1_home-52:before {
  content: "\e68f";
}

.nc-icon-outline.ui-1_home-minimal:before {
  content: "\e690";
}

.nc-icon-outline.ui-1_home-simple:before {
  content: "\e691";
}

.nc-icon-outline.ui-1_leaf-80:before {
  content: "\e692";
}

.nc-icon-outline.ui-1_leaf-81:before {
  content: "\e693";
}

.nc-icon-outline.ui-1_leaf-edit:before {
  content: "\e694";
}

.nc-icon-outline.ui-1_lock-circle-open:before {
  content: "\e695";
}

.nc-icon-outline.ui-1_lock-circle:before {
  content: "\e696";
}

.nc-icon-outline.ui-1_lock-open:before {
  content: "\e697";
}

.nc-icon-outline.ui-1_lock:before {
  content: "\e698";
}

.nc-icon-outline.ui-1_notification-69:before {
  content: "\e699";
}

.nc-icon-outline.ui-1_notification-70:before {
  content: "\e69a";
}

.nc-icon-outline.ui-1_pencil:before {
  content: "\e69b";
}

.nc-icon-outline.ui-1_preferences-circle-rotate:before {
  content: "\e69c";
}

.nc-icon-outline.ui-1_preferences-circle:before {
  content: "\e69d";
}

.nc-icon-outline.ui-1_preferences-container-circle-rotate:before {
  content: "\e69e";
}

.nc-icon-outline.ui-1_preferences-container-circle:before {
  content: "\e69f";
}

.nc-icon-outline.ui-1_preferences-container-rotate:before {
  content: "\e6a0";
}

.nc-icon-outline.ui-1_preferences-container:before {
  content: "\e6a1";
}

.nc-icon-outline.ui-1_preferences-rotate:before {
  content: "\e6a2";
}

.nc-icon-outline.ui-1_preferences:before {
  content: "\e6a3";
}

.nc-icon-outline.ui-1_send:before {
  content: "\e6a4";
}

.nc-icon-outline.ui-1_settings-gear-63:before {
  content: "\e6a5";
}

.nc-icon-outline.ui-1_settings-gear-64:before {
  content: "\e6a6";
}

.nc-icon-outline.ui-1_settings-gear-65:before {
  content: "\e6a7";
}

.nc-icon-outline.ui-1_settings-tool-66:before {
  content: "\e6a8";
}

.nc-icon-outline.ui-1_settings-tool-67:before {
  content: "\e6a9";
}

.nc-icon-outline.ui-1_settings:before {
  content: "\e6aa";
}

.nc-icon-outline.ui-1_simple-add:before {
  content: "\e6ab";
}

.nc-icon-outline.ui-1_simple-delete:before {
  content: "\e6ac";
}

.nc-icon-outline.ui-1_simple-remove:before {
  content: "\e6ad";
}

.nc-icon-outline.ui-1_trash-round:before {
  content: "\e6ae";
}

.nc-icon-outline.ui-1_trash-simple:before {
  content: "\e6af";
}

.nc-icon-outline.ui-1_trash:before {
  content: "\e6b0";
}

.nc-icon-outline.ui-1_ui-03:before {
  content: "\e6b1";
}

.nc-icon-outline.ui-1_ui-04:before {
  content: "\e6b2";
}

.nc-icon-outline.ui-1_zoom-bold-in:before {
  content: "\e6b3";
}

.nc-icon-outline.ui-1_zoom-bold-out:before {
  content: "\e6b4";
}

.nc-icon-outline.ui-1_zoom-bold:before {
  content: "\e6b5";
}

.nc-icon-outline.ui-1_zoom-in:before {
  content: "\e6b6";
}

.nc-icon-outline.ui-1_zoom-out:before {
  content: "\e6b7";
}

.nc-icon-outline.ui-1_zoom-split-in:before {
  content: "\e6b8";
}

.nc-icon-outline.ui-1_zoom-split-out:before {
  content: "\e6b9";
}

.nc-icon-outline.ui-1_zoom-split:before {
  content: "\e6ba";
}

.nc-icon-outline.ui-1_zoom:before {
  content: "\e6bb";
}

.nc-icon-outline.ui-2_alert:before {
  content: "\e6bc";
}

.nc-icon-outline.ui-2_alert-:before {
  content: "\e6bd";
}

.nc-icon-outline.ui-2_alert-circle:before {
  content: "\e6be";
}

.nc-icon-outline.ui-2_alert-circle-:before {
  content: "\e6bf";
}

.nc-icon-outline.ui-2_alert-circle-i:before {
  content: "\e6c0";
}

.nc-icon-outline.ui-2_alert-i:before {
  content: "\e6c1";
}

.nc-icon-outline.ui-2_alert-square:before {
  content: "\e6c2";
}

.nc-icon-outline.ui-2_alert-square-:before {
  content: "\e6c3";
}

.nc-icon-outline.ui-2_alert-square-i:before {
  content: "\e6c4";
}

.nc-icon-outline.ui-2_archive:before {
  content: "\e6c5";
}

.nc-icon-outline.ui-2_ban-bold:before {
  content: "\e6c6";
}

.nc-icon-outline.ui-2_ban:before {
  content: "\e6c7";
}

.nc-icon-outline.ui-2_battery-81:before {
  content: "\e6c8";
}

.nc-icon-outline.ui-2_battery-83:before {
  content: "\e6c9";
}

.nc-icon-outline.ui-2_battery-half:before {
  content: "\e6ca";
}

.nc-icon-outline.ui-2_battery-low:before {
  content: "\e6cb";
}

.nc-icon-outline.ui-2_bluetooth:before {
  content: "\e6cc";
}

.nc-icon-outline.ui-2_book:before {
  content: "\e6cd";
}

.nc-icon-outline.ui-2_chart-bar-52:before {
  content: "\e6ce";
}

.nc-icon-outline.ui-2_chart-bar-53:before {
  content: "\e6cf";
}

.nc-icon-outline.ui-2_chat-content:before {
  content: "\e6d0";
}

.nc-icon-outline.ui-2_chat-round-content:before {
  content: "\e6d1";
}

.nc-icon-outline.ui-2_chat-round:before {
  content: "\e6d2";
}

.nc-icon-outline.ui-2_chat:before {
  content: "\e6d3";
}

.nc-icon-outline.ui-2_circle-bold-delete:before {
  content: "\e6d4";
}

.nc-icon-outline.ui-2_cloud-25:before {
  content: "\e6d5";
}

.nc-icon-outline.ui-2_cloud-26:before {
  content: "\e6d6";
}

.nc-icon-outline.ui-2_disk:before {
  content: "\e6d7";
}

.nc-icon-outline.ui-2_enlarge-57:before {
  content: "\e6d8";
}

.nc-icon-outline.ui-2_enlarge-58:before {
  content: "\e6d9";
}

.nc-icon-outline.ui-2_enlarge-59:before {
  content: "\e6da";
}

.nc-icon-outline.ui-2_fat-add:before {
  content: "\e6db";
}

.nc-icon-outline.ui-2_fat-delete:before {
  content: "\e6dc";
}

.nc-icon-outline.ui-2_fat-remove:before {
  content: "\e6dd";
}

.nc-icon-outline.ui-2_favourite-28:before {
  content: "\e6de";
}

.nc-icon-outline.ui-2_favourite-31:before {
  content: "\e6df";
}

.nc-icon-outline.ui-2_favourite-add-29:before {
  content: "\e6e0";
}

.nc-icon-outline.ui-2_favourite-add-32:before {
  content: "\e6e1";
}

.nc-icon-outline.ui-2_favourite-remove-30:before {
  content: "\e6e2";
}

.nc-icon-outline.ui-2_favourite-remove-33:before {
  content: "\e6e3";
}

.nc-icon-outline.ui-2_filter:before {
  content: "\e6e4";
}

.nc-icon-outline.ui-2_fullsize:before {
  content: "\e6e5";
}

.nc-icon-outline.ui-2_grid-45:before {
  content: "\e6e6";
}

.nc-icon-outline.ui-2_grid-46:before {
  content: "\e6e7";
}

.nc-icon-outline.ui-2_grid-48:before {
  content: "\e6e8";
}

.nc-icon-outline.ui-2_grid-49:before {
  content: "\e6e9";
}

.nc-icon-outline.ui-2_grid-50:before {
  content: "\e6ea";
}

.nc-icon-outline.ui-2_grid-square:before {
  content: "\e6eb";
}

.nc-icon-outline.ui-2_hourglass:before {
  content: "\e6ec";
}

.nc-icon-outline.ui-2_lab:before {
  content: "\e6ed";
}

.nc-icon-outline.ui-2_layers:before {
  content: "\e6ee";
}

.nc-icon-outline.ui-2_like:before {
  content: "\e6ef";
}

.nc-icon-outline.ui-2_link-66:before {
  content: "\e6f0";
}

.nc-icon-outline.ui-2_link-67:before {
  content: "\e6f1";
}

.nc-icon-outline.ui-2_link-68:before {
  content: "\e6f2";
}

.nc-icon-outline.ui-2_link-69:before {
  content: "\e6f3";
}

.nc-icon-outline.ui-2_link-71:before {
  content: "\e6f4";
}

.nc-icon-outline.ui-2_link-72:before {
  content: "\e6f5";
}

.nc-icon-outline.ui-2_link-broken-70:before {
  content: "\e6f6";
}

.nc-icon-outline.ui-2_link-broken-73:before {
  content: "\e6f7";
}

.nc-icon-outline.ui-2_menu-34:before {
  content: "\e6f8";
}

.nc-icon-outline.ui-2_menu-35:before {
  content: "\e6f9";
}

.nc-icon-outline.ui-2_menu-bold:before {
  content: "\e6fa";
}

.nc-icon-outline.ui-2_menu-dots:before {
  content: "\e6fb";
}

.nc-icon-outline.ui-2_menu-square:before {
  content: "\e6fc";
}

.nc-icon-outline.ui-2_node:before {
  content: "\e6fd";
}

.nc-icon-outline.ui-2_paragraph:before {
  content: "\e6fe";
}

.nc-icon-outline.ui-2_phone:before {
  content: "\e6ff";
}

.nc-icon-outline.ui-2_settings-90:before {
  content: "\e700";
}

.nc-icon-outline.ui-2_settings-91:before {
  content: "\e701";
}

.nc-icon-outline.ui-2_share-bold:before {
  content: "\e702";
}

.nc-icon-outline.ui-2_share:before {
  content: "\e703";
}

.nc-icon-outline.ui-2_small-add:before {
  content: "\e704";
}

.nc-icon-outline.ui-2_small-delete:before {
  content: "\e705";
}

.nc-icon-outline.ui-2_small-remove:before {
  content: "\e706";
}

.nc-icon-outline.ui-2_square-add-08:before {
  content: "\e707";
}

.nc-icon-outline.ui-2_square-add-11:before {
  content: "\e708";
}

.nc-icon-outline.ui-2_square-delete-10:before {
  content: "\e709";
}

.nc-icon-outline.ui-2_square-delete-13:before {
  content: "\e70a";
}

.nc-icon-outline.ui-2_square-remove-09:before {
  content: "\e70b";
}

.nc-icon-outline.ui-2_square-remove-12:before {
  content: "\e70c";
}

.nc-icon-outline.ui-2_target:before {
  content: "\e70d";
}

.nc-icon-outline.ui-2_tile-55:before {
  content: "\e70e";
}

.nc-icon-outline.ui-2_tile-56:before {
  content: "\e70f";
}

.nc-icon-outline.ui-2_time-alarm:before {
  content: "\e710";
}

.nc-icon-outline.ui-2_time-clock:before {
  content: "\e711";
}

.nc-icon-outline.ui-2_time-countdown:before {
  content: "\e712";
}

.nc-icon-outline.ui-2_time:before {
  content: "\e713";
}

.nc-icon-outline.ui-2_webpage:before {
  content: "\e714";
}

.nc-icon-outline.ui-2_window-add:before {
  content: "\e715";
}

.nc-icon-outline.ui-2_window-delete:before {
  content: "\e716";
}

.nc-icon-outline.transportation_air-baloon:before {
  content: "\e717";
}

.nc-icon-outline.transportation_bike-sport:before {
  content: "\e718";
}

.nc-icon-outline.transportation_bike:before {
  content: "\e719";
}

.nc-icon-outline.transportation_boat-front:before {
  content: "\e71a";
}

.nc-icon-outline.transportation_boat-small-02:before {
  content: "\e71b";
}

.nc-icon-outline.transportation_boat-small-03:before {
  content: "\e71c";
}

.nc-icon-outline.transportation_boat:before {
  content: "\e71d";
}

.nc-icon-outline.transportation_bus-front-10:before {
  content: "\e71e";
}

.nc-icon-outline.transportation_bus-front-12:before {
  content: "\e71f";
}

.nc-icon-outline.transportation_bus:before {
  content: "\e720";
}

.nc-icon-outline.transportation_car-front:before {
  content: "\e721";
}

.nc-icon-outline.transportation_car-simple:before {
  content: "\e722";
}

.nc-icon-outline.transportation_car-sport:before {
  content: "\e723";
}

.nc-icon-outline.transportation_car-taxi:before {
  content: "\e724";
}

.nc-icon-outline.transportation_car:before {
  content: "\e725";
}

.nc-icon-outline.transportation_helicopter:before {
  content: "\e726";
}

.nc-icon-outline.transportation_helmet:before {
  content: "\e727";
}

.nc-icon-outline.transportation_light-traffic:before {
  content: "\e728";
}

.nc-icon-outline.transportation_moto:before {
  content: "\e729";
}

.nc-icon-outline.transportation_plane-17:before {
  content: "\e72a";
}

.nc-icon-outline.transportation_plane-18:before {
  content: "\e72b";
}

.nc-icon-outline.transportation_road:before {
  content: "\e72c";
}

.nc-icon-outline.transportation_skateboard:before {
  content: "\e72d";
}

.nc-icon-outline.transportation_tractor:before {
  content: "\e72e";
}

.nc-icon-outline.transportation_train-speed:before {
  content: "\e72f";
}

.nc-icon-outline.transportation_train:before {
  content: "\e730";
}

.nc-icon-outline.transportation_tram:before {
  content: "\e731";
}

.nc-icon-outline.transportation_truck-front:before {
  content: "\e732";
}

.nc-icon-outline.transportation_vespa-front:before {
  content: "\e733";
}

.nc-icon-outline.transportation_vespa:before {
  content: "\e734";
}

.nc-icon-outline.tech_cable-49:before {
  content: "\e735";
}

.nc-icon-outline.tech_cable-50:before {
  content: "\e736";
}

.nc-icon-outline.tech_cd-reader:before {
  content: "\e737";
}

.nc-icon-outline.tech_computer-monitor:before {
  content: "\e738";
}

.nc-icon-outline.tech_computer-old:before {
  content: "\e739";
}

.nc-icon-outline.tech_computer:before {
  content: "\e73a";
}

.nc-icon-outline.tech_controller-modern:before {
  content: "\e73b";
}

.nc-icon-outline.tech_controller:before {
  content: "\e73c";
}

.nc-icon-outline.tech_desktop-screen:before {
  content: "\e73d";
}

.nc-icon-outline.tech_desktop:before {
  content: "\e73e";
}

.nc-icon-outline.tech_disk-reader:before {
  content: "\e73f";
}

.nc-icon-outline.tech_disk:before {
  content: "\e740";
}

.nc-icon-outline.tech_gopro:before {
  content: "\e741";
}

.nc-icon-outline.tech_headphones:before {
  content: "\e742";
}

.nc-icon-outline.tech_keyboard-mouse:before {
  content: "\e743";
}

.nc-icon-outline.tech_keyboard-wifi:before {
  content: "\e744";
}

.nc-icon-outline.tech_keyboard:before {
  content: "\e745";
}

.nc-icon-outline.tech_laptop-camera:before {
  content: "\e746";
}

.nc-icon-outline.tech_laptop-front:before {
  content: "\e747";
}

.nc-icon-outline.tech_laptop:before {
  content: "\e748";
}

.nc-icon-outline.tech_mobile-button:before {
  content: "\e749";
}

.nc-icon-outline.tech_mobile-camera:before {
  content: "\e74a";
}

.nc-icon-outline.tech_mobile-recharger-08:before {
  content: "\e74b";
}

.nc-icon-outline.tech_mobile-recharger-09:before {
  content: "\e74c";
}

.nc-icon-outline.tech_mobile-toolbar:before {
  content: "\e74d";
}

.nc-icon-outline.tech_mobile:before {
  content: "\e74e";
}

.nc-icon-outline.tech_music:before {
  content: "\e74f";
}

.nc-icon-outline.tech_navigation:before {
  content: "\e750";
}

.nc-icon-outline.tech_player-19:before {
  content: "\e751";
}

.nc-icon-outline.tech_player-48:before {
  content: "\e752";
}

.nc-icon-outline.tech_print-fold:before {
  content: "\e753";
}

.nc-icon-outline.tech_print-round-fold:before {
  content: "\e754";
}

.nc-icon-outline.tech_print-round:before {
  content: "\e755";
}

.nc-icon-outline.tech_print:before {
  content: "\e756";
}

.nc-icon-outline.tech_ram:before {
  content: "\e757";
}

.nc-icon-outline.tech_remote:before {
  content: "\e758";
}

.nc-icon-outline.tech_signal:before {
  content: "\e759";
}

.nc-icon-outline.tech_socket:before {
  content: "\e75a";
}

.nc-icon-outline.tech_sync:before {
  content: "\e75b";
}

.nc-icon-outline.tech_tablet-button:before {
  content: "\e75c";
}

.nc-icon-outline.tech_tablet-reader-31:before {
  content: "\e75d";
}

.nc-icon-outline.tech_tablet-reader-42:before {
  content: "\e75e";
}

.nc-icon-outline.tech_tablet-toolbar:before {
  content: "\e75f";
}

.nc-icon-outline.tech_tablet:before {
  content: "\e760";
}

.nc-icon-outline.tech_tv-old:before {
  content: "\e761";
}

.nc-icon-outline.tech_tv:before {
  content: "\e762";
}

.nc-icon-outline.tech_watch-circle:before {
  content: "\e763";
}

.nc-icon-outline.tech_watch-time:before {
  content: "\e764";
}

.nc-icon-outline.tech_watch:before {
  content: "\e765";
}

.nc-icon-outline.tech_webcam-38:before {
  content: "\e766";
}

.nc-icon-outline.tech_webcam-39:before {
  content: "\e767";
}

.nc-icon-outline.tech_wifi-router:before {
  content: "\e768";
}

.nc-icon-outline.tech_wifi:before {
  content: "\e769";
}

.nc-icon-outline.shopping_award:before {
  content: "\e76a";
}

.nc-icon-outline.shopping_bag-09:before {
  content: "\e76b";
}

.nc-icon-outline.shopping_bag-16:before {
  content: "\e76c";
}

.nc-icon-outline.shopping_bag-17:before {
  content: "\e76d";
}

.nc-icon-outline.shopping_bag-20:before {
  content: "\e76e";
}

.nc-icon-outline.shopping_bag-add-18:before {
  content: "\e76f";
}

.nc-icon-outline.shopping_bag-add-21:before {
  content: "\e770";
}

.nc-icon-outline.shopping_bag-edit:before {
  content: "\e771";
}

.nc-icon-outline.shopping_bag-remove-19:before {
  content: "\e772";
}

.nc-icon-outline.shopping_bag-remove-22:before {
  content: "\e773";
}

.nc-icon-outline.shopping_barcode-scan:before {
  content: "\e774";
}

.nc-icon-outline.shopping_barcode:before {
  content: "\e775";
}

.nc-icon-outline.shopping_bardcode-qr:before {
  content: "\e776";
}

.nc-icon-outline.shopping_basket-add:before {
  content: "\e777";
}

.nc-icon-outline.shopping_basket-edit:before {
  content: "\e778";
}

.nc-icon-outline.shopping_basket-remove:before {
  content: "\e779";
}

.nc-icon-outline.shopping_basket-simple-add:before {
  content: "\e77a";
}

.nc-icon-outline.shopping_basket-simple-remove:before {
  content: "\e77b";
}

.nc-icon-outline.shopping_basket-simple:before {
  content: "\e77c";
}

.nc-icon-outline.shopping_basket:before {
  content: "\e77d";
}

.nc-icon-outline.shopping_bitcoin:before {
  content: "\e77e";
}

.nc-icon-outline.shopping_board:before {
  content: "\e77f";
}

.nc-icon-outline.shopping_box-3d-50:before {
  content: "\e780";
}

.nc-icon-outline.shopping_box-3d-67:before {
  content: "\e781";
}

.nc-icon-outline.shopping_box-ribbon:before {
  content: "\e782";
}

.nc-icon-outline.shopping_box:before {
  content: "\e783";
}

.nc-icon-outline.shopping_cart-add:before {
  content: "\e784";
}

.nc-icon-outline.shopping_cart-modern-add:before {
  content: "\e785";
}

.nc-icon-outline.shopping_cart-modern-in:before {
  content: "\e786";
}

.nc-icon-outline.shopping_cart-modern-remove:before {
  content: "\e787";
}

.nc-icon-outline.shopping_cart-modern:before {
  content: "\e788";
}

.nc-icon-outline.shopping_cart-remove:before {
  content: "\e789";
}

.nc-icon-outline.shopping_cart-simple-add:before {
  content: "\e78a";
}

.nc-icon-outline.shopping_cart-simple-in:before {
  content: "\e78b";
}

.nc-icon-outline.shopping_cart-simple-remove:before {
  content: "\e78c";
}

.nc-icon-outline.shopping_cart-simple:before {
  content: "\e78d";
}

.nc-icon-outline.shopping_cart:before {
  content: "\e78e";
}

.nc-icon-outline.shopping_cash-register:before {
  content: "\e78f";
}

.nc-icon-outline.shopping_chart:before {
  content: "\e790";
}

.nc-icon-outline.shopping_credit-card-in:before {
  content: "\e791";
}

.nc-icon-outline.shopping_credit-card:before {
  content: "\e792";
}

.nc-icon-outline.shopping_credit-locked:before {
  content: "\e793";
}

.nc-icon-outline.shopping_delivery-fast:before {
  content: "\e794";
}

.nc-icon-outline.shopping_delivery-time:before {
  content: "\e795";
}

.nc-icon-outline.shopping_delivery-track:before {
  content: "\e796";
}

.nc-icon-outline.shopping_delivery:before {
  content: "\e797";
}

.nc-icon-outline.shopping_discount:before {
  content: "\e798";
}

.nc-icon-outline.shopping_gift:before {
  content: "\e799";
}

.nc-icon-outline.shopping_hand-card:before {
  content: "\e79a";
}

.nc-icon-outline.shopping_list:before {
  content: "\e79b";
}

.nc-icon-outline.shopping_mobile-card:before {
  content: "\e79c";
}

.nc-icon-outline.shopping_mobile-cart:before {
  content: "\e79d";
}

.nc-icon-outline.shopping_mobile-touch:before {
  content: "\e79e";
}

.nc-icon-outline.shopping_newsletter:before {
  content: "\e79f";
}

.nc-icon-outline.shopping_pos:before {
  content: "\e7a0";
}

.nc-icon-outline.shopping_receipt-list-42:before {
  content: "\e7a1";
}

.nc-icon-outline.shopping_receipt-list-43:before {
  content: "\e7a2";
}

.nc-icon-outline.shopping_receipt:before {
  content: "\e7a3";
}

.nc-icon-outline.shopping_shop-location:before {
  content: "\e7a4";
}

.nc-icon-outline.shopping_shop:before {
  content: "\e7a5";
}

.nc-icon-outline.shopping_stock:before {
  content: "\e7a6";
}

.nc-icon-outline.shopping_tag-content:before {
  content: "\e7a7";
}

.nc-icon-outline.shopping_tag-cut:before {
  content: "\e7a8";
}

.nc-icon-outline.shopping_tag-line:before {
  content: "\e7a9";
}

.nc-icon-outline.shopping_tag-sale:before {
  content: "\e7aa";
}

.nc-icon-outline.shopping_tag:before {
  content: "\e7ab";
}

.nc-icon-outline.shopping_wallet:before {
  content: "\e7ac";
}

.nc-icon-outline.education_abc:before {
  content: "\e7ad";
}

.nc-icon-outline.education_agenda-bookmark:before {
  content: "\e7ae";
}

.nc-icon-outline.education_atom:before {
  content: "\e7af";
}

.nc-icon-outline.education_award-55:before {
  content: "\e7b0";
}

.nc-icon-outline.education_backpack-57:before {
  content: "\e7b1";
}

.nc-icon-outline.education_backpack-58:before {
  content: "\e7b2";
}

.nc-icon-outline.education_ball-basket:before {
  content: "\e7b3";
}

.nc-icon-outline.education_ball-soccer:before {
  content: "\e7b4";
}

.nc-icon-outline.education_board-51:before {
  content: "\e7b5";
}

.nc-icon-outline.education_book-39:before {
  content: "\e7b6";
}

.nc-icon-outline.education_book-bookmark:before {
  content: "\e7b7";
}

.nc-icon-outline.education_book-open:before {
  content: "\e7b8";
}

.nc-icon-outline.education_books-46:before {
  content: "\e7b9";
}

.nc-icon-outline.education_chalkboard:before {
  content: "\e7ba";
}

.nc-icon-outline.education_flask:before {
  content: "\e7bb";
}

.nc-icon-outline.education_glasses:before {
  content: "\e7bc";
}

.nc-icon-outline.education_grammar-check:before {
  content: "\e7bd";
}

.nc-icon-outline.education_hat:before {
  content: "\e7be";
}

.nc-icon-outline.education_language:before {
  content: "\e7bf";
}

.nc-icon-outline.education_microscope:before {
  content: "\e7c0";
}

.nc-icon-outline.education_molecule:before {
  content: "\e7c1";
}

.nc-icon-outline.education_notepad:before {
  content: "\e7c2";
}

.nc-icon-outline.education_paper-diploma:before {
  content: "\e7c3";
}

.nc-icon-outline.education_paper:before {
  content: "\e7c4";
}

.nc-icon-outline.education_pencil-47:before {
  content: "\e7c5";
}

.nc-icon-outline.education_school:before {
  content: "\e7c6";
}

.nc-icon-outline.objects_alien-29:before {
  content: "\e7c7";
}

.nc-icon-outline.objects_alien-33:before {
  content: "\e7c8";
}

.nc-icon-outline.objects_anchor:before {
  content: "\e7c9";
}

.nc-icon-outline.objects_astronaut:before {
  content: "\e7ca";
}

.nc-icon-outline.objects_axe:before {
  content: "\e7cb";
}

.nc-icon-outline.objects_baby-bottle:before {
  content: "\e7cc";
}

.nc-icon-outline.objects_baby:before {
  content: "\e7cd";
}

.nc-icon-outline.objects_baloon:before {
  content: "\e7ce";
}

.nc-icon-outline.objects_battery:before {
  content: "\e7cf";
}

.nc-icon-outline.objects_bear:before {
  content: "\e7d0";
}

.nc-icon-outline.objects_billiard:before {
  content: "\e7d1";
}

.nc-icon-outline.objects_binocular:before {
  content: "\e7d2";
}

.nc-icon-outline.objects_bow:before {
  content: "\e7d3";
}

.nc-icon-outline.objects_bowling:before {
  content: "\e7d4";
}

.nc-icon-outline.objects_broom:before {
  content: "\e7d5";
}

.nc-icon-outline.objects_cone:before {
  content: "\e7d6";
}

.nc-icon-outline.objects_controller:before {
  content: "\e7d7";
}

.nc-icon-outline.objects_diamond:before {
  content: "\e7d8";
}

.nc-icon-outline.objects_dice:before {
  content: "\e7d9";
}

.nc-icon-outline.objects_globe:before {
  content: "\e7da";
}

.nc-icon-outline.objects_hut:before {
  content: "\e7db";
}

.nc-icon-outline.objects_key-25:before {
  content: "\e7dc";
}

.nc-icon-outline.objects_key-26:before {
  content: "\e7dd";
}

.nc-icon-outline.objects_lamp:before {
  content: "\e7de";
}

.nc-icon-outline.objects_leaf-36:before {
  content: "\e7df";
}

.nc-icon-outline.objects_leaf-38:before {
  content: "\e7e0";
}

.nc-icon-outline.objects_light:before {
  content: "\e7e1";
}

.nc-icon-outline.objects_pipe:before {
  content: "\e7e2";
}

.nc-icon-outline.objects_planet:before {
  content: "\e7e3";
}

.nc-icon-outline.objects_puzzle-09:before {
  content: "\e7e4";
}

.nc-icon-outline.objects_puzzle-10:before {
  content: "\e7e5";
}

.nc-icon-outline.objects_shovel:before {
  content: "\e7e6";
}

.nc-icon-outline.objects_skull:before {
  content: "\e7e7";
}

.nc-icon-outline.objects_spaceship:before {
  content: "\e7e8";
}

.nc-icon-outline.objects_spray:before {
  content: "\e7e9";
}

.nc-icon-outline.objects_support-16:before {
  content: "\e7ea";
}

.nc-icon-outline.objects_support-17:before {
  content: "\e7eb";
}

.nc-icon-outline.objects_umbrella-13:before {
  content: "\e7ec";
}

.nc-icon-outline.objects_umbrella-14:before {
  content: "\e7ed";
}

.nc-icon-outline.objects_wool-ball:before {
  content: "\e7ee";
}

.nc-icon-outline.media-1_3d:before {
  content: "\e7ef";
}

.nc-icon-outline.media-1_action-73:before {
  content: "\e7f0";
}

.nc-icon-outline.media-1_action-74:before {
  content: "\e7f1";
}

.nc-icon-outline.media-1_album:before {
  content: "\e7f2";
}

.nc-icon-outline.media-1_audio-91:before {
  content: "\e7f3";
}

.nc-icon-outline.media-1_audio-92:before {
  content: "\e7f4";
}

.nc-icon-outline.media-1_balance:before {
  content: "\e7f5";
}

.nc-icon-outline.media-1_brightness-46:before {
  content: "\e7f6";
}

.nc-icon-outline.media-1_brightness-47:before {
  content: "\e7f7";
}

.nc-icon-outline.media-1_button-circle-pause:before {
  content: "\e7f8";
}

.nc-icon-outline.media-1_button-circle-play:before {
  content: "\e7f9";
}

.nc-icon-outline.media-1_button-circle-stop:before {
  content: "\e7fa";
}

.nc-icon-outline.media-1_button-eject:before {
  content: "\e7fb";
}

.nc-icon-outline.media-1_button-next:before {
  content: "\e7fc";
}

.nc-icon-outline.media-1_button-pause:before {
  content: "\e7fd";
}

.nc-icon-outline.media-1_button-play:before {
  content: "\e7fe";
}

.nc-icon-outline.media-1_button-power:before {
  content: "\e7ff";
}

.nc-icon-outline.media-1_button-previous:before {
  content: "\e800";
}

.nc-icon-outline.media-1_button-record:before {
  content: "\e801";
}

.nc-icon-outline.media-1_button-rewind:before {
  content: "\e802";
}

.nc-icon-outline.media-1_button-skip:before {
  content: "\e803";
}

.nc-icon-outline.media-1_button-stop:before {
  content: "\e804";
}

.nc-icon-outline.media-1_camera-18:before {
  content: "\e805";
}

.nc-icon-outline.media-1_camera-19:before {
  content: "\e806";
}

.nc-icon-outline.media-1_camera-20:before {
  content: "\e807";
}

.nc-icon-outline.media-1_camera-ban-36:before {
  content: "\e808";
}

.nc-icon-outline.media-1_camera-ban-37:before {
  content: "\e809";
}

.nc-icon-outline.media-1_camera-compact:before {
  content: "\e80a";
}

.nc-icon-outline.media-1_camera-screen:before {
  content: "\e80b";
}

.nc-icon-outline.media-1_camera-square-57:before {
  content: "\e80c";
}

.nc-icon-outline.media-1_camera-square-58:before {
  content: "\e80d";
}

.nc-icon-outline.media-1_camera-time:before {
  content: "\e80e";
}

.nc-icon-outline.media-1_countdown-34:before {
  content: "\e80f";
}

.nc-icon-outline.media-1_countdown-35:before {
  content: "\e810";
}

.nc-icon-outline.media-1_edit-color:before {
  content: "\e811";
}

.nc-icon-outline.media-1_edit-contrast-42:before {
  content: "\e812";
}

.nc-icon-outline.media-1_edit-contrast-43:before {
  content: "\e813";
}

.nc-icon-outline.media-1_edit-saturation:before {
  content: "\e814";
}

.nc-icon-outline.media-1_flash-21:before {
  content: "\e815";
}

.nc-icon-outline.media-1_flash-24:before {
  content: "\e816";
}

.nc-icon-outline.media-1_flash-29:before {
  content: "\e817";
}

.nc-icon-outline.media-1_flash-auto-22:before {
  content: "\e818";
}

.nc-icon-outline.media-1_flash-auto-25:before {
  content: "\e819";
}

.nc-icon-outline.media-1_flash-off-23:before {
  content: "\e81a";
}

.nc-icon-outline.media-1_flash-off-26:before {
  content: "\e81b";
}

.nc-icon-outline.media-1_focus-32:before {
  content: "\e81c";
}

.nc-icon-outline.media-1_focus-38:before {
  content: "\e81d";
}

.nc-icon-outline.media-1_focus-40:before {
  content: "\e81e";
}

.nc-icon-outline.media-1_focus-circle:before {
  content: "\e81f";
}

.nc-icon-outline.media-1_frame-12:before {
  content: "\e820";
}

.nc-icon-outline.media-1_frame-41:before {
  content: "\e821";
}

.nc-icon-outline.media-1_grid:before {
  content: "\e822";
}

.nc-icon-outline.media-1_image-01:before {
  content: "\e823";
}

.nc-icon-outline.media-1_image-02:before {
  content: "\e824";
}

.nc-icon-outline.media-1_image-05:before {
  content: "\e825";
}

.nc-icon-outline.media-1_image-add:before {
  content: "\e826";
}

.nc-icon-outline.media-1_image-delete:before {
  content: "\e827";
}

.nc-icon-outline.media-1_image-location:before {
  content: "\e828";
}

.nc-icon-outline.media-1_kid:before {
  content: "\e829";
}

.nc-icon-outline.media-1_layers:before {
  content: "\e82a";
}

.nc-icon-outline.media-1_lens-31:before {
  content: "\e82b";
}

.nc-icon-outline.media-1_lens-56:before {
  content: "\e82c";
}

.nc-icon-outline.media-1_macro:before {
  content: "\e82d";
}

.nc-icon-outline.media-1_movie-61:before {
  content: "\e82e";
}

.nc-icon-outline.media-1_movie-62:before {
  content: "\e82f";
}

.nc-icon-outline.media-1_night:before {
  content: "\e830";
}

.nc-icon-outline.media-1_picture:before {
  content: "\e831";
}

.nc-icon-outline.media-1_play-68:before {
  content: "\e832";
}

.nc-icon-outline.media-1_play-69:before {
  content: "\e833";
}

.nc-icon-outline.media-1_player:before {
  content: "\e834";
}

.nc-icon-outline.media-1_polaroid-add:before {
  content: "\e835";
}

.nc-icon-outline.media-1_polaroid-delete:before {
  content: "\e836";
}

.nc-icon-outline.media-1_polaroid-multiple:before {
  content: "\e837";
}

.nc-icon-outline.media-1_polaroid-user:before {
  content: "\e838";
}

.nc-icon-outline.media-1_polaroid:before {
  content: "\e839";
}

.nc-icon-outline.media-1_roll:before {
  content: "\e83a";
}

.nc-icon-outline.media-1_rotate-left:before {
  content: "\e83b";
}

.nc-icon-outline.media-1_rotate-right:before {
  content: "\e83c";
}

.nc-icon-outline.media-1_sd:before {
  content: "\e83d";
}

.nc-icon-outline.media-1_selfie:before {
  content: "\e83e";
}

.nc-icon-outline.media-1_shake:before {
  content: "\e83f";
}

.nc-icon-outline.media-1_speaker:before {
  content: "\e840";
}

.nc-icon-outline.media-1_sport:before {
  content: "\e841";
}

.nc-icon-outline.media-1_ticket-75:before {
  content: "\e842";
}

.nc-icon-outline.media-1_ticket-76:before {
  content: "\e843";
}

.nc-icon-outline.media-1_touch:before {
  content: "\e844";
}

.nc-icon-outline.media-1_tripod:before {
  content: "\e845";
}

.nc-icon-outline.media-1_video-64:before {
  content: "\e846";
}

.nc-icon-outline.media-1_video-65:before {
  content: "\e847";
}

.nc-icon-outline.media-1_video-66:before {
  content: "\e848";
}

.nc-icon-outline.media-1_video-67:before {
  content: "\e849";
}

.nc-icon-outline.media-1_videocamera-71:before {
  content: "\e84a";
}

.nc-icon-outline.media-1_videocamera-72:before {
  content: "\e84b";
}

.nc-icon-outline.media-1_volume-93:before {
  content: "\e84c";
}

.nc-icon-outline.media-1_volume-97:before {
  content: "\e84d";
}

.nc-icon-outline.media-1_volume-98:before {
  content: "\e84e";
}

.nc-icon-outline.media-1_volume-ban:before {
  content: "\e84f";
}

.nc-icon-outline.media-1_volume-down:before {
  content: "\e850";
}

.nc-icon-outline.media-1_volume-off:before {
  content: "\e851";
}

.nc-icon-outline.media-1_volume-up:before {
  content: "\e852";
}

.nc-icon-outline.media-2_guitar:before {
  content: "\e853";
}

.nc-icon-outline.media-2_headphones-mic:before {
  content: "\e854";
}

.nc-icon-outline.media-2_headphones:before {
  content: "\e855";
}

.nc-icon-outline.media-2_knob:before {
  content: "\e856";
}

.nc-icon-outline.media-2_mic:before {
  content: "\e857";
}

.nc-icon-outline.media-2_music-album:before {
  content: "\e858";
}

.nc-icon-outline.media-2_music-cloud:before {
  content: "\e859";
}

.nc-icon-outline.media-2_note-03:before {
  content: "\e85a";
}

.nc-icon-outline.media-2_note-04:before {
  content: "\e85b";
}

.nc-icon-outline.media-2_piano:before {
  content: "\e85c";
}

.nc-icon-outline.media-2_radio:before {
  content: "\e85d";
}

.nc-icon-outline.media-2_remix:before {
  content: "\e85e";
}

.nc-icon-outline.media-2_sound-wave:before {
  content: "\e85f";
}

.nc-icon-outline.media-2_speaker-01:before {
  content: "\e860";
}

.nc-icon-outline.media-2_speaker-05:before {
  content: "\e861";
}

.nc-icon-outline.media-2_tape:before {
  content: "\e862";
}

.nc-icon-outline.location_appointment:before {
  content: "\e863";
}

.nc-icon-outline.location_bookmark-add:before {
  content: "\e864";
}

.nc-icon-outline.location_bookmark-remove:before {
  content: "\e865";
}

.nc-icon-outline.location_bookmark:before {
  content: "\e866";
}

.nc-icon-outline.location_compass-04:before {
  content: "\e867";
}

.nc-icon-outline.location_compass-05:before {
  content: "\e868";
}

.nc-icon-outline.location_compass-06:before {
  content: "\e869";
}

.nc-icon-outline.location_crosshair:before {
  content: "\e86a";
}

.nc-icon-outline.location_explore-user:before {
  content: "\e86b";
}

.nc-icon-outline.location_explore:before {
  content: "\e86c";
}

.nc-icon-outline.location_flag-complex:before {
  content: "\e86d";
}

.nc-icon-outline.location_flag-diagonal-33:before {
  content: "\e86e";
}

.nc-icon-outline.location_flag-diagonal-34:before {
  content: "\e86f";
}

.nc-icon-outline.location_flag-points-31:before {
  content: "\e870";
}

.nc-icon-outline.location_flag-points-32:before {
  content: "\e871";
}

.nc-icon-outline.location_flag-simple:before {
  content: "\e872";
}

.nc-icon-outline.location_flag-triangle:before {
  content: "\e873";
}

.nc-icon-outline.location_flag:before {
  content: "\e874";
}

.nc-icon-outline.location_gps:before {
  content: "\e875";
}

.nc-icon-outline.location_map-big:before {
  content: "\e876";
}

.nc-icon-outline.location_map-compass:before {
  content: "\e877";
}

.nc-icon-outline.location_map-gps:before {
  content: "\e878";
}

.nc-icon-outline.location_map-marker:before {
  content: "\e879";
}

.nc-icon-outline.location_map-pin:before {
  content: "\e87a";
}

.nc-icon-outline.location_map:before {
  content: "\e87b";
}

.nc-icon-outline.location_marker:before {
  content: "\e87c";
}

.nc-icon-outline.location_pin-add:before {
  content: "\e87d";
}

.nc-icon-outline.location_pin-copy:before {
  content: "\e87e";
}

.nc-icon-outline.location_pin-remove:before {
  content: "\e87f";
}

.nc-icon-outline.location_pin:before {
  content: "\e880";
}

.nc-icon-outline.location_pins:before {
  content: "\e881";
}

.nc-icon-outline.location_position-marker:before {
  content: "\e882";
}

.nc-icon-outline.location_position-pin:before {
  content: "\e883";
}

.nc-icon-outline.location_position-user:before {
  content: "\e884";
}

.nc-icon-outline.location_radar:before {
  content: "\e885";
}

.nc-icon-outline.location_road:before {
  content: "\e886";
}

.nc-icon-outline.location_route-alert:before {
  content: "\e887";
}

.nc-icon-outline.location_route-close:before {
  content: "\e888";
}

.nc-icon-outline.location_route-open:before {
  content: "\e889";
}

.nc-icon-outline.location_square-marker:before {
  content: "\e88a";
}

.nc-icon-outline.location_square-pin:before {
  content: "\e88b";
}

.nc-icon-outline.location_treasure-map-21:before {
  content: "\e88c";
}

.nc-icon-outline.location_treasure-map-40:before {
  content: "\e88d";
}

.nc-icon-outline.location_worl-marker:before {
  content: "\e88e";
}

.nc-icon-outline.location_world-pin:before {
  content: "\e88f";
}

.nc-icon-outline.location_world:before {
  content: "\e890";
}

.nc-icon-outline.health_ambulance:before {
  content: "\e891";
}

.nc-icon-outline.health_apple:before {
  content: "\e892";
}

.nc-icon-outline.health_bag-49:before {
  content: "\e893";
}

.nc-icon-outline.health_bag-50:before {
  content: "\e894";
}

.nc-icon-outline.health_brain:before {
  content: "\e895";
}

.nc-icon-outline.health_dna-27:before {
  content: "\e896";
}

.nc-icon-outline.health_dna-38:before {
  content: "\e897";
}

.nc-icon-outline.health_doctor:before {
  content: "\e898";
}

.nc-icon-outline.health_flask:before {
  content: "\e899";
}

.nc-icon-outline.health_heartbeat-16:before {
  content: "\e89a";
}

.nc-icon-outline.health_height:before {
  content: "\e89b";
}

.nc-icon-outline.health_hospital-32:before {
  content: "\e89c";
}

.nc-icon-outline.health_hospital-33:before {
  content: "\e89d";
}

.nc-icon-outline.health_hospital-34:before {
  content: "\e89e";
}

.nc-icon-outline.health_humidity-26:before {
  content: "\e89f";
}

.nc-icon-outline.health_humidity-52:before {
  content: "\e8a0";
}

.nc-icon-outline.health_intestine:before {
  content: "\e8a1";
}

.nc-icon-outline.health_lungs:before {
  content: "\e8a2";
}

.nc-icon-outline.health_molecule-39:before {
  content: "\e8a3";
}

.nc-icon-outline.health_molecule-40:before {
  content: "\e8a4";
}

.nc-icon-outline.health_notebook:before {
  content: "\e8a5";
}

.nc-icon-outline.health_nurse:before {
  content: "\e8a6";
}

.nc-icon-outline.health_patch-46:before {
  content: "\e8a7";
}

.nc-icon-outline.health_pill-42:before {
  content: "\e8a8";
}

.nc-icon-outline.health_pill-43:before {
  content: "\e8a9";
}

.nc-icon-outline.health_pill-container-44:before {
  content: "\e8aa";
}

.nc-icon-outline.health_pill-container-47:before {
  content: "\e8ab";
}

.nc-icon-outline.health_pulse-chart:before {
  content: "\e8ac";
}

.nc-icon-outline.health_pulse-phone:before {
  content: "\e8ad";
}

.nc-icon-outline.health_pulse-sleep:before {
  content: "\e8ae";
}

.nc-icon-outline.health_pulse-watch:before {
  content: "\e8af";
}

.nc-icon-outline.health_pulse:before {
  content: "\e8b0";
}

.nc-icon-outline.health_sleep:before {
  content: "\e8b1";
}

.nc-icon-outline.health_steps:before {
  content: "\e8b2";
}

.nc-icon-outline.health_syringe:before {
  content: "\e8b3";
}

.nc-icon-outline.health_temperature-23:before {
  content: "\e8b4";
}

.nc-icon-outline.health_temperature-24:before {
  content: "\e8b5";
}

.nc-icon-outline.health_tooth:before {
  content: "\e8b6";
}

.nc-icon-outline.health_weed:before {
  content: "\e8b7";
}

.nc-icon-outline.health_weight:before {
  content: "\e8b8";
}

.nc-icon-outline.health_wheelchair:before {
  content: "\e8b9";
}

.nc-icon-outline.health_woman:before {
  content: "\e8ba";
}

.nc-icon-outline.furniture_air-conditioner:before {
  content: "\e8bb";
}

.nc-icon-outline.furniture_armchair:before {
  content: "\e8bc";
}

.nc-icon-outline.furniture_bath-tub:before {
  content: "\e8bd";
}

.nc-icon-outline.furniture_bed-09:before {
  content: "\e8be";
}

.nc-icon-outline.furniture_bed-23:before {
  content: "\e8bf";
}

.nc-icon-outline.furniture_bed-side:before {
  content: "\e8c0";
}

.nc-icon-outline.furniture_cabinet:before {
  content: "\e8c1";
}

.nc-icon-outline.furniture_cactus:before {
  content: "\e8c2";
}

.nc-icon-outline.furniture_chair:before {
  content: "\e8c3";
}

.nc-icon-outline.furniture_coat-hanger:before {
  content: "\e8c4";
}

.nc-icon-outline.furniture_coffee:before {
  content: "\e8c5";
}

.nc-icon-outline.furniture_cradle:before {
  content: "\e8c6";
}

.nc-icon-outline.furniture_curtain:before {
  content: "\e8c7";
}

.nc-icon-outline.furniture_desk-drawer:before {
  content: "\e8c8";
}

.nc-icon-outline.furniture_desk:before {
  content: "\e8c9";
}

.nc-icon-outline.furniture_door:before {
  content: "\e8ca";
}

.nc-icon-outline.furniture_drawer:before {
  content: "\e8cb";
}

.nc-icon-outline.furniture_fridge:before {
  content: "\e8cc";
}

.nc-icon-outline.furniture_hanger-clothes:before {
  content: "\e8cd";
}

.nc-icon-outline.furniture_hanger:before {
  content: "\e8ce";
}

.nc-icon-outline.furniture_heater:before {
  content: "\e8cf";
}

.nc-icon-outline.furniture_iron:before {
  content: "\e8d0";
}

.nc-icon-outline.furniture_lamp-floor:before {
  content: "\e8d1";
}

.nc-icon-outline.furniture_lamp:before {
  content: "\e8d2";
}

.nc-icon-outline.furniture_library:before {
  content: "\e8d3";
}

.nc-icon-outline.furniture_light:before {
  content: "\e8d4";
}

.nc-icon-outline.furniture_mixer:before {
  content: "\e8d5";
}

.nc-icon-outline.furniture_oven:before {
  content: "\e8d6";
}

.nc-icon-outline.furniture_shower:before {
  content: "\e8d7";
}

.nc-icon-outline.furniture_sink-wash:before {
  content: "\e8d8";
}

.nc-icon-outline.furniture_sink:before {
  content: "\e8d9";
}

.nc-icon-outline.furniture_sofa:before {
  content: "\e8da";
}

.nc-icon-outline.furniture_storage-hanger:before {
  content: "\e8db";
}

.nc-icon-outline.furniture_storage:before {
  content: "\e8dc";
}

.nc-icon-outline.furniture_table:before {
  content: "\e8dd";
}

.nc-icon-outline.furniture_toilet-paper:before {
  content: "\e8de";
}

.nc-icon-outline.furniture_toilet:before {
  content: "\e8df";
}

.nc-icon-outline.furniture_tv:before {
  content: "\e8e0";
}

.nc-icon-outline.furniture_wardrobe:before {
  content: "\e8e1";
}

.nc-icon-outline.furniture_wash:before {
  content: "\e8e2";
}

.nc-icon-outline.files_add:before {
  content: "\e8e3";
}

.nc-icon-outline.files_archive-3d-check:before {
  content: "\e8e4";
}

.nc-icon-outline.files_archive-3d-content:before {
  content: "\e8e5";
}

.nc-icon-outline.files_archive-check:before {
  content: "\e8e6";
}

.nc-icon-outline.files_archive-content:before {
  content: "\e8e7";
}

.nc-icon-outline.files_archive-paper-check:before {
  content: "\e8e8";
}

.nc-icon-outline.files_archive-paper:before {
  content: "\e8e9";
}

.nc-icon-outline.files_archive:before {
  content: "\e8ea";
}

.nc-icon-outline.files_audio:before {
  content: "\e8eb";
}

.nc-icon-outline.files_book-07:before {
  content: "\e8ec";
}

.nc-icon-outline.files_book-08:before {
  content: "\e8ed";
}

.nc-icon-outline.files_bookmark:before {
  content: "\e8ee";
}

.nc-icon-outline.files_box:before {
  content: "\e8ef";
}

.nc-icon-outline.files_chart-bar:before {
  content: "\e8f0";
}

.nc-icon-outline.files_chart-pie:before {
  content: "\e8f1";
}

.nc-icon-outline.files_check:before {
  content: "\e8f2";
}

.nc-icon-outline.files_cloud:before {
  content: "\e8f3";
}

.nc-icon-outline.files_copy:before {
  content: "\e8f4";
}

.nc-icon-outline.files_dev:before {
  content: "\e8f5";
}

.nc-icon-outline.files_download:before {
  content: "\e8f6";
}

.nc-icon-outline.files_drawer:before {
  content: "\e8f7";
}

.nc-icon-outline.files_edit:before {
  content: "\e8f8";
}

.nc-icon-outline.files_exclamation:before {
  content: "\e8f9";
}

.nc-icon-outline.files_folder-13:before {
  content: "\e8fa";
}

.nc-icon-outline.files_folder-14:before {
  content: "\e8fb";
}

.nc-icon-outline.files_folder-15:before {
  content: "\e8fc";
}

.nc-icon-outline.files_folder-16:before {
  content: "\e8fd";
}

.nc-icon-outline.files_folder-17:before {
  content: "\e8fe";
}

.nc-icon-outline.files_folder-18:before {
  content: "\e8ff";
}

.nc-icon-outline.files_folder-19:before {
  content: "\e900";
}

.nc-icon-outline.files_folder-add:before {
  content: "\e901";
}

.nc-icon-outline.files_folder-audio:before {
  content: "\e902";
}

.nc-icon-outline.files_folder-bookmark:before {
  content: "\e903";
}

.nc-icon-outline.files_folder-chart-bar:before {
  content: "\e904";
}

.nc-icon-outline.files_folder-chart-pie:before {
  content: "\e905";
}

.nc-icon-outline.files_folder-check:before {
  content: "\e906";
}

.nc-icon-outline.files_folder-cloud:before {
  content: "\e907";
}

.nc-icon-outline.files_folder-dev:before {
  content: "\e908";
}

.nc-icon-outline.files_folder-download:before {
  content: "\e909";
}

.nc-icon-outline.files_folder-edit:before {
  content: "\e90a";
}

.nc-icon-outline.files_folder-exclamation:before {
  content: "\e90b";
}

.nc-icon-outline.files_folder-gallery:before {
  content: "\e90c";
}

.nc-icon-outline.files_folder-heart:before {
  content: "\e90d";
}

.nc-icon-outline.files_folder-image:before {
  content: "\e90e";
}

.nc-icon-outline.files_folder-info:before {
  content: "\e90f";
}

.nc-icon-outline.files_folder-link:before {
  content: "\e910";
}

.nc-icon-outline.files_folder-locked:before {
  content: "\e911";
}

.nc-icon-outline.files_folder-money:before {
  content: "\e912";
}

.nc-icon-outline.files_folder-music:before {
  content: "\e913";
}

.nc-icon-outline.files_folder-no-access:before {
  content: "\e914";
}

.nc-icon-outline.files_folder-play:before {
  content: "\e915";
}

.nc-icon-outline.files_folder-question:before {
  content: "\e916";
}

.nc-icon-outline.files_folder-refresh:before {
  content: "\e917";
}

.nc-icon-outline.files_folder-remove:before {
  content: "\e918";
}

.nc-icon-outline.files_folder-search:before {
  content: "\e919";
}

.nc-icon-outline.files_folder-settings-81:before {
  content: "\e91a";
}

.nc-icon-outline.files_folder-settings-97:before {
  content: "\e91b";
}

.nc-icon-outline.files_folder-shared:before {
  content: "\e91c";
}

.nc-icon-outline.files_folder-star:before {
  content: "\e91d";
}

.nc-icon-outline.files_folder-time:before {
  content: "\e91e";
}

.nc-icon-outline.files_folder-upload:before {
  content: "\e91f";
}

.nc-icon-outline.files_folder-user:before {
  content: "\e920";
}

.nc-icon-outline.files_folder-vector:before {
  content: "\e921";
}

.nc-icon-outline.files_gallery:before {
  content: "\e922";
}

.nc-icon-outline.files_heart:before {
  content: "\e923";
}

.nc-icon-outline.files_image:before {
  content: "\e924";
}

.nc-icon-outline.files_info:before {
  content: "\e925";
}

.nc-icon-outline.files_link:before {
  content: "\e926";
}

.nc-icon-outline.files_locked:before {
  content: "\e927";
}

.nc-icon-outline.files_money:before {
  content: "\e928";
}

.nc-icon-outline.files_music:before {
  content: "\e929";
}

.nc-icon-outline.files_no-access:before {
  content: "\e92a";
}

.nc-icon-outline.files_notebook:before {
  content: "\e92b";
}

.nc-icon-outline.files_paper:before {
  content: "\e92c";
}

.nc-icon-outline.files_play:before {
  content: "\e92d";
}

.nc-icon-outline.files_question:before {
  content: "\e92e";
}

.nc-icon-outline.files_refresh:before {
  content: "\e92f";
}

.nc-icon-outline.files_remove:before {
  content: "\e930";
}

.nc-icon-outline.files_replace-folder:before {
  content: "\e931";
}

.nc-icon-outline.files_replace:before {
  content: "\e932";
}

.nc-icon-outline.files_search:before {
  content: "\e933";
}

.nc-icon-outline.files_settings-46:before {
  content: "\e934";
}

.nc-icon-outline.files_settings-99:before {
  content: "\e935";
}

.nc-icon-outline.files_shared:before {
  content: "\e936";
}

.nc-icon-outline.files_single-content-02:before {
  content: "\e937";
}

.nc-icon-outline.files_single-content-03:before {
  content: "\e938";
}

.nc-icon-outline.files_single-copies:before {
  content: "\e939";
}

.nc-icon-outline.files_single-copy-04:before {
  content: "\e93a";
}

.nc-icon-outline.files_single-copy-06:before {
  content: "\e93b";
}

.nc-icon-outline.files_single-folded-content:before {
  content: "\e93c";
}

.nc-icon-outline.files_single-folded:before {
  content: "\e93d";
}

.nc-icon-outline.files_single-paragraph:before {
  content: "\e93e";
}

.nc-icon-outline.files_single:before {
  content: "\e93f";
}

.nc-icon-outline.files_star:before {
  content: "\e940";
}

.nc-icon-outline.files_time:before {
  content: "\e941";
}

.nc-icon-outline.files_upload:before {
  content: "\e942";
}

.nc-icon-outline.files_user:before {
  content: "\e943";
}

.nc-icon-outline.files_vector:before {
  content: "\e944";
}

.nc-icon-outline.files_zip-54:before {
  content: "\e945";
}

.nc-icon-outline.files_zip-55:before {
  content: "\e946";
}

.nc-icon-outline.design_album:before {
  content: "\e947";
}

.nc-icon-outline.design_align-bottom:before {
  content: "\e948";
}

.nc-icon-outline.design_align-center-horizontal:before {
  content: "\e949";
}

.nc-icon-outline.design_align-center-vertical:before {
  content: "\e94a";
}

.nc-icon-outline.design_align-left:before {
  content: "\e94b";
}

.nc-icon-outline.design_align-right:before {
  content: "\e94c";
}

.nc-icon-outline.design_align-top:before {
  content: "\e94d";
}

.nc-icon-outline.design_app:before {
  content: "\e94e";
}

.nc-icon-outline.design_artboard:before {
  content: "\e94f";
}

.nc-icon-outline.design_blend:before {
  content: "\e950";
}

.nc-icon-outline.design_book-bookmark:before {
  content: "\e951";
}

.nc-icon-outline.design_book-open:before {
  content: "\e952";
}

.nc-icon-outline.design_brush:before {
  content: "\e953";
}

.nc-icon-outline.design_bug:before {
  content: "\e954";
}

.nc-icon-outline.design_bullet-list-67:before {
  content: "\e955";
}

.nc-icon-outline.design_bullet-list-68:before {
  content: "\e956";
}

.nc-icon-outline.design_bullet-list-69:before {
  content: "\e957";
}

.nc-icon-outline.design_bullet-list-70:before {
  content: "\e958";
}

.nc-icon-outline.design_clone:before {
  content: "\e959";
}

.nc-icon-outline.design_code-editor:before {
  content: "\e95a";
}

.nc-icon-outline.design_code:before {
  content: "\e95b";
}

.nc-icon-outline.design_collection:before {
  content: "\e95c";
}

.nc-icon-outline.design_command:before {
  content: "\e95d";
}

.nc-icon-outline.design_compass:before {
  content: "\e95e";
}

.nc-icon-outline.design_contrast:before {
  content: "\e95f";
}

.nc-icon-outline.design_copy:before {
  content: "\e960";
}

.nc-icon-outline.design_crop:before {
  content: "\e961";
}

.nc-icon-outline.design_cursor-48:before {
  content: "\e962";
}

.nc-icon-outline.design_cursor-49:before {
  content: "\e963";
}

.nc-icon-outline.design_design-dev:before {
  content: "\e964";
}

.nc-icon-outline.design_design-responsive:before {
  content: "\e965";
}

.nc-icon-outline.design_design:before {
  content: "\e966";
}

.nc-icon-outline.design_distribute-horizontal:before {
  content: "\e967";
}

.nc-icon-outline.design_distribute-vertical:before {
  content: "\e968";
}

.nc-icon-outline.design_drag:before {
  content: "\e969";
}

.nc-icon-outline.design_eraser-32:before {
  content: "\e96a";
}

.nc-icon-outline.design_eraser-33:before {
  content: "\e96b";
}

.nc-icon-outline.design_eraser-46:before {
  content: "\e96c";
}

.nc-icon-outline.design_flip-horizontal:before {
  content: "\e96d";
}

.nc-icon-outline.design_flip-vertical:before {
  content: "\e96e";
}

.nc-icon-outline.design_image:before {
  content: "\e96f";
}

.nc-icon-outline.design_magnet:before {
  content: "\e970";
}

.nc-icon-outline.design_marker:before {
  content: "\e971";
}

.nc-icon-outline.design_measure-02:before {
  content: "\e972";
}

.nc-icon-outline.design_measure-17:before {
  content: "\e973";
}

.nc-icon-outline.design_measure-big:before {
  content: "\e974";
}

.nc-icon-outline.design_mobile-design:before {
  content: "\e975";
}

.nc-icon-outline.design_mobile-dev:before {
  content: "\e976";
}

.nc-icon-outline.design_mouse-08:before {
  content: "\e977";
}

.nc-icon-outline.design_mouse-09:before {
  content: "\e978";
}

.nc-icon-outline.design_mouse-10:before {
  content: "\e979";
}

.nc-icon-outline.design_newsletter-dev:before {
  content: "\e97a";
}

.nc-icon-outline.design_note-code:before {
  content: "\e97b";
}

.nc-icon-outline.design_paint-16:before {
  content: "\e97c";
}

.nc-icon-outline.design_paint-37:before {
  content: "\e97d";
}

.nc-icon-outline.design_paint-38:before {
  content: "\e97e";
}

.nc-icon-outline.design_paint-bucket-39:before {
  content: "\e97f";
}

.nc-icon-outline.design_paint-bucket-40:before {
  content: "\e980";
}

.nc-icon-outline.design_palette:before {
  content: "\e981";
}

.nc-icon-outline.design_pantone:before {
  content: "\e982";
}

.nc-icon-outline.design_paper-design:before {
  content: "\e983";
}

.nc-icon-outline.design_paper-dev:before {
  content: "\e984";
}

.nc-icon-outline.design_patch-19:before {
  content: "\e985";
}

.nc-icon-outline.design_patch-34:before {
  content: "\e986";
}

.nc-icon-outline.design_path-exclude:before {
  content: "\e987";
}

.nc-icon-outline.design_path-intersect:before {
  content: "\e988";
}

.nc-icon-outline.design_path-minus:before {
  content: "\e989";
}

.nc-icon-outline.design_path-unite:before {
  content: "\e98a";
}

.nc-icon-outline.design_pen-01:before {
  content: "\e98b";
}

.nc-icon-outline.design_pen-23:before {
  content: "\e98c";
}

.nc-icon-outline.design_pen-tool:before {
  content: "\e98d";
}

.nc-icon-outline.design_phone:before {
  content: "\e98e";
}

.nc-icon-outline.design_photo-editor:before {
  content: "\e98f";
}

.nc-icon-outline.design_scissors-dashed:before {
  content: "\e990";
}

.nc-icon-outline.design_scissors:before {
  content: "\e991";
}

.nc-icon-outline.design_shape-adjust:before {
  content: "\e992";
}

.nc-icon-outline.design_shape-circle:before {
  content: "\e993";
}

.nc-icon-outline.design_shape-polygon:before {
  content: "\e994";
}

.nc-icon-outline.design_shape-square:before {
  content: "\e995";
}

.nc-icon-outline.design_shape-triangle:before {
  content: "\e996";
}

.nc-icon-outline.design_shapes:before {
  content: "\e997";
}

.nc-icon-outline.design_sharpener:before {
  content: "\e998";
}

.nc-icon-outline.design_slice:before {
  content: "\e999";
}

.nc-icon-outline.design_spray:before {
  content: "\e99a";
}

.nc-icon-outline.design_stamp:before {
  content: "\e99b";
}

.nc-icon-outline.design_tablet-mobile:before {
  content: "\e99c";
}

.nc-icon-outline.design_tablet:before {
  content: "\e99d";
}

.nc-icon-outline.design_text:before {
  content: "\e99e";
}

.nc-icon-outline.design_todo:before {
  content: "\e99f";
}

.nc-icon-outline.design_usb:before {
  content: "\e9a0";
}

.nc-icon-outline.design_vector:before {
  content: "\e9a1";
}

.nc-icon-outline.design_wand-11:before {
  content: "\e9a2";
}

.nc-icon-outline.design_wand-99:before {
  content: "\e9a3";
}

.nc-icon-outline.design_watch-dev:before {
  content: "\e9a4";
}

.nc-icon-outline.design_web-design:before {
  content: "\e9a5";
}

.nc-icon-outline.design_webpage:before {
  content: "\e9a6";
}

.nc-icon-outline.design_window-code:before {
  content: "\e9a7";
}

.nc-icon-outline.design_window-dev:before {
  content: "\e9a8";
}

.nc-icon-outline.design_window-paragraph:before {
  content: "\e9a9";
}

.nc-icon-outline.design_window-responsive:before {
  content: "\e9aa";
}

.nc-icon-outline.clothes_baby:before {
  content: "\e9ab";
}

.nc-icon-outline.clothes_backpack:before {
  content: "\e9ac";
}

.nc-icon-outline.clothes_bag-21:before {
  content: "\e9ad";
}

.nc-icon-outline.clothes_bag-22:before {
  content: "\e9ae";
}

.nc-icon-outline.clothes_belt:before {
  content: "\e9af";
}

.nc-icon-outline.clothes_boot-woman:before {
  content: "\e9b0";
}

.nc-icon-outline.clothes_boot:before {
  content: "\e9b1";
}

.nc-icon-outline.clothes_bra:before {
  content: "\e9b2";
}

.nc-icon-outline.clothes_button:before {
  content: "\e9b3";
}

.nc-icon-outline.clothes_cap:before {
  content: "\e9b4";
}

.nc-icon-outline.clothes_coat:before {
  content: "\e9b5";
}

.nc-icon-outline.clothes_corset:before {
  content: "\e9b6";
}

.nc-icon-outline.clothes_dress-man:before {
  content: "\e9b7";
}

.nc-icon-outline.clothes_dress-woman:before {
  content: "\e9b8";
}

.nc-icon-outline.clothes_flip:before {
  content: "\e9b9";
}

.nc-icon-outline.clothes_glasses:before {
  content: "\e9ba";
}

.nc-icon-outline.clothes_gloves:before {
  content: "\e9bb";
}

.nc-icon-outline.clothes_hat-top:before {
  content: "\e9bc";
}

.nc-icon-outline.clothes_hat:before {
  content: "\e9bd";
}

.nc-icon-outline.clothes_hoodie:before {
  content: "\e9be";
}

.nc-icon-outline.clothes_iron-dont:before {
  content: "\e9bf";
}

.nc-icon-outline.clothes_iron:before {
  content: "\e9c0";
}

.nc-icon-outline.clothes_jeans-41:before {
  content: "\e9c1";
}

.nc-icon-outline.clothes_jeans-43:before {
  content: "\e9c2";
}

.nc-icon-outline.clothes_jeans-pocket:before {
  content: "\e9c3";
}

.nc-icon-outline.clothes_kitchen:before {
  content: "\e9c4";
}

.nc-icon-outline.clothes_long-sleeve:before {
  content: "\e9c5";
}

.nc-icon-outline.clothes_makeup:before {
  content: "\e9c6";
}

.nc-icon-outline.clothes_needle:before {
  content: "\e9c7";
}

.nc-icon-outline.clothes_pajamas:before {
  content: "\e9c8";
}

.nc-icon-outline.clothes_ring:before {
  content: "\e9c9";
}

.nc-icon-outline.clothes_scarf:before {
  content: "\e9ca";
}

.nc-icon-outline.clothes_shirt-business:before {
  content: "\e9cb";
}

.nc-icon-outline.clothes_shirt-buttons:before {
  content: "\e9cc";
}

.nc-icon-outline.clothes_shirt-neck:before {
  content: "\e9cd";
}

.nc-icon-outline.clothes_shirt:before {
  content: "\e9ce";
}

.nc-icon-outline.clothes_shoe-man:before {
  content: "\e9cf";
}

.nc-icon-outline.clothes_shoe-sport:before {
  content: "\e9d0";
}

.nc-icon-outline.clothes_shoe-woman:before {
  content: "\e9d1";
}

.nc-icon-outline.clothes_skirt:before {
  content: "\e9d2";
}

.nc-icon-outline.clothes_slacks-12:before {
  content: "\e9d3";
}

.nc-icon-outline.clothes_slacks-13:before {
  content: "\e9d4";
}

.nc-icon-outline.clothes_sock:before {
  content: "\e9d5";
}

.nc-icon-outline.clothes_tie-bow:before {
  content: "\e9d6";
}

.nc-icon-outline.clothes_tshirt-53:before {
  content: "\e9d7";
}

.nc-icon-outline.clothes_tshirt-54:before {
  content: "\e9d8";
}

.nc-icon-outline.clothes_tshirt-sport:before {
  content: "\e9d9";
}

.nc-icon-outline.clothes_underwear-man:before {
  content: "\e9da";
}

.nc-icon-outline.clothes_underwear:before {
  content: "\e9db";
}

.nc-icon-outline.clothes_vest-sport:before {
  content: "\e9dc";
}

.nc-icon-outline.clothes_vest:before {
  content: "\e9dd";
}

.nc-icon-outline.clothes_wash-30:before {
  content: "\e9de";
}

.nc-icon-outline.clothes_wash-60:before {
  content: "\e9df";
}

.nc-icon-outline.clothes_wash-90:before {
  content: "\e9e0";
}

.nc-icon-outline.clothes_wash-hand:before {
  content: "\e9e1";
}

.nc-icon-outline.clothes_wash:before {
  content: "\e9e2";
}

.nc-icon-outline.business_agenda:before {
  content: "\e9e3";
}

.nc-icon-outline.business_atm:before {
  content: "\e9e4";
}

.nc-icon-outline.business_award-48:before {
  content: "\e9e5";
}

.nc-icon-outline.business_award-49:before {
  content: "\e9e6";
}

.nc-icon-outline.business_award-74:before {
  content: "\e9e7";
}

.nc-icon-outline.business_badge:before {
  content: "\e9e8";
}

.nc-icon-outline.business_bank:before {
  content: "\e9e9";
}

.nc-icon-outline.business_board-27:before {
  content: "\e9ea";
}

.nc-icon-outline.business_board-28:before {
  content: "\e9eb";
}

.nc-icon-outline.business_board-29:before {
  content: "\e9ec";
}

.nc-icon-outline.business_board-30:before {
  content: "\e9ed";
}

.nc-icon-outline.business_books:before {
  content: "\e9ee";
}

.nc-icon-outline.business_briefcase-24:before {
  content: "\e9ef";
}

.nc-icon-outline.business_briefcase-25:before {
  content: "\e9f0";
}

.nc-icon-outline.business_briefcase-26:before {
  content: "\e9f1";
}

.nc-icon-outline.business_building:before {
  content: "\e9f2";
}

.nc-icon-outline.business_bulb-61:before {
  content: "\e9f3";
}

.nc-icon-outline.business_bulb-62:before {
  content: "\e9f4";
}

.nc-icon-outline.business_bulb-63:before {
  content: "\e9f5";
}

.nc-icon-outline.business_business-contact-85:before {
  content: "\e9f6";
}

.nc-icon-outline.business_business-contact-86:before {
  content: "\e9f7";
}

.nc-icon-outline.business_business-contact-87:before {
  content: "\e9f8";
}

.nc-icon-outline.business_business-contact-88:before {
  content: "\e9f9";
}

.nc-icon-outline.business_business-contact-89:before {
  content: "\e9fa";
}

.nc-icon-outline.business_businessman-03:before {
  content: "\e9fb";
}

.nc-icon-outline.business_businessman-04:before {
  content: "\e9fc";
}

.nc-icon-outline.business_calculator:before {
  content: "\e9fd";
}

.nc-icon-outline.business_chair:before {
  content: "\e9fe";
}

.nc-icon-outline.business_chart-bar-32:before {
  content: "\e9ff";
}

.nc-icon-outline.business_chart-bar-33:before {
  content: "\ea00";
}

.nc-icon-outline.business_chart-growth:before {
  content: "\ea01";
}

.nc-icon-outline.business_chart-pie-35:before {
  content: "\ea02";
}

.nc-icon-outline.business_chart-pie-36:before {
  content: "\ea03";
}

.nc-icon-outline.business_chart:before {
  content: "\ea04";
}

.nc-icon-outline.business_cheque:before {
  content: "\ea05";
}

.nc-icon-outline.business_coins:before {
  content: "\ea06";
}

.nc-icon-outline.business_connect:before {
  content: "\ea07";
}

.nc-icon-outline.business_contacts:before {
  content: "\ea08";
}

.nc-icon-outline.business_currency-dollar:before {
  content: "\ea09";
}

.nc-icon-outline.business_currency-euro:before {
  content: "\ea0a";
}

.nc-icon-outline.business_currency-pound:before {
  content: "\ea0b";
}

.nc-icon-outline.business_currency-yen:before {
  content: "\ea0c";
}

.nc-icon-outline.business_factory:before {
  content: "\ea0d";
}

.nc-icon-outline.business_globe:before {
  content: "\ea0e";
}

.nc-icon-outline.business_goal-64:before {
  content: "\ea0f";
}

.nc-icon-outline.business_goal-65:before {
  content: "\ea10";
}

.nc-icon-outline.business_gold:before {
  content: "\ea11";
}

.nc-icon-outline.business_hammer:before {
  content: "\ea12";
}

.nc-icon-outline.business_handout:before {
  content: "\ea13";
}

.nc-icon-outline.business_handshake:before {
  content: "\ea14";
}

.nc-icon-outline.business_hat:before {
  content: "\ea15";
}

.nc-icon-outline.business_hierarchy-53:before {
  content: "\ea16";
}

.nc-icon-outline.business_hierarchy-54:before {
  content: "\ea17";
}

.nc-icon-outline.business_hierarchy-55:before {
  content: "\ea18";
}

.nc-icon-outline.business_hierarchy-56:before {
  content: "\ea19";
}

.nc-icon-outline.business_laptop-71:before {
  content: "\ea1a";
}

.nc-icon-outline.business_laptop-72:before {
  content: "\ea1b";
}

.nc-icon-outline.business_laptop-91:before {
  content: "\ea1c";
}

.nc-icon-outline.business_law:before {
  content: "\ea1d";
}

.nc-icon-outline.business_math:before {
  content: "\ea1e";
}

.nc-icon-outline.business_money-11:before {
  content: "\ea1f";
}

.nc-icon-outline.business_money-12:before {
  content: "\ea20";
}

.nc-icon-outline.business_money-13:before {
  content: "\ea21";
}

.nc-icon-outline.business_money-bag:before {
  content: "\ea22";
}

.nc-icon-outline.business_money-coins:before {
  content: "\ea23";
}

.nc-icon-outline.business_money-growth:before {
  content: "\ea24";
}

.nc-icon-outline.business_money-time:before {
  content: "\ea25";
}

.nc-icon-outline.business_net:before {
  content: "\ea26";
}

.nc-icon-outline.business_notes:before {
  content: "\ea27";
}

.nc-icon-outline.business_payment:before {
  content: "\ea28";
}

.nc-icon-outline.business_percentage-38:before {
  content: "\ea29";
}

.nc-icon-outline.business_percentage-39:before {
  content: "\ea2a";
}

.nc-icon-outline.business_pig:before {
  content: "\ea2b";
}

.nc-icon-outline.business_pin:before {
  content: "\ea2c";
}

.nc-icon-outline.business_plug:before {
  content: "\ea2d";
}

.nc-icon-outline.business_progress:before {
  content: "\ea2e";
}

.nc-icon-outline.business_round-dollar:before {
  content: "\ea2f";
}

.nc-icon-outline.business_round-euro:before {
  content: "\ea30";
}

.nc-icon-outline.business_round-pound:before {
  content: "\ea31";
}

.nc-icon-outline.business_round-yen:before {
  content: "\ea32";
}

.nc-icon-outline.business_safe:before {
  content: "\ea33";
}

.nc-icon-outline.business_scale:before {
  content: "\ea34";
}

.nc-icon-outline.business_sign:before {
  content: "\ea35";
}

.nc-icon-outline.business_signature:before {
  content: "\ea36";
}

.nc-icon-outline.business_stock:before {
  content: "\ea37";
}

.nc-icon-outline.business_strategy:before {
  content: "\ea38";
}

.nc-icon-outline.business_tie-01:before {
  content: "\ea39";
}

.nc-icon-outline.business_tie-02:before {
  content: "\ea3a";
}

.nc-icon-outline.business_wallet-43:before {
  content: "\ea3b";
}

.nc-icon-outline.business_wallet-44:before {
  content: "\ea3c";
}

.nc-icon-outline.business_wallet-90:before {
  content: "\ea3d";
}

.nc-icon-outline.arrows-1_back-78:before {
  content: "\ea3e";
}

.nc-icon-outline.arrows-1_back-80:before {
  content: "\ea3f";
}

.nc-icon-outline.arrows-1_bold-direction:before {
  content: "\ea40";
}

.nc-icon-outline.arrows-1_bold-down:before {
  content: "\ea41";
}

.nc-icon-outline.arrows-1_bold-left:before {
  content: "\ea42";
}

.nc-icon-outline.arrows-1_bold-right:before {
  content: "\ea43";
}

.nc-icon-outline.arrows-1_bold-up:before {
  content: "\ea44";
}

.nc-icon-outline.arrows-1_circle-down-12:before {
  content: "\ea45";
}

.nc-icon-outline.arrows-1_circle-down-40:before {
  content: "\ea46";
}

.nc-icon-outline.arrows-1_circle-left-10:before {
  content: "\ea47";
}

.nc-icon-outline.arrows-1_circle-left-38:before {
  content: "\ea48";
}

.nc-icon-outline.arrows-1_circle-right-09:before {
  content: "\ea49";
}

.nc-icon-outline.arrows-1_circle-right-37:before {
  content: "\ea4a";
}

.nc-icon-outline.arrows-1_circle-up-11:before {
  content: "\ea4b";
}

.nc-icon-outline.arrows-1_circle-up-39:before {
  content: "\ea4c";
}

.nc-icon-outline.arrows-1_cloud-download-93:before {
  content: "\ea4d";
}

.nc-icon-outline.arrows-1_cloud-download-95:before {
  content: "\ea4e";
}

.nc-icon-outline.arrows-1_cloud-upload-94:before {
  content: "\ea4f";
}

.nc-icon-outline.arrows-1_cloud-upload-96:before {
  content: "\ea50";
}

.nc-icon-outline.arrows-1_curved-next:before {
  content: "\ea51";
}

.nc-icon-outline.arrows-1_curved-previous:before {
  content: "\ea52";
}

.nc-icon-outline.arrows-1_direction-53:before {
  content: "\ea53";
}

.nc-icon-outline.arrows-1_direction-56:before {
  content: "\ea54";
}

.nc-icon-outline.arrows-1_double-left:before {
  content: "\ea55";
}

.nc-icon-outline.arrows-1_double-right:before {
  content: "\ea56";
}

.nc-icon-outline.arrows-1_download:before {
  content: "\ea57";
}

.nc-icon-outline.arrows-1_enlarge-diagonal-43:before {
  content: "\ea58";
}

.nc-icon-outline.arrows-1_enlarge-diagonal-44:before {
  content: "\ea59";
}

.nc-icon-outline.arrows-1_enlarge-horizontal:before {
  content: "\ea5a";
}

.nc-icon-outline.arrows-1_enlarge-vertical:before {
  content: "\ea5b";
}

.nc-icon-outline.arrows-1_fit-horizontal:before {
  content: "\ea5c";
}

.nc-icon-outline.arrows-1_fit-vertical:before {
  content: "\ea5d";
}

.nc-icon-outline.arrows-1_fullscreen-70:before {
  content: "\ea5e";
}

.nc-icon-outline.arrows-1_fullscreen-71:before {
  content: "\ea5f";
}

.nc-icon-outline.arrows-1_fullscreen-76:before {
  content: "\ea60";
}

.nc-icon-outline.arrows-1_fullscreen-77:before {
  content: "\ea61";
}

.nc-icon-outline.arrows-1_fullscreen-double-74:before {
  content: "\ea62";
}

.nc-icon-outline.arrows-1_fullscreen-double-75:before {
  content: "\ea63";
}

.nc-icon-outline.arrows-1_fullscreen-split-72:before {
  content: "\ea64";
}

.nc-icon-outline.arrows-1_fullscreen-split-73:before {
  content: "\ea65";
}

.nc-icon-outline.arrows-1_log-in:before {
  content: "\ea66";
}

.nc-icon-outline.arrows-1_log-out:before {
  content: "\ea67";
}

.nc-icon-outline.arrows-1_loop-82:before {
  content: "\ea68";
}

.nc-icon-outline.arrows-1_loop-83:before {
  content: "\ea69";
}

.nc-icon-outline.arrows-1_minimal-down:before {
  content: "\ea6a";
}

.nc-icon-outline.arrows-1_minimal-left:before {
  content: "\ea6b";
}

.nc-icon-outline.arrows-1_minimal-right:before {
  content: "\ea6c";
}

.nc-icon-outline.arrows-1_minimal-up:before {
  content: "\ea6d";
}

.nc-icon-outline.arrows-1_redo-79:before {
  content: "\ea6e";
}

.nc-icon-outline.arrows-1_redo-81:before {
  content: "\ea6f";
}

.nc-icon-outline.arrows-1_refresh-68:before {
  content: "\ea70";
}

.nc-icon-outline.arrows-1_refresh-69:before {
  content: "\ea71";
}

.nc-icon-outline.arrows-1_round-down:before {
  content: "\ea72";
}

.nc-icon-outline.arrows-1_round-left:before {
  content: "\ea73";
}

.nc-icon-outline.arrows-1_round-right:before {
  content: "\ea74";
}

.nc-icon-outline.arrows-1_round-up:before {
  content: "\ea75";
}

.nc-icon-outline.arrows-1_share-66:before {
  content: "\ea76";
}

.nc-icon-outline.arrows-1_share-91:before {
  content: "\ea77";
}

.nc-icon-outline.arrows-1_share-92:before {
  content: "\ea78";
}

.nc-icon-outline.arrows-1_shuffle-97:before {
  content: "\ea79";
}

.nc-icon-outline.arrows-1_shuffle-98:before {
  content: "\ea7a";
}

.nc-icon-outline.arrows-1_simple-down:before {
  content: "\ea7b";
}

.nc-icon-outline.arrows-1_simple-left:before {
  content: "\ea7c";
}

.nc-icon-outline.arrows-1_simple-right:before {
  content: "\ea7d";
}

.nc-icon-outline.arrows-1_simple-up:before {
  content: "\ea7e";
}

.nc-icon-outline.arrows-1_small-triangle-down:before {
  content: "\ea7f";
}

.nc-icon-outline.arrows-1_small-triangle-left:before {
  content: "\ea80";
}

.nc-icon-outline.arrows-1_small-triangle-right:before {
  content: "\ea81";
}

.nc-icon-outline.arrows-1_small-triangle-up:before {
  content: "\ea82";
}

.nc-icon-outline.arrows-1_square-down:before {
  content: "\ea83";
}

.nc-icon-outline.arrows-1_square-left:before {
  content: "\ea84";
}

.nc-icon-outline.arrows-1_square-right:before {
  content: "\ea85";
}

.nc-icon-outline.arrows-1_square-up:before {
  content: "\ea86";
}

.nc-icon-outline.arrows-1_strong-down:before {
  content: "\ea87";
}

.nc-icon-outline.arrows-1_strong-left:before {
  content: "\ea88";
}

.nc-icon-outline.arrows-1_strong-right:before {
  content: "\ea89";
}

.nc-icon-outline.arrows-1_strong-up:before {
  content: "\ea8a";
}

.nc-icon-outline.arrows-1_tail-down:before {
  content: "\ea8b";
}

.nc-icon-outline.arrows-1_tail-left:before {
  content: "\ea8c";
}

.nc-icon-outline.arrows-1_tail-right:before {
  content: "\ea8d";
}

.nc-icon-outline.arrows-1_tail-triangle-down:before {
  content: "\ea8e";
}

.nc-icon-outline.arrows-1_tail-triangle-left:before {
  content: "\ea8f";
}

.nc-icon-outline.arrows-1_tail-triangle-right:before {
  content: "\ea90";
}

.nc-icon-outline.arrows-1_tail-triangle-up:before {
  content: "\ea91";
}

.nc-icon-outline.arrows-1_tail-up:before {
  content: "\ea92";
}

.nc-icon-outline.arrows-1_trend-down:before {
  content: "\ea93";
}

.nc-icon-outline.arrows-1_trend-up:before {
  content: "\ea94";
}

.nc-icon-outline.arrows-1_triangle-down-20:before {
  content: "\ea95";
}

.nc-icon-outline.arrows-1_triangle-down-65:before {
  content: "\ea96";
}

.nc-icon-outline.arrows-1_triangle-left-18:before {
  content: "\ea97";
}

.nc-icon-outline.arrows-1_triangle-left-63:before {
  content: "\ea98";
}

.nc-icon-outline.arrows-1_triangle-right-17:before {
  content: "\ea99";
}

.nc-icon-outline.arrows-1_triangle-right-62:before {
  content: "\ea9a";
}

.nc-icon-outline.arrows-1_triangle-up-19:before {
  content: "\ea9b";
}

.nc-icon-outline.arrows-1_triangle-up-64:before {
  content: "\ea9c";
}

.nc-icon-outline.arrows-1_window-zoom-in:before {
  content: "\ea9d";
}

.nc-icon-outline.arrows-1_window-zoom-out:before {
  content: "\ea9e";
}

.nc-icon-outline.arrows-1_zoom-88:before {
  content: "\ea9f";
}

.nc-icon-outline.arrows-1_zoom-99:before {
  content: "\eaa0";
}

.nc-icon-outline.arrows-1_zoom-100:before {
  content: "\eaa1";
}

.nc-icon-outline.arrows-2_block-down:before {
  content: "\eaa2";
}

.nc-icon-outline.arrows-2_block-left:before {
  content: "\eaa3";
}

.nc-icon-outline.arrows-2_block-right:before {
  content: "\eaa4";
}

.nc-icon-outline.arrows-2_block-up:before {
  content: "\eaa5";
}

.nc-icon-outline.arrows-2_circle-in:before {
  content: "\eaa6";
}

.nc-icon-outline.arrows-2_circle-out:before {
  content: "\eaa7";
}

.nc-icon-outline.arrows-2_circuit-round:before {
  content: "\eaa8";
}

.nc-icon-outline.arrows-2_circuit:before {
  content: "\eaa9";
}

.nc-icon-outline.arrows-2_computer-upload:before {
  content: "\eaaa";
}

.nc-icon-outline.arrows-2_conversion:before {
  content: "\eaab";
}

.nc-icon-outline.arrows-2_corner-down-round:before {
  content: "\eaac";
}

.nc-icon-outline.arrows-2_corner-down:before {
  content: "\eaad";
}

.nc-icon-outline.arrows-2_corner-left-down:before {
  content: "\eaae";
}

.nc-icon-outline.arrows-2_corner-left-round:before {
  content: "\eaaf";
}

.nc-icon-outline.arrows-2_corner-left:before {
  content: "\eab0";
}

.nc-icon-outline.arrows-2_corner-right-down:before {
  content: "\eab1";
}

.nc-icon-outline.arrows-2_corner-right-round:before {
  content: "\eab2";
}

.nc-icon-outline.arrows-2_corner-right:before {
  content: "\eab3";
}

.nc-icon-outline.arrows-2_corner-up-left:before {
  content: "\eab4";
}

.nc-icon-outline.arrows-2_corner-up-right:before {
  content: "\eab5";
}

.nc-icon-outline.arrows-2_corner-up-round:before {
  content: "\eab6";
}

.nc-icon-outline.arrows-2_corner-up:before {
  content: "\eab7";
}

.nc-icon-outline.arrows-2_cross-down:before {
  content: "\eab8";
}

.nc-icon-outline.arrows-2_cross-horizontal:before {
  content: "\eab9";
}

.nc-icon-outline.arrows-2_cross-left:before {
  content: "\eaba";
}

.nc-icon-outline.arrows-2_cross-right:before {
  content: "\eabb";
}

.nc-icon-outline.arrows-2_cross-up:before {
  content: "\eabc";
}

.nc-icon-outline.arrows-2_cross-vertical:before {
  content: "\eabd";
}

.nc-icon-outline.arrows-2_curve-circuit:before {
  content: "\eabe";
}

.nc-icon-outline.arrows-2_curve-directions:before {
  content: "\eabf";
}

.nc-icon-outline.arrows-2_curve-split:before {
  content: "\eac0";
}

.nc-icon-outline.arrows-2_delete-49:before {
  content: "\eac1";
}

.nc-icon-outline.arrows-2_delete-50:before {
  content: "\eac2";
}

.nc-icon-outline.arrows-2_direction:before {
  content: "\eac3";
}

.nc-icon-outline.arrows-2_dots-download:before {
  content: "\eac4";
}

.nc-icon-outline.arrows-2_dots-upload:before {
  content: "\eac5";
}

.nc-icon-outline.arrows-2_eject:before {
  content: "\eac6";
}

.nc-icon-outline.arrows-2_enlarge-circle:before {
  content: "\eac7";
}

.nc-icon-outline.arrows-2_file-download-87:before {
  content: "\eac8";
}

.nc-icon-outline.arrows-2_file-download-89:before {
  content: "\eac9";
}

.nc-icon-outline.arrows-2_file-download-94:before {
  content: "\eaca";
}

.nc-icon-outline.arrows-2_file-upload-86:before {
  content: "\eacb";
}

.nc-icon-outline.arrows-2_file-upload-88:before {
  content: "\eacc";
}

.nc-icon-outline.arrows-2_file-upload-93:before {
  content: "\eacd";
}

.nc-icon-outline.arrows-2_fork-round:before {
  content: "\eace";
}

.nc-icon-outline.arrows-2_fork:before {
  content: "\eacf";
}

.nc-icon-outline.arrows-2_hit-down:before {
  content: "\ead0";
}

.nc-icon-outline.arrows-2_hit-left:before {
  content: "\ead1";
}

.nc-icon-outline.arrows-2_hit-right:before {
  content: "\ead2";
}

.nc-icon-outline.arrows-2_hit-up:before {
  content: "\ead3";
}

.nc-icon-outline.arrows-2_lines:before {
  content: "\ead4";
}

.nc-icon-outline.arrows-2_log-out:before {
  content: "\ead5";
}

.nc-icon-outline.arrows-2_loop:before {
  content: "\ead6";
}

.nc-icon-outline.arrows-2_merge-round:before {
  content: "\ead7";
}

.nc-icon-outline.arrows-2_merge:before {
  content: "\ead8";
}

.nc-icon-outline.arrows-2_move-05:before {
  content: "\ead9";
}

.nc-icon-outline.arrows-2_move-06:before {
  content: "\eada";
}

.nc-icon-outline.arrows-2_move-92:before {
  content: "\eadb";
}

.nc-icon-outline.arrows-2_move-down-right:before {
  content: "\eadc";
}

.nc-icon-outline.arrows-2_move-down:before {
  content: "\eadd";
}

.nc-icon-outline.arrows-2_move-left:before {
  content: "\eade";
}

.nc-icon-outline.arrows-2_move-right:before {
  content: "\eadf";
}

.nc-icon-outline.arrows-2_move-up-left:before {
  content: "\eae0";
}

.nc-icon-outline.arrows-2_move-up:before {
  content: "\eae1";
}

.nc-icon-outline.arrows-2_push-next:before {
  content: "\eae2";
}

.nc-icon-outline.arrows-2_push-previous:before {
  content: "\eae3";
}

.nc-icon-outline.arrows-2_reload:before {
  content: "\eae4";
}

.nc-icon-outline.arrows-2_replay:before {
  content: "\eae5";
}

.nc-icon-outline.arrows-2_rotate-left:before {
  content: "\eae6";
}

.nc-icon-outline.arrows-2_rotate-right:before {
  content: "\eae7";
}

.nc-icon-outline.arrows-2_round-left-down:before {
  content: "\eae8";
}

.nc-icon-outline.arrows-2_round-right-down:before {
  content: "\eae9";
}

.nc-icon-outline.arrows-2_round-up-left:before {
  content: "\eaea";
}

.nc-icon-outline.arrows-2_round-up-right:before {
  content: "\eaeb";
}

.nc-icon-outline.arrows-2_select-83:before {
  content: "\eaec";
}

.nc-icon-outline.arrows-2_select-84:before {
  content: "\eaed";
}

.nc-icon-outline.arrows-2_separate-round:before {
  content: "\eaee";
}

.nc-icon-outline.arrows-2_separate:before {
  content: "\eaef";
}

.nc-icon-outline.arrows-2_share-left:before {
  content: "\eaf0";
}

.nc-icon-outline.arrows-2_share-right:before {
  content: "\eaf1";
}

.nc-icon-outline.arrows-2_skew-down:before {
  content: "\eaf2";
}

.nc-icon-outline.arrows-2_skew-left:before {
  content: "\eaf3";
}

.nc-icon-outline.arrows-2_skew-right:before {
  content: "\eaf4";
}

.nc-icon-outline.arrows-2_skew-up:before {
  content: "\eaf5";
}

.nc-icon-outline.arrows-2_small-left:before {
  content: "\eaf6";
}

.nc-icon-outline.arrows-2_small-right:before {
  content: "\eaf7";
}

.nc-icon-outline.arrows-2_split-horizontal:before {
  content: "\eaf8";
}

.nc-icon-outline.arrows-2_split-round:before {
  content: "\eaf9";
}

.nc-icon-outline.arrows-2_split-vertical:before {
  content: "\eafa";
}

.nc-icon-outline.arrows-2_split:before {
  content: "\eafb";
}

.nc-icon-outline.arrows-2_square-download:before {
  content: "\eafc";
}

.nc-icon-outline.arrows-2_square-upload:before {
  content: "\eafd";
}

.nc-icon-outline.arrows-2_time:before {
  content: "\eafe";
}

.nc-icon-outline.arrows-2_triangle-down:before {
  content: "\eaff";
}

.nc-icon-outline.arrows-2_triangle-left:before {
  content: "\eb00";
}

.nc-icon-outline.arrows-2_triangle-right:before {
  content: "\eb01";
}

.nc-icon-outline.arrows-2_triangle-up:before {
  content: "\eb02";
}

.nc-icon-outline.arrows-2_unite-round:before {
  content: "\eb03";
}

.nc-icon-outline.arrows-2_unite:before {
  content: "\eb04";
}

.nc-icon-outline.arrows-2_zoom:before {
  content: "\eb05";
}

.nc-icon-outline.arrows-3_circle-down:before {
  content: "\eb06";
}

.nc-icon-outline.arrows-3_circle-left:before {
  content: "\eb07";
}

.nc-icon-outline.arrows-3_circle-right:before {
  content: "\eb08";
}

.nc-icon-outline.arrows-3_circle-simple-down:before {
  content: "\eb09";
}

.nc-icon-outline.arrows-3_circle-simple-left:before {
  content: "\eb0a";
}

.nc-icon-outline.arrows-3_circle-simple-right:before {
  content: "\eb0b";
}

.nc-icon-outline.arrows-3_circle-simple-up:before {
  content: "\eb0c";
}

.nc-icon-outline.arrows-3_circle-up:before {
  content: "\eb0d";
}

.nc-icon-outline.arrows-3_cloud-refresh:before {
  content: "\eb0e";
}

.nc-icon-outline.arrows-3_separate:before {
  content: "\eb0f";
}

.nc-icon-outline.arrows-3_small-down:before {
  content: "\eb10";
}

.nc-icon-outline.arrows-3_small-up:before {
  content: "\eb11";
}

.nc-icon-outline.arrows-3_square-corner-down-left-17:before {
  content: "\eb12";
}

.nc-icon-outline.arrows-3_square-corner-down-left-18:before {
  content: "\eb13";
}

.nc-icon-outline.arrows-3_square-corner-up-left:before {
  content: "\eb14";
}

.nc-icon-outline.arrows-3_square-corner-up-right:before {
  content: "\eb15";
}

.nc-icon-outline.arrows-3_square-down-06:before {
  content: "\eb16";
}

.nc-icon-outline.arrows-3_square-down-22:before {
  content: "\eb17";
}

.nc-icon-outline.arrows-3_square-enlarge:before {
  content: "\eb18";
}

.nc-icon-outline.arrows-3_square-left-04:before {
  content: "\eb19";
}

.nc-icon-outline.arrows-3_square-left-20:before {
  content: "\eb1a";
}

.nc-icon-outline.arrows-3_square-right-03:before {
  content: "\eb1b";
}

.nc-icon-outline.arrows-3_square-right-19:before {
  content: "\eb1c";
}

.nc-icon-outline.arrows-3_square-simple-down:before {
  content: "\eb1d";
}

.nc-icon-outline.arrows-3_square-simple-left:before {
  content: "\eb1e";
}

.nc-icon-outline.arrows-3_square-simple-right:before {
  content: "\eb1f";
}

.nc-icon-outline.arrows-3_square-simple-up:before {
  content: "\eb20";
}

.nc-icon-outline.arrows-3_square-up-05:before {
  content: "\eb21";
}

.nc-icon-outline.arrows-3_square-up-21:before {
  content: "\eb22";
}

.nc-icon-outline.arrows-3_square-zoom:before {
  content: "\eb23";
}

.nc-icon-outline.arrows-3_super-bold-down:before {
  content: "\eb24";
}

.nc-icon-outline.arrows-3_super-bold-left:before {
  content: "\eb25";
}

.nc-icon-outline.arrows-3_super-bold-right:before {
  content: "\eb26";
}

.nc-icon-outline.arrows-3_super-bold-up:before {
  content: "\eb27";
}

.nc-icon-outline.objects-outline_binocular:before {
  content: "\e7d2";
}

.nc-icon-outline.design-outline_wand-99:before {
  content: "\e9a3";
}

.nc-icon-outline.business-outline_hierarchy-53:before {
  content: "\ea16";
}

.nc-icon-outline.business-outline_goal-64:before {
  content: "\ea0f";
}

.nc-icon-outline.health-outline_pulse-chart:before {
  content: "\e8ac";
}
