.gh-input-text-inline {
  background: none;
  border: 0;
  outline: none;
  width: 100%;
  font-size: 1em;
  padding: 5px;
  color: @brand-subtler;
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;
  margin: 0 -5px;

  &.code {
    font-family: monospace;
  }

  &:hover {
    cursor: text;
  }

  &::placeholder {
    color: lighten(@brand-subtler, 20%);
  }

  &:empty:before {
    content: attr(data-placeholder);
    color: lighten(@brand-subtler, 20%);
    display: inline-block;
  }

  &.title {
    font-size: 2em;
    letter-spacing: -0.02em;
    font-weight: @bold;
    line-height: 1.2em;
    color: @brand-dark;
  }

  &:hover {
    background: lighten(@brand-info, 37%);
  }

  &:focus,
  &:active {
    background: lighten(@brand-info, 37%);

    &:empty:before {
      color: lighten(@brand-subtler, 10%);
    }
  }
}

.due-date {
  display: inline-block;

  .gh-select-toggle {
    padding: 3px 15px 3px 15px;
    border: 1px solid darken(@brand-light, 3%);
    &:before {
      content: none;
    }
  }

  img.system-logo {
    width: 20px;
  }

  cursor: pointer;
  .grey {
    color: @brand-dark-grey;
    opacity: 0.64;
    font-size: 14px;
    margin-right: 25px;
  }
  .green {
    color: @brand-green;
    font-size: 14px;
    margin-right: 25px;
  }
  .red {
    color: @brand-red;
    font-size: 14px;
    margin-right: 25px;
  }
  .remove {
    float: right;
    width: 12px;
    height: 12px;
    background: @brand-green;
    border-radius: 30px;
    margin-top: 6px;
    color: @grey-10-v2;
    text-align: center;
    position: absolute;
    margin-left: 10px;
    transform: rotate(45deg);

    &.grey {
      background: @brand-dark-grey;
    }

    &.red {
      background: @brand-red;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: linear-gradient(@grey-10-v2, @grey-10-v2),
        linear-gradient(@grey-10-v2, @grey-10-v2);
      background-size:
        9px 2px,
        2px 9px;
      background-position:
        2px 5px,
        5px 2px;
      background-repeat: no-repeat;
    }
  }
}

.gh-input-text-clear {
  position: relative;
  display: inline-block;

  .gh-clear-input {
    position: absolute;
    right: 10px;
    top: 10px;
    background: darken(@brand-light, 3%);
    width: 20px;
    border-radius: 10px;
    text-align: center;
    height: 20px;
    font-size: 8px;
    line-height: 20px;
    color: @brand-subtle;
    cursor: pointer;

    &:hover {
      color: @brand-dark;
    }
    &.small-input {
      top: 5px;
    }
  }
}

.gh-typeahead {
  position: relative;

  .dropdown-menu {
    padding: 0;
    margin: 0;
    min-width: auto;
    width: fit-content;
    width: -moz-fit-content;

    li {
      a {
        padding: 6px 10px;
        color: @brand-dark;
        font-size: 15px;
        cursor: pointer;
      }

      &.active {
        a {
          color: @grey-10-v2;
          background: @brand-jira;
          width: 100%;
        }
      }
    }
  }

  input::-ms-clear {
    display: none;
  }
}

.gh-f-large {
  max-width: 660px;

  &.center {
    margin: 0 auto;
    position: relative;
  }
}

.gh-f-title {
  font-size: 2.5em;
  font-weight: @bold;
  color: @brand-dark;
  margin-bottom: 3px;
}

.gh-f-title-medium {
  font-size: 1.6em;
  font-weight: @regular;
  color: @brand-dark;
  margin-bottom: 3px;
}

.gh-f-description {
  font-size: 1.1em;
  margin-bottom: 15px;
  color: fade(@brand-dark, 65%);

  .questionmark {
    display: inline-block;
    right: 5px;
    top: 12px;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    line-height: 18px;
    background: darken(@brand-light, 7%);
    text-align: center;
    color: @grey-10-v2;
    font-size: 12px;
    font-weight: @bold;
  }
}

.gh-fg {
  padding: 15px 0;
  border-bottom: 2px solid @brand-light;

  .gh-fg-title {
    font-size: 1.05em;
    line-height: 1.4em;
    font-weight: 600;
    color: @brand-dark;
    margin-bottom: 4px;
    position: relative;

    &.required:before {
      position: absolute;
      content: "*";
      left: -12px;
      top: -3px;
      color: red;
    }

    span {
      font-weight: @regular;
      color: lighten(@brand-subtle, 15%);
    }

    &.gh-fg-title-clickable {
      cursor: pointer;

      &:hover {
        color: @brand-subtle;
        cursor: pointer;
      }
    }
  }

  .gh-fg-description {
    margin-bottom: 15px;
    color: fade(@brand-dark, 45%);
  }

  .gh-checkbox-group {
    .gh-checkbox-container {
      margin-top: 10px;

      label {
        display: inline;
        padding-left: 5px;
      }

      .gh-fg-description {
        padding-left: 23px;
      }
    }
  }

  &.gh-fg-first {
    padding-top: 0;
  }

  &.gh-fg-last {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.gh-fa {
  margin-top: 40px;

  &.gh-fa-fixed {
    position: fixed;
    padding: 20px;
    background: @brand-light;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.gh-input-select {
  font-size: 1.2em;
  width: 100%;
  padding: 0 15px;
  height: 42px;
  line-height: 42px;
  outline: none;
  background: @grey-10-v2;
  border: 3px solid @brand-light;
  border-radius: 4px;
  transition: border-color 0.2s ease-out;

  @supports (-moz-appearance: none) {
    padding: 0 15px;
  }

  &:focus {
    border-color: @link;
  }

  &:disabled {
    background: @brand-light;
    color: @brand-subtle;
    cursor: not-allowed;
  }

  &.sm {
    height: 35px;
    padding: 5px 10px;
    font-size: 1.1em;

    &::placeholder {
      font-size: 12px;
    }
  }

  &.gh-xs {
    height: 30px;
    padding: 0 7px;
    font-size: 1em;
    line-height: 30px;

    &::placeholder {
      font-size: 12px;
    }
  }
}

.gh-input-search-text {
  width: 100%;
  height: 30px;
  background: @grey-10-v2;
  border: 1px solid @grey-60-v2;
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px rgba(116, 133, 162, 0.15);
  border-radius: 38px;
  padding-left: 9px;
  color: @ink-60-v2;
  font-size: 1em;
  font-family:
    SF Pro Text,
    sans-serif;
  font-style: normal;
  font-weight: normal;
  outline: none;
}

.gh-input-text {
  font-size: 1.2em;
  width: 100%;
  padding: 10px 15px;
  height: 42px;
  outline: none;
  border: 2px solid darken(@brand-light, 3%);
  border-radius: 4px;
  transition: border-color 0.2s ease-out;

  &.code {
    font-family: monospace;
  }

  &.disabled,
  &[disabled] {
    background: @brand-light;
    color: @brand-grey;
  }

  &.gh-xxlg {
    height: 60px;
    line-height: 60px;
    padding: 19px 20px;
    font-weight: @medium;
    font-size: 2em;
  }

  @-moz-document url-prefix() {
    &.gh-xxlg {
      font-size: 1.5em; /*reduce font size to match what is seen in chrome*/
    }
  }

  &:focus {
    border-color: @link;
  }

  .placeholder();

  &.sm {
    height: 35px;
    line-height: 35px;
    padding: 5px 10px;
    font-size: 1.1em;

    &::placeholder {
      font-size: 12px;
    }
  }

  &.gh-xs {
    height: 30px;
    padding: 0 7px;
    font-size: 1em;
    line-height: 30px;

    &::placeholder {
      font-size: 12px;
    }
  }
}

.gh-input-info {
  padding-right: 40px !important;
  position: relative;

  .gh-b-input-info {
    position: absolute;
    right: 5px;
    top: 12px;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    line-height: 18px;
    background: darken(@brand-light, 7%);
    text-align: center;
    color: @grey-10-v2;
    font-size: 12px;
    font-weight: @bold;
    cursor: help;

    &.pink {
      background: @brand-pink;
    }

    &.green {
      background: @brand-green;
    }

    .ui-1_check {
      font-size: 12px;
      line-height: 18px;
      font-weight: @bold;
    }
  }
}

.gh-fe {
  font-weight: @medium;
  color: @red-100-v2;
  font-size: 1em;
  margin: 5px 0 3px 0;
}

.gh-textarea {
  font-size: 1.2em;
  width: 100%;
  padding: 10px 15px;
  outline: none;
  border: 2px solid darken(@brand-light, 3%);
  border-radius: 4px;
  transition: border-color 0.2s ease-out;
  resize: none;

  &.gh-textarea-narrow {
    height: 80px;
  }

  &.gh-textarea-big {
    height: 200px;
  }

  &:focus {
    border-color: @link;
  }

  .placeholder();

  &.sm {
    height: 35px;
    padding: 5px 10px;
    font-size: 1.1em;

    &::placeholder {
      font-size: 12px;
    }
  }
}

.gh-dynamic-input-group {
  position: relative;
  display: flex;
  height: 40px;

  &.gh-xxlg {
    height: 60px;

    .gh-dynamic-prefix,
    .gh-dynamic-suffix {
      padding: 0 20px;
      line-height: 56px;
      font-size: 2em;
    }
  }

  &.suffixed {
    flex-direction: row-reverse;
  }

  .gh-dynamic-prefix,
  .gh-dynamic-suffix {
    padding: 0 15px;
    width: fit-content;
    width: -moz-fit-content;
    border: 2px solid darken(@brand-light, 3%);
    white-space: nowrap;
    line-height: 38px;
    color: @brand-grey;
  }

  .gh-dynamic-prefix {
    border-right: none;
    border-radius: 4px 0 0 4px;
  }

  .gh-dynamic-suffix {
    border-left: none;
    border-radius: 0 4px 4px 0;
  }

  .gh-input-text,
  .gh-input-text-v2 {
    &.dynamic-prefix {
      border-radius: 0 4px 4px 0;
    }

    &.dynamic-suffix {
      border-radius: 4px 0 0 4px;
    }
  }

  &.required {
    &:before {
      position: absolute;
      left: -16px;
      top: -3px;
      font-size: 25px;
      content: "*";
      color: @brand-jira;
    }
  }
}

.parent-okr-selector-hint-popover {
  .parent-okr-selector-hint-title {
    font-weight: @medium;
    font-size: 15px;
  }

  .parent-okr-selector-hint-body {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
