.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.align-items-c {
  align-items: center;
}

.align-items-st {
  align-items: flex-start;
}

.align-items-e {
  align-items: flex-end;
}

.align-items-s {
  align-items: stretch;
}

.align-self-c {
  align-self: center;
}

.align-self-st {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.justify-cont-c {
  justify-content: center;
}

.justify-cont-sb {
  justify-content: space-between;
}

.justify-cont-fe {
  justify-content: flex-end;
}

.flex-w-w {
  flex-wrap: wrap;
}

.flex-d-c {
  flex-direction: column;
}

.flex-d-r {
  flex-direction: row;
}

.centered-vert {
  &:extend(.d-flex,
  .align-items-c);
}

.vert-start {
  &:extend(.d-flex,
  .align-items-st);
}

.centered-horiz {
  &:extend(.d-flex,
  .justify-cont-c);
}

.centered-content {
  &:extend(.d-flex,
  .align-items-c,
  .justify-cont-c);
}

.space-between-horiz {
  &:extend(.d-flex,
  .justify-cont-sb);
}

.space-between-vert {
  &:extend(.d-flex,
  .flex-d-c,
  .justify-cont-sb);
}

.align-start-vert {
  &:extend(.d-flex,
  .flex-d-c,
  .align-items-st);
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-a {
  flex: auto;
}

.flex-n {
  flex: none;
}

.flex-w {
  flex-wrap: wrap;
}

.p-rel {
  position: relative;
}

.p-abs {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.pos-abs-full-size {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.vh-100 {
  height: 100vh;
}
