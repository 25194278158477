.gh-select-toggle {
  font-size: 1.2em;
  width: 100%;
  padding: 7px 35px 7px 15px;
  outline: none;
  border: 2px solid darken(@brand-light, 3%);
  border-radius: 4px;
  transition: border-color 0.2s ease-out;

  .item-container {
    display: flex;
    align-items: center;

    .gh-icon {
      margin-right: 9px;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: saturate(darken(@brand-light, 15%), 20%);

    &:before {
      color: @brand-subtle;
    }
  }

  &:before {
    content: "\e114";
    position: absolute;
    right: 15px;
    top: 17px;
    color: @brand-light;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    transition: color 0.2s ease-out;
  }
}

.gh-select {
  position: relative;

  .dropdown-menu {
    padding: 0;

    ul {
      li {
        padding: 15px 30px;
        font-size: 1.1em;
        border-bottom: 1px solid #eee;
        position: relative;

        &:hover {
          background: @brand-light;
          cursor: pointer;
        }

        &.current {
          background: @link;
          color: #fff;
        }

        h3 {
          font-size: 1em;
          margin-bottom: 8px;
          line-height: 1.5em;
        }

        p {
          font-size: 0.9em;
          font-weight: @regular;
          opacity: 0.75;
          margin: 0;
        }

        .item-container {
          display: flex;
          align-items: center;

          .gh-icon {
            margin-right: 9px;
          }
        }

        .option-info {
          font-size: 12px;
          font-weight: @regular;
          color: @black-50;
        }
      }
    }
  }

  &.readonly {
    .gh-select-toggle {
      &:before {
        display: none;
      }

      &:hover {
        cursor: default;
      }
    }
  }

  &.disabled {
    .gh-select-toggle {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
