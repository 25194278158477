.welcome-modal-v1 {
  .section {
    .table {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px;

      .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin: 40px 0;

        .icon {
          padding-right: 10px;
          color: @teal-100;

          .nc-icon-outline {
            font-size: @font-size-h3;
          }
        }

        .bullet {
          font-weight: @mediumbold;
          font-size: @font-size-large;
        }

        .description {
          font-weight: @regular;
          font-size: @font-size-base;
          color: @black-70;
          margin-top: 10px;
        }
      }

      .column {
        flex-direction: column;
        flex: 1;

        .container {
          position: relative;

          .video {
            position: absolute;
            top: -60px;
            left: 50px;
            right: 0;
            bottom: 0;

            iframe {
              width: 80%;
              height: 200px;
              border-radius: 10px;
            }
          }

          .shape {
            position: absolute;
            top: -140px;
            opacity: 0.1;
          }
        }
      }
    }

    .lets-go {
      border-radius: 90px;
      margin-bottom: 40px;
    }

    .title {
      font-size: @font-size-h3;
      font-weight: @bold;
      margin-bottom: 20px;
    }

    .subtitle {
      font-size: @font-size-h5;
      color: @ink-100;
    }
  }
}
