.gh-checkbox-list {
  .item {
    padding: 15px 35px 15px 50px;
    margin: 0;
    border-bottom: 1px solid @brand-light;
    position: relative;
    cursor: pointer;

    &:last-child {
      border: 0;
    }

    &:hover {
      background: @brand-light;

      .checkbox {
        border-color: darken(@brand-light, 10%);
      }
    }

    &.checked {
      .checkbox {
        background: @brand-jira;

        &:before {
          content: "\ea25";
          font: normal normal normal 11px/1 "Nucleo Mini";
          color: #fff;
          position: absolute;
          top: 7px;
          left: 6px;
        }
      }
    }

    .checkbox {
      width: 30px;
      height: 30px;
      background: #fff;
      border: 3px solid @brand-light;
      display: inline-block;
      border-radius: 5px;
      text-align: center;
      line-height: 24px;
      color: #fff;
      top: 11px;
      margin: 0;
      left: 10px;
      position: absolute;
    }

    .title {
      position: relative;
      font-weight: @medium;
      font-size: 1.2em;
    }

    .subtitle {
      font-size: 0.9em;
      color: @brand-grey;
    }

    .remove {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 16px;
      color: fade(@brand-subtler, 50%);

      &:hover {
        color: @brand-danger;
      }
    }
  }

  &.gh-checkbox-list-small {
    .item {
      padding: 5px 30px 5px 25px;
      border-bottom: none;
    }

    .title {
      font-size: 0.95em;
      font-weight: @regular;
      line-height: 1.6em;
    }

    .subtitle {
      font-size: 0.85em;
    }

    .checkbox {
      width: 15px;
      height: 15px;
      top: 8px;
      left: 0;
    }
  }
}
