.delighted-web[id^="delighted-web-"] {
  width: 700px !important;
  margin-left: var(--left-nav-width) !important;

  .delighted-web-survey-powered {
    opacity: 0 !important;
  }
}

body {
  &.hide-left-nav {
    .delighted-web[id^="delighted-web-"] {
      margin-left: var(--left-nav-hidden-width) !important;
    }
  }
}
