.gh-siv-input {
  width: 100%;
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: text;
  outline: none;

  &:hover {
    border: 1px solid @ink-50;
    box-shadow: inset 0 1px 3px fade(@ink-70, 10%);
  }

  &:focus {
    border: 1px solid @blue-80;
  }
}

.gh-siv-top-bar {
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid @brand-border;
  justify-content: space-between;

  &.modal-top {
    padding: 15px 40px;
  }

  &.no-bottom-border {
    border-bottom: none;
  }

  .gh-modal-close-button {
    position: unset;
  }
}

.gh-siv-top-bar,
.gh-siv-top-bar-labels,
.gh-siv-top-bar-actions,
.gh-siv-selector {
  display: flex;
  align-items: center;
}

.gh-siv-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  color: @ink-90;

  .editable-textarea textarea,
  .shadow-textarea {
    border: 1px solid transparent;
    outline: 0;
    border-radius: 4px;
    padding-left: 6px;
    margin-left: -8px;

    &:hover {
      border: 1px solid @ink-50;
      box-shadow: inset 0 1px 3px fade(@ink-70, 10%);
    }

    &:focus,
    &:focus:hover {
      box-shadow: inset 0 1px 3px fade(@ink-70, 10%);
      border: 1px solid @blue-80;
    }
  }

  &.section-title,
  &.row-title {
    margin-bottom: 0;
  }

  &.row-title {
    margin-top: 10px;
  }

  &.big {
    align-items: flex-start;

    .gh-icon {
      margin-top: 10px;
    }
  }

  .gh-icon {
    margin-right: 10px;
  }

  h2 {
    font-weight: @medium;
  }

  h3 {
    font-weight: @regular;
    font-size: 20px;
  }

  h4 {
    font-weight: @regular;
    font-size: 16px;
  }
}

.gh-siv-row {
  display: flex;
  align-items: flex-start;
  min-height: 40px;
  margin-bottom: 12px;
  padding: 1px;

  &:last-child {
    margin-bottom: 0;
  }

  .gh-siv-row-name {
    width: @row-name-width;
    color: @black-80;
    font-size: 13px;
    line-height: 16px;
    flex-shrink: 0;
    cursor: default;

    .cf-name {
      word-break: break-word;
      margin-right: 12px;
      display: contents;
    }
  }

  .gh-siv-row-content {
    display: flex;
    flex: 1;
    font-size: 14px;
    line-height: 1.5em;
    line-height: 20px;
    color: @black-90;
    min-width: 0;

    .gh-siv-icon-row-action {
      display: inline-block;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      color: @ink-50;
      border-radius: 3px;
      margin-right: 5px;
      border-radius: 4px;
      font-size: 11px;

      &:hover {
        background: @ink-20;
        color: @ink-90;
      }
    }

    .with-more-items {
      display: flex;
      align-items: center;

      .target-info {
        width: 13px;
        height: 13px;
        border-radius: 6.5px;
        border-width: 1.5px;
        border-color: @brand-grey;
        color: @brand-grey2;
        border-style: solid;
        line-height: 10px;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        margin-top: 4px;
        cursor: pointer;

        &.red {
          border-color: @magenta-90;
          color: @magenta-90;
        }
      }
    }

    &.content-textarea {
      line-height: 1.5em;
    }

    &.disabled {
      cursor: not-allowed;
    }

    .gh-siv-clickable {
      color: @ink-100;

      &:hover,
      &:focus {
        color: @brand-grey2;
        cursor: pointer;
      }
    }
  }
}

.gh-siv-selector {
  margin-top: -4px;
  margin-left: -8px;
  padding: 6px 8px;
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid @ink-50;
    box-shadow: inset 0 1px 3px fade(@ink-70, 10%);
    cursor: pointer;
  }
}

.gh-siv-section {
  margin: 30px 0 20px;

  .gh-siv-top-bar {
    padding-bottom: 10px;
    border-bottom: 3px solid @black-10;
  }

  .gh-siv-section-item {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding: 12px 0;
    border-bottom: 1px solid @ink-20;

    &.collapsed {
      display: none;
    }

    &.empty-section {
      font-size: 20px;

      .icon {
        font-size: 40px;
      }
    }

    .gh-icon {
      min-width: 20px;
    }

    .gh-siv-item-details {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &.row-direction {
        flex-direction: row;
      }

      .gh-siv-item-name {
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        &.underline-on-focus-visible {
          &:focus-visible {
            text-decoration: underline;
          }
        }
      }

      .gh-siv-private {
        opacity: 0.7;
      }
    }

    .content-wrapper.children-kr-attainment {
      .gh-siv-item-details {
        flex: 1;
      }

      .actions-menu {
        margin-left: auto;
      }
    }

    .task-due-date {
      color: @black-50;
      font-size: 12px;
      line-height: 20px;
      cursor: pointer;

      .gh-select-toggle {
        font-size: 14px;
        padding: 0;
        border: none;
        line-height: 16px;

        &:before {
          content: none;
        }

        .clip-text {
          font-weight: @regular;
          font-size: 12px;
          line-height: 20px;
          font-style: normal;
        }

        .system-logo {
          width: 20px;
        }
      }
    }

    .dropdown-menu {
      padding: 0px;

      li {
        margin: 0px;

        &.first-of-kind {
          padding-top: 0px;
          margin-top: 0px;
        }
      }
    }
  }

  .gh-siv-section-bottom-bar {
    display: flex;
  }

  &.progress-section {
    .gh-siv-title {
      flex-wrap: wrap;
      max-width: 75%;
      min-width: 75%;
    }
    .gh-siv-top-bar-actions {
      max-width: 30%;
    }
  }
}

.gh-siv-section-action {
  padding-left: 10px;

  .gh-font-icon-info {
    font-size: 16px;
    color: @ink-50-v2;
  }
}

.tooltip.top.goal-view-add-kr-tooltip .tooltip-arrow {
  border-top-color: @ink-80-v2;
}

.tooltip.goal-view-add-kr-tooltip .tooltip-inner {
  max-width: 280px;
  text-align: start;
  padding: 14px 12px 16px 16px;
  font-size: 14px;
  color: @grey-10-v2;
  background-color: @ink-80-v2;
}

.gh-siv-row-content,
.with-more-items,
.gh-siv-section-item,
.gh-siv-top-bar-labels,
.gh-siv-selector {
  > * {
    margin-right: 10px;
  }

  > :last-child {
    margin-right: 0px;
  }
}

.single-goal {
  .gh-siv-top-bar-labels {
    > * {
      margin-right: 16px;
    }
  }
}

.gh-siv-grey-content {
  color: @black-50;
}

.gh-siv-item-hidden-action {
  visibility: hidden;
  cursor: pointer;

  &.negative {
    color: @brand-danger;
  }
}

.gh-siv-section-item,
.gh-siv-row-content {
  &:hover {
    .gh-siv-item-hidden-action {
      visibility: visible;
    }
  }
}

.gh-siv-section-item {
  jira-icon {
    margin-top: 2px;
  }
}

.add-section-item {
  white-space: nowrap;

  .info-icon-wrap {
    display: inline-block;
    margin-right: 1em;
  }
}

.info-icon {
  color: @ink-50-v2; // replace with token from the DS once implemented
}
