gh-dropdown-button {
  display: inline-block;

  div[uib-dropdown] {
    .dropdown-menu {
      width: 280px;
      overflow: hidden;
    }
  }
}
