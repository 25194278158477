.permission-wrapper {
  .permission-principal-list {
    margin-top: 2px;
  }

  .permission {
    min-height: 40px;
    width: 100%;
    border-bottom: 1px solid @brand-border;

    &.search {
      border-bottom: 0;
      .gh-input-text {
        height: 24px;
        width: 50%;
        padding: 0 5px;
        border: 0;
        line-height: 24px;

        &.wide-search {
          width: 100%;
        }
      }

      .dropdown-menu {
        width: 393px;
      }
    }
  }

  .list-link {
    text-align: right;
    margin: 15px auto 15px auto;
    border: none;
    color: @brand-jira;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: @brand-info;
      border: none;
    }
  }
}
