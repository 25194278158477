.session-tree-selector {
  &:extend(tree-selector);
  visibility: visible;
  position: absolute;

  tree-selector {
    position: relative;
    box-shadow: none;
    padding: 0;
  }

  tree-selector.bottom-dynamic-filters {
    .branch.first > .leading-node {
      margin-top: 10px;
    }
  }
}
