.gh-badge {
  font-size: 0.9em;
  color: #fff;
  line-height: 1.4em;
  display: inline-block;
  padding: 4px 7px 3px 7px;
  background: @brand-jira;
  border-radius: 3px;
  font-weight: @medium;
  letter-spacing: 0.03em;
  font-family: Arial, sans-serif;

  &.open {
    background: @brand-warning;
  }

  &.closed {
    background: @brand-subtle;
  }

  &.locked {
    background: @brand-primary;
  }

  &.warning {
    background: lighten(desaturate(@brand-warning, 5%), 18%);
    color: darken(@brand-warning, 40%);
  }

  &.danger {
    background: @brand-danger;
  }

  &.draft {
    background: #e2e8f1;
    color: #42596c;
  }

  &.needs-reviewers {
    background-color: #e3541b;
    color: #fff;
    border: 1px solid #bb3500;
  }

  &.in-review {
    border: 1px solid #5404ce;
    background: @brand-lilac;
  }

  &.approved {
    color: #fff;
    background: #25dda0;
  }

  &.in-review,
  &.needs-reviewers,
  &.approved,
  &.draft {
    text-transform: uppercase;
    font-weight: @mediumbold;
    border-radius: 5px;
  }

  // used for confidenceType text badges
  &.text-confidence {
    color: darken(@brand-jira, 20%);
  }
}
