.gh-radio-list-border {
  border-radius: 10px;
  padding: 0 5px;
  overflow: hidden;

  .item {
    padding: 9px 35px 9px 30px;
    position: relative;
    cursor: pointer;
    margin: 0px -5px;

    &:last-child {
      border: 0;
    }

    &:hover {
      .radio {
        border: 1px solid @grey-40;
        box-shadow: inset 0 1px 2px rgba(90, 127, 158, 0.2);
      }
    }

    &.checked {
      .radio {
        background: #ffffff;
        border: 5px solid @brand-jira;
        box-shadow: 0 1px 1px rgba(90, 127, 158, 0.1);
      }

      .title {
        font-weight: 500;
      }

      &:hover {
        .radio {
          border-color: @blue-100-v2;
          box-shadow: 0 1px 1px rgba(90, 127, 158, 0.1);
        }
      }
    }

    .radio {
      background: #ffffff;
      border: 1px solid @grey-30;
      box-shadow: inset 0 1px 2px rgba(90, 127, 158, 0.2);
      border-radius: 10px;
      width: 18px;
      height: 18px;
      background: @brand-white;
      display: inline-block;
      text-align: center;
      line-height: 24px;
      color: @brand-white;
      top: 11px;
      margin: 0;
      left: 10px;
      position: absolute;
    }

    .title {
      position: relative;
      font-weight: @brand-light;
      font-size: 14px;
      font-style: normal;
      line-height: 19px;
      margin-left: 6px;
    }

    .subtitle {
      font-size: 0.9em;
      color: @brand-grey;
    }

    .remove {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 16px;
      color: fade(@brand-subtler, 50%);

      &:hover {
        color: @brand-danger;
      }
    }
  }
}
