#simple-access {
  font-size: 13px;
  color: lighten(@brand-dark, 10%);

  .access-type {
    cursor: pointer;
    font-weight: @medium;
    color: @brand-dark;
    font-size: 14px;

    &:hover {
      color: @brand-subtler;
    }

    > span {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &--v2 {
      height: 28px;
      padding: 0px 12px 0px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      color: @ink-85-v2;

      &:hover {
        color: @ink-100-v3;
      }
    }
  }

  h4 {
    font-weight: @semibold;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .title--v2 {
    display: flex;
    padding: 8px;
    align-items: center;
    margin: 0;
    overflow: hidden;
    color: @ink-70-v2;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 590;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    width: 100%;
  }

  #simple-access-toggle {
    position: relative;
  }

  #simple-access-holder {
    position: relative;
    z-index: 2;
  }

  #simple-access-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }

  .simple-access-dropdown {
    width: 350px;
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 10px;
    background: #fff;
    box-shadow: 0 0 14px rgba(0, 85, 204, 0.2);
  }

  .simple-access-dropdown--v2 {
    width: 320px;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    border-radius: 4px;
    background: @brand-white;
    box-shadow:
      0px 0px 1px 0px rgba(18, 57, 101, 0.5),
      0px 4px 12px 0px rgba(21, 78, 164, 0.2);
    margin: 0;
  }

  .typeahead + .dropdown-menu {
    box-shadow: 0 0 14px rgba(0, 85, 204, 0.2);
    li {
      padding: 5px 10px 5px 24px;
      position: relative;

      &.active {
        background: @brand-jira;
        color: #fff;
      }
    }
  }

  .principal {
    position: relative;
    padding: 4px 0 4px 24px;

    .image {
      position: absolute;
      left: 0;
      top: 4px;

      &--v2 {
        position: static;
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
      }
    }

    .grant {
      position: absolute;
      right: 0;
      top: 4px;

      .dropdown-toggle {
        white-space: nowrap;
        width: auto;
        color: @brand-dark;
        font-weight: @medium;
      }

      &--v2 {
        position: static;
        margin-left: auto;
        color: @ink-70-v2;
        font-family: inherit;
        font-size: 12px;
        font-style: normal;
        font-weight: 510;
        line-height: 16px;

        .dropdown-toggle {
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          display: flex;
          align-items: center;
        }
      }
    }

    .name {
      max-width: 200px;

      &--v2 {
        span {
          display: flex;
          height: 19px;
          flex-direction: column;
          justify-content: center;
          flex: 1 0 0;
          overflow: hidden;
          color: @ink-100-v3;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 510;
          line-height: 18px;
        }
      }
    }

    .tooltip {
      max-width: 200px;
    }

    &--v2 {
      display: flex;
      padding: 8px;
      align-items: center;
      gap: 4px;
      align-self: stretch;
    }
  }

  .bold-p {
    font-size: 0.9em;
    font-weight: @medium;
  }

  input {
    margin: 0;
  }

  label {
    display: flex;
    font-weight: @regular;
    margin: 0;
    margin-left: 7px;
    font-size: 1em;
  }

  .simple-action-section {
    padding: 20px 30px;
    border-top: 1px solid @brand-light;

    .principals {
      margin-bottom: 15px;

      &--v2 {
        margin: 0;
        width: 100%;
      }
    }

    .access-options {
      border: 1px solid @brand-light;
      border-radius: 4px;
      margin: 20px 0 0 0;

      .access-option {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 0;

        .ant-radio {
          align-self: baseline;
        }

        &--v2 {
          height: 35px;
          padding: 8px;
          gap: 4px;
          margin: 0;

          div {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-left: -8px;
          }
        }
      }

      [class^="gh-font-icon-"],
      [class*=" gh-font-icon-"] {
        top: 2px;
      }

      &--v2 {
        border: none;
        margin: 0;
        width: 100%;
      }
    }

    &--v2 {
      width: 100%;
      display: flex;
      padding: 4px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-radius: 4px;
      padding: 4px 4px 12px 4px;

      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &:nth-child(2) {
        padding: 12px 4px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  [class^="gh-font-icon-"],
  [class*=" gh-font-icon-"] {
    margin-right: 5px;
  }

  .gh-font-icon-arrow-down-small {
    margin-right: 0;
  }
}

.invite-user-wrapper--v2 {
  display: flex;
  padding: 8px;
  padding-left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.search-wrapper--v2 {
  width: 100%;
  padding-left: 8px;
}

.h-19 {
  height: 19px;
}

.h-36 {
  height: 36px;
}
