@media print {
  body {
    overflow: auto;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    padding-top: 0;

    * {
      overflow: visible;
      height: auto;
    }
  }

  .master-container {
    overflow: auto;
    height: auto;
  }
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
