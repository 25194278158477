.gh-assignees-list {
  &.fixed {
    overflow-y: auto;
    height: 100%;
  }

  &.pl-55 {
    .assignee {
      padding-left: 55px;
    }
  }

  &.narrow {
    .assignee {
      padding: 5px 0 5px 10px;
      border-radius: 3px;
      .name {
        font-size: 1em;
        font-weight: @regular;
      }
    }
  }

  .section {
    font-size: 1.2em;
    margin-top: 20px;
    padding: 15px 0 15px 15px;
    font-weight: @bold;
  }

  .assignee {
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid @brand-light;
    position: relative;
    display: block;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &.selected {
      background: @brand-light;
      border-bottom-color: lighten(@brand-light, 10%);

      .meta {
        color: fade(@brand-white, 50%);
      }

      &:hover {
        background: darken(@brand-light, 7%);
      }
    }

    &.with-action {
      padding-right: 160px;
    }

    .gh-b {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -14px;
    }

    &:hover {
      background: @brand-light;
    }

    &.assignee-group,
    .name {
      font-size: 1.2em;
      font-weight: @medium;
      color: @brand-dark;
      word-break: break-word;
    }

    .meta {
      color: fade(@brand-dark, 50%);
    }

    .picture {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -15px;

      .team-member-notified {
        padding-left: 45px;
        padding-top: 1px;
        color: fade(@brand-dark, 45%);
      }

      &.role {
        height: 30px;
        width: 30px;
        border-radius: 15px;
        background: lighten(@brand-grey, 15%);
        line-height: 34px;
        font-size: 18px;
        text-align: center;
        color: @brand-white;
      }
    }
  }

  &.select-employee-list {
    .assignee {
      padding: 15px 0 15px 55px;
    }
  }
}

.assignee-selector-wrapper {
  @vert-modal-padding: 80px;
  height: calc(100vh - @vert-modal-padding);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.horizontal {
    margin: 0;
  }

  .tab-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;

    .active {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex: 1;

      .gh-assignees-list {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1;
      }
    }
  }
}

@media only screen and (max-height: 1280px) {
  .gh-assignees-list {
    &.fixed {
      height: 650px;
    }
  }
}
@media only screen and (max-height: 1020px) {
  .gh-assignees-list {
    &.fixed {
      height: 350px;
    }
  }
}
