.modal {
  &.inbox-modal {
    .modal-dialog {
      left: var(--left-nav-hidden-width);
      width: 680px;

      .modal-content {
        padding: 0;
      }
    }
  }
}

#inbox-header {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 40px;
  height: 60px;
  border-bottom: 3px solid darken(@brand-light, 5%);

  h3 {
    font-weight: bold;
    font-size: 1.2em;
  }

  button[gh-button] {
    &:focus {
      box-shadow:
        0px 0px 0px 1px @brand-white,
        0px 0px 0px 2px @blue-80-v3;
    }
  }

  .gh-modal-close-button {
    background: none;
    border: none;

    &:hover {
      background: @grey-20-v2;
      border-radius: 4px;
      color: @ink-40-v3;
    }

    &:focus {
      box-shadow:
        0px 0px 0px 1px @brand-white,
        0px 0px 0px 2px @blue-80-v3;
      border-radius: 4px;
      color: @ink-40-v3;
    }
  }

  .header-indicator {
    display: inline-block;
    margin-left: 20px;
    flex-grow: 1;

    .spinner-wrap {
      margin-right: 10px;
    }
  }
}
// new notification popup alert in inbox sidebar
#new-message-inbox-alert {
  position: absolute;
  width: 100%;
  z-index: 2000;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.9em;
  background: @brand-jira;
  color: #fff;
  cursor: pointer;
}

#inbox-empty-screen {
  margin-top: 60px;

  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 13px;
    letter-spacing: 0.04em;
    line-height: 20px;
  }
}

#inbox-messages {
  border-bottom: 1px solid #eee;
  position: absolute;
  top: 60px;
  bottom: 0px;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;

  #inbox-footer {
    .loading-more-messages-indicator,
    .gh-b {
      margin: 0;
      padding: 20px 40px;
    }

    .gh-b {
      border: none;

      &:hover {
        color: @brand-primary;
      }
    }
  }

  // color flash on loading more notifications
  .new-messages-cover {
    position: absolute;
    width: 100%;
    background: yellow;
    opacity: 0;
    transition: opacity 0.3s ease-out;

    &.shown {
      opacity: 0.2;
    }
  }
}
