.session feature-banner {
  float: right;
  max-width: 420px;
  margin-right: 25px;
}

#session-form,
.session {
  .feature-banner:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid @blue-5;
    right: 90%;
    top: -16px;
  }
}

#session-form,
#connections-list {
  .feature-banner {
    margin-top: 20px;
  }
}

#configuration-view .feature-banner {
  max-width: 1100px;
}

#configuration-view #api-tokens .feature-banner {
  max-width: 700px;
  margin-bottom: 20px;
}

.designer-grid .feature-banner {
  margin: 20px 30px 0 30px;
}

.insightboards-row,
#dataflow,
.badges,
.session,
.automation-grid {
  .feature-banner {
    margin-bottom: 20px;
  }
}

#kpis .feature-banner {
  margin: 20px 40px;
}

.whiteboards-list .feature-banner {
  margin-bottom: 20px;
  margin-top: -20px;
}
