gh-attachments {
  position: relative;
  -webkit-font-smoothing: antialiased;

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .add {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
      border-radius: 7px;
      background: @black-10;
      font-size: 20px;
      color: @ink-80;
      cursor: pointer;

      &:hover {
        background: @brand-light;
        color: @brand-jira;
      }
    }

    .add-first {
      display: flex;
      align-items: center;
      position: relative;
      height: 50px;
      min-width: 200px;
      margin-bottom: 10px;
      padding: 0 15px 0 35px;
      border: 1px dashed @brand-border;
      border-radius: 7px;

      &.loading {
        padding-left: 0px;
        border-color: transparent;
      }

      .gh-font-icon-attachment {
        position: absolute;
        left: 15px;
        top: 15px;
        font-size: 16px;
      }

      .context {
        font-size: 13px;

        .select-file {
          margin-right: 5px;
          color: @brand-jira;
          cursor: pointer;

          &:hover {
            color: lighten(@brand-jira, 15%);
          }
        }
      }
    }
  }

  .drop-box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;

    &.dragover,
    &.dnd-error {
      margin: 20px;
      z-index: 5;
      border-radius: 7px;

      .header {
        margin-top: 25px;
        line-height: 24px;
        font-size: 18px;
        font-weight: @mediumbold;
      }
    }

    &.dragover {
      background: @blue-10;
      border: 2px dashed @blue-80;

      .drop-area {
        .invisible-mask {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          // increased z-index compared to parent .drop-box.dragover to avoid flickering
          z-index: 6;
          opacity: 0;
        }

        .content {
          .header {
            color: @blue-80;
          }
        }
      }
    }

    &.dnd-error {
      background: @magenta-20;
      border: 2px dashed #f77c99;

      .upload-error {
        display: block;

        .header {
          color: @magenta-90;
        }

        .context {
          margin-top: 10px;
        }

        .gh-b {
          margin-top: 30px;
        }
      }
    }
  }
}
