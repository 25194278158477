.btn-primary {
  padding: 15px 30px 15px 55px;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 5px;
  border: 0;
  transition: 0.2s ease-in background;

  .icon {
    font-size: 16px;
    position: absolute;
    top: 15px;
    left: 20px;
  }

  &:hover {
    background: darken(@brand-primary, 5%);
  }
}

.btn-smd {
  padding: 6px 14px;
  font-size: 12px;
  text-transform: none;
  font-weight: 400;
  letter-spacing: 0.04em;
}

.btn-md {
  padding: 11px 25px;
  font-size: 13px;
  font-weight: @light;
  letter-spacing: 1px;
  border-radius: 5px;
  text-transform: none;
  border: 0;

  &.btn-dark {
    background: lighten(@brand-dark, 20%);
    color: #fff;

    &:hover {
      background: lighten(@brand-dark, 10%);
    }
  }

  &.uc {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: @regular;
  }
}
