.gh-icon {
  width: 40px;
  height: 40px;
  position: relative;
  line-height: 1em;
  display: inline-block;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    content: "";
    display: block;
    background-size: cover;
  }

  &.large {
    width: 64px;
    height: 64px;

    &:before {
      width: 64px;
      height: 64px;
      background-size: cover;
    }
  }

  &.gh-icon-attachment {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/attachment.svg")
        no-repeat;
    }
  }

  &.gh-icon-attention {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/attention.svg")
        no-repeat;
    }
  }

  &.gh-neutral-warn {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/neutral-warn.svg")
        no-repeat;
    }
  }

  &.gh-icon-session {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/session.svg") no-repeat;
    }
  }

  &.gh-icon-check-white {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/check-white.svg")
        no-repeat;
    }
  }

  &.gh-icon-automation {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/automation.svg")
        no-repeat;
    }
  }

  &.gh-icon-comments {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/comments.svg") no-repeat;
    }
  }

  &.gh-icon-comments-outline {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/comments-outline.svg")
        no-repeat;
    }
  }

  &.gh-icon-alignment {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/alignment.svg")
        no-repeat;
    }
  }

  &.gh-icon-links {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/links.svg") no-repeat;
    }
  }

  &.gh-icon-link {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/gh-link.svg") no-repeat;
    }
  }

  &.gh-icon-more {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/more.svg") no-repeat;
    }
  }

  &.gh-icon-goal {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/goal.svg") no-repeat;
    }
  }

  &.gh-icon-search {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/search.svg") no-repeat;
    }
  }

  &.gh-icon-search-background {
    background: url("../../../../wwwroot/fe/img/icons/search.svg") scroll
      no-repeat 7px 6px;
    padding-left: 30px;
  }

  &.gh-icon-upload {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/upload.svg") no-repeat;
    }
  }

  &.gh-icon-whiteboard {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/whiteboard.svg")
        no-repeat;
    }
  }

  &.gh-icon-metric {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/metric.svg") no-repeat;
    }
  }

  &.gh-icon-cascading-metric-alignment {
    &::before {
      background: url("../../../../wwwroot/fe/img/icons/cascading-metric-alignment.svg")
        no-repeat;
    }
  }

  &.gh-icon-contributing-metric {
    &::before {
      background: url("../../../../wwwroot/fe/img/icons/contributing-metric.svg")
        no-repeat;
    }
  }

  &.gh-icon-update {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/update.svg") no-repeat;
    }
  }

  &.gh-icon-task {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/task.svg") no-repeat;
      background-size: cover;
    }
  }

  &.gh-icon-task-green {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/task-green.svg")
        no-repeat;
      background-size: cover;
    }
  }

  &.gh-icon-plus {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/plus.svg") no-repeat;
    }
  }

  &.gh-icon-minus {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/minus.svg") no-repeat;
    }
  }

  &.gh-icon-mention {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/mention.svg") no-repeat;
    }
  }

  &.gh-icon-conversation {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/conversation.svg")
        no-repeat;
    }
  }

  &.gh-icon-badge {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/badge.svg") no-repeat;
    }
  }

  &.gh-icon-progress {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/progress.svg") no-repeat;
    }
  }

  &.gh-icon-select-user {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/select-user.svg")
        no-repeat;
    }
  }

  &.gh-icon-inactive-task {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/inactive-task.svg")
        no-repeat;
    }
  }
  &.gh-icon-logo-jira {
    &:before {
      background: url("../../../../wwwroot/fe/img/logos/jira.png") no-repeat;
    }
  }

  &.gh-icon-employee {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/employee.svg") no-repeat;
    }
  }

  &.gh-icon-team {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/team.svg") no-repeat;
    }
  }

  &.gh-icon-insight {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/insight.svg") no-repeat;
    }
  }

  &.gh-icon-insightboard {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/insightboard.svg")
        no-repeat;
    }
  }

  &.gh-icon-okr-view {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/shareable_filter.svg")
        no-repeat;
    }
  }

  &.gh-icon-report {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/report.svg") no-repeat;
    }
  }

  &.gh-icon-kpi {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/kpi.svg") no-repeat;
    }
  }

  &.gh-icon-kpiview {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/filtered-view-kpis.svg")
        no-repeat;
    }
  }

  &.gh-icon-list {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/list.svg") no-repeat;
    }
  }

  &.gh-icon-eye {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/eye.svg") no-repeat;
    }
  }

  &.gh-icon-all-time {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/all-time.svg") no-repeat;
    }
  }

  &.gh-icon-private {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/private.svg") no-repeat;
    }
  }

  &.gh-icon-public {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/public.svg") no-repeat;
    }
  }

  &.gh-icon-aligned-under {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/aligned-under.svg")
        no-repeat;
    }
  }

  &.gh-icon-related {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/related.svg") no-repeat;
    }
  }

  &.gh-icon-announcement {
    &:before {
      background: url("../../../../wwwroot/fe/img/icons/announcement.svg")
        no-repeat;
    }
  }

  &.normal {
    width: 22px;
    height: 22px;

    &:before {
      background-size: cover;
      top: 0;
      width: 22px;
      height: 22px;
    }
  }

  &.small {
    width: 20px;
    height: 20px;

    &:before {
      background-size: cover;
      top: 0;
      width: 20px;
      height: 20px;
    }
  }

  &.icon-size-14px {
    width: 14px;
    height: 14px;

    &:before {
      background-size: cover;
      top: 0;
      width: 14px;
      height: 14px;
    }
  }

  &.tiny {
    width: 16px;
    height: 16px;

    &:before {
      background-size: cover;
      top: 0;
      width: 16px;
      height: 16px;
    }
  }

  &.miniature {
    width: 8px;
    height: 8px;

    &:before {
      background-size: cover;
      top: 0;
      width: 8px;
      height: 8px;
    }
  }
}

.gh-icon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.gh-arrow-up,
.gh-arrow-down {
  height: 16px;
  width: 16px;
  background: no-repeat url("../../../../wwwroot/fe/img/icons/arrow-down.svg");
  display: block;
}

.gh-arrow-up {
  transform: rotate(180deg);
}
