#edition-plan-container {
  text-align: center;
  padding: 45px 0;
  background: @brand-white;

  .heading-container {
    h1 {
      font-size: 32px;
      line-height: 36px;
      font-weight: @mediumbold;
      color: @ink-100-v3;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-align: center;
      color: @ink-40-v3;
    }
  }

  .plans-details {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: stretch;

    .plan-details {
      margin: 0px 7px;
      background-color: @brand-white;
      min-width: 262px;
      max-width: 262px;
      padding: 8px 13px;
      border-radius: 18px;
      border: 2px solid transparent;
      box-shadow: 0px 1px 15px rgba(33, 76, 107, 0.2);

      &.current {
        border-color: @ink-30;
        box-shadow: none;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 60px;
      }

      img {
        width: 35px;
        height: 35px;
        margin-bottom: 8px;
      }

      h4 {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        color: @ink-90;
      }

      p {
        padding: 0 5px;
        font-size: 12px;
        color: @ink-70;
        line-height: 16px;
        font-weight: @medium;
        text-align: center;
        margin-bottom: 12px;
      }

      .price {
        margin-bottom: 10px;

        h3 {
          font-weight: @mediumbold;
          font-size: 27px;
          line-height: 27px;
          color: @ink-90;
        }

        span {
          color: fade(@ink-60, 100%);
          font-size: 11px;
          font-weight: @medium;
          line-height: 16px;
        }
      }

      .upgrade-plan {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 200px;
        margin-bottom: 10px;
        border: none;
        border-radius: 38px;
        background-color: @ink-90;
        color: @brand-white;
        font-size: 13px;
        font-weight: @medium;
        transition: 0.3s all ease-in-out;

        &:hover,
        &:focus {
          background-color: @ink-100;
        }

        &.upgrade-plan-disabled,
        &.current-plan {
          color: fade(@ink-80, 100%);
          background-color: @brand-white;
          border: 1px solid fade(@ink-30, 100%, 100%);
          opacity: 0.5;
          cursor: default;

          &:hover,
          &:focus {
            color: fade(@ink-80, 100%);
            background-color: @brand-white;
          }
        }

        &.downgrade {
          color: @blue-100;
          background-color: @brand-white;
          box-shadow:
            0px 0px 1px @blue-200,
            0px 1px 2px rgba(119, 138, 156, 0.3);

          &:hover,
          &:focus {
            color: @blue-200;
            box-shadow:
              0px 0px 2px @blue-200,
              0px 1px 2px rgba(119, 138, 156, 0.3);
          }
        }
      }

      .contact-sales-btn {
        color: fade(@blue-100, 100%);
        border: none;
        font-size: 12px;
        font-weight: @medium;
        background: none;
        border-bottom: 1px solid @blue-100;

        &:hover {
          color: @brand-grey;
          border-bottom-color: fade(@brand-grey, 75%);
        }
      }

      .plan-list {
        position: relative;
        margin-top: 12px;
        padding-top: 13px;
        line-height: 18px;
        text-align: left;
        // TODO: Set dynamically min-height?
        min-height: 235px;

        &:before {
          content: "";
          position: absolute;
          width: 256px;
          height: 1px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          background: @ink-20;
        }

        li {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          flex-direction: row-reverse;
          margin-left: 5px;
          margin-bottom: 7px;
        }

        .gh-font-icon-check {
          color: @brand-primary;
          margin-right: 3px;
          margin-top: 2px;
          font-size: 18px;
          font-weight: bolder;
        }
      }
    }
  }
}

// Can't do that modal
#cant-do-that {
  text-align: center;

  img {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  h3 {
    font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 23px;
    color: @ink-100;
  }

  p {
    font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: @black-70;
    margin-bottom: 40px;
  }

  .link-blue {
    display: inline-block;
    font-size: 12px;
    line-height: 19px;
    color: @blue-100;
    margin-bottom: 30px;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .assistence-cont {
    border-top: 1px solid rgba(200, 209, 223, 0.5);
    margin: 0 -40px -18px;
    padding-top: 25px;

    p {
      font-family: "SF Display Pro", "Helvetica Neue", "SegoeUI", sans-serif;
      font-size: 12px;
      line-height: 19px;
      max-width: 220px;
      margin: 0 auto;

      span {
        color: @blue-100;
        cursor: pointer;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}

// Conditionally added classes
.animate-plan-details {
  animation: fade ease 1s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
