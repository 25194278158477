no-results-error {
  &:extend(.centered-content);
  flex-direction: column;
  margin-top: 145px;

  .context {
    margin-top: 25px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: @ink-80-v2;
  }

  .suggestion {
    margin-top: 8px;
    width: 320px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}
