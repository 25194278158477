.input-border {
  border: 1px solid @grey-20;
  box-shadow: 0px 1px 1px rgba(90, 127, 158, 0.1);
  border-radius: 4px;

  &:hover:not(.disabled) {
    border: 1px solid @grey-40;
  }

  &:active {
    border: 1px solid @blue-30-v2;
    box-shadow: inset 0 0 0 1px @blue-30-v2;
  }

  &:focus {
    box-shadow:
      0px 0px 0px 2px @blue-5,
      inset 0px 1px 2px rgba(90, 127, 158, 0.2);
    border: 1px solid @blue-40-v2;
  }

  &.shadow-inset {
    box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);
  }
}

.input-text-border {
  border: 1px solid @grey-20;
  box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);
  border-radius: 4px;

  &:hover {
    border: 1px solid @grey-40;
  }

  &:focus {
    border: 2px solid @blue-30-v2;
  }
}

.fake-placeholder {
  font-size: 14px;
  color: @ink-50-v2;
  font-style: unset;
}

.disabled-dropdown {
  background: @grey-20-v2;
  color: @ink-50-v2;
  cursor: not-allowed;
}

.gh-input-text-v2 {
  height: 38px;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  outline: none;
  .input-border;
  .shadow-inset;
  .hov-cursor-p;

  &:focus {
    cursor: text;
  }

  &::placeholder {
    .fake-placeholder;
  }
}

.gh-textarea-v2 {
  background: #ffffff;
  font-size: 14px;
  width: 100%;
  padding: 10px 15px;
  outline: none;
  transition: border-color 0.2s ease-out;
  resize: none;
  .input-border;
  .shadow-inset;
  .hov-cursor-p;

  &::placeholder {
    .fake-placeholder;
  }

  &.text-v2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

// Create less file for the gtmhub-dropdown directive: https://gtmhub.atlassian.net/browse/GVS-2919
.gh-select-v2,
.gh-select-filter-v2 {
  .input-border;
  .centered-vert;
  .align-items-s;
  .hov-cursor-p;

  &.disabled {
    .disabled-dropdown;
  }
}

.gh-select-toggle-v2,
.gh-select-filter-toggle-v2 {
  .centered-vert;
  .flex-1;
  .justify-cont-sb;
}

// used for filters
.gh-select-filter-v2 {
  height: 30px;
  font-size: 13px;

  .gh-select-filter-toggle-v2 {
    padding: 7px 8px;
  }
}

// used for dropdowns in forms
.gh-select-v2 {
  height: 38px;
  font-size: 14px;
  padding: 0 10px;

  .gh-select-toggle-v2 {
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
      border: none;

      &.search-input {
        opacity: 1;
        padding-left: 10px;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    border-radius: 4px;

    > ul {
      max-height: 240px;
      overflow-y: auto;
    }

    ul {
      li {
        color: @ink-100-v2;
        padding: 10px;
        font-size: 14px;

        &:first-of-type {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        &:last-of-type {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        &:hover {
          background: @grey-10;
          .hov-cursor-p;
        }

        &.active {
          background: @blue-80-v2;
          color: @brand-white;
        }
      }
    }
  }
}

.mandatory {
  &:after {
    content: "*";
    position: absolute;
  }
}
