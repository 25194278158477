.parent-okr-selector {
  width: 100%;

  .gh-icon {
    min-width: 16px;
    margin-right: 5px;
  }

  .selected-okr-template {
    display: flex;
    align-items: center;
  }

  .loading-indicator,
  .no-results,
  .show-message {
    position: absolute;
    padding: 0;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 7px 10px 0 rgba(9, 30, 66, 0.25);
    width: 100%;
    z-index: 999;
    background-color: @brand-white;
    display: flex;
    align-items: center;
  }

  .no-results,
  .show-message {
    padding: 10px 10px 18px 10px;
    font-size: 15px;
    line-height: 28px;
    color: @ink-90;
  }

  tags-input.parent-input-container {
    width: 100%;

    input::placeholder {
      color: @ink-60-v2;
    }

    .gh-icon {
      margin-left: 5px;
    }

    .autocomplete {
      margin-top: 0;
      padding: 0;
      border: 0;
      border-radius: 3px;
      box-shadow: 0 7px 10px 0 rgba(9, 30, 66, 0.25);
      width: 100%;

      .suggestion-list {
        width: 100%;
        border-radius: 3px;
        padding: 5px 0;

        .suggestion-item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 30px;

          ti-autocomplete-match {
            width: 100%;

            ng-include {
              width: 100%;

              .parent-okr-autocomplete-row-template {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left-side {
                  display: flex;
                  align-items: center;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }

              .centered-vert;
              height: 23px;
              font-size: 14px;
              color: @ink-100;

              span {
                .ellipsis;
              }

              em {
                font: inherit;
                background: transparent;
                .fw-700;
              }
            }
          }

          &.selected {
            background: @black-10;

            em {
              color: initial;
              background-color: transparent;
            }
          }

          &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }

          span.disabled {
            margin-left: 4px;
            text-transform: lowercase;
          }
        }
      }
    }

    .host {
      margin-top: 0;
      margin-bottom: 0;

      .tags {
        border: 1px solid @ink-50;
        box-shadow: inset 0 1px 2px rgba(90, 127, 158, 0.2);
        border-radius: 4px;
        font-size: 14px;
        color: @ink-90;

        .input {
          padding-left: 6px;
        }

        .tag-list {
          .tag-item {
            margin: 3px;
            padding: 1px 10px 1px 1px;
            border: 0;
            border-radius: 20px;
            background: @black-10;
            line-height: 24px;
            font-size: 14px;
            color: @ink-100;

            white-space: nowrap;
            text-overflow: ellipsis;

            ng-include {
              .d-flex;

              .gh-font-icon-close-medium {
                display: none;
                color: @ink-80;
                margin-left: 8px;
                align-self: center;

                &:hover,
                &:active,
                &:focus {
                  color: @ink-90;
                }
              }
            }

            &:hover {
              ng-include .gh-font-icon-close-medium {
                display: initial;
              }
            }
          }
        }

        &.focused {
          border: 1px solid @ink-50;
          box-shadow: inset 0 1px 2px rgba(90, 127, 158, 0.2);
        }
      }
    }

    &.borderless {
      .host {
        .tags {
          box-shadow: none;
          border: 0;
          padding: 1px; // Compensate for the border of the active element

          .tag-list .tag-item ng-include .gh-font-icon-close-medium {
            display: none;
          }

          &:hover {
            border: 1px solid @ink-50;
            box-shadow: inset 0 1px 2px rgba(90, 127, 158, 0.2);
            padding: 0;
          }

          .tag-list .tag-item:hover ng-include .gh-font-icon-close-medium {
            display: initial;
            background: @black-10;
            border-radius: 20px;
          }
        }
      }
    }

    &.mandatory-selection {
      .host {
        .tags {
          .tag-list {
            .tag-item {
              &:hover {
                ng-include .gh-font-icon-close-medium {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  // if the input is disabled
  tags-input[disabled] {
    .host {
      .tags {
        background-color: inherit;

        .tag-item {
          opacity: 1;
        }

        .input {
          background-color: inherit;
        }

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  // TODO Temporary fix to remove the avatars tooltip - will be fixed later
  .dropdown-menu {
    display: none;
  }
}
