.gh-alert {
  padding: 12px 15px;
  margin: 8px 0 15px 0;
  border-radius: 5px;
  clear: both;
  color: #fff;
}

.gh-alert-danger {
  background: #f4856a;
}

.gh-alert-warning {
  background: #ecb151;
}
