session-selector-dropdown {
  position: relative;
  min-width: 340px;

  .dropdown-container {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .dropdown-field {
    width: 100%;
  }

  .current-session-wrapper {
    &.gh-input-text {
      height: 35px;
      font-size: 14px;
    }

    position: relative;
    padding: 1px;
    border: none;

    .dropdown-close-trigger {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .current-session {
      display: flex;
      padding: 3px;
      flex-direction: row;
      justify-content: flex-start;
      justify-items: center;
      margin: 2px;
      padding-right: 10px;
      padding-left: 10px;
      line-height: 24px;
      font-size: 14px;
      color: @ink-100;

      .current-session-color {
        display: inline-block;
        margin-right: 4px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        vertical-align: middle;
        align-self: center;
      }

      .current-session-title {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        max-width: 350px;
      }
    }

    input {
      padding: 0px 10px;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);

      &::placeholder {
        font-size: 15px;
        color: @ink-50-v2;
        opacity: 1; /* Firefox */
      }
    }
  }

  .dropdown-menu-with-tooltip {
    width: calc(100% - 24px);
  }

  .dropdown-menu-sessions {
    li {
      padding: 10px;

      &:hover {
        background-color: @blue-10-v3;
        cursor: pointer;
      }
    }

    li.active {
      position: relative;
      background-color: @blue-80-v2;

      .session-title {
        color: #fff;
      }
    }
  }

  #session-search-results {
    .session-list .session-item {
      border-radius: 0px;
      padding: 8px;

      &.non-selectable {
        pointer-events: none;
        opacity: 0.5;
        color: #ffffff;
      }

      .session-color {
        margin-left: 0px;
      }

      .session-title {
        width: auto;
        font-family: sans-serif;
      }

      &.active .session-title {
        color: #fff;
      }
    }
  }
}
