.assignee-details-popover.popover.bottom {
  max-width: unset;
  border: none;
  box-shadow: none;
  filter: drop-shadow(0px 2px 3px @brand-grey);

  .arrow {
    border: none;

    // bootstrap popover element inline style overwrites
    top: -8px !important;
    left: 26px !important;
  }

  .popover-content {
    padding: 14px 16px;
    width: 289px;
  }
}

.assignee-details-popover-content {
  .assignee-details-header {
    display: flex;
    position: relative;

    .assignee-details-header-title {
      margin-left: 8px;
      color: @ink-100-v2;

      &.deactivated {
        color: @ink-10-v3;
      }

      .assignee-display-name {
        display: flex;
        font-size: 16px;
        font-weight: @medium;
        line-height: 23px;
        padding-right: 24px;

        .user-full-name span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .assignee-info {
        font-size: 11px;
        font-weight: @light;
        line-height: 15px;
        color: @ink-70-v2;
      }
    }

    .assignee-details-close-icon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      font-size: 16px;
      line-height: 16px;
      color: @ink-50-v2;

      &:hover {
        color: @brand-grey;

        .nc-icon-outline {
          font-weight: @ultrabold;
        }
      }
    }
  }

  .assignee-details-body {
    .assignee-details-section-title {
      font-size: 11px;
      font-weight: @light;
      line-height: 15px;
      color: @ink-50-v2;
      margin-top: 12px;
    }

    .assignee-details-list-item {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .assignee-details-list-item-name {
        color: @ink-100-v2;
        font-size: 14px;
        font-weight: @regular;
        line-height: 19px;
        margin-left: 4px;
      }

      .no-manager-text {
        color: @ink-50-v2;
        align-self: flex-end;
      }
    }

    .assignee-details-list-more-available {
      font-size: 11px;
      font-weight: @light;
      line-height: 15px;
      margin-top: 8px;
      color: @ink-50-v2;
      margin-left: 24px;
    }
  }

  .assignee-details-actions {
    margin-top: 24px;

    button.secondary {
      font-weight: @medium;
    }
  }
}
