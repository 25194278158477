.fw-100 {
  font-weight: @thin !important;
}

.fw-200 {
  font-weight: @extralight !important;
}

.fw-300 {
  font-weight: @light !important;
}

.fw-400 {
  font-weight: @regular !important;
}

.fw-500 {
  font-weight: @medium !important;
}

.fw-600 {
  font-weight: @mediumbold !important;
}

.fw-700 {
  font-weight: @bold !important;
}

.fw-800 {
  font-weight: @extrabold !important;
}

.fw-900 {
  font-weight: @ultrabold !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.t-align-l {
  text-align: left;
}

.t-align-r {
  text-align: right;
}

.t-align-c {
  text-align: center;
}

.t-d-underline {
  text-decoration: underline;
}

.t-d-line-through {
  text-decoration: line-through;
}

.w-s-normal {
  white-space: normal;
}

.tt-capitalize {
  text-transform: capitalize;
}

.tt-uppercase {
  text-transform: uppercase;
}
