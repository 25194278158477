@import "./components/assignee-details-popover/assignee-details-popover.component.less";
@import "./components/dynamic-assignee-tooltip/dynamic-assignee-tooltip.component.less";

.filter-modal {
  .modal-content {
    overflow: visible;
  }

  .dropdown-menu {
    right: 40px;
  }

  .assignee-typeahead {
    padding: 5px 10px;

    .name {
      display: inline-block;
      overflow: hidden;
      max-width: 280px;
      padding-left: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 26px;
      vertical-align: middle;
    }
  }
}

#filter-modal {
  display: flex;
  flex-direction: column;
  min-height: 300px;

  .section-name {
    font-weight: 500;
    font-size: 13px;
    // color: #5a7f9e;
    line-height: 36px;
    color: @ink-70;
  }
}
