// modal
.account-picker-modal {
  .modal-dialog {
    width: 600px;

    .modal-content {
      padding: 0px;
      min-height: 100px;
    }
  }
}

// template
#account-picker {
  -webkit-font-smoothing: antialiased;

  .account-picker-header {
    padding: 15px 15px 15px 25px;
    border-bottom: 1px solid #f0f5fa;

    .title {
      font-size: 13px;
      line-height: 24px;
      color: @brand-black;
      font-weight: @bold;
    }
  }

  .account-picker-body {
    padding-bottom: 10px;

    .account-wrapper {
      .account-section {
        &.border-bottom {
          border-bottom: 1px solid #f0f5fa;
        }

        .account {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          padding: 0px 25px 0px 45px;
          // border: 1px solid @brand-white;

          &.current {
            border-bottom: 1px solid #f0f5fa;
            border-top: 1px solid #f0f5fa;

            &.no-border {
              border-top: none;
            }

            .account-name {
              font-weight: @bold;
            }
          }

          .current {
            position: absolute;
            left: 20px;
            margin-top: 4px;

            color: @teal-100;
            font-size: 18px;
          }

          &:hover {
            background-color: @brand-lighter-blue;
            mix-blend-mode: darken;

            &.managed {
              cursor: pointer;
            }
          }

          .account-name {
            font-size: 13px;
            line-height: 36px;
            color: @black-90;
          }

          .account-domain {
            font-size: 12px;
            line-height: 36px;
            font-weight: @regular;
            color: @black-70;
          }
        }
      }
    }

    .create-account {
      display: flex;
      justify-content: flex-end;

      padding: 15px 25px 5px 25px;

      .link-accent {
        font-size: 13px;
        line-height: 24px;
        color: @royal-200;

        span {
          margin-left: 5px;
        }
      }
    }
  }
}
