.user-invitation {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-height: 120px;

  .col-md-3 {
    padding-right: 0;
  }

  .form-group {
    margin-bottom: 0px;
    margin-top: 15px;
  }

  .email-invitations {
    margin-top: 15px;
    font-size: @font-size-small;

    input {
      width: auto;
    }
  }

  .help-block {
    margin-left: 10px;
    font-size: @font-size-small;
    color: @brand-danger;
  }

  .email {
    width: 100%;
    border: 2px solid darken(@brand-light, 3%);
    border-radius: 4px;
    padding: 5px;
  }

  .gh-b {
    font-size: 1em;
  }

  .gh-b.gh-accent {
    padding: 0px 10px 0px 10px;
    margin: 10px 0 15px 15px;
  }

  .gh-b.gh-b-border {
    padding: 2px 10px 2px 10px;
    margin: 10px 0 15px 10px;
  }
}
