@gray: grey-0, grey-5, grey-10, grey-20, grey-30, grey-40, grey-50;

each(@gray, {
  .@{value} {
    color: @@value;
  }
});

@gray-v2: grey-10-v2, grey-20-v2, grey-40-v2, grey-60-v2, grey-80-v2,
  grey-100-v2;

each(@gray-v2, {
  .@{value} {
    color: @@value;
  }
});

@gh-black: black-50, black-80;

each(@gh-black, {
  .@{value} {
    color: @@value;
  }
});

@ink-v2: ink-50-v2, ink-60-v2, ink-70-v2, ink-80-v2, ink-90-v2, ink-100-v2;

each(@ink-v2, {
  .@{value} {
    color: @@value;
  }
});

@ink-v3: ink-10-v3, ink-20-v3, ink-40-v3, ink-60-v3, ink-80-v3, ink-100-v3;

each(@ink-v3, {
  .@{value} {
    color: @@value;
  }
});

.hov-ink-80-v3 {
  &:hover {
    color: @ink-80-v3;
  }
}

@blue-v2: blue-5, blue-20-v2, blue-40-v2, blue-60-v2, blue-80-v2, blue-100-v2;

each(@blue-v2, {
  .@{value} {
    color: @@value;
  }
});

@red-v2: red-10-v2, red-20-v2, red-40-v2, red-60-v2, red-80-v2, red-100-v2;

each(@red-v2, {
  .@{value} {
    color: @@value;
  }
});

.link-blue,
.link-blue:focus {
  color: @blue-80-v2;
}

.link-hover:hover {
  color: @blue-100-v2;
}

.modal-grey {
  color: @brand-modal-grey-darker;
}

.brand-black {
  color: @brand-black;
}

.brand-dark {
  color: @brand-dark;
}

.text-white {
  color: #fff;
}
