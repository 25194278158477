@import "_variables.less";

body {
  min-height: 100%;
  background: @body-bg;
  color: @brand-dark;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
}
