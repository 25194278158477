.gh-modal-header {
  margin-bottom: 20px;
  padding: 22px 40px 22px 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: -40px;
  margin-left: -40px;
  margin-right: -40px;
  background: @brand-light;

  h1 {
    font-size: 1.2em;
    line-height: 1.4em;
    font-weight: @medium;
    letter-spacing: -0.02em;
  }

  p {
    font-size: 1em;
    line-height: 1.3em;
    margin-top: 9px;
    color: @brand-subtle;
  }

  a.back {
    .icon {
      font-size: 10px;
      margin-right: 5px;
    }
  }

  em {
    overflow-wrap: break-word;
  }
}

.gh-modal-full-width-header {
  margin: -50px -50px 27px;
  padding: 22px 40px 5px 40px;
}

.modal.fixed-selector {
  &.medium {
    .modal-dialog {
      .modal-content {
        margin-left: -400px;
      }
    }
  }
  .gh-modal-body {
    padding: 20px 0 60px 0;
  }
  .modal-dialog {
    height: 93%;
    padding: 0;
    position: relative;

    .modal-content {
      position: absolute;
      left: 50%;
      margin-left: -200px;
      height: 100%;
      bottom: 0;
      float: none;
      overflow-y: auto;

      .gh-modal-header {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        width: 100%;
        margin: 0;
      }

      .owners-list {
        top: 67px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0;
        bottom: 58px;
        overflow: scroll;
        overflow-x: auto;
        height: auto;
        padding: 0 40px 30px 40px;
      }

      .gh-fa {
        position: absolute;
        bottom: 0;
        margin: 0;
        padding: 10px;
        background: #eef0f4;
        left: 0;
        width: 100%;
        border-radius: 0 0 5px 5px;
      }
    }
  }
}

.gh-confirm {
  .gh-fa {
    position: relative;
    display: flex;
    justify-content: center;
  }
}

.gh-modal-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 20px;
  color: lighten(@brand-grey, 20%);
  cursor: pointer;

  &:hover {
    color: @brand-grey;

    .icon {
      font-weight: @ultrabold;
    }
  }

  &.close-left {
    left: 20px;
  }

  &.top-0px {
    top: 0px;
  }

  &.right-0px {
    right: 0px;
  }

  &.size-16px {
    height: 16px;
    width: 16px;
  }

  &.p-rel {
    .p-rel;
  }
}
