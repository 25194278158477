.gh-dropdown {
  position: relative;
  display: inline-block;

  .toggle {
    color: @brand-grey;
    cursor: pointer;
    font-size: 18px;

    &:hover {
      color: @brand-dark;
    }
  }

  .dropdown-menu {
    padding: 15px 25px;
    white-space: nowrap;

    a {
      color: @brand-dark;
      font-size: 1.2em;
      display: block;
      padding: 3px 0;

      &:hover {
        cursor: pointer;
        color: @brand-jira;
      }
    }
  }
}

.gh-dropdown-wrapper {
  position: relative;
  display: inline-block;

  .dropdown-toggle {
    cursor: pointer;
    outline: none;
    border: none;
    color: fade(@brand-dark, 30%);
    background: none;
    font-size: 14px;
    border-radius: 3px;
    width: 24px;
    height: 23px;
    display: flex;
    justify-content: center;

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      color: @brand-jira;
      background: @brand-lighter-blue;
    }

    &.small {
      height: 20px;
      width: 20px;

      .icon {
        font-size: 17px;
      }
    }
  }

  .dropdown-text {
    display: flex;
    align-items: center;
    color: @black-70;
    padding: 3px 10px;
    width: auto;
    height: auto;

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      color: @ink-90;
      background: none;
    }

    &.disabled,
    .disabled :hover {
      opacity: 0.5;
      color: @black-70;

      cursor: not-allowed;
    }

    .icon {
      margin-left: 7px;
      font-size: 7px;
    }

    #dropdown-button-label {
      text-align: right;
    }
  }

  ul {
    &.dropdown-menu {
      padding: 7px 0;

      &.sm {
        width: 120px;
      }
      &.md {
        max-width: 300px;
        min-width: 220px;
      }

      li {
        width: 100%;
        display: block;
        margin: 2px 0px;

        &.first-of-kind {
          border-top: 1px solid @brand-border;
          padding-top: 5px;
          margin-top: 5px;
        }

        &.last-of-kind {
          border-bottom: 1px solid @brand-border;
          padding-bottom: 5px;
          margin-bottom: 5px;
        }

        .dropdown-menu-button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: none;
          white-space: nowrap;
          width: 100%;
          border: 0;
          padding: 0px 15px 0px 23px;
          text-align: left;
          font-size: 13px;
          line-height: 2.5em;

          &:hover,
          &:focus,
          &:active {
            background: @brand-lighter-blue;
            color: @brand-dark;
          }

          &.warn {
            color: @brand-danger;
          }

          &.selected {
            position: relative;
            &:before {
              content: "\e013";
              position: absolute;
              top: 0px;
              left: 10px;
              font-size: 7px;
              font-family: "Glyphicons Halflings";
            }
          }

          &.center {
            padding: 0px;
            justify-content: center;
          }
        }
      }

      hr {
        margin: 5px 0;
      }
    }
  }
}
/* generic handler for all legacy dropdown menus */
.dropdown-menu {
  ul {
    margin-bottom: 0px;
  }
}
