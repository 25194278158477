@import (reference) "../../../less/_variables.less";

#create-first-goal-form {
  .create-first-goal {
    &.selectHowToStart {
      max-width: 660px;
    }
    &.startWithTemplate {
      max-width: 780px;

      .form-actions {
        margin-top: 20px;
      }
    }
    &.startOnYourOwn {
      max-width: 540px;
    }

    .gh-f-title {
      font-size: 30px;
      line-height: 34px;
      margin-bottom: 10px;
      color: @ink-100-v2;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .gh-fg {
      padding-top: 10px;
      padding-left: 7px;
    }

    .gh-input-info {
      // there is a .gh-input-info padding-right: 40px !important; which needs to be overridden
      padding-right: 0px !important;
    }

    .section-name {
      top: 0;
      margin-bottom: 5px;

      span.label-text {
        display: inline-block;
        position: relative;
        font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
        color: @black-80;
        font-weight: @semibold;
        font-size: 12px;

        .gh-b-input-info {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: -18px;
          top: 50%;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          border-radius: 9px;
          line-height: 18px;
          border: 1px solid @grey-30;
          text-align: center;
          color: @grey-30;
          font-size: 9px;
          font-weight: 700;
          cursor: help;

          &.green {
            background-color: @brand-green;
            border-color: @brand-green;
            color: @brand-white;
          }
        }

        &.large {
          font-size: 14px;
          line-height: 18px;
          font-weight: @regular;
          margin-bottom: 5px;
          color: @ink-100-v2;
        }
      }
    }

    input {
      font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
      font-size: 14px;
      color: @ink-100;
      transition: 0.3s all ease-in-out;

      &::placeholder {
        font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
        font-size: 14px;
        color: @grey-40;
      }
    }

    .gh-fe {
      text-align: left;
    }

    .form-actions {
      margin-top: 0;
      border-top: 0;
      padding-top: 0px;
      padding-bottom: 0px;

      .gh-b {
        border-radius: 38px;
        padding: 7px 25px;
        margin-left: 5px;
      }
    }
  }

  .create-first-goal-form-card {
    position: relative;
    padding: 20px;
    border: 2px solid @grey-10;
    border-radius: 25px;
    width: 50%;

    &.start-with-template {
      background: url("./../../../../../wwwroot/fe/img/screens/onboarding-home/visit-marketplace-inverted.svg")
        no-repeat;
      background-color: @grey-5;
      background-position-x: calc(100% + 5px);
      background-position-y: calc(10%);
      background-size: auto 130px;
    }

    &.start-on-your-own {
      background: url("./../../../../../wwwroot/fe/img/screens/onboarding-home/live-demo.svg")
        no-repeat;
      background-position-x: calc(100% + 5px);
      background-position-y: calc(10%);
      background-size: auto 130px;
    }

    .card-text-width {
      width: 75%;
    }

    h2 {
      font-family: "SF Pro Display", Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      font-weight: @bold;
      font-size: 20px;
      line-height: 26px;
      color: @ink-80-v2;
      letter-spacing: 0.005em;
      margin-bottom: 10px;
    }

    p {
      font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      color: @ink-70-v2;
      font-size: 14px;
      line-height: 18px;
      margin: 0px 20px 15px 0px;
    }
  }

  .recommended-okr-templates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .okr-template {
      width: 32%;
      border: 1px solid @black-20;
      padding: 20px 5px 10px 15px;
      word-break: break-word;

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-top: 15px;

          .installs-count {
            font-family: "Helvetica Neue";
            font-size: 12px;
            line-height: 18px;
            color: #8c92b1;
            margin-left: 10px;
          }

          button {
            padding: 5px 18px;
            font-size: 13px;
            line-height: 18px;
            border: none;
          }
        }

        img {
          max-width: 120px;
        }

        .item-info {
          .item-icon {
            margin-bottom: 20px;
          }

          .title {
            position: relative;
            font-family: "Axiforma-Bold", sans-serif;
            color: @ink-90-v2;
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 10px;
            text-align: left;

            padding-left: 25px;

            .item-icon {
              position: absolute;
              top: 1px;
              left: 0px;
            }
          }

          .description {
            font-size: 1.3rem;
            color: #8c92b1;
            line-height: 1.5em;
            padding-left: 25px;
          }

          > .metrics {
            position: relative;
            padding-top: 20px;
            margin-top: 20px;
            border-top: 1px solid @black-20;
            font-family: "Helvetica Neue", Arial, sans-serif;

            .metric {
              margin: 10px 0px;

              > .name {
                position: relative;
                padding: 0 0 0 25px;
                font-size: 12px;
                color: #344f5e;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                > .item-icon {
                  position: absolute;
                  top: -1px;
                  left: 0px;
                }
              }
            }
          }
        }

        .branding {
          width: 100%;
          min-height: 35px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          border-top: 1px solid #e5eaf2;
          margin-top: 15px;
          padding-top: 10px;

          .brand-name {
            font-family: "Helvetica Neue";
            font-size: 12px;
            line-height: 18px;
            color: @brand-modal-grey-darker;
          }

          .brand-logo {
            margin-left: 10px;

            img {
              height: 24px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .on-my-own {
    margin-left: 20px;
    cursor: pointer;
    color: @blue-80-v2;
  }

  a {
    color: @blue-80-v2;
  }

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 4;

    &.limit {
      text-align: center;
      color: @brand-white;
      font-size: 16px;
      line-height: 30px;
      padding: 100px 60px 0 60px;
      font-weight: @light;
      background: fade(@brand-dark, 80%);
    }
  }
}
