.gh-sessions {
  .level-disabled {
    position: relative;
    .gh-session-title {
      opacity: 0.5;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.with-icon {
    .gh-session {
      padding-left: 40px;
      min-height: 50px;

      .gh-icon-wrapper {
        position: absolute;
        font-size: 32px;
        left: 8px;
        top: auto;
        width: 24px;
      }
      .icon {
        position: absolute;
        font-size: 32px;
        left: 8px;
        top: 15px;
        color: @brand-light;
      }

      &:hover {
        .icon {
          color: lighten(@brand-subtler, 10%);
        }
      }
    }
  }
  .gh-session {
    justify-content: space-between;
    padding: 7px 10px;
    border-bottom: 1px solid @brand-light;
    position: relative;
    cursor: pointer;

    &.archived {
      .gh-session-title {
        opacity: 0.5;
      }
    }

    &:hover {
      background: @brand-light;
    }

    .information-wrapper {
      max-width: calc(100% - 130px);
    }

    .gh-session-title {
      font-weight: @medium;
      font-size: 1em;
      word-break: break-all;
    }

    .gh-session-range {
      font-size: 0.9em;
      color: @brand-subtler;
    }

    .badge-container {
      .gh-badge {
        margin-left: 20px;
      }
    }
  }

  .empty-session-modal {
    display: flex;
    justify-content: center;
    font-weight: @mediumbold;

    &:first-child {
      margin-top: 10%;
    }
  }
}

.modal.session-selector .modal-dialog .modal-content {
  padding: 50px;
}
