[class^="gh-font-icon-"],
[class*=" gh-font-icon-"] {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.small {
    width: 8px;
    height: 8px;
    font-size: 8px;
  }

  &.medium {
    width: 16px;
    height: 16px;
    font-size: 16px;
  }

  &.medium-large {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  &.large {
    width: 24px;
    height: 24px;
    font-size: 22px;
  }

  &.x-large {
    width: 32px;
    height: 32px;
    font-size: 30px;
  }
}

// Added this class here because is used in other less files like .gh-font-icon()
.gh-font-icon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "@{icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
