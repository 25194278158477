plan-details {
  background-color: @brand-white;
  border: 2px solid transparent;
  box-shadow: 0px 1px 15px rgba(33, 76, 107, 0.2);

  &.selected {
    box-shadow: none;
    border-color: @blue-60-v3;
  }

  .price {
    position: relative;
    line-height: 36px;
    width: fit-content;
    margin: 0 auto;

    &:before {
      content: "$";
      position: absolute;
      left: -12px;
      top: -3px;
      font-size: 18px;
    }
  }
}
