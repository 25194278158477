.tooltip.new-look {
  opacity: 1 !important;

  strong {
    background: @ink-70-v2;
    padding: 2px;
    border-radius: 2px;
  }

  .tooltip-inner {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: @ink-80-v2;
    border-radius: 2px;
    box-shadow:
      0 3px 6px -4px rgb(0 0 0 / 12%),
      0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
  }

  &.top {
    padding: 6px 0;

    .tooltip-arrow {
      margin-top: -6px;
      margin-left: -6px;
      border-width: 6px 6px 0;
      border-top-color: @ink-80-v2;
    }
  }

  &.left {
    padding: 0 6px;

    .tooltip-arrow {
      margin-top: -6px;
      margin-left: -6px;
      border-width: 6px 0 6px 6px;
      border-left-color: @ink-80-v2;
    }
  }

  &.right {
    padding: 0 6px;

    .tooltip-arrow {
      margin-top: -6px;
      margin-left: 0;
      border-width: 6px 6px 6px 0;
      border-right-color: @ink-80-v2;
    }
  }

  &.bottom {
    padding: 6px 0;

    .tooltip-arrow {
      margin-top: 0;
      margin-left: -6px;
      border-width: 0 6px 6px 6px;
      border-bottom-color: @ink-80-v2;
    }
  }
}
