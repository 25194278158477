editable-textarea {
  width: 100%;

  &:focus {
    outline: none;
  }

  &.visible {
    text-align: start;

    .editable-textarea {
      margin-top: 10px;
    }
  }

  &.required {
    &:before {
      position: absolute;
      left: 9px;
      font-size: 25px;
      content: "*";
      color: @brand-jira;
    }
  }

  &.with-offset {
    .editable-textarea {
      @offset: 5px;
      margin-bottom: 10px;
    }
  }

  &.inline-height-22 {
    .editable-textarea {
      .input-wrapper {
        margin: 0;
        padding: 0;
        background: inherit;
      }

      .with-shadow-input,
      .shadow-input {
        line-height: 22px;
        min-height: 24px;
        font-size: 20px;
        letter-spacing: -0.02em;
        font-weight: @mediumbold;
        min-width: 20px;
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        border: 1px solid transparent;
        border-radius: 4px;
        outline: none;

        &:hover {
          border: 1px solid @ink-50;
          box-shadow: inset 0 1px 3px fade(@ink-70, 10%);
        }

        &:focus,
        &:focus:hover {
          border: none;
          box-shadow: inset 0 1px 3px fade(@ink-70, 10%);
        }
      }

      .with-shadow-input--v2,
      .shadow-input--v2 {
        font-size: 14px;
        display: flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        overflow: hidden;
        color: @ink-85-v2;
        text-overflow: ellipsis;
        font-weight: 700;

        &:hover,
        &:active {
          border-radius: 4px;
          background: @brand-white;
          box-shadow: 0px 0px 1px 0px rgba(18, 57, 101, 0.8);
        }

        &:focus,
        &:focus-visible {
          border-radius: 4px;
          background: @brand-white;
          box-shadow:
            0px 0px 0px 2px @brand-white,
            0px 0px 0px 3px @blue-80-v2;
          outline: none;
        }
      }
    }
  }
}

.editable-textarea {
  @fakeHeight: 34px;
  @top: 8px;
  position: relative;

  .shadow-textarea {
    white-space: pre-wrap;
    word-break: break-word;
    opacity: 0;
    width: 100%;
  }

  textarea {
    padding: 0;
    resize: none;
    border: none;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:focus,
    &:focus:hover {
      z-index: 10;
      box-shadow: inset 0 1px 3px fade(@ink-70, 10%);
    }

    &:disabled {
      background: none;
    }
  }

  input {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: inherit;

    &.with-shadow-input {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .shadow-input {
    opacity: 0;
    height: 0;
  }

  textarea,
  input {
    &::placeholder {
      font-style: italic;
      color: @ink-50;
    }
  }

  textarea {
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;

    &:hover {
      border: 1px solid @ink-50;
      box-shadow: inset 0 1px 3px fade(@ink-70, 10%);
    }
  }

  .shadow-textarea,
  textarea {
    font-weight: 500;
    font-size: 31px;
    color: @ink-90;
    line-height: @fakeHeight;
    min-height: @fakeHeight;
  }

  .input-wrapper {
    margin: 0 -@ghCellOffset;
    padding: 0 @ghCellOffset;
    background: lighten(@brand-info, 36%);
  }
}
