.account-suspended-container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: @brand-white;
  position: fixed;

  #account-suspended {
    width: 500px;
    margin: 0 auto;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3,
    p {
      text-align: unset;
    }

    .sadface {
      display: flex;

      .media-2_sound-wave {
        font-size: 120px;
      }
    }
  }
}
