div.change-avatar {
  div.change-avatar-content {
    display: flex;
    flex-direction: column;

    span.gh-font-icon-trash-bin {
      padding: 6px;
      color: @black-100;
      font-size: 19px;
      border-radius: 100%;
      position: absolute;
      top: 50px;
      right: 120px;
      background: @brand-white;
      box-shadow: 0 3px 7px @brand-border;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }

    button.gh-b {
      border-radius: 90px;
      padding: 8px;
    }

    button.gh-b.full-width {
      width: 100%;
    }

    .remove-photo a {
      color: @brand-danger;
      cursor: pointer;
      font-size: 12px;
      font-weight: @mediumbold;
    }

    .upload-avatar {
      position: relative;

      h3 {
        font-size: 14px;
        color: @brand-modal-grey-darker;
        font-weight: @regular;
        line-height: 18px;
      }

      div.spur {
        position: absolute;
        width: 80%;
        left: 35px;
        top: 55px;

        .back-icon {
          font-size: 40px;
          color: rgba(135, 166, 188, 0.5);
          margin-top: -10px;

          span {
            margin-left: 10px;
          }
        }
      }

      div.crop-area {
        width: 100%;
        height: 250px;
        border: 1px dashed rgba(135, 166, 188, 0.9);
        position: relative;
        border-radius: 10px;

        &.dragover {
          border-color: @brand-primary;
          background: fade(@brand-primary, 10%);
        }

        img-crop {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
          overflow: hidden;

          canvas {
            margin: 0 !important;
          }
        }

        button {
          position: absolute;
          bottom: 50px;
          right: 96px;
          padding: 8px 25px;
        }
      }
    }
  }
}

.modal {
  .modal-dialog {
    .modal-footer {
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      color: #5d6f8a;
      p {
        margin-bottom: 0px;
        a {
          margin-left: 5px;
          font-weight: @mediumbold;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
