@brand-white: #fff;
@brand-black: #000000;
@brand-primary: #1cb987;
@brand-warning: #ffbd5e;
@brand-hot-trialaccount: #fe519b; // this is used for trial accounts styling
@brand-purple: #794aff;
@brand-info: #50ace5;
@brand-blue: #0000ff;
@brand-orange: #ffa500;
@body-bg: #f4f7f9;
//- @brand-dark: #26343b;
@brand-dark: #2e4354;
@brand-fog: #4c667b;
@brand-grey: #96a1a9;
@brand-grey2: #51617a;
@brand-grey-out: #e3e8f2;
@brand-jira: #0052cc;
@brand-medium: lighten(#2e4354, 6%);
@brand-lilac: #6236ff;
@brand-lilac-v2: #5940f6;
@brand-metric: #cafced;
@brand-navy: #221c74;
@brand-red: #c57e65;
@brand-green: #65c59b;
@brand-dark-grey: #2f4454;
@brand-pink: #a085ff;
@brand-lighter-grey: #f2f4f6;
@brand-lighter-blue: #eff7fe;
@brand-demo: #b87ee5;
@brand-modal-grey-darker: #5d6f8a;
@brand-black-lighter: #0d0d0e;

@brand-light-grey: #f4fafd;
@brand-list-grey: #c6d5e1;

@brand-nav-grey: #8a95a6;
@brand-nav-grey2: #afb4b9;
@brand-nav-subtle: #677887;

@brand-shiny: lighten(saturate(@brand-primary, 10%), 10%);
//-@font-family-sans-serif: 'Roboto', sans-serif;
//-@font-family-sans-serif: "proxima-nova", sans-serif;
@font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif: "Noto Serif", Georgia, serif;
@safe: "Arial", sans-serif;
@brand-danger: #ff5f5f;
@brand-error: darken(desaturate(@brand-danger, 15%), 3%);
@brand-light: #eef0f4;
@brand-lighter: lighten(#eef0f4, 20%);
@brand-subtle: #87a6bc;
@brand-subtler: desaturate(#87a6bc, 20%);

@light-purple: #f9faff;
@list-box-shadow: 0 0 2px rgba(0, 85, 204, 0.2);
@list-box-shadow-hover: 0 0 4px rgba(0, 85, 204, 0.3);
@list-cell-border: #f1f4f9;
@list-border-color: #f2f3f8;
@list-columns-color: #677887;
@list-filter-color: #3f576e;
@list-toggled-button-color: #d0f8c3;

@input-box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);

@dropdown-box-shadow:
  0 5px 10px rgba(9, 30, 66, 0.25),
  0 0 1px rgba(63, 87, 110, 0.31);

@brand-hot: #c84206;
@brand-link: #3d6eb6;
@link: #2a77e4;
@container-lg: 1360px;
@s: 30px;

@already-exists-tag: #aed2fe;

@brand-rebel: #0c4641;
@brand-border: #eee;
@brand-dark-border: #162d3a;

@list-separator: #f0f5fa;

@thin: 100;
@extralight: 200;
@light: 300;
@regular: 400;
@medium: 500;
@semibold: 500;
@mediumbold: 600;
@bold: 700;
@extrabold: 800;
@ultrabold: 900;

@input-border-focus: fade(@brand-primary, 60%);

@row-name-width: 25%;

@modal-content-padding: 50px;
@modal-medium-x-large-width: 660px;

//  screen sizes
@screen-width-med: 1250px;

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

code {
  color: @brand-info;
  background: fade(@brand-info, 10%);
}

.glyph() {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.placeholder(@fontSize: 14px, @color: lighten(@brand-subtle, 10%), @fontStyle: italic) {
  &:-ms-input-placeholder {
    font-size: @fontSize;
    color: @color;
    font-style: @fontStyle;
  }

  // for ms edge
  &::-ms-input-placeholder {
    font-size: @fontSize;
    color: @color;
    font-style: @fontStyle;
  }

  &::placeholder {
    font-size: @fontSize;
    color: @color;
    font-style: @fontStyle;
  }
}

.styled-scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: @brand-grey;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }
}

.transition(@transition) {
  -webkit-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.form-control-focus(@color: fade(@input-border-focus, 30%)) {
  &:focus {
    .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075)");
  }
}

.container-fluid {
  &.wrapper {
    padding: 0 40px;
  }
}

.badge {
  text-transform: uppercase;
  font-weight: @regular;

  &.badge-danger {
    background: @brand-danger;
  }

  &.badge-success {
    background: @brand-primary;
  }
}

.popover-noborder {
  border: none;

  .arrow {
    border-color: transparent !important;
  }
}

.font-thin {
  font-weight: @thin;
}

.font-extralight {
  font-weight: @extralight;
}

.font-light {
  font-weight: @light;
}

.font-normal {
  font-weight: @regular;
}

.font-medium {
  font-weight: @medium;
}

.font-semibold {
  font-weight: @semibold;
}

.font-mediumbold {
  font-weight: @mediumbold;
}

.font-bold {
  font-weight: @bold;
}

.font-extrabold {
  font-weight: @extrabold;
}

.font-black {
  font-weight: @ultrabold;
}

.text-4xs {
  font-size: 12px;
  line-height: 17px;
}

.text-3xs {
  // this is to be recalculated so it is not the same as text-4xs
  font-size: 12px;
  line-height: 17px;
}

.text-2xs {
  font-size: 13px;
  line-height: 19px;
}

.text-xs {
  font-size: 14px;
  line-height: 19px;
}

.text-sm {
  font-size: 15px;
  line-height: 21px;
}

.text-base {
  font-size: 16px;
  line-height: 23px;
}

.text-lg {
  font-size: 16px;
  line-height: 21px;
}

.text-xl {
  font-size: 18px;
  line-height: 25px;
}

.text-2xl {
  font-size: 20px;
  line-height: 27px;
}

.text-3xl {
  font-size: 26px;
  line-height: 31px;
}

.text-4xl {
  font-size: 30px;
  line-height: 35px;
}

.text-5xl {
  font-size: 40px;
  line-height: 41px;
}

// FIGMA 1:1

// COLOR PALETTE

// ink
@ink-10: #f4f6fa;
@ink-20: #e9f0f9;
@ink-30: #d7e4ef;
@ink-40: #c1d3e1;
@ink-50: #a9c3d7;
@ink-60: #8dabc4;
@ink-70: #5a7f9e;
@ink-80: #214c6b;
@ink-90: #003851;
@ink-100: #0d232f;

// black
@black-10: #f5f5fb;
@black-20: #eeeff8;
@black-30: #dbe1ea;
@black-40: #c8d1df;
@black-50: #aab6cb;
@black-60: #96a1bb;
@black-70: #7989a0;
@black-80: #51618a;
@black-90: #384758;
@black-100: #131724;

// blue
@blue-10: #f0fcff;
@blue-20: #e1f8ff;
@blue-30: #d6f5ff;
@blue-50: #b4edff;
@blue-60: #7ad9ff;
@blue-80: #37b9ff;
@blue-90: #3299ff;
@blue-100: #0070de;
@blue-200: #0061c1;
@blue-300: #004589;

// blue v2
@blue-5: #d3eaff;
@blue-20-v2: #93c0f6;
@blue-30-v2: #9bbeff;
@blue-40-v2: #75a2f7;
@blue-60-v2: #417aea;
@blue-80-v2: #0057d7;
@blue-100-v2: #003a7b;

// teal
@teal-10: #effdf9;
@teal-20: #ddfff5;
@teal-30: #ccfff0;
@teal-50: #b6ffe9;
@teal-60: #a1f3db;
@teal-80: #8fe7d2;
@teal-90: #2bd6a5;
@teal-100: #18c191;
@teal-200: #00aa79;
@teal-300: #008981;

// yellow
@yellow-10: #fffdf2;
@yellow-20: #fffae2;
@yellow-30: #fff8d6;
@yellow-50: #fff4bb;
@yellow-60: #ffed8e;
@yellow-80: #ffe143;
@yellow-90: #facd14;
@yellow-100: #eec301;
@yellow-200: #d59000;
@yellow-300: #8a6116;

// orange
@orange-10: #fefbf8;
@orange-20: #fdf5ec;
@orange-30: #fcebda;
@orange-50: #ffddaf;
@orange-60: #ffc58b;
@orange-80: #ffae49;
@orange-90: #fd9a00;
@orange-100: #fd6d40;
@orange-200: #c05717;
@orange-300: #8b2200;

// magenta
@magenta-10: #fffbfc;
@magenta-20: #fff4f7;
@magenta-30: #ffe9ef;
@magenta-50: #ffd3e0;
@magenta-60: #ffb1c3;
@magenta-80: #f67c99;
@magenta-90: #eb597b;
@magenta-100: #e83862;
@magenta-200: #bd005b;
@magenta-300: #80005a;

// purple
@purple-10: #fdf9fe;
@purple-20: #f9f0fd;
@purple-30: #f5e5fd;
@purple-50: #ead0ff;
@purple-60: #d8baff;
@purple-80: #c69aff;
@purple-90: #a970fe;
@purple-100: #914de7;
@purple-200: #6b33ba;
@purple-300: #50248f;

// indigo
@indigo-10: #f9fbff;
@indigo-20: #f4f5fe;
@indigo-30: #e2e9ff;
@indigo-50: #c9d5ff;
@indigo-60: #b5c0ff;
@indigo-80: #98a2ff;
@indigo-90: #8280ff;
@indigo-100: #715aff;
@indigo-200: #364097;
@indigo-300: #2a367c;

// royal
@royal-10: #edf4ff;
@royal-20: #e8f4ff;
@royal-30: #d2eaff;
@royal-50: #b1daff;
@royal-60: #96c6ff;
@royal-80: #81acff;
@royal-90: #3076ff;
@royal-100: #005fff;
@royal-200: #064adb;
@royal-300: #0331ac;

// grey
@grey-0: @brand-white;
@grey-5: #f1f3f8;
@grey-10: #d8e1ee;
@grey-20: #c0cbdd;
@grey-30: #9dabc5;
@grey-40: #8393ae;
@grey-50: #607293;

// ink v2
@ink-50-v2: #7485a2;
@ink-60-v2: #617294;
@ink-70-v2: #445573;
@ink-80-v2: #283d5c;
@ink-85-v2: #20354f;
@ink-90-v2: #0d232f;
@ink-100-v2: #021421;

// Placeholders
@placeholder-color-dark: #e3e8f2;
@placeholder-color-light: rgba(227, 232, 242, 0.5);
@placeholder-color-lighter: #f3f4f9;

// new color versions
// yellow
@yellow-10-v2: #fffae7;
@yellow-20-v2: #fff0b3;
@yellow-40-v2: #ffe380;
@yellow-60-v2: #ffc400;
@yellow-80-v2: #f7a600;
@yellow-100-v2: #d0852c;

// red
@red-10-v2: #fff2f2;
@red-20-v2: #ffd8d8;
@red-40-v2: #ffb1b1;
@red-60-v2: #f67c7c;
@red-80-v2: #da555d;
@red-100-v2: #b14055;

// magenta
@magenta-10-v2: #fbeefe;
@magenta-20-v2: #eecaff;
@magenta-40-v2: #dc9ef2;
@magenta-60-v2: #c573d9;
@magenta-80-v2: #a65fbf;
@magenta-100-v2: #903fa5;

// purple
@purple-10-v2: #f0eefe;
@purple-20-v2: #d3caff;
@purple-40-v2: #ac9ef2;
@purple-60-v2: #8d73d9;
@purple-80-v2: #7e5fbf;
@purple-100-v2: #603fa5;

// blue
@blue-10-v3: #e5f2ff;
@blue-20-v3: #9bbeff;
@blue-40-v3: #75a2f7;
@blue-60-v3: #417aea;
@blue-80-v3: #0057d7;
@blue-100-v3: #003a7b;

// yellow
@yellow-100-v3: #bd6d0e;

// cyan
@cyan-10-v2: #e5faff;
@cyan-20-v2: #97d3ff;
@cyan-40-v2: #56b8ff;
@cyan-60-v2: #1da0fe;
@cyan-80-v2: #027ed9;
@cyan-100-v2: #005a9b;

// teal
@teal-10-v2: #e3fcef;
@teal-20-v2: #afefe0;
@teal-40-v2: #66dec1;
@teal-60-v2: #18c191;
@teal-80-v2: #00a688;
@teal-100-v2: #01837b;

// green
@green-10-v2: #e8fce3;
@green-20-v2: #b0efaf;
@green-40-v2: #66de80;
@green-60-v2: #2bc560;
@green-80-v2: #00a64c;
@green-100-v2: #0c7d1e;

// grey
@grey-10-v2: #ffffff;
@grey-20-v2: #f1f3f8;
@grey-40-v2: #d8e1ee;
@grey-60-v2: #c0cbdd;
@grey-80-v2: #9dabc5;
@grey-100-v2: #8393ae;

// ink
@ink-10-v3: #7485a2;
@ink-20-v3: #617294;
@ink-40-v3: #445573;
@ink-60-v3: #283d5c;
@ink-80-v3: #0d232f;
@ink-100-v3: #021421;

// grey
@grey-10-v3: #d8dfea;

@emoji-font-family: "apple color emoji", "segoe ui emoji", "noto color emoji",
  "android emoji", "emojisymbols", "emojione mozilla", "twemoji mozilla",
  "segoe ui symbol", "sans-serif";

// vertical margins
@margin-sm: 12px; // Form controls and items

@font-size-sm: 12px;
