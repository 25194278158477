.twa {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1em 1em;
}

.twa-lg {
  height: 1.33em;
  width: 1.33em;
  margin: 0 0.0665em 0 0.133em;
  vertical-align: -0.133em;
  background-size: 1.33em 1.33em;
}

.twa-2x {
  height: 2em;
  width: 2em;
  margin: 0 0.1em 0 0.2em;
  vertical-align: -0.2em;
  background-size: 2em 2em;
}

.twa-3x {
  height: 3em;
  width: 3em;
  margin: 0 0.15em 0 0.3em;
  vertical-align: -0.3em;
  background-size: 3em 3em;
}

.twa-4x {
  height: 4em;
  width: 4em;
  margin: 0 0.2em 0 0.4em;
  vertical-align: -0.4em;
  background-size: 4em 4em;
}

.twa-5x {
  height: 5em;
  width: 5em;
  margin: 0 0.25em 0 0.5em;
  vertical-align: -0.5em;
  background-size: 5em 5em;
}

// .twa-mahjong-red-dragon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f004.svg"); }
//
// .twa-mahjong {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f004.svg"); }
//
// .twa-joker {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f0cf.svg"); }
//
// .twa-black-joker {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f0cf.svg"); }
//
// .twa-a-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f170.svg"); }
//
// .twa-a {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f170.svg"); }
//
// .twa-b-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f171.svg"); }
//
// .twa-b {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f171.svg"); }
//
// .twa-o-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f17e.svg"); }
//
// .twa-o2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f17e.svg"); }
//
// .twa-p-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f17f.svg"); }
//
// .twa-parking {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f17f.svg"); }
//
// .twa-ab-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f18e.svg"); }
//
// .twa-ab {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f18e.svg"); }
//
// .twa-squared-cl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f191.svg"); }
//
// .twa-cl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f191.svg"); }
//
// .twa-squared-cool {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f192.svg"); }
//
// .twa-cool {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f192.svg"); }
//
// .twa-squared-free {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f193.svg"); }
//
// .twa-free {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f193.svg"); }
//
// .twa-squared-id {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f194.svg"); }
//
// .twa-id {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f194.svg"); }
//
// .twa-squared-new {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f195.svg"); }
//
// .twa-new {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f195.svg"); }
//
// .twa-squared-ng {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f196.svg"); }
//
// .twa-ng {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f196.svg"); }
//
// .twa-squared-ok {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f197.svg"); }
//
// .twa-ok {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f197.svg"); }
//
// .twa-squared-sos {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f198.svg"); }
//
// .twa-sos {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f198.svg"); }
//
// .twa-upexc-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f199.svg"); }
//
// .twa-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f199.svg"); }
//
// .twa-squared-vs {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f19a.svg"); }
//
// .twa-vs {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f19a.svg"); }
//
// .twa-ascension-island-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1e8.svg"); }
//
// .twa-andorra-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1e9.svg"); }
//
// .twa-emirates-uae-united-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ea.svg"); }
//
// .twa-afghanistan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1eb.svg"); }
//
// .twa-antigua-barbuda-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ec.svg"); }
//
// .twa-anguilla-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ee.svg"); }
//
// .twa-albania-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f1.svg"); }
//
// .twa-armenia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f2.svg"); }
//
// .twa-angola-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f4.svg"); }
//
// .twa-antarctica-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f6.svg"); }
//
// .twa-argentina-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f7.svg"); }
//
// .twa-american-samoa-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f8.svg"); }
//
// .twa-austria-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f9.svg"); }
//
// .twa-australia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1fa.svg"); }
//
// .twa-aruba-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1fc.svg"); }
//
// .twa-aland-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1fd.svg"); }
//
// .twa-azerbaijan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ff.svg"); }
//
// .twa-aa {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6.svg"); }
//
// .twa-squared-a {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6.svg"); }
//
// .twa-bosnia-herzegovina-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1e6.svg"); }
//
// .twa-barbados-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1e7.svg"); }
//
// .twa-bangladesh-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1e9.svg"); }
//
// .twa-belgium-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ea.svg"); }
//
// .twa-burkina-faso-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1eb.svg"); }
//
// .twa-bulgaria-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ec.svg"); }
//
// .twa-bahrain-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ed.svg"); }
//
// .twa-burundi-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ee.svg"); }
//
// .twa-benin-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ef.svg"); }
//
// .twa-barthelemy-barthelemy-saint-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f1.svg"); }
//
// .twa-bermuda-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f2.svg"); }
//
// .twa-brunei-darussalam-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f3.svg"); }
//
// .twa-bolivia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f4.svg"); }
//
// .twa-bonaire-caribbean-eustatius-netherlands-saba-sint-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f6.svg"); }
//
// .twa-brazil-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f7.svg"); }
//
// .twa-bahamas-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f8.svg"); }
//
// .twa-bhutan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f9.svg"); }
//
// .twa-bouvet-island-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1fb.svg"); }
//
// .twa-botswana-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1fc.svg"); }
//
// .twa-belarus-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1fe.svg"); }
//
// .twa-belize-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ff.svg"); }
//
// .twa-bb {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7.svg"); }
//
// .twa-squared-b {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7.svg"); }
//
// .twa-canada-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1e6.svg"); }
//
// .twa-cocos-island-keeling-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1e8.svg"); }
//
// .twa-congo-congo-kinshasa-democratic-republic-of-congo-drc-kinshasa-republic-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1e9.svg"); }
//
// .twa-central-african-republic-republic-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1eb.svg"); }
//
// .twa-brazzaville-congo-congo-republic-congo-brazzaville-republic-republic-of-the-congo-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ec.svg"); }
//
// .twa-switzerland-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ed.svg"); }
//
// .twa-cote-ivoire-cote-ivoire-ivory-coast-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ee.svg"); }
//
// .twa-cook-island-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f0.svg"); }
//
// .twa-chile-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f1.svg"); }
//
// .twa-cameroon-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f2.svg"); }
//
// .twa-china-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f3.svg"); }
//
// .twa-cn {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f3.svg"); }
//
// .twa-colombia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f4.svg"); }
//
// .twa-clipperton-island-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f5.svg"); }
//
// .twa-costa-rica-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f7.svg"); }
//
// .twa-cuba-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fa.svg"); }
//
// .twa-cabo-cape-verde-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fb.svg"); }
//
// .twa-antilles-curacao-curaçao-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fc.svg"); }
//
// .twa-christmas-island-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fd.svg"); }
//
// .twa-cyprus-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fe.svg"); }
//
// .twa-czech-republic-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ff.svg"); }
//
// .twa-cc {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8.svg"); }
//
// .twa-squared-c {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8.svg"); }
//
// .twa-germany-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ea.svg"); }
//
// .twa-de {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ea.svg"); }
//
// .twa-diego-garcia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ec.svg"); }
//
// .twa-djibouti-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ef.svg"); }
//
// .twa-denmark-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1f0.svg"); }
//
// .twa-dominica-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1f2.svg"); }
//
// .twa-dominican-republic-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1f4.svg"); }
//
// .twa-algeria-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ff.svg"); }
//
// .twa-dd {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9.svg"); }
//
// .twa-squared-d {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9.svg"); }
//
// .twa-ceuta-melilla-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1e6.svg"); }
//
// .twa-ecuador-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1e8.svg"); }
//
// .twa-estonia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1ea.svg"); }
//
// .twa-egypt-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1ec.svg"); }
//
// .twa-sahara-west-western-sahara-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1ed.svg"); }
//
// .twa-eritrea-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f7.svg"); }
//
// .twa-spain-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f8.svg"); }
//
// .twa-es {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f8.svg"); }
//
// .twa-ethiopia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f9.svg"); }
//
// .twa-european-union-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1fa.svg"); }
//
// .twa-ee {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea.svg"); }
//
// .twa-squared-e {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea.svg"); }
//
// .twa-finland-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1ee.svg"); }
//
// .twa-fiji-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1ef.svg"); }
//
// .twa-falkland-falklands-island-islas-malvinas-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f0.svg"); }
//
// .twa-micronesia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f2.svg"); }
//
// .twa-faroe-island-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f4.svg"); }
//
// .twa-france-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f7.svg"); }
//
// .twa-fr {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f7.svg"); }
//
// .twa-ff {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb.svg"); }
//
// .twa-squared-f {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb.svg"); }
//
// .twa-gabon-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e6.svg"); }
//
// .twa-britain-british-cornwall-england-great-britain-ireland-northern-ireland-scotland-uk-union-jack-united-united-kingdom-wales-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e7.svg"); }
//
// .twa-gb {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e7.svg"); }
//
// .twa-grenada-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e9.svg"); }
//
// .twa-georgia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ea.svg"); }
//
// .twa-french-guiana-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1eb.svg"); }
//
// .twa-guernsey-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ec.svg"); }
//
// .twa-ghana-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ed.svg"); }
//
// .twa-gibraltar-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ee.svg"); }
//
// .twa-greenland-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f1.svg"); }
//
// .twa-gambia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f2.svg"); }
//
// .twa-guinea-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f3.svg"); }
//
// .twa-guadeloupe-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f5.svg"); }
//
// .twa-equatorial-guinea-guinea-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f6.svg"); }
//
// .twa-greece-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f7.svg"); }
//
// .twa-georgia-island-south-south-georgia-south-sandwich-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f8.svg"); }
//
// .twa-guatemala-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f9.svg"); }
//
// .twa-guam-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fa.svg"); }
//
// .twa-bissau-guinea-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fc.svg"); }
//
// .twa-guyana-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fe.svg"); }
//
// .twa-gg {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec.svg"); }
//
// .twa-squared-g {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec.svg"); }
//
// .twa-china-hong-kong-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f0.svg"); }
//
// .twa-heard-island-mcdonald-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f2.svg"); }
//
// .twa-honduras-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f3.svg"); }
//
// .twa-croatia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f7.svg"); }
//
// .twa-haiti-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f9.svg"); }
//
// .twa-hungary-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1fa.svg"); }
//
// .twa-hh {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed.svg"); }
//
// .twa-squared-h {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed.svg"); }
//
// .twa-canary-island-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1e8.svg"); }
//
// .twa-indonesia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1e9.svg"); }
//
// .twa-ireland-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1ea.svg"); }
//
// .twa-israel-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f1.svg"); }
//
// .twa-isle-of-man-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f2.svg"); }
//
// .twa-india-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f3.svg"); }
//
// .twa-british-chagos-indian-ocean-island-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f4.svg"); }
//
// .twa-iraq-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f6.svg"); }
//
// .twa-iran-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f7.svg"); }
//
// .twa-iceland-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f8.svg"); }
//
// .twa-italy-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f9.svg"); }
//
// .twa-it {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f9.svg"); }
//
// .twa-ii {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee.svg"); }
//
// .twa-squared-i {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee.svg"); }
//
// .twa-jersey-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef-1f1ea.svg"); }
//
// .twa-jamaica-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f2.svg"); }
//
// .twa-jordan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f4.svg"); }
//
// .twa-japan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f5.svg"); }
//
// .twa-jp {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f5.svg"); }
//
// .twa-jj {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef.svg"); }
//
// .twa-squared-j {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef.svg"); }
//
// .twa-kenya-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ea.svg"); }
//
// .twa-kyrgyzstan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ec.svg"); }
//
// .twa-cambodia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ed.svg"); }
//
// .twa-kiribati-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ee.svg"); }
//
// .twa-comoros-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f2.svg"); }
//
// .twa-kitts-nevis-saint-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f3.svg"); }
//
// .twa-korea-north-north-korea-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f5.svg"); }
//
// .twa-korea-south-south-korea-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f7.svg"); }
//
// .twa-kr {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f7.svg"); }
//
// .twa-kuwait-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1fc.svg"); }
//
// .twa-cayman-island-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1fe.svg"); }
//
// .twa-kazakhstan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ff.svg"); }
//
// .twa-kk {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0.svg"); }
//
// .twa-squared-k {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0.svg"); }
//
// .twa-laos-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e6.svg"); }
//
// .twa-lebanon-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e7.svg"); }
//
// .twa-lucia-saint-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e8.svg"); }
//
// .twa-liechtenstein-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1ee.svg"); }
//
// .twa-sri-lanka-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f0.svg"); }
//
// .twa-liberia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f7.svg"); }
//
// .twa-lesotho-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f8.svg"); }
//
// .twa-lithuania-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f9.svg"); }
//
// .twa-luxembourg-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1fa.svg"); }
//
// .twa-latvia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1fb.svg"); }
//
// .twa-libya-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1fe.svg"); }
//
// .twa-ll {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1.svg"); }
//
// .twa-squared-l {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1.svg"); }
//
// .twa-morocco-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1e6.svg"); }
//
// .twa-monaco-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1e8.svg"); }
//
// .twa-moldova-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1e9.svg"); }
//
// .twa-montenegro-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ea.svg"); }
//
// .twa-french-martin-saint-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1eb.svg"); }
//
// .twa-madagascar-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ec.svg"); }
//
// .twa-island-marshall-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ed.svg"); }
//
// .twa-macedonia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f0.svg"); }
//
// .twa-mali-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f1.svg"); }
//
// .twa-burma-myanmar-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f2.svg"); }
//
// .twa-mongolia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f3.svg"); }
//
// .twa-china-macao-macau-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f4.svg"); }
//
// .twa-island-mariana-north-northern-mariana-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f5.svg"); }
//
// .twa-martinique-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f6.svg"); }
//
// .twa-mauritania-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f7.svg"); }
//
// .twa-montserrat-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f8.svg"); }
//
// .twa-malta-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f9.svg"); }
//
// .twa-mauritius-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fa.svg"); }
//
// .twa-maldives-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fb.svg"); }
//
// .twa-malawi-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fc.svg"); }
//
// .twa-mexico-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fd.svg"); }
//
// .twa-malaysia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fe.svg"); }
//
// .twa-mozambique-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ff.svg"); }
//
// .twa-mm {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2.svg"); }
//
// .twa-squared-m {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2.svg"); }
//
// .twa-namibia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1e6.svg"); }
//
// .twa-new-new-caledonia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1e8.svg"); }
//
// .twa-niger-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ea.svg"); }
//
// .twa-island-norfolk-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1eb.svg"); }
//
// .twa-nigeria-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ec.svg"); }
//
// .twa-nicaragua-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ee.svg"); }
//
// .twa-netherlands-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f1.svg"); }
//
// .twa-norway-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f4.svg"); }
//
// .twa-nepal-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f5.svg"); }
//
// .twa-nauru-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f7.svg"); }
//
// .twa-niue-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1fa.svg"); }
//
// .twa-new-new-zealand-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ff.svg"); }
//
// .twa-nn {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3.svg"); }
//
// .twa-squared-n {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3.svg"); }
//
// .twa-oman-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f4-1f1f2.svg"); }
//
// .twa-oo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f4.svg"); }
//
// .twa-squared-o {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f4.svg"); }
//
// .twa-panama-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1e6.svg"); }
//
// .twa-peru-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1ea.svg"); }
//
// .twa-french-polynesia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1eb.svg"); }
//
// .twa-guinea-new-papua-new-guinea-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1ec.svg"); }
//
// .twa-philippines-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1ed.svg"); }
//
// .twa-pakistan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f0.svg"); }
//
// .twa-poland-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f1.svg"); }
//
// .twa-miquelon-pierre-saint-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f2.svg"); }
//
// .twa-island-pitcairn-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f3.svg"); }
//
// .twa-puerto-rico-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f7.svg"); }
//
// .twa-palestine-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f8.svg"); }
//
// .twa-portugal-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f9.svg"); }
//
// .twa-palau-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1fc.svg"); }
//
// .twa-paraguay-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1fe.svg"); }
//
// .twa-pp {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5.svg"); }
//
// .twa-squared-p {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5.svg"); }
//
// .twa-qatar-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f6-1f1e6.svg"); }
//
// .twa-qq {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f6.svg"); }
//
// .twa-squared-q {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f6.svg"); }
//
// .twa-reunion-reunion-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1ea.svg"); }
//
// .twa-romania-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1f4.svg"); }
//
// .twa-serbia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1f8.svg"); }
//
// .twa-russia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1fa.svg"); }
//
// .twa-ru {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1fa.svg"); }
//
// .twa-rwanda-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1fc.svg"); }
//
// .twa-rr {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7.svg"); }
//
// .twa-squared-r {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7.svg"); }
//
// .twa-saudi-arabia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e6.svg"); }
//
// .twa-island-solomon-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e7.svg"); }
//
// .twa-seychelles-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e8.svg"); }
//
// .twa-sudan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e9.svg"); }
//
// .twa-sweden-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ea.svg"); }
//
// .twa-singapore-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ec.svg"); }
//
// .twa-helena-saint-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ed.svg"); }
//
// .twa-slovenia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ee.svg"); }
//
// .twa-jan-mayen-svalbard-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ef.svg"); }
//
// .twa-slovakia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f0.svg"); }
//
// .twa-sierra-leone-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f1.svg"); }
//
// .twa-san-marino-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f2.svg"); }
//
// .twa-senegal-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f3.svg"); }
//
// .twa-somalia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f4.svg"); }
//
// .twa-suriname-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f7.svg"); }
//
// .twa-south-south-sudan-sudan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f8.svg"); }
//
// .twa-principe-principe-sao-tome-sao-tome-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f9.svg"); }
//
// .twa-el-salvador-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1fb.svg"); }
//
// .twa-maarten-sint-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1fd.svg"); }
//
// .twa-syria-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1fe.svg"); }
//
// .twa-swaziland-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ff.svg"); }
//
// .twa-ss {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8.svg"); }
//
// .twa-squared-s {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8.svg"); }
//
// .twa-tristan-da-cunha-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1e6.svg"); }
//
// .twa-caicos-island-turks-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1e8.svg"); }
//
// .twa-chad-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1e9.svg"); }
//
// .twa-antarctic-french-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1eb.svg"); }
//
// .twa-togo-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ec.svg"); }
//
// .twa-thailand-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ed.svg"); }
//
// .twa-tajikistan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ef.svg"); }
//
// .twa-tokelau-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f0.svg"); }
//
// .twa-east-east-timor-timor-leste-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f1.svg"); }
//
// .twa-turkmenistan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f2.svg"); }
//
// .twa-tunisia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f3.svg"); }
//
// .twa-tonga-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f4.svg"); }
//
// .twa-turkey-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f7.svg"); }
//
// .twa-tobago-trinidad-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f9.svg"); }
//
// .twa-tuvalu-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1fb.svg"); }
//
// .twa-china-taiwan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1fc.svg"); }
//
// .twa-tanzania-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ff.svg"); }
//
// .twa-tt {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9.svg"); }
//
// .twa-squared-t {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9.svg"); }
//
// .twa-ukraine-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1e6.svg"); }
//
// .twa-uganda-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1ec.svg"); }
//
// .twa-america-island-minor-outlying-united-united-states-us-usa-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1f2.svg"); }
//
// .twa-america-stars-and-stripes-united-united-states-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1f8.svg"); }
//
// .twa-us {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1f8.svg"); }
//
// .twa-uruguay-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1fe.svg"); }
//
// .twa-uzbekistan-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1ff.svg"); }
//
// .twa-uu {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa.svg"); }
//
// .twa-squared-u {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa.svg"); }
//
// .twa-vatican-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1e6.svg"); }
//
// .twa-grenadines-saint-vincent-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1e8.svg"); }
//
// .twa-venezuela-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1ea.svg"); }
//
// .twa-british-island-virgin-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1ec.svg"); }
//
// .twa-america-american-island-united-united-states-us-usa-virgin-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1ee.svg"); }
//
// .twa-viet-nam-vietnam-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1f3.svg"); }
//
// .twa-vanuatu-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1fa.svg"); }
//
// .twa-vv {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb.svg"); }
//
// .twa-squared-v {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb.svg"); }
//
// .twa-futuna-wallis-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fc-1f1eb.svg"); }
//
// .twa-samoa-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fc-1f1f8.svg"); }
//
// .twa-ww {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fc.svg"); }
//
// .twa-squared-w {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fc.svg"); }
//
// .twa-kosovo-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fd-1f1f0.svg"); }
//
// .twa-xx {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fd.svg"); }
//
// .twa-squared-x {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fd.svg"); }
//
// .twa-yemen-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fe-1f1ea.svg"); }
//
// .twa-mayotte-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fe-1f1f9.svg"); }
//
// .twa-yy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fe.svg"); }
//
// .twa-squared-y {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fe.svg"); }
//
// .twa-south-south-africa-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ff-1f1e6.svg"); }
//
// .twa-zambia-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ff-1f1f2.svg"); }
//
// .twa-zimbabwe-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ff-1f1fc.svg"); }
//
// .twa-zz {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ff.svg"); }
//
// .twa-squared-z {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ff.svg"); }
//
// .twa-squared-katakana-koko {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f201.svg"); }
//
// .twa-koko {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f201.svg"); }
//
// .twa-squared-katakana-sa {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f202.svg"); }
//
// .twa-sa {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f202.svg"); }
//
// .twa-squared-negation-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f21a.svg"); }
//
// .twa-u7121 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f21a.svg"); }
//
// .twa-squared-finger-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f22f.svg"); }
//
// .twa-u6307 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f22f.svg"); }
//
// .twa-squared-prohibit-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f232.svg"); }
//
// .twa-u7981 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f232.svg"); }
//
// .twa-squared-empty-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f233.svg"); }
//
// .twa-u7a7a {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f233.svg"); }
//
// .twa-squared-together-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f234.svg"); }
//
// .twa-u5408 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f234.svg"); }
//
// .twa-squared-fullness-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f235.svg"); }
//
// .twa-u6e80 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f235.svg"); }
//
// .twa-squared-exist-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f236.svg"); }
//
// .twa-u6709 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f236.svg"); }
//
// .twa-squared-moon-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f237.svg"); }
//
// .twa-u6708 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f237.svg"); }
//
// .twa-squared-apply-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f238.svg"); }
//
// .twa-u7533 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f238.svg"); }
//
// .twa-squared-divide-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f239.svg"); }
//
// .twa-u5272 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f239.svg"); }
//
// .twa-squared-operating-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f23a.svg"); }
//
// .twa-u55b6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f23a.svg"); }
//
// .twa-circled-advantage-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f250.svg"); }
//
// .twa-ideograph-advantage {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f250.svg"); }
//
// .twa-circled-accept-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f251.svg"); }
//
// .twa-accept {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f251.svg"); }
//
// .twa-cyclone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f300.svg"); }
//
// .twa-foggy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f301.svg"); }
//
// .twa-closed-umbrella {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f302.svg"); }
//
// .twa-night-with-stars {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f303.svg"); }
//
// .twa-stars {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f303.svg"); }
//
// .twa-sunrise-over-mountains {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f304.svg"); }
//
// .twa-sunrise {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f305.svg"); }
//
// .twa-cityscape-at-dusk {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f306.svg"); }
//
// .twa-city-sunset {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f306.svg"); }
//
// .twa-sunset {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f307.svg"); }
//
// .twa-city-sunrise {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f307.svg"); }
//
// .twa-rainbow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f308.svg"); }
//
// .twa-bridge-at-night {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f309.svg"); }
//
// .twa-water-wave {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30a.svg"); }
//
// .twa-ocean {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30a.svg"); }
//
// .twa-volcano {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30b.svg"); }
//
// .twa-milky-way {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30c.svg"); }
//
// .twa-globe-showing-europe-africa {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30d.svg"); }
//
// .twa-earth-africa {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30d.svg"); }
//
// .twa-globe-showing-americas {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30e.svg"); }
//
// .twa-earth-americas {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30e.svg"); }
//
// .twa-globe-showing-asia-australia {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30f.svg"); }
//
// .twa-earth-asia {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f30f.svg"); }
//
// .twa-globe-with-meridians {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f310.svg"); }
//
// .twa-new-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f311.svg"); }
//
// .twa-waxing-crescent-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f312.svg"); }
//
// .twa-first-quarter-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f313.svg"); }
//
// .twa-waxing-gibbous-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f314.svg"); }
//
// .twa-full-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f315.svg"); }
//
// .twa-waning-gibbous-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f316.svg"); }
//
// .twa-last-quarter-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f317.svg"); }
//
// .twa-waning-crescent-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f318.svg"); }
//
// .twa-crescent-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f319.svg"); }
//
// .twa-moon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f319.svg"); }
//
// .twa-new-moon-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f31a.svg"); }
//
// .twa-new-moon-with-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f31a.svg"); }
//
// .twa-first-quarter-moon-with-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f31b.svg"); }
//
// .twa-last-quarter-moon-with-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f31c.svg"); }
//
// .twa-full-moon-with-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f31d.svg"); }
//
.twa-sun-with-face {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f31e.svg");
}
//
// .twa-glowing-star {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f31f.svg"); }
//
// .twa-star2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f31f.svg"); }
//
// .twa-shooting-star {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f320.svg"); }
//
// .twa-thermometer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f321.svg"); }
//
// .twa-sun-behind-small-cloud {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f324.svg"); }
//
// .twa-sun-behind-large-cloud {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f325.svg"); }
//
// .twa-sun-behind-cloud-with-rain {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f326.svg"); }
//
// .twa-cloud-with-rain {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f327.svg"); }
//
// .twa-cloud-with-snow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f328.svg"); }
//
// .twa-cloud-with-lightning {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f329.svg"); }
//
// .twa-tornado {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f32a.svg"); }
//
// .twa-fog {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f32b.svg"); }
//
// .twa-wind-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f32c.svg"); }
//
// .twa-hot-dog {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f32d.svg"); }
//
// .twa-taco {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f32e.svg"); }
//
// .twa-burrito {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f32f.svg"); }
//
// .twa-chestnut {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f330.svg"); }
//
// .twa-seedling {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f331.svg"); }
//
// .twa-evergreen {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f332.svg"); }
//
// .twa-evergreen-tree {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f332.svg"); }
//
// .twa-deciduous-tree {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f333.svg"); }
//
// .twa-palm-tree {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f334.svg"); }
//
// .twa-cactus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f335.svg"); }
//
// .twa-hot-pepper {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f336.svg"); }
//
// .twa-tulip {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f337.svg"); }
//
// .twa-cherry-blossom {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f338.svg"); }
//
// .twa-rose {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f339.svg"); }
//
// .twa-hibiscus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f33a.svg"); }
//
// .twa-sunflower {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f33b.svg"); }
//
// .twa-blossom {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f33c.svg"); }
//
// .twa-ear-of-corn {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f33d.svg"); }
//
// .twa-corn {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f33d.svg"); }
//
// .twa-sheaf-of-rice {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f33e.svg"); }
//
// .twa-ear-of-rice {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f33e.svg"); }
//
// .twa-herb {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f33f.svg"); }
//
.twa-four-leaf-clover {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f340.svg");
}
//
// .twa-maple-leaf {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f341.svg"); }
//
// .twa-fallen-leaf {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f342.svg"); }
//
// .twa-leaf-fluttering-in-wind {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f343.svg"); }
//
// .twa-leaves {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f343.svg"); }
//
// .twa-mushroom {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f344.svg"); }
//
// .twa-tomato {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f345.svg"); }
//
// .twa-eggplant {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f346.svg"); }
//
// .twa-grapes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f347.svg"); }
//
// .twa-melon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f348.svg"); }
//
// .twa-watermelon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f349.svg"); }
//
// .twa-tangerine {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f34a.svg"); }
//
.twa-lemon {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f34b.svg");
}
//
// .twa-banana {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f34c.svg"); }
//
// .twa-pineapple {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f34d.svg"); }
//
// .twa-red-apple {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f34e.svg"); }
//
// .twa-apple {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f34e.svg"); }
//
// .twa-green-apple {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f34f.svg"); }
//
// .twa-pear {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f350.svg"); }
//
// .twa-peach {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f351.svg"); }
//
// .twa-cherries {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f352.svg"); }
//
// .twa-strawberry {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f353.svg"); }
//
// .twa-hamburger {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f354.svg"); }
//
// .twa-pizza {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f355.svg"); }
//
// .twa-meat-on-bone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f356.svg"); }
//
// .twa-poultry-leg {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f357.svg"); }
//
// .twa-rice-cracker {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f358.svg"); }
//
// .twa-rice-ball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f359.svg"); }
//
// .twa-cooked-rice {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35a.svg"); }
//
// .twa-rice {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35a.svg"); }
//
// .twa-curry-rice {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35b.svg"); }
//
// .twa-curry {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35b.svg"); }
//
// .twa-steaming-bowl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35c.svg"); }
//
// .twa-ramen {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35c.svg"); }
//
// .twa-spaghetti {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35d.svg"); }
//
// .twa-bread {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35e.svg"); }
//
// .twa-french-fries {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35f.svg"); }
//
// .twa-fries {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f35f.svg"); }
//
// .twa-roasted-sweet-potato {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f360.svg"); }
//
// .twa-sweet-potato {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f360.svg"); }
//
// .twa-dango {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f361.svg"); }
//
// .twa-oden {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f362.svg"); }
//
// .twa-sushi {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f363.svg"); }
//
// .twa-fried-shrimp {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f364.svg"); }
//
// .twa-fish-cake-with-swirl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f365.svg"); }
//
// .twa-fish-cake {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f365.svg"); }
//
// .twa-soft-ice-cream {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f366.svg"); }
//
// .twa-icecream {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f366.svg"); }
//
// .twa-shaved-ice {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f367.svg"); }
//
// .twa-ice-cream {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f368.svg"); }
//
// .twa-doughnut {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f369.svg"); }
//
// .twa-cookie {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f36a.svg"); }
//
// .twa-chocolate-bar {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f36b.svg"); }
//
// .twa-candy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f36c.svg"); }
//
// .twa-lollipop {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f36d.svg"); }
//
// .twa-custard {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f36e.svg"); }
//
// .twa-honey-pot {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f36f.svg"); }
//
// .twa-shortcake {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f370.svg"); }
//
.twa-cake {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f370.svg");
}
//
// .twa-bento-box {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f371.svg"); }
//
// .twa-bento {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f371.svg"); }
//
// .twa-pot-of-food {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f372.svg"); }
//
// .twa-stew {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f372.svg"); }
//
// .twa-cooking {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f373.svg"); }
//
// .twa-fork-and-knife {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f374.svg"); }
//
// .twa-teacup-without-handle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f375.svg"); }
//
// .twa-tea {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f375.svg"); }
//
// .twa-sake {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f376.svg"); }
//
.twa-wine-glass {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f377.svg");
}
//
// .twa-cocktail-glass {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f378.svg"); }
//
// .twa-cocktail {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f378.svg"); }
//
// .twa-tropical-drink {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f379.svg"); }
//
// .twa-beer-mug {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f37a.svg"); }
//
.twa-beer {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f37a.svg");
}
//
// .twa-clinking-beer-mugs {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f37b.svg"); }
//
// .twa-beers {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f37b.svg"); }
//
// .twa-baby-bottle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f37c.svg"); }
//
// .twa-fork-and-knife-with-plate {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f37d.svg"); }
//
// .twa-bottle-with-popping-cork {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f37e.svg"); }
//
// .twa-popcorn {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f37f.svg"); }
//
// .twa-ribbon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f380.svg"); }
//
// .twa-wrapped-present {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f381.svg"); }
//
// .twa-gift {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f381.svg"); }
//
// .twa-birthday-cake {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f382.svg"); }
//
// .twa-birthday {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f382.svg"); }
//
// .twa-jack-o-lantern {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f383.svg"); }
//
// .twa-christmas-tree {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f384.svg"); }
//
// .twa-father-christmas-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f385-1f3fb.svg"); }
//
// .twa-father-christmas-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f385-1f3fc.svg"); }
//
// .twa-father-christmas-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f385-1f3fd.svg"); }
//
// .twa-father-christmas-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f385-1f3fe.svg"); }
//
// .twa-father-christmas-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f385-1f3ff.svg"); }
//
// .twa-santa-claus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f385.svg"); }
//
// .twa-santa {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f385.svg"); }
//
// .twa-fireworks {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f386.svg"); }
//
// .twa-sparkler {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f387.svg"); }
//
// .twa-balloon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f388.svg"); }
//
// .twa-party-popper {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f389.svg"); }
//
.twa-tada {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f389.svg");
}
//
// .twa-confetti-ball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f38a.svg"); }
//
// .twa-tanabata-tree {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f38b.svg"); }
//
// .twa-crossed-flags {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f38c.svg"); }
//
// .twa-pine-decoration {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f38d.svg"); }
//
// .twa-bamboo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f38d.svg"); }
//
// .twa-japanese-dolls {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f38e.svg"); }
//
// .twa-dolls {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f38e.svg"); }
//
// .twa-carp-streamer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f38f.svg"); }
//
// .twa-flags {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f38f.svg"); }
//
// .twa-wind-chime {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f390.svg"); }
//
// .twa-moon-ceremony {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f391.svg"); }
//
// .twa-rice-scene {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f391.svg"); }
//
// .twa-school-backpack {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f392.svg"); }
//
// .twa-school-satchel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f392.svg"); }
//
// .twa-graduation-cap {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f393.svg"); }
//
// .twa-mortar-board {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f393.svg"); }
//
// .twa-military-medal {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f396.svg"); }
//
// .twa-reminder-ribbon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f397.svg"); }
//
// .twa-studio-microphone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f399.svg"); }
//
// .twa-level-slider {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f39a.svg"); }
//
// .twa-control-knobs {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f39b.svg"); }
//
// .twa-film-frames {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f39e.svg"); }
//
// .twa-admission-tickets {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f39f.svg"); }
//
// .twa-carousel-horse {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a0.svg"); }
//
// .twa-ferris-wheel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a1.svg"); }
//
// .twa-roller-coaster {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a2.svg"); }
//
// .twa-fishing-pole {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a3.svg"); }
//
// .twa-fishing-pole-and-fish {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a3.svg"); }
//
// .twa-microphone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a4.svg"); }
//
// .twa-movie-camera {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a5.svg"); }
//
// .twa-cinema {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a6.svg"); }
//
// .twa-headphone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a7.svg"); }
//
// .twa-headphones {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a7.svg"); }
//
// .twa-artist-palette {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a8.svg"); }
//
// .twa-art {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a8.svg"); }
//
// .twa-top-hat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a9.svg"); }
//
// .twa-tophat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a9.svg"); }
//
// .twa-circus-tent {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3aa.svg"); }
//
// .twa-ticket {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ab.svg"); }
//
// .twa-clapper-board {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ac.svg"); }
//
// .twa-clapper {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ac.svg"); }
//
// .twa-performing-arts {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ad.svg"); }
//
// .twa-video-game {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ae.svg"); }
//
// .twa-direct-hit {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3af.svg"); }
//
// .twa-dart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3af.svg"); }
//
// .twa-slot-machine {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b0.svg"); }
//
// .twa-billiards {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b1.svg"); }
//
// .twa-8ball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b1.svg"); }
//
// .twa-game-die {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b2.svg"); }
//
// .twa-bowling {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b3.svg"); }
//
// .twa-flower-playing-cards {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b4.svg"); }
//
// .twa-musical-note {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b5.svg"); }
//
// .twa-musical-notes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b6.svg"); }
//
// .twa-notes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b6.svg"); }
//
// .twa-saxophone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b7.svg"); }
//
.twa-guitar {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f3b8.svg");
}
//
// .twa-musical-keyboard {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b9.svg"); }
//
// .twa-trumpet {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ba.svg"); }
//
// .twa-violin {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bb.svg"); }
//
// .twa-musical-score {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bc.svg"); }
//
// .twa-running-shirt {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bd.svg"); }
//
// .twa-running-shirt-with-sash {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bd.svg"); }
//
// .twa-tennis {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3be.svg"); }
//
// .twa-skis {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bf.svg"); }
//
// .twa-ski {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bf.svg"); }
//
// .twa-basketball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c0.svg"); }
//
// .twa-chequered-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c1.svg"); }
//
// .twa-checkered-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c1.svg"); }
//
// .twa-snowboarder-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c2-1f3fb.svg"); }
//
// .twa-snowboarder-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c2-1f3fc.svg"); }
//
// .twa-snowboarder-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c2-1f3fd.svg"); }
//
// .twa-snowboarder-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c2-1f3fe.svg"); }
//
// .twa-snowboarder-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c2-1f3ff.svg"); }
//
// .twa-snowboarder {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c2.svg"); }
//
// .twa-runner-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c3-1f3fb.svg"); }
//
// .twa-runner-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c3-1f3fc.svg"); }
//
// .twa-runner-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c3-1f3fd.svg"); }
//
// .twa-runner-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c3-1f3fe.svg"); }
//
// .twa-runner-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c3-1f3ff.svg"); }
//
// .twa-runner {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c3.svg"); }
//
// .twa-surfer-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c4-1f3fb.svg"); }
//
// .twa-surfer-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c4-1f3fc.svg"); }
//
// .twa-surfer-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c4-1f3fd.svg"); }
//
// .twa-surfer-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c4-1f3fe.svg"); }
//
// .twa-surfer-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c4-1f3ff.svg"); }
//
// .twa-surfer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c4.svg"); }
//
// .twa-sports-medal {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c5.svg"); }
//
// .twa-trophy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c6.svg"); }
//
// .twa-horse-racing-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c7-1f3fb.svg"); }
//
// .twa-horse-racing-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c7-1f3fc.svg"); }
//
// .twa-horse-racing-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c7-1f3fd.svg"); }
//
// .twa-horse-racing-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c7-1f3fe.svg"); }
//
// .twa-horse-racing-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c7-1f3ff.svg"); }
//
// .twa-horse-racing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c7.svg"); }
//
// .twa-american-football {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c8.svg"); }
//
// .twa-football {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c8.svg"); }
//
// .twa-rugby-football {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c9.svg"); }
//
// .twa-swimmer-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ca-1f3fb.svg"); }
//
// .twa-swimmer-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ca-1f3fc.svg"); }
//
// .twa-swimmer-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ca-1f3fd.svg"); }
//
// .twa-swimmer-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ca-1f3fe.svg"); }
//
// .twa-swimmer-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ca-1f3ff.svg"); }
//
// .twa-swimmer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ca.svg"); }
//
// .twa-weight-lifter-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb-1f3fb.svg"); }
//
// .twa-weight-lifter-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb-1f3fc.svg"); }
//
// .twa-weight-lifter-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb-1f3fd.svg"); }
//
// .twa-weight-lifter-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb-1f3fe.svg"); }
//
// .twa-weight-lifter-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb-1f3ff.svg"); }
//
// .twa-weight-lifter {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb.svg"); }
//
// .twa-golfer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cc.svg"); }
//
// .twa-motorcycle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cd.svg"); }
//
// .twa-racing-car {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ce.svg"); }
//
// .twa-cricket {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cf.svg"); }
//
// .twa-volleyball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d0.svg"); }
//
// .twa-field-hockey {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d1.svg"); }
//
// .twa-ice-hockey-stick-and-puck {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d2.svg"); }
//
// .twa-ping-pong {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d3.svg"); }
//
// .twa-snow-capped-mountain {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d4.svg"); }
//
// .twa-camping {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d5.svg"); }
//
// .twa-beach-with-umbrella {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d6.svg"); }
//
// .twa-building-construction {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d7.svg"); }
//
// .twa-house-buildings {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d8.svg"); }
//
// .twa-cityscape {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d9.svg"); }
//
// .twa-derelict-house-building {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3da.svg"); }
//
// .twa-classical-building {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3db.svg"); }
//
// .twa-desert {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3dc.svg"); }
//
// .twa-desert-island {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3dd.svg"); }
//
// .twa-national-park {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3de.svg"); }
//
// .twa-stadium {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3df.svg"); }
//
// .twa-house-building {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e0.svg"); }
//
// .twa-house {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e0.svg"); }
//
// .twa-house-with-garden {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e1.svg"); }
//
// .twa-office-building {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e2.svg"); }
//
// .twa-office {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e2.svg"); }
//
// .twa-japanese-post-office {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e3.svg"); }
//
// .twa-post-office {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e4.svg"); }
//
// .twa-european-post-office {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e4.svg"); }
//
// .twa-hospital {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e5.svg"); }
//
// .twa-bank {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e6.svg"); }
//
// .twa-atm-sign {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e7.svg"); }
//
// .twa-atm {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e7.svg"); }
//
// .twa-hotel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e8.svg"); }
//
// .twa-love-hotel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e9.svg"); }
//
// .twa-convenience-store {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ea.svg"); }
//
// .twa-school {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3eb.svg"); }
//
// .twa-department-store {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ec.svg"); }
//
// .twa-factory {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ed.svg"); }
//
// .twa-red-paper-lantern {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ee.svg"); }
//
// .twa-japanese-castle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ef.svg"); }
//
// .twa-castle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f0.svg"); }
//
// .twa-european-castle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f0.svg"); }
//
// .twa-rainbow-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f3-fe0f-200d-1f308.svg"); }
//
// .twa-pride {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f3-fe0f-200d-1f308.svg"); }
//
// .twa-waving-white-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f3.svg"); }
//
// .twa-pirate-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f4-200d-2620-fe0f.svg"); }
//
// .twa-jolly-roger {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f4-200d-2620-fe0f.svg"); }
//
// .twa-waving-black-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f4.svg"); }
//
// .twa-rosette {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f5.svg"); }
//
// .twa-label {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f7.svg"); }
//
// .twa-badminton {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f8.svg"); }
//
// .twa-bow-and-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f9.svg"); }
//
// .twa-amphora {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fa.svg"); }
//
// .twa-skin-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fb.svg"); }
//
// .twa-skin-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fc.svg"); }
//
// .twa-skin-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fd.svg"); }
//
// .twa-skin-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fe.svg"); }
//
// .twa-skin-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ff.svg"); }
//
// .twa-rat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f400.svg"); }
//
// .twa-mouse {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f401.svg"); }
//
// .twa-mouse2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f401.svg"); }
//
// .twa-ox {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f402.svg"); }
//
// .twa-water-buffalo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f403.svg"); }
//
// .twa-cow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f404.svg"); }
//
// .twa-cow2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f404.svg"); }
//
// .twa-tiger {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f405.svg"); }
//
// .twa-tiger2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f405.svg"); }
//
// .twa-leopard {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f406.svg"); }
//
// .twa-rabbit {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f407.svg"); }
//
// .twa-rabbit2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f407.svg"); }
//
// .twa-cat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f408.svg"); }
//
// .twa-cat2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f408.svg"); }
//
// .twa-dragon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f409.svg"); }
//
// .twa-crocodile {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f40a.svg"); }
//
// .twa-whale {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f40b.svg"); }
//
// .twa-whale2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f40b.svg"); }
//
// .twa-snail {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f40c.svg"); }
//
// .twa-snake {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f40d.svg"); }
//
// .twa-horse {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f40e.svg"); }
//
// .twa-racehorse {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f40e.svg"); }
//
// .twa-ram {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f40f.svg"); }
//
// .twa-goat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f410.svg"); }
//
// .twa-sheep {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f411.svg"); }
//
// .twa-monkey {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f412.svg"); }
//
// .twa-rooster {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f413.svg"); }
//
// .twa-chicken {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f414.svg"); }
//
// .twa-dog {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f415.svg"); }
//
// .twa-dog2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f415.svg"); }
//
// .twa-pig {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f416.svg"); }
//
// .twa-pig2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f416.svg"); }
//
// .twa-boar {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f417.svg"); }
//
// .twa-elephant {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f418.svg"); }
//
// .twa-octopus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f419.svg"); }
//
// .twa-spiral-shell {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f41a.svg"); }
//
// .twa-shell {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f41a.svg"); }
//
// .twa-bug {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f41b.svg"); }
//
// .twa-ant {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f41c.svg"); }
//
// .twa-honeybee {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f41d.svg"); }
//
// .twa-lady-beetle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f41e.svg"); }
//
// .twa-beetle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f41e.svg"); }
//
// .twa-fish {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f41f.svg"); }
//
// .twa-tropical-fish {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f420.svg"); }
//
// .twa-blowfish {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f421.svg"); }
//
// .twa-turtle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f422.svg"); }
//
// .twa-hatching-chick {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f423.svg"); }
//
// .twa-baby-chick {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f424.svg"); }
//
// .twa-front-facing-baby-chick {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f425.svg"); }
//
// .twa-hatched-chick {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f425.svg"); }
//
// .twa-bird {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f426.svg"); }
//
// .twa-penguin {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f427.svg"); }
//
// .twa-koala {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f428.svg"); }
//
// .twa-poodle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f429.svg"); }
//
// .twa-camel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f42a.svg"); }
//
// .twa-dromedary-camel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f42a.svg"); }
//
// .twa-two-hump-camel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f42b.svg"); }
//
// .twa-dolphin {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f42c.svg"); }
//
// .twa-mouse-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f42d.svg"); }
//
// .twa-cow-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f42e.svg"); }
//
// .twa-tiger-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f42f.svg"); }
//
// .twa-rabbit-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f430.svg"); }
//
// .twa-cat-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f431.svg"); }
//
// .twa-dragon-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f432.svg"); }
//
// .twa-spouting-whale {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f433.svg"); }
//
// .twa-horse-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f434.svg"); }
//
// .twa-monkey-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f435.svg"); }
//
// .twa-dog-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f436.svg"); }
//
// .twa-pig-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f437.svg"); }
//
// .twa-frog-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f438.svg"); }
//
// .twa-frog {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f438.svg"); }
//
// .twa-hamster-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f439.svg"); }
//
// .twa-hamster {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f439.svg"); }
//
// .twa-wolf-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f43a.svg"); }
//
// .twa-wolf {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f43a.svg"); }
//
// .twa-bear-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f43b.svg"); }
//
// .twa-bear {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f43b.svg"); }
//
// .twa-panda-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f43c.svg"); }
//
// .twa-pig-nose {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f43d.svg"); }
//
// .twa-paw-prints {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f43e.svg"); }
//
// .twa-chipmunk {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f43f.svg"); }
//
.twa-eyes {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f440.svg");
}

// .twa-eye-in-speech-bubble {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f441-200d-1f5e8.svg"); }
//
// .twa-eye {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f441.svg"); }
//
// .twa-ear-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f442-1f3fb.svg"); }
//
// .twa-ear-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f442-1f3fc.svg"); }
//
// .twa-ear-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f442-1f3fd.svg"); }
//
// .twa-ear-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f442-1f3fe.svg"); }
//
// .twa-ear-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f442-1f3ff.svg"); }
//
// .twa-ear {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f442.svg"); }
//
// .twa-nose-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f443-1f3fb.svg"); }
//
// .twa-nose-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f443-1f3fc.svg"); }
//
// .twa-nose-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f443-1f3fd.svg"); }
//
// .twa-nose-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f443-1f3fe.svg"); }
//
// .twa-nose-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f443-1f3ff.svg"); }
//
// .twa-nose {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f443.svg"); }
//
// .twa-mouth {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f444.svg"); }
//
// .twa-lips {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f444.svg"); }
//
// .twa-tongue {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f445.svg"); }
//
// .twa-white-up-pointing-backhand-index-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f446-1f3fb.svg"); }
//
// .twa-white-up-pointing-backhand-index-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f446-1f3fc.svg"); }
//
// .twa-white-up-pointing-backhand-index-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f446-1f3fd.svg"); }
//
// .twa-white-up-pointing-backhand-index-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f446-1f3fe.svg"); }
//
// .twa-white-up-pointing-backhand-index-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f446-1f3ff.svg"); }
//
// .twa-backhand-index-pointing-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f446.svg"); }
//
// .twa-point-up-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f446.svg"); }
//
// .twa-white-down-pointing-backhand-index-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f447-1f3fb.svg"); }
//
// .twa-white-down-pointing-backhand-index-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f447-1f3fc.svg"); }
//
// .twa-white-down-pointing-backhand-index-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f447-1f3fd.svg"); }
//
// .twa-white-down-pointing-backhand-index-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f447-1f3fe.svg"); }
//
// .twa-white-down-pointing-backhand-index-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f447-1f3ff.svg"); }
//
// .twa-backhand-index-pointing-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f447.svg"); }
//
// .twa-point-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f447.svg"); }
//
// .twa-white-left-pointing-backhand-index-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f448-1f3fb.svg"); }
//
// .twa-white-left-pointing-backhand-index-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f448-1f3fc.svg"); }
//
// .twa-white-left-pointing-backhand-index-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f448-1f3fd.svg"); }
//
// .twa-white-left-pointing-backhand-index-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f448-1f3fe.svg"); }
//
// .twa-white-left-pointing-backhand-index-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f448-1f3ff.svg"); }
//
// .twa-backhand-index-pointing-left {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f448.svg"); }
//
// .twa-point-left {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f448.svg"); }
//
// .twa-white-right-pointing-backhand-index-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f449-1f3fb.svg"); }
//
// .twa-white-right-pointing-backhand-index-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f449-1f3fc.svg"); }
//
// .twa-white-right-pointing-backhand-index-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f449-1f3fd.svg"); }
//
// .twa-white-right-pointing-backhand-index-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f449-1f3fe.svg"); }
//
// .twa-white-right-pointing-backhand-index-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f449-1f3ff.svg"); }
//
// .twa-backhand-index-pointing-right {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f449.svg"); }
//
// .twa-point-right {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f449.svg"); }
//
// .twa-fisted-hand-sign-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44a-1f3fb.svg"); }
//
// .twa-fisted-hand-sign-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44a-1f3fc.svg"); }
//
// .twa-fisted-hand-sign-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44a-1f3fd.svg"); }
//
// .twa-fisted-hand-sign-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44a-1f3fe.svg"); }
//
// .twa-fisted-hand-sign-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44a-1f3ff.svg"); }
//
// .twa-oncoming-fist {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44a.svg"); }
//
.twa-punch {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f44a.svg");
}
//
// .twa-waving-hand-sign-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44b-1f3fb.svg"); }
//
// .twa-waving-hand-sign-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44b-1f3fc.svg"); }
//
// .twa-waving-hand-sign-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44b-1f3fd.svg"); }
//
// .twa-waving-hand-sign-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44b-1f3fe.svg"); }
//
// .twa-waving-hand-sign-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44b-1f3ff.svg"); }
//
// .twa-waving-hand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44b.svg"); }
//
// .twa-wave {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44b.svg"); }
//
// .twa-ok-hand-sign-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44c-1f3fb.svg"); }
//
// .twa-ok-hand-sign-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44c-1f3fc.svg"); }
//
// .twa-ok-hand-sign-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44c-1f3fd.svg"); }
//
// .twa-ok-hand-sign-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44c-1f3fe.svg"); }
//
// .twa-ok-hand-sign-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44c-1f3ff.svg"); }
//
.twa-ok-hand {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f44c.svg");
}
//
// .twa-thumbs-up-sign-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44d-1f3fb.svg"); }
//
// .twa-thumbs-up-sign-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44d-1f3fc.svg"); }
//
// .twa-thumbs-up-sign-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44d-1f3fd.svg"); }
//
// .twa-thumbs-up-sign-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44d-1f3fe.svg"); }
//
// .twa-thumbs-up-sign-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44d-1f3ff.svg"); }
//
// .twa-thumbs-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44d.svg"); }
//
.twa-thumbsup {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f44d.svg");
}
//
// .twa-thumbs-down-sign-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44e-1f3fb.svg"); }
//
// .twa-thumbs-down-sign-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44e-1f3fc.svg"); }
//
// .twa-thumbs-down-sign-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44e-1f3fd.svg"); }
//
// .twa-thumbs-down-sign-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44e-1f3fe.svg"); }
//
// .twa-thumbs-down-sign-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44e-1f3ff.svg"); }
//
// .twa-thumbs-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44e.svg"); }
//
.twa-thumbsdown {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f44e.svg");
}
//
// .twa-clapping-hands-sign-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44f-1f3fb.svg"); }
//
// .twa-clapping-hands-sign-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44f-1f3fc.svg"); }
//
// .twa-clapping-hands-sign-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44f-1f3fd.svg"); }
//
// .twa-clapping-hands-sign-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44f-1f3fe.svg"); }
//
// .twa-clapping-hands-sign-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44f-1f3ff.svg"); }
//
// .twa-clapping-hands {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f44f.svg"); }
//
.twa-clap {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f44f.svg");
}
//
// .twa-open-hands-sign-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f450-1f3fb.svg"); }
//
// .twa-open-hands-sign-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f450-1f3fc.svg"); }
//
// .twa-open-hands-sign-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f450-1f3fd.svg"); }
//
// .twa-open-hands-sign-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f450-1f3fe.svg"); }
//
// .twa-open-hands-sign-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f450-1f3ff.svg"); }
//
// .twa-open-hands {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f450.svg"); }
//
// .twa-crown {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f451.svg"); }
//
// .twa-womans-hat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f452.svg"); }
//
// .twa-glasses {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f453.svg"); }
//
// .twa-eyeglasses {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f453.svg"); }
//
// .twa-necktie {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f454.svg"); }
//
// .twa-t-shirt {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f455.svg"); }
//
// .twa-shirt {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f455.svg"); }
//
// .twa-jeans {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f456.svg"); }
//
// .twa-dress {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f457.svg"); }
//
// .twa-kimono {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f458.svg"); }
//
// .twa-bikini {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f459.svg"); }
//
// .twa-womans-clothes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f45a.svg"); }
//
// .twa-purse {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f45b.svg"); }
//
// .twa-handbag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f45c.svg"); }
//
// .twa-pouch {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f45d.svg"); }
//
// .twa-mans-shoe {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f45e.svg"); }
//
// .twa-running-shoe {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f45f.svg"); }
//
// .twa-high-heeled-shoe {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f460.svg"); }
//
// .twa-high-heel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f460.svg"); }
//
// .twa-womans-sandal {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f461.svg"); }
//
// .twa-sandal {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f461.svg"); }
//
// .twa-womans-boot {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f462.svg"); }
//
// .twa-boot {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f462.svg"); }
//
// .twa-footprints {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f463.svg"); }
//
// .twa-feet {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f463.svg"); }
//
// .twa-bust-in-silhouette {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f464.svg"); }
//
// .twa-busts-in-silhouette {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f465.svg"); }
//
// .twa-boy-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f466-1f3fb.svg"); }
//
// .twa-boy-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f466-1f3fc.svg"); }
//
// .twa-boy-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f466-1f3fd.svg"); }
//
// .twa-boy-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f466-1f3fe.svg"); }
//
// .twa-boy-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f466-1f3ff.svg"); }
//
// .twa-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f466.svg"); }
//
// .twa-girl-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f467-1f3fb.svg"); }
//
// .twa-girl-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f467-1f3fc.svg"); }
//
// .twa-girl-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f467-1f3fd.svg"); }
//
// .twa-girl-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f467-1f3fe.svg"); }
//
// .twa-girl-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f467-1f3ff.svg"); }
//
// .twa-girl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f467.svg"); }
//
// .twa-man-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-1f3fb.svg"); }
//
// .twa-man-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-1f3fc.svg"); }
//
// .twa-man-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-1f3fd.svg"); }
//
// .twa-man-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-1f3fe.svg"); }
//
// .twa-man-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-1f3ff.svg"); }
//
// .twa-family-man-man-boy-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f466-200d-1f466.svg"); }
//
// .twa-family-man-man-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f466.svg"); }
//
// .twa-family-man-man-girl-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f467-200d-1f466.svg"); }
//
// .twa-family-man-man-girl-girl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f467-200d-1f467.svg"); }
//
// .twa-family-man-man-girl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f467.svg"); }
//
// .twa-family-man-woman-boy-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f466-200d-1f466.svg"); }
//
// .twa-family-man-woman-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f466.svg"); }
//
// .twa-family-man-woman-girl-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f467-200d-1f466.svg"); }
//
// .twa-family-man-woman-girl-girl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f467-200d-1f467.svg"); }
//
// .twa-family-man-woman-girl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f467.svg"); }
//
// .twa-couple-with-heart-man-man {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-2764-fe0f-200d-1f468.svg"); }
//
// .twa-kiss-man-man {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.svg"); }
//
// .twa-man {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f468.svg"); }
//
// .twa-woman-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-1f3fb.svg"); }
//
// .twa-woman-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-1f3fc.svg"); }
//
// .twa-woman-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-1f3fd.svg"); }
//
// .twa-woman-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-1f3fe.svg"); }
//
// .twa-woman-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-1f3ff.svg"); }
//
// .twa-family-woman-woman-boy-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f466-200d-1f466.svg"); }
//
// .twa-family-woman-woman-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f466.svg"); }
//
// .twa-family-woman-woman-girl-boy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f467-200d-1f466.svg"); }
//
// .twa-family-woman-woman-girl-girl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f467-200d-1f467.svg"); }
//
// .twa-family-woman-woman-girl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f467.svg"); }
//
// .twa-couple-with-heart-woman-man {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2764-fe0f-200d-1f468.svg"); }
//
// .twa-couple-with-heart-woman-woman {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2764-fe0f-200d-1f469.svg"); }
//
// .twa-kiss-woman-man {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.svg"); }
//
// .twa-kiss-woman-woman {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.svg"); }
//
// .twa-woman {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f469.svg"); }
//
// .twa-family {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46a.svg"); }
//
// .twa-man-and-woman-holding-hands {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46b.svg"); }
//
// .twa-couple {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46b.svg"); }
//
// .twa-two-men-holding-hands {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46c.svg"); }
//
// .twa-two-women-holding-hands {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46d.svg"); }
//
// .twa-police-officer-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e-1f3fb.svg"); }
//
// .twa-police-officer-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e-1f3fc.svg"); }
//
// .twa-police-officer-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e-1f3fd.svg"); }
//
// .twa-police-officer-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e-1f3fe.svg"); }
//
// .twa-police-officer-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e-1f3ff.svg"); }
//
// .twa-police-officer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e.svg"); }
//
// .twa-cop {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e.svg"); }
//
// .twa-women-partying {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46f.svg"); }
//
// .twa-dancers {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f46f.svg"); }
//
// .twa-bride-with-veil-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f470-1f3fb.svg"); }
//
// .twa-bride-with-veil-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f470-1f3fc.svg"); }
//
// .twa-bride-with-veil-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f470-1f3fd.svg"); }
//
// .twa-bride-with-veil-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f470-1f3fe.svg"); }
//
// .twa-bride-with-veil-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f470-1f3ff.svg"); }
//
// .twa-bride-with-veil {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f470.svg"); }
//
// .twa-person-with-blond-hair-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f471-1f3fb.svg"); }
//
// .twa-person-with-blond-hair-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f471-1f3fc.svg"); }
//
// .twa-person-with-blond-hair-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f471-1f3fd.svg"); }
//
// .twa-person-with-blond-hair-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f471-1f3fe.svg"); }
//
// .twa-person-with-blond-hair-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f471-1f3ff.svg"); }
//
// .twa-person-with-blond-hair {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f471.svg"); }
//
// .twa-man-with-gua-pi-mao-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f472-1f3fb.svg"); }
//
// .twa-man-with-gua-pi-mao-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f472-1f3fc.svg"); }
//
// .twa-man-with-gua-pi-mao-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f472-1f3fd.svg"); }
//
// .twa-man-with-gua-pi-mao-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f472-1f3fe.svg"); }
//
// .twa-man-with-gua-pi-mao-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f472-1f3ff.svg"); }
//
// .twa-man-with-chinese-cap {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f472.svg"); }
//
// .twa-man-with-gua-pi-mao {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f472.svg"); }
//
// .twa-man-with-turban-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f473-1f3fb.svg"); }
//
// .twa-man-with-turban-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f473-1f3fc.svg"); }
//
// .twa-man-with-turban-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f473-1f3fd.svg"); }
//
// .twa-man-with-turban-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f473-1f3fe.svg"); }
//
// .twa-man-with-turban-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f473-1f3ff.svg"); }
//
// .twa-man-with-turban {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f473.svg"); }
//
// .twa-older-man-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f474-1f3fb.svg"); }
//
// .twa-older-man-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f474-1f3fc.svg"); }
//
// .twa-older-man-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f474-1f3fd.svg"); }
//
// .twa-older-man-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f474-1f3fe.svg"); }
//
// .twa-older-man-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f474-1f3ff.svg"); }
//
// .twa-old-man {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f474.svg"); }
//
// .twa-older-man {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f474.svg"); }
//
// .twa-older-woman-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f475-1f3fb.svg"); }
//
// .twa-older-woman-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f475-1f3fc.svg"); }
//
// .twa-older-woman-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f475-1f3fd.svg"); }
//
// .twa-older-woman-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f475-1f3fe.svg"); }
//
// .twa-older-woman-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f475-1f3ff.svg"); }
//
// .twa-old-woman {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f475.svg"); }
//
// .twa-older-woman {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f475.svg"); }
//
// .twa-baby-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f476-1f3fb.svg"); }
//
// .twa-baby-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f476-1f3fc.svg"); }
//
// .twa-baby-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f476-1f3fd.svg"); }
//
// .twa-baby-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f476-1f3fe.svg"); }
//
// .twa-baby-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f476-1f3ff.svg"); }
//
// .twa-baby {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f476.svg"); }
//
// .twa-construction-worker-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f477-1f3fb.svg"); }
//
// .twa-construction-worker-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f477-1f3fc.svg"); }
//
// .twa-construction-worker-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f477-1f3fd.svg"); }
//
// .twa-construction-worker-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f477-1f3fe.svg"); }
//
// .twa-construction-worker-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f477-1f3ff.svg"); }
//
// .twa-construction-worker {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f477.svg"); }
//
// .twa-princess-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f478-1f3fb.svg"); }
//
// .twa-princess-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f478-1f3fc.svg"); }
//
// .twa-princess-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f478-1f3fd.svg"); }
//
// .twa-princess-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f478-1f3fe.svg"); }
//
// .twa-princess-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f478-1f3ff.svg"); }
//
// .twa-princess {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f478.svg"); }
//
// .twa-ogre {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f479.svg"); }
//
// .twa-japanese-ogre {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f479.svg"); }
//
// .twa-goblin {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47a.svg"); }
//
// .twa-japanese-goblin {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47a.svg"); }
//
.twa-ghost {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f47b.svg");
}
// background-image: url("https://twemoji.maxcdn.com/2/svg/1f47b.svg"); }
//
// .twa-baby-angel-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47c-1f3fb.svg"); }
//
// .twa-baby-angel-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47c-1f3fc.svg"); }
//
// .twa-baby-angel-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47c-1f3fd.svg"); }
//
// .twa-baby-angel-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47c-1f3fe.svg"); }
//
// .twa-baby-angel-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47c-1f3ff.svg"); }
//
// .twa-baby-angel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47c.svg"); }
//
// .twa-angel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47c.svg"); }
//
// .twa-alien {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47d.svg"); }
//
// .twa-alien-monster {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47e.svg"); }
//
// .twa-space-invader {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47e.svg"); }
//
// .twa-imp {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f47f.svg"); }
//
// .twa-skull {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f480.svg"); }
//
// .twa-information-desk-person-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f481-1f3fb.svg"); }
//
// .twa-information-desk-person-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f481-1f3fc.svg"); }
//
// .twa-information-desk-person-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f481-1f3fd.svg"); }
//
// .twa-information-desk-person-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f481-1f3fe.svg"); }
//
// .twa-information-desk-person-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f481-1f3ff.svg"); }
//
// .twa-information-desk-person {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f481.svg"); }
//
// .twa-guardsman-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f482-1f3fb.svg"); }
//
// .twa-guardsman-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f482-1f3fc.svg"); }
//
// .twa-guardsman-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f482-1f3fd.svg"); }
//
// .twa-guardsman-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f482-1f3fe.svg"); }
//
// .twa-guardsman-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f482-1f3ff.svg"); }
//
// .twa-guardsman {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f482.svg"); }
//
// .twa-dancer-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f483-1f3fb.svg"); }
//
// .twa-dancer-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f483-1f3fc.svg"); }
//
// .twa-dancer-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f483-1f3fd.svg"); }
//
// .twa-dancer-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f483-1f3fe.svg"); }
//
// .twa-dancer-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f483-1f3ff.svg"); }
//
.twa-dancer {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f483.svg");
}
//
// .twa-lipstick {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f484.svg"); }
//
// .twa-nail-polish-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f485-1f3fb.svg"); }
//
// .twa-nail-polish-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f485-1f3fc.svg"); }
//
// .twa-nail-polish-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f485-1f3fd.svg"); }
//
// .twa-nail-polish-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f485-1f3fe.svg"); }
//
// .twa-nail-polish-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f485-1f3ff.svg"); }
//
// .twa-nail-polish {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f485.svg"); }
//
// .twa-nail-care {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f485.svg"); }
//
// .twa-face-massage-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f486-1f3fb.svg"); }
//
// .twa-face-massage-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f486-1f3fc.svg"); }
//
// .twa-face-massage-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f486-1f3fd.svg"); }
//
// .twa-face-massage-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f486-1f3fe.svg"); }
//
// .twa-face-massage-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f486-1f3ff.svg"); }
//
// .twa-face-massage {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f486.svg"); }
//
// .twa-massage {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f486.svg"); }
//
// .twa-haircut-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f487-1f3fb.svg"); }
//
// .twa-haircut-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f487-1f3fc.svg"); }
//
// .twa-haircut-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f487-1f3fd.svg"); }
//
// .twa-haircut-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f487-1f3fe.svg"); }
//
// .twa-haircut-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f487-1f3ff.svg"); }
//
// .twa-haircut {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f487.svg"); }
//
// .twa-barber-pole {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f488.svg"); }
//
// .twa-barber {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f488.svg"); }
//
// .twa-syringe {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f489.svg"); }
//
// .twa-pill {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f48a.svg"); }
//
// .twa-kiss-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f48b.svg"); }
//
// .twa-love-letter {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f48c.svg"); }
//
// .twa-ring {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f48d.svg"); }
//
// .twa-gem-stone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f48e.svg"); }
//
// .twa-gem {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f48e.svg"); }
//
// .twa-kiss {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f48f.svg"); }
//
// .twa-couplekiss {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f48f.svg"); }
//
// .twa-bouquet {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f490.svg"); }
//
// .twa-couple-with-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f491.svg"); }
//
// .twa-wedding {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f492.svg"); }
//
// .twa-beating-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f493.svg"); }
//
// .twa-heartbeat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f493.svg"); }
//
// .twa-broken-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f494.svg"); }
//
// .twa-two-hearts {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f495.svg"); }
//
// .twa-sparkling-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f496.svg"); }
//
// .twa-growing-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f497.svg"); }
//
// .twa-heartpulse {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f497.svg"); }
//
// .twa-heart-with-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f498.svg"); }
//
// .twa-cupid {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f498.svg"); }
//
// .twa-blue-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f499.svg"); }
//
// .twa-green-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f49a.svg"); }
//
// .twa-yellow-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f49b.svg"); }
//
// .twa-purple-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f49c.svg"); }
//
// .twa-heart-with-ribbon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f49d.svg"); }
//
// .twa-gift-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f49d.svg"); }
//
// .twa-revolving-hearts {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f49e.svg"); }
//
// .twa-heart-decoration {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f49f.svg"); }
//
// .twa-diamond-with-a-dot {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a0.svg"); }
//
// .twa-diamond-shape-with-a-dot-inside {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a0.svg"); }
//
// .twa-light-bulb {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a1.svg"); }
//
.twa-bulb {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f4a1.svg");
}
//
// .twa-anger-symbol {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a2.svg"); }
//
// .twa-anger {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a2.svg"); }
//
// .twa-bomb {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a3.svg"); }
//
// .twa-zzz {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a4.svg"); }
//
.twa-collision {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f4a5.svg");
}
//
// .twa-boom {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a5.svg"); }
//
// .twa-sweat-droplets {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a6.svg"); }
//
// .twa-sweat-drops {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a6.svg"); }
//
// .twa-droplet {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a7.svg"); }
//
// .twa-dashing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a8.svg"); }
//
// .twa-dash {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a8.svg"); }
//
// .twa-pile-of-poo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a9.svg"); }
//
.twa-poop {
  // background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a9.svg"); }
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f4a9.svg");
}
//
// .twa-flexed-biceps-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4aa-1f3fb.svg"); }
//
// .twa-flexed-biceps-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4aa-1f3fc.svg"); }
//
// .twa-flexed-biceps-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4aa-1f3fd.svg"); }
//
// .twa-flexed-biceps-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4aa-1f3fe.svg"); }
//
// .twa-flexed-biceps-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4aa-1f3ff.svg"); }
//
// .twa-flexed-biceps {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4aa.svg"); }
//
.twa-muscle {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f4aa.svg");
}
//
// .twa-dizzy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ab.svg"); }
//
.twa-speech-balloon {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f4ac.svg");
}
//
// .twa-thought-balloon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ad.svg"); }
//
// .twa-white-flower {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ae.svg"); }
//
// .twa-hundred-points {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4af.svg"); }
//
.twa-100 {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f4af.svg");
}
//
// .twa-money-bag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b0.svg"); }
//
// .twa-moneybag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b0.svg"); }
//
// .twa-currency-exchange {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b1.svg"); }
//
// .twa-heavy-dollar-sign {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b2.svg"); }
//
// .twa-credit-card {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b3.svg"); }
//
// .twa-yen-banknote {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b4.svg"); }
//
// .twa-yen {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b4.svg"); }
//
// .twa-dollar-banknote {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b5.svg"); }
//
// .twa-dollar {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b5.svg"); }
//
// .twa-euro-banknote {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b6.svg"); }
//
// .twa-euro {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b6.svg"); }
//
// .twa-pound-banknote {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b7.svg"); }
//
// .twa-pound {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b7.svg"); }
//
// .twa-money-with-wings {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b8.svg"); }
//
// .twa-chart-increasing-with-yen {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b9.svg"); }
//
// .twa-chart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b9.svg"); }
//
// .twa-seat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ba.svg"); }
//
// .twa-laptop-computer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bb.svg"); }
//
// .twa-computer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bb.svg"); }
//
// .twa-briefcase {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bc.svg"); }
//
// .twa-minidisc {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bd.svg"); }
//
// .twa-floppy-disk {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4be.svg"); }
//
// .twa-optical-disc {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bf.svg"); }
//
// .twa-cd {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bf.svg"); }
//
// .twa-dvd {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c0.svg"); }
//
// .twa-file-folder {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c1.svg"); }
//
// .twa-open-file-folder {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c2.svg"); }
//
// .twa-page-with-curl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c3.svg"); }
//
// .twa-page-facing-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c4.svg"); }
//
// .twa-calendar {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c5.svg"); }
//
// .twa-date {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c5.svg"); }
//
// .twa-tear-off-calendar {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c6.svg"); }
//
// .twa-card-index {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c7.svg"); }
//
// .twa-chart-increasing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c8.svg"); }
//
// .twa-chart-with-upwards-trend {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c8.svg"); }
//
// .twa-chart-decreasing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c9.svg"); }
//
// .twa-chart-with-downwards-trend {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c9.svg"); }
//
// .twa-bar-chart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ca.svg"); }
//
// .twa-clipboard {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4cb.svg"); }
//
// .twa-pushpin {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4cc.svg"); }
//
// .twa-round-pushpin {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4cd.svg"); }
//
// .twa-paperclip {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ce.svg"); }
//
// .twa-straight-ruler {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4cf.svg"); }
//
// .twa-triangular-ruler {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d0.svg"); }
//
// .twa-bookmark-tabs {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d1.svg"); }
//
// .twa-ledger {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d2.svg"); }
//
// .twa-notebook {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d3.svg"); }
//
// .twa-notebook-with-decorative-cover {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d4.svg"); }
//
// .twa-closed-book {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d5.svg"); }
//
// .twa-open-book {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d6.svg"); }
//
// .twa-green-book {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d7.svg"); }
//
// .twa-blue-book {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d8.svg"); }
//
// .twa-orange-book {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d9.svg"); }
//
// .twa-books {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4da.svg"); }
//
// .twa-name-badge {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4db.svg"); }
//
// .twa-scroll {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4dc.svg"); }
//
// .twa-memo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4dd.svg"); }
//
// .twa-telephone-receiver {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4de.svg"); }
//
// .twa-pager {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4df.svg"); }
//
// .twa-fax-machine {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e0.svg"); }
//
// .twa-fax {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e0.svg"); }
//
// .twa-satellite-antenna {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e1.svg"); }
//
// .twa-loudspeaker {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e2.svg"); }
//
// .twa-megaphone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e3.svg"); }
//
// .twa-mega {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e3.svg"); }
//
// .twa-outbox-tray {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e4.svg"); }
//
// .twa-inbox-tray {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e5.svg"); }
//
// .twa-package {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e6.svg"); }
//
// .twa-e-mail {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e7.svg"); }
//
// .twa-incoming-envelope {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e8.svg"); }
//
// .twa-envelope-with-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e9.svg"); }
//
// .twa-closed-mailbox-with-lowered-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ea.svg"); }
//
// .twa-mailbox-closed {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ea.svg"); }
//
// .twa-closed-mailbox-with-raised-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4eb.svg"); }
//
// .twa-mailbox {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4eb.svg"); }
//
// .twa-open-mailbox-with-raised-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ec.svg"); }
//
// .twa-mailbox-with-mail {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ec.svg"); }
//
// .twa-open-mailbox-with-lowered-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ed.svg"); }
//
// .twa-mailbox-with-no-mail {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ed.svg"); }
//
// .twa-postbox {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ee.svg"); }
//
// .twa-postal-horn {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ef.svg"); }
//
// .twa-newspaper {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f0.svg"); }
//
// .twa-mobile-phone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f1.svg"); }
//
// .twa-iphone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f1.svg"); }
//
// .twa-mobile-phone-with-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f2.svg"); }
//
// .twa-calling {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f2.svg"); }
//
// .twa-vibration-mode {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f3.svg"); }
//
// .twa-mobile-phone-off {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f4.svg"); }
//
// .twa-no-mobile-phones {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f5.svg"); }
//
// .twa-antenna-bars {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f6.svg"); }
//
// .twa-signal-strength {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f6.svg"); }
//
// .twa-camera {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f7.svg"); }
//
// .twa-camera-with-flash {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f8.svg"); }
//
// .twa-video-camera {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f9.svg"); }
//
// .twa-television {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fa.svg"); }
//
// .twa-tv {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fa.svg"); }
//
// .twa-radio {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fb.svg"); }
//
// .twa-videocassette {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fc.svg"); }
//
// .twa-vhs {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fc.svg"); }
//
// .twa-film-projector {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fd.svg"); }
//
// .twa-prayer-beads {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ff.svg"); }
//
// .twa-shuffle-tracks-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f500.svg"); }
//
// .twa-twisted-rightwards-arrows {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f500.svg"); }
//
// .twa-repeat-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f501.svg"); }
//
// .twa-repeat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f501.svg"); }
//
// .twa-repeat-single-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f502.svg"); }
//
// .twa-repeat-one {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f502.svg"); }
//
// .twa-clockwise-vertical-arrows {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f503.svg"); }
//
// .twa-arrows-clockwise {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f503.svg"); }
//
// .twa-anticlockwise-arrows-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f504.svg"); }
//
// .twa-arrows-counterclockwise {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f504.svg"); }
//
// .twa-dim-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f505.svg"); }
//
// .twa-low-brightness {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f505.svg"); }
//
// .twa-bright-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f506.svg"); }
//
// .twa-high-brightness {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f506.svg"); }
//
// .twa-speaker-off {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f507.svg"); }
//
// .twa-mute {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f507.svg"); }
//
// .twa-speaker {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f508.svg"); }
//
// .twa-speaker-on {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f509.svg"); }
//
// .twa-sound {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f509.svg"); }
//
// .twa-speaker-loud {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f50a.svg"); }
//
// .twa-battery {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f50b.svg"); }
//
// .twa-electric-plug {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f50c.svg"); }
//
// .twa-left-pointing-magnifying-glass {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f50d.svg"); }
//
// .twa-mag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f50d.svg"); }
//
// .twa-right-pointing-magnifying-glass {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f50e.svg"); }
//
// .twa-mag-right {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f50e.svg"); }
//
// .twa-lock-with-pen {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f50f.svg"); }
//
// .twa-lock-with-ink-pen {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f50f.svg"); }
//
// .twa-closed-lock-with-key {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f510.svg"); }
//
// .twa-key {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f511.svg"); }
//
// .twa-lock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f512.svg"); }
//
// .twa-open-lock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f513.svg"); }
//
// .twa-unlock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f513.svg"); }
//
// .twa-bell {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f514.svg"); }
//
// .twa-bell-with-slash {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f515.svg"); }
//
// .twa-no-bell {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f515.svg"); }
//
// .twa-bookmark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f516.svg"); }
//
// .twa-link {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f517.svg"); }
//
// .twa-radio-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f518.svg"); }
//
// .twa-back-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f519.svg"); }
//
// .twa-end-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51a.svg"); }
//
// .twa-end {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51a.svg"); }
//
// .twa-onexc-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51b.svg"); }
//
// .twa-on {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51b.svg"); }
//
// .twa-soon-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51c.svg"); }
//
// .twa-soon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51c.svg"); }
//
// .twa-top-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51d.svg"); }
//
// .twa-top {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51d.svg"); }
//
// .twa-no-one-under-eighteen {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51e.svg"); }
//
// .twa-underage {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51e.svg"); }
//
// .twa-keycap-ten {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f51f.svg"); }
//
// .twa-input-latin-uppercase {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f520.svg"); }
//
// .twa-capital-abcd {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f520.svg"); }
//
// .twa-input-latin-lowercase {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f521.svg"); }
//
// .twa-abcd {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f521.svg"); }
//
// .twa-input-numbers {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f522.svg"); }
//
// .twa-1234 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f522.svg"); }
//
// .twa-input-symbols {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f523.svg"); }
//
// .twa-symbols {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f523.svg"); }
//
// .twa-input-latin-letters {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f524.svg"); }
//
// .twa-abc {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f524.svg"); }
//
.twa-fire {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f525.svg");
}
//
// .twa-flashlight {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f526.svg"); }
//
// .twa-wrench {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f527.svg"); }
//
// .twa-hammer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f528.svg"); }
//
// .twa-nut-and-bolt {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f529.svg"); }
//
// .twa-kitchen-knife {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f52a.svg"); }
//
// .twa-hocho {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f52a.svg"); }
//
// .twa-pistol {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f52b.svg"); }
//
// .twa-gun {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f52b.svg"); }
//
// .twa-microscope {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f52c.svg"); }
//
// .twa-telescope {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f52d.svg"); }
//
// .twa-crystal-ball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f52e.svg"); }
//
// .twa-dotted-six-pointed-star {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f52f.svg"); }
//
// .twa-six-pointed-star {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f52f.svg"); }
//
// .twa-japanese-symbol-for-beginner {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f530.svg"); }
//
// .twa-beginner {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f530.svg"); }
//
// .twa-trident-emblem {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f531.svg"); }
//
// .twa-trident {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f531.svg"); }
//
// .twa-black-square-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f532.svg"); }
//
// .twa-white-square-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f533.svg"); }
//
.twa-red-circle {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f534.svg");
}
//
// .twa-blue-circle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f535.svg"); }
//
// .twa-large-blue-circle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f535.svg"); }
//
.twa-large-orange-diamond {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f536.svg");
}
//
// .twa-large-blue-diamond {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f537.svg"); }
//
// .twa-small-orange-diamond {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f538.svg"); }
//
// .twa-small-blue-diamond {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f539.svg"); }
//
// .twa-red-triangle-pointed-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f53a.svg"); }
//
// .twa-small-red-triangle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f53a.svg"); }
//
// .twa-red-triangle-pointed-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f53b.svg"); }
//
// .twa-small-red-triangle-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f53b.svg"); }
//
// .twa-up-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f53c.svg"); }
//
// .twa-arrow-up-small {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f53c.svg"); }
//
// .twa-down-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f53d.svg"); }
//
// .twa-arrow-down-small {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f53d.svg"); }
//
// .twa-om {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f549.svg"); }
//
// .twa-dove {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f54a.svg"); }
//
// .twa-kaaba {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f54b.svg"); }
//
// .twa-mosque {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f54c.svg"); }
//
// .twa-synagogue {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f54d.svg"); }
//
// .twa-menorah {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f54e.svg"); }
//
.twa-one-oclock {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f550.svg");
}
//
// .twa-clock1 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f550.svg"); }
//
// .twa-two-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f551.svg"); }
//
// .twa-clock2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f551.svg"); }
//
// .twa-three-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f552.svg"); }
//
// .twa-clock3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f552.svg"); }
//
// .twa-four-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f553.svg"); }
//
// .twa-clock4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f553.svg"); }
//
// .twa-five-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f554.svg"); }
//
// .twa-clock5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f554.svg"); }
//
// .twa-six-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f555.svg"); }
//
// .twa-clock6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f555.svg"); }
//
// .twa-seven-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f556.svg"); }
//
// .twa-clock7 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f556.svg"); }
//
// .twa-eight-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f557.svg"); }
//
// .twa-clock8 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f557.svg"); }
//
// .twa-nine-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f558.svg"); }
//
// .twa-clock9 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f558.svg"); }
//
// .twa-ten-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f559.svg"); }
//
// .twa-clock10 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f559.svg"); }
//
// .twa-eleven-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55a.svg"); }
//
// .twa-clock11 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55a.svg"); }
//
// .twa-twelve-oclock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55b.svg"); }
//
// .twa-clock12 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55b.svg"); }
//
// .twa-one-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55c.svg"); }
//
// .twa-clock130 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55c.svg"); }
//
// .twa-two-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55d.svg"); }
//
// .twa-clock230 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55d.svg"); }
//
// .twa-three-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55e.svg"); }
//
// .twa-clock330 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55e.svg"); }
//
// .twa-four-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55f.svg"); }
//
// .twa-clock430 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f55f.svg"); }
//
// .twa-five-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f560.svg"); }
//
// .twa-clock530 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f560.svg"); }
//
// .twa-six-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f561.svg"); }
//
// .twa-clock630 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f561.svg"); }
//
// .twa-seven-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f562.svg"); }
//
// .twa-clock730 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f562.svg"); }
//
// .twa-eight-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f563.svg"); }
//
// .twa-clock830 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f563.svg"); }
//
// .twa-nine-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f564.svg"); }
//
// .twa-clock930 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f564.svg"); }
//
// .twa-ten-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f565.svg"); }
//
// .twa-clock1030 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f565.svg"); }
//
// .twa-eleven-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f566.svg"); }
//
// .twa-clock1130 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f566.svg"); }
//
// .twa-twelve-thirty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f567.svg"); }
//
// .twa-clock1230 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f567.svg"); }
//
// .twa-candle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f56f.svg"); }
//
// .twa-mantelpiece-clock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f570.svg"); }
//
// .twa-hole {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f573.svg"); }
//
// .twa-man-in-business-suit-levitating {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f574.svg"); }
//
// .twa-sleuth-or-spy-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f575-1f3fb.svg"); }
//
// .twa-sleuth-or-spy-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f575-1f3fc.svg"); }
//
// .twa-sleuth-or-spy-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f575-1f3fd.svg"); }
//
// .twa-sleuth-or-spy-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f575-1f3fe.svg"); }
//
// .twa-sleuth-or-spy-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f575-1f3ff.svg"); }
//
// .twa-detective {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f575.svg"); }
//
// .twa-sunglasses {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f576.svg"); }
//
// .twa-spider {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f577.svg"); }
//
// .twa-spider-web {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f578.svg"); }
//
// .twa-joystick {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f579.svg"); }
//
// .twa-man-dancing-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f57a-1f3fb.svg"); }
//
// .twa-man-dancing-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f57a-1f3fc.svg"); }
//
// .twa-man-dancing-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f57a-1f3fd.svg"); }
//
// .twa-man-dancing-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f57a-1f3fe.svg"); }
//
// .twa-man-dancing-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f57a-1f3ff.svg"); }
//
// .twa-man-dancing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f57a.svg"); }
//
// .twa-linked-paperclips {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f587.svg"); }
//
// .twa-pen {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f58a.svg"); }
//
// .twa-fountain-pen {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f58b.svg"); }
//
// .twa-paintbrush {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f58c.svg"); }
//
// .twa-crayon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f58d.svg"); }
//
// .twa-raised-hand-with-fingers-splayed-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f590-1f3fb.svg"); }
//
// .twa-raised-hand-with-fingers-splayed-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f590-1f3fc.svg"); }
//
// .twa-raised-hand-with-fingers-splayed-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f590-1f3fd.svg"); }
//
// .twa-raised-hand-with-fingers-splayed-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f590-1f3fe.svg"); }
//
// .twa-raised-hand-with-fingers-splayed-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f590-1f3ff.svg"); }
//
// .twa-raised-hand-with-fingers-splayed {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f590.svg"); }
//
// .twa-reversed-hand-with-middle-finger-extended-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f595-1f3fb.svg"); }
//
// .twa-reversed-hand-with-middle-finger-extended-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f595-1f3fc.svg"); }
//
// .twa-reversed-hand-with-middle-finger-extended-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f595-1f3fd.svg"); }
//
// .twa-reversed-hand-with-middle-finger-extended-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f595-1f3fe.svg"); }
//
// .twa-reversed-hand-with-middle-finger-extended-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f595-1f3ff.svg"); }
//
// .twa-middle-finger {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f595.svg"); }
//
// .twa-raised-hand-with-part-between-middle-and-ring-fingers-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f596-1f3fb.svg"); }
//
// .twa-raised-hand-with-part-between-middle-and-ring-fingers-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f596-1f3fc.svg"); }
//
// .twa-raised-hand-with-part-between-middle-and-ring-fingers-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f596-1f3fd.svg"); }
//
// .twa-raised-hand-with-part-between-middle-and-ring-fingers-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f596-1f3fe.svg"); }
//
// .twa-raised-hand-with-part-between-middle-and-ring-fingers-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f596-1f3ff.svg"); }
//
// .twa-vulcan-salute {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f596.svg"); }
//
// .twa-black-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5a4.svg"); }
//
// .twa-desktop-computer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5a5.svg"); }
//
// .twa-printer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5a8.svg"); }
//
// .twa-computer-mouse {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5b1.svg"); }
//
// .twa-trackball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5b2.svg"); }
//
// .twa-frame-with-picture {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5bc.svg"); }
//
// .twa-card-index-dividers {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5c2.svg"); }
//
// .twa-card-file-box {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5c3.svg"); }
//
// .twa-file-cabinet {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5c4.svg"); }
//
// .twa-wastebasket {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5d1.svg"); }
//
// .twa-spiral-notepad {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5d2.svg"); }
//
// .twa-spiral-calendar {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5d3.svg"); }
//
// .twa-compression {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5dc.svg"); }
//
// .twa-old-key {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5dd.svg"); }
//
// .twa-rolled-up-newspaper {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5de.svg"); }
//
// .twa-dagger {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5e1.svg"); }
//
// .twa-speaking-head {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5e3.svg"); }
//
// .twa-left-speech-bubble {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5e8.svg"); }
//
// .twa-right-anger-bubble {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5ef.svg"); }
//
// .twa-ballot-box-with-ballot {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5f3.svg"); }
//
// .twa-world-map {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fa.svg"); }
//
// .twa-mount-fuji {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fb.svg"); }
//
// .twa-tokyo-tower {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fc.svg"); }
//
// .twa-statue-of-liberty {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fd.svg"); }
//
// .twa-map-of-japan {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fe.svg"); }
//
// .twa-japan {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fe.svg"); }
//
// .twa-moai {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5ff.svg"); }
//
// .twa-moyai {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f5ff.svg"); }
//
// .twa-grinning-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f600.svg"); }
//
.twa-grinning {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f600.svg");
}
//
// .twa-grinning-face-with-smiling-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f601.svg"); }
//
// .twa-grin {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f601.svg"); }
//
// .twa-face-with-tears-of-joy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f602.svg"); }
//
.twa-joy {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f602.svg");
}
//
// .twa-smiling-face-with-open-mouth {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f603.svg"); }
//
// .twa-smiley {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f603.svg"); }
//
// .twa-smiling-face-with-open-mouth-and-smiling-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f604.svg"); }
//
.twa-smile {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f604.svg");
}
//
// .twa-smiling-face-with-open-mouth-and-cold-sweat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f605.svg"); }
//
// .twa-sweat-smile {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f605.svg"); }
//
// .twa-smiling-face-with-open-mouth-and-tightly-closed-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f606.svg"); }
//
// .twa-laughing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f606.svg"); }
//
// .twa-smiling-face-with-halo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f607.svg"); }
//
// .twa-innocent {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f607.svg"); }
//
// .twa-smiling-face-with-horns {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f608.svg"); }
//
// .twa-smiling-imp {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f608.svg"); }
//
// .twa-winking-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f609.svg"); }
//
// .twa-wink {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f609.svg"); }
//
// .twa-smiling-face-with-smiling-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60a.svg"); }
//
// .twa-blush {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60a.svg"); }
//
// .twa-face-savouring-delicious-food {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60b.svg"); }
//
// .twa-yum {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60b.svg"); }
//
// .twa-relieved-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60c.svg"); }
//
// .twa-satisfied {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60c.svg"); }
//
// .twa-smiling-face-with-heart-shaped-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60d.svg"); }
//
// .twa-heart-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60d.svg"); }
//
// .twa-smiling-face-with-sunglasses {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60e.svg"); }
//
// .twa-smirking-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f60f.svg"); }
//
.twa-smirk {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f60f.svg");
}
//
// .twa-neutral-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f610.svg"); }
//
// .twa-expressionless-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f611.svg"); }
//
.twa-expressionless {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f611.svg");
}
//
// .twa-unamused-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f612.svg"); }
//
// .twa-unamused {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f612.svg"); }
//
// .twa-face-with-cold-sweat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f613.svg"); }
//
// .twa-sweat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f613.svg"); }
//
// .twa-pensive-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f614.svg"); }
//
// .twa-pensive {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f614.svg"); }
//
// .twa-confused-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f615.svg"); }
//
// .twa-confused {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f615.svg"); }
//
// .twa-confounded-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f616.svg"); }
//
// .twa-confounded {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f616.svg"); }
//
// .twa-kissing-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f617.svg"); }
//
// .twa-kissing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f617.svg"); }
//
// .twa-face-throwing-a-kiss {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f618.svg"); }
//
// .twa-kissing-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f618.svg"); }
//
// .twa-kissing-face-with-smiling-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f619.svg"); }
//
// .twa-kissing-smiling-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f619.svg"); }
//
// .twa-kissing-face-with-closed-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61a.svg"); }
//
// .twa-kissing-closed-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61a.svg"); }
//
// .twa-face-with-stuck-out-tongue {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61b.svg"); }
//
// .twa-stuck-out-tongue {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61b.svg"); }
//
// .twa-face-with-stuck-out-tongue-and-winking-eye {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61c.svg"); }
//
// .twa-stuck-out-tongue-winking-eye {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61c.svg"); }
//
// .twa-face-with-stuck-out-tongue-and-tightly-closed-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61d.svg"); }
//
// .twa-stuck-out-tongue-closed-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61d.svg"); }
//
// .twa-disappointed-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61e.svg"); }
//
.twa-disappointed {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f61e.svg");
}
//
// .twa-worried-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f61f.svg"); }
//
.twa-worried {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f61f.svg");
}
//
// .twa-angry-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f620.svg"); }
//
// .twa-angry {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f620.svg"); }
//
// .twa-pouting-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f621.svg"); }
//
.twa-rage {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f621.svg");
}
//
// .twa-crying-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f622.svg"); }
//
.twa-cry {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f622.svg");
}
//
// .twa-persevering-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f623.svg"); }
//
// .twa-persevere {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f623.svg"); }
//
// .twa-face-with-steam-from-nose {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f624.svg"); }
//
.twa-triumph {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f624.svg");
}
//
// .twa-disappointed-but-relieved-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f625.svg"); }
//
// .twa-relieved {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f625.svg"); }
//
// .twa-frowning-face-with-open-mouth {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f626.svg"); }
//
// .twa-frowning {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f626.svg"); }
//
// .twa-anguished-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f627.svg"); }
//
// .twa-anguished {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f627.svg"); }
//
// .twa-fearful-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f628.svg"); }
//
// .twa-fearful {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f628.svg"); }
//
// .twa-weary-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f629.svg"); }
//
.twa-weary {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f629.svg");
}
.twa-shrugging {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f937.svg");
}
//
// .twa-sleepy-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62a.svg"); }
//
// .twa-sleepy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62a.svg"); }
//
// .twa-tired-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62b.svg"); }
//
// .twa-grimacing-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62c.svg"); }
//
// .twa-grimacing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62c.svg"); }
//
// .twa-loudly-crying-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62d.svg"); }
//
.twa-sob {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f62d.svg");
}
//
// .twa-face-with-open-mouth {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62e.svg"); }
//
// .twa-open-mouth {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62e.svg"); }
//
// .twa-hushed-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62f.svg"); }
//
// .twa-hushed {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f62f.svg"); }
//
// .twa-face-with-open-mouth-and-cold-sweat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f630.svg"); }
//
// .twa-cold-sweat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f630.svg"); }
//
// .twa-face-screaming-in-fear {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f631.svg"); }
//
// .twa-scream {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f631.svg"); }
//
// .twa-astonished-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f632.svg"); }
//
// .twa-astonished {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f632.svg"); }
//
// .twa-flushed-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f633.svg"); }
//
// .twa-flushed {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f633.svg"); }
//
// .twa-sleeping-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f634.svg"); }
//
// .twa-sleeping {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f634.svg"); }
//
// .twa-dizzy-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f635.svg"); }
//
// .twa-face-without-mouth {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f636.svg"); }
//
// .twa-no-mouth {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f636.svg"); }
//
// .twa-face-with-medical-mask {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f637.svg"); }
//
// .twa-mask {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f637.svg"); }
//
// .twa-grinning-cat-face-with-smiling-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f638.svg"); }
//
// .twa-smile-cat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f638.svg"); }
//
// .twa-cat-face-with-tears-of-joy {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f639.svg"); }
//
// .twa-joy-cat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f639.svg"); }
//
// .twa-smiling-cat-face-with-open-mouth {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63a.svg"); }
//
// .twa-smiley-cat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63a.svg"); }
//
// .twa-smiling-cat-face-with-heart-shaped-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63b.svg"); }
//
// .twa-heart-eyes-cat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63b.svg"); }
//
// .twa-cat-face-with-wry-smile {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63c.svg"); }
//
// .twa-smirk-cat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63c.svg"); }
//
// .twa-kissing-cat-face-with-closed-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63d.svg"); }
//
// .twa-kissing-cat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63d.svg"); }
//
// .twa-pouting-cat-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63e.svg"); }
//
// .twa-pouting-cat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63e.svg"); }
//
// .twa-crying-cat-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f63f.svg"); }
//
// .twa-weary-cat-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f640.svg"); }
//
// .twa-scream-cat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f640.svg"); }
//
// .twa-slightly-frowning-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f641.svg"); }
//
// .twa-slightly-smiling-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f642.svg"); }
//
// .twa-upside-down-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f643.svg"); }
//
// .twa-face-with-rolling-eyes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f644.svg"); }
//
// .twa-face-with-no-good-gesture-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f645-1f3fb.svg"); }
//
// .twa-face-with-no-good-gesture-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f645-1f3fc.svg"); }
//
// .twa-face-with-no-good-gesture-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f645-1f3fd.svg"); }
//
// .twa-face-with-no-good-gesture-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f645-1f3fe.svg"); }
//
// .twa-face-with-no-good-gesture-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f645-1f3ff.svg"); }
//
// .twa-gesturing-no {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f645.svg"); }
//
// .twa-no-good {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f645.svg"); }
//
// .twa-face-with-ok-gesture-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f646-1f3fb.svg"); }
//
// .twa-face-with-ok-gesture-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f646-1f3fc.svg"); }
//
// .twa-face-with-ok-gesture-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f646-1f3fd.svg"); }
//
// .twa-face-with-ok-gesture-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f646-1f3fe.svg"); }
//
// .twa-face-with-ok-gesture-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f646-1f3ff.svg"); }
//
// .twa-gesturing-ok {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f646.svg"); }
//
// .twa-ok-woman {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f646.svg"); }
//
// .twa-person-bowing-deeply-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f647-1f3fb.svg"); }
//
// .twa-person-bowing-deeply-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f647-1f3fc.svg"); }
//
// .twa-person-bowing-deeply-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f647-1f3fd.svg"); }
//
// .twa-person-bowing-deeply-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f647-1f3fe.svg"); }
//
// .twa-person-bowing-deeply-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f647-1f3ff.svg"); }
//
// .twa-person-bowing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f647.svg"); }
//
// .twa-bow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f647.svg"); }
//
.twa-see-no-evil {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f648.svg");
}

.twa-hear-no-evil {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f649.svg");
}

.twa-speak-no-evil {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f64a.svg");
}
//
// .twa-happy-person-raising-one-hand-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b-1f3fb.svg"); }
//
// .twa-happy-person-raising-one-hand-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b-1f3fc.svg"); }
//
// .twa-happy-person-raising-one-hand-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b-1f3fd.svg"); }
//
// .twa-happy-person-raising-one-hand-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b-1f3fe.svg"); }
//
// .twa-happy-person-raising-one-hand-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b-1f3ff.svg"); }
//
// .twa-happy-person-raising-hand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b.svg"); }
//
// .twa-happy-person-raised-hand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b.svg"); }
//
// .twa-person-raising-both-hands-in-celebration-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64c-1f3fb.svg"); }
//
// .twa-person-raising-both-hands-in-celebration-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64c-1f3fc.svg"); }
//
// .twa-person-raising-both-hands-in-celebration-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64c-1f3fd.svg"); }
//
// .twa-person-raising-both-hands-in-celebration-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64c-1f3fe.svg"); }
//
// .twa-person-raising-both-hands-in-celebration-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64c-1f3ff.svg"); }
//
// .twa-person-raising-hands {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64c.svg"); }
//
// .twa-raised-hands {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64c.svg"); }
//
// .twa-person-frowning-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64d-1f3fb.svg"); }
//
// .twa-person-frowning-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64d-1f3fc.svg"); }
//
// .twa-person-frowning-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64d-1f3fd.svg"); }
//
// .twa-person-frowning-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64d-1f3fe.svg"); }
//
// .twa-person-frowning-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64d-1f3ff.svg"); }
//
// .twa-person-frowning {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64d.svg"); }
//
// .twa-person-with-pouting-face-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e-1f3fb.svg"); }
//
// .twa-person-with-pouting-face-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e-1f3fc.svg"); }
//
// .twa-person-with-pouting-face-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e-1f3fd.svg"); }
//
// .twa-person-with-pouting-face-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e-1f3fe.svg"); }
//
// .twa-person-with-pouting-face-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e-1f3ff.svg"); }
//
// .twa-person-pouting {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e.svg"); }
//
// .twa-person-with-pouting-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e.svg"); }
//
// .twa-person-with-folded-hands-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64f-1f3fb.svg"); }
//
// .twa-person-with-folded-hands-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64f-1f3fc.svg"); }
//
// .twa-person-with-folded-hands-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64f-1f3fd.svg"); }
//
// .twa-person-with-folded-hands-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64f-1f3fe.svg"); }
//
// .twa-person-with-folded-hands-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64f-1f3ff.svg"); }
//
// .twa-folded-hands {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64f.svg"); }
//
// .twa-pray {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f64f.svg"); }
//
.twa-rocket {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/1f680.svg");
}
//
// .twa-helicopter {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f681.svg"); }
//
// .twa-locomotive {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f682.svg"); }
//
// .twa-steam-locomotive {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f682.svg"); }
//
// .twa-railway-car {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f683.svg"); }
//
// .twa-high-speed-train {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f684.svg"); }
//
// .twa-bullettrain-side {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f684.svg"); }
//
// .twa-high-speed-train-with-bullet-nose {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f685.svg"); }
//
// .twa-bullettrain-front {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f685.svg"); }
//
// .twa-train {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f686.svg"); }
//
// .twa-train2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f686.svg"); }
//
// .twa-metro {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f687.svg"); }
//
// .twa-light-rail {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f688.svg"); }
//
// .twa-station {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f689.svg"); }
//
// .twa-tram {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f68a.svg"); }
//
// .twa-tram-car {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f68b.svg"); }
//
// .twa-bus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f68c.svg"); }
//
// .twa-oncoming-bus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f68d.svg"); }
//
// .twa-trolleybus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f68e.svg"); }
//
// .twa-bus-stop {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f68f.svg"); }
//
// .twa-busstop {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f68f.svg"); }
//
// .twa-minibus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f690.svg"); }
//
// .twa-ambulance {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f691.svg"); }
//
// .twa-fire-engine {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f692.svg"); }
//
// .twa-police-car {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f693.svg"); }
//
// .twa-oncoming-police-car {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f694.svg"); }
//
// .twa-taxi {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f695.svg"); }
//
// .twa-oncoming-taxi {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f696.svg"); }
//
// .twa-automobile {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f697.svg"); }
//
// .twa-car {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f697.svg"); }
//
// .twa-oncoming-automobile {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f698.svg"); }
//
// .twa-recreational-vehicle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f699.svg"); }
//
// .twa-blue-car {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f699.svg"); }
//
// .twa-delivery-truck {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f69a.svg"); }
//
// .twa-truck {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f69a.svg"); }
//
// .twa-articulated-lorry {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f69b.svg"); }
//
// .twa-tractor {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f69c.svg"); }
//
// .twa-monorail {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f69d.svg"); }
//
// .twa-mountain-railway {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f69e.svg"); }
//
// .twa-suspension-railway {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f69f.svg"); }
//
// .twa-mountain-cableway {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a0.svg"); }
//
// .twa-aerial-tramway {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a1.svg"); }
//
// .twa-ship {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a2.svg"); }
//
// .twa-rowboat-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a3-1f3fb.svg"); }
//
// .twa-rowboat-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a3-1f3fc.svg"); }
//
// .twa-rowboat-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a3-1f3fd.svg"); }
//
// .twa-rowboat-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a3-1f3fe.svg"); }
//
// .twa-rowboat-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a3-1f3ff.svg"); }
//
// .twa-rowboat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a3.svg"); }
//
// .twa-speedboat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a4.svg"); }
//
// .twa-horizontal-traffic-light {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a5.svg"); }
//
// .twa-traffic-light {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a5.svg"); }
//
// .twa-vertical-traffic-light {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a6.svg"); }
//
// .twa-construction {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a7.svg"); }
//
// .twa-police-cars-light {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a8.svg"); }
//
// .twa-rotating-light {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a8.svg"); }
//
// .twa-triangular-flag {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a9.svg"); }
//
// .twa-triangular-flag-on-post {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a9.svg"); }
//
// .twa-door {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6aa.svg"); }
//
// .twa-prohibited {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ab.svg"); }
//
// .twa-no-entry-sign {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ab.svg"); }
//
// .twa-smoking {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ac.svg"); }
//
// .twa-no-smoking {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ad.svg"); }
//
// .twa-litter-in-bin-sign {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ae.svg"); }
//
// .twa-put-litter-in-its-place {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ae.svg"); }
//
// .twa-no-littering {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6af.svg"); }
//
// .twa-do-not-litter {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6af.svg"); }
//
// .twa-potable-water {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b0.svg"); }
//
// .twa-non-potable-water {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b1.svg"); }
//
// .twa-bicycle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b2.svg"); }
//
// .twa-bike {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b2.svg"); }
//
// .twa-no-bicycles {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b3.svg"); }
//
// .twa-bicyclist-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b4-1f3fb.svg"); }
//
// .twa-bicyclist-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b4-1f3fc.svg"); }
//
// .twa-bicyclist-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b4-1f3fd.svg"); }
//
// .twa-bicyclist-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b4-1f3fe.svg"); }
//
// .twa-bicyclist-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b4-1f3ff.svg"); }
//
// .twa-bicyclist {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b4.svg"); }
//
// .twa-mountain-bicyclist-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5-1f3fb.svg"); }
//
// .twa-mountain-bicyclist-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5-1f3fc.svg"); }
//
// .twa-mountain-bicyclist-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5-1f3fd.svg"); }
//
// .twa-mountain-bicyclist-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5-1f3fe.svg"); }
//
// .twa-mountain-bicyclist-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5-1f3ff.svg"); }
//
// .twa-mountain-biker {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5.svg"); }
//
// .twa-mountain-bicyclist {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5.svg"); }
//
// .twa-pedestrian-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6-1f3fb.svg"); }
//
// .twa-pedestrian-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6-1f3fc.svg"); }
//
// .twa-pedestrian-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6-1f3fd.svg"); }
//
// .twa-pedestrian-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6-1f3fe.svg"); }
//
// .twa-pedestrian-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6-1f3ff.svg"); }
//
// .twa-pedestrian {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6.svg"); }
//
// .twa-walking {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6.svg"); }
//
// .twa-no-pedestrians {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b7.svg"); }
//
// .twa-children-crossing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b8.svg"); }
//
// .twa-mens-room {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b9.svg"); }
//
// .twa-mens {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b9.svg"); }
//
// .twa-womens-room {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ba.svg"); }
//
// .twa-womens {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ba.svg"); }
//
// .twa-restroom {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6bb.svg"); }
//
// .twa-baby-symbol {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6bc.svg"); }
//
// .twa-toilet {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6bd.svg"); }
//
// .twa-water-closet {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6be.svg"); }
//
// .twa-wc {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6be.svg"); }
//
// .twa-shower {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6bf.svg"); }
//
// .twa-bath-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c0-1f3fb.svg"); }
//
// .twa-bath-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c0-1f3fc.svg"); }
//
// .twa-bath-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c0-1f3fd.svg"); }
//
// .twa-bath-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c0-1f3fe.svg"); }
//
// .twa-bath-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c0-1f3ff.svg"); }
//
// .twa-person-taking-bath {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c0.svg"); }
//
// .twa-bath {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c0.svg"); }
//
// .twa-bathtub {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c1.svg"); }
//
// .twa-passport-control {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c2.svg"); }
//
// .twa-customs {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c3.svg"); }
//
// .twa-baggage-claim {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c4.svg"); }
//
// .twa-left-luggage {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c5.svg"); }
//
// .twa-couch-and-lamp {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6cb.svg"); }
//
// .twa-person-in-bed {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6cc.svg"); }
//
// .twa-shopping-bags {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6cd.svg"); }
//
// .twa-bellhop-bell {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ce.svg"); }
//
// .twa-bed {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6cf.svg"); }
//
// .twa-place-of-worship {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6d0.svg"); }
//
// .twa-octagonal-sign {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6d1.svg"); }
//
// .twa-shopping-trolley {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6d2.svg"); }
//
// .twa-hammer-and-wrench {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e0.svg"); }
//
// .twa-shield {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e1.svg"); }
//
// .twa-oil-drum {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e2.svg"); }
//
// .twa-motorway {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e3.svg"); }
//
// .twa-railway-track {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e4.svg"); }
//
// .twa-motor-boat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e5.svg"); }
//
// .twa-small-airplane {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e9.svg"); }
//
// .twa-airplane-departure {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6eb.svg"); }
//
// .twa-airplane-arrival {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ec.svg"); }
//
// .twa-satellite {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f0.svg"); }
//
// .twa-passenger-ship {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f3.svg"); }
//
// .twa-scooter {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f4.svg"); }
//
// .twa-motor-scooter {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f5.svg"); }
//
// .twa-canoe {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f6.svg"); }
//
// .twa-zipper-mouth-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f910.svg"); }
//
// .twa-money-mouth-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f911.svg"); }
//
// .twa-face-with-thermometer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f912.svg"); }
//
// .twa-nerd-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f913.svg"); }
//
// .twa-thinking-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f914.svg"); }
//
// .twa-face-with-head-bandage {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f915.svg"); }
//
// .twa-robot-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f916.svg"); }
//
// .twa-hugging-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f917.svg"); }
//
// .twa-sign-of-the-horns-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f918-1f3fb.svg"); }
//
// .twa-sign-of-the-horns-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f918-1f3fc.svg"); }
//
// .twa-sign-of-the-horns-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f918-1f3fd.svg"); }
//
// .twa-sign-of-the-horns-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f918-1f3fe.svg"); }
//
// .twa-sign-of-the-horns-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f918-1f3ff.svg"); }
//
// .twa-sign-of-the-horns {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f918.svg"); }
//
// .twa-call-me-hand-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f919-1f3fb.svg"); }
//
// .twa-call-me-hand-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f919-1f3fc.svg"); }
//
// .twa-call-me-hand-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f919-1f3fd.svg"); }
//
// .twa-call-me-hand-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f919-1f3fe.svg"); }
//
// .twa-call-me-hand-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f919-1f3ff.svg"); }
//
// .twa-call-me-hand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f919.svg"); }
//
// .twa-raised-back-of-hand-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91a-1f3fb.svg"); }
//
// .twa-raised-back-of-hand-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91a-1f3fc.svg"); }
//
// .twa-raised-back-of-hand-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91a-1f3fd.svg"); }
//
// .twa-raised-back-of-hand-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91a-1f3fe.svg"); }
//
// .twa-raised-back-of-hand-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91a-1f3ff.svg"); }
//
// .twa-raised-back-of-hand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91a.svg"); }
//
// .twa-left-facing-fist-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91b-1f3fb.svg"); }
//
// .twa-left-facing-fist-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91b-1f3fc.svg"); }
//
// .twa-left-facing-fist-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91b-1f3fd.svg"); }
//
// .twa-left-facing-fist-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91b-1f3fe.svg"); }
//
// .twa-left-facing-fist-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91b-1f3ff.svg"); }
//
// .twa-left-facing-fist {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91b.svg"); }
//
// .twa-right-facing-fist-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91c-1f3fb.svg"); }
//
// .twa-right-facing-fist-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91c-1f3fc.svg"); }
//
// .twa-right-facing-fist-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91c-1f3fd.svg"); }
//
// .twa-right-facing-fist-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91c-1f3fe.svg"); }
//
// .twa-right-facing-fist-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91c-1f3ff.svg"); }
//
// .twa-right-facing-fist {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91c.svg"); }
//
// .twa-handshake-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91d-1f3fb.svg"); }
//
// .twa-handshake-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91d-1f3fc.svg"); }
//
// .twa-handshake-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91d-1f3fd.svg"); }
//
// .twa-handshake-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91d-1f3fe.svg"); }
//
// .twa-handshake-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91d-1f3ff.svg"); }
//
// .twa-handshake {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91d.svg"); }
//
// .twa-hand-with-index-and-middle-fingers-crossed-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91e-1f3fb.svg"); }
//
// .twa-hand-with-index-and-middle-fingers-crossed-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91e-1f3fc.svg"); }
//
// .twa-hand-with-index-and-middle-fingers-crossed-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91e-1f3fd.svg"); }
//
// .twa-hand-with-index-and-middle-fingers-crossed-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91e-1f3fe.svg"); }
//
// .twa-hand-with-index-and-middle-fingers-crossed-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91e-1f3ff.svg"); }
//
// .twa-hand-with-index-and-middle-fingers-crossed {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f91e.svg"); }
//
// .twa-face-with-cowboy-hat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f920.svg"); }
//
// .twa-clown-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f921.svg"); }
//
// .twa-nauseated-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f922.svg"); }
//
// .twa-rolling-on-the-floor-laughing {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f923.svg"); }
//
// .twa-drooling-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f924.svg"); }
//
// .twa-lying-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f925.svg"); }
//
// .twa-face-palm-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f926-1f3fb.svg"); }
//
// .twa-face-palm-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f926-1f3fc.svg"); }
//
// .twa-face-palm-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f926-1f3fd.svg"); }
//
// .twa-face-palm-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f926-1f3fe.svg"); }
//
// .twa-face-palm-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f926-1f3ff.svg"); }
//
.twa-face-palm {
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f926.svg");
}

// .twa-sneezing-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f927.svg"); }
//
// .twa-pregnant-woman-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f930-1f3fb.svg"); }
//
// .twa-pregnant-woman-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f930-1f3fc.svg"); }
//
// .twa-pregnant-woman-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f930-1f3fd.svg"); }
//
// .twa-pregnant-woman-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f930-1f3fe.svg"); }
//
// .twa-pregnant-woman-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f930-1f3ff.svg"); }
//
// .twa-pregnant-woman {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f930.svg"); }
//
// .twa-selfie-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f933-1f3fb.svg"); }
//
// .twa-selfie-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f933-1f3fc.svg"); }
//
// .twa-selfie-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f933-1f3fd.svg"); }
//
// .twa-selfie-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f933-1f3fe.svg"); }
//
// .twa-selfie-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f933-1f3ff.svg"); }
//
// .twa-selfie {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f933.svg"); }
//
// .twa-prince-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f934-1f3fb.svg"); }
//
// .twa-prince-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f934-1f3fc.svg"); }
//
// .twa-prince-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f934-1f3fd.svg"); }
//
// .twa-prince-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f934-1f3fe.svg"); }
//
// .twa-prince-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f934-1f3ff.svg"); }
//
// .twa-prince {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f934.svg"); }
//
// .twa-man-in-tuxedo-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f935-1f3fb.svg"); }
//
// .twa-man-in-tuxedo-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f935-1f3fc.svg"); }
//
// .twa-man-in-tuxedo-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f935-1f3fd.svg"); }
//
// .twa-man-in-tuxedo-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f935-1f3fe.svg"); }
//
// .twa-man-in-tuxedo-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f935-1f3ff.svg"); }
//
// .twa-man-in-tuxedo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f935.svg"); }
//
// .twa-mother-christmas-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f936-1f3fb.svg"); }
//
// .twa-mother-christmas-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f936-1f3fc.svg"); }
//
// .twa-mother-christmas-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f936-1f3fd.svg"); }
//
// .twa-mother-christmas-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f936-1f3fe.svg"); }
//
// .twa-mother-christmas-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f936-1f3ff.svg"); }
//
// .twa-mother-christmas {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f936.svg"); }
//
// .twa-shrug-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f937-1f3fb.svg"); }
//
// .twa-shrug-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f937-1f3fc.svg"); }
//
// .twa-shrug-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f937-1f3fd.svg"); }
//
// .twa-shrug-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f937-1f3fe.svg"); }
//
// .twa-shrug-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f937-1f3ff.svg"); }
//
// .twa-shrug {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f937.svg"); }
//
// .twa-person-doing-cartwheel-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f938-1f3fb.svg"); }
//
// .twa-person-doing-cartwheel-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f938-1f3fc.svg"); }
//
// .twa-person-doing-cartwheel-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f938-1f3fd.svg"); }
//
// .twa-person-doing-cartwheel-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f938-1f3fe.svg"); }
//
// .twa-person-doing-cartwheel-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f938-1f3ff.svg"); }
//
// .twa-person-doing-cartwheel {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f938.svg"); }
//
// .twa-juggling-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f939-1f3fb.svg"); }
//
// .twa-juggling-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f939-1f3fc.svg"); }
//
// .twa-juggling-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f939-1f3fd.svg"); }
//
// .twa-juggling-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f939-1f3fe.svg"); }
//
// .twa-juggling-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f939-1f3ff.svg"); }
//
// .twa-juggling {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f939.svg"); }
//
// .twa-fencer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93a.svg"); }
//
// .twa-wrestlers-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93c-1f3fb.svg"); }
//
// .twa-wrestlers-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93c-1f3fc.svg"); }
//
// .twa-wrestlers-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93c-1f3fd.svg"); }
//
// .twa-wrestlers-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93c-1f3fe.svg"); }
//
// .twa-wrestlers-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93c-1f3ff.svg"); }
//
// .twa-wrestlers {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93c.svg"); }
//
// .twa-water-polo-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93d-1f3fb.svg"); }
//
// .twa-water-polo-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93d-1f3fc.svg"); }
//
// .twa-water-polo-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93d-1f3fd.svg"); }
//
// .twa-water-polo-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93d-1f3fe.svg"); }
//
// .twa-water-polo-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93d-1f3ff.svg"); }
//
// .twa-water-polo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93d.svg"); }
//
// .twa-handball-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93e-1f3fb.svg"); }
//
// .twa-handball-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93e-1f3fc.svg"); }
//
// .twa-handball-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93e-1f3fd.svg"); }
//
// .twa-handball-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93e-1f3fe.svg"); }
//
// .twa-handball-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93e-1f3ff.svg"); }
//
// .twa-handball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f93e.svg"); }
//
// .twa-wilted-flower {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f940.svg"); }
//
// .twa-drum-with-drumsticks {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f941.svg"); }
//
// .twa-clinking-glasses {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f942.svg"); }
//
// .twa-tumbler-glass {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f943.svg"); }
//
// .twa-spoon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f944.svg"); }
//
// .twa-goal-net {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f945.svg"); }
//
// .twa-first-place-medal {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f947.svg"); }
//
// .twa-second-place-medal {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f948.svg"); }
//
// .twa-third-place-medal {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f949.svg"); }
//
// .twa-boxing-glove {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f94a.svg"); }
//
// .twa-martial-arts-uniform {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f94b.svg"); }
//
// .twa-croissant {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f950.svg"); }
//
// .twa-avocado {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f951.svg"); }
//
// .twa-cucumber {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f952.svg"); }
//
// .twa-bacon {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f953.svg"); }
//
// .twa-potato {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f954.svg"); }
//
// .twa-carrot {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f955.svg"); }
//
// .twa-baguette-bread {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f956.svg"); }
//
// .twa-green-salad {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f957.svg"); }
//
// .twa-shallow-pan-of-food {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f958.svg"); }
//
// .twa-stuffed-flatbread {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f959.svg"); }
//
// .twa-egg {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f95a.svg"); }
//
// .twa-glass-of-milk {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f95b.svg"); }
//
// .twa-peanuts {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f95c.svg"); }
//
// .twa-kiwifruit {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f95d.svg"); }
//
// .twa-pancakes {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f95e.svg"); }
//
// .twa-crab {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f980.svg"); }
//
// .twa-lion-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f981.svg"); }
//
// .twa-scorpion {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f982.svg"); }
//
// .twa-turkey {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f983.svg"); }
//
// .twa-unicorn-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f984.svg"); }
//
// .twa-eagle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f985.svg"); }
//
// .twa-duck {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f986.svg"); }
//
// .twa-bat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f987.svg"); }
//
// .twa-shark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f988.svg"); }
//
// .twa-owl {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f989.svg"); }
//
// .twa-fox-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f98a.svg"); }
//
// .twa-butterfly {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f98b.svg"); }
//
// .twa-deer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f98c.svg"); }
//
// .twa-gorilla {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f98d.svg"); }
//
// .twa-lizard {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f98e.svg"); }
//
// .twa-rhinoceros {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f98f.svg"); }
//
// .twa-shrimp {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f990.svg"); }
//
// .twa-squid {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f991.svg"); }
//
// .twa-cheese-wedge {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/1f9c0.svg"); }
//
.twa-double-exclamation-mark {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/203c.svg");
}
//
// .twa-bangbang {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/203c.svg"); }
//
// .twa-exclamation-question-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2049.svg"); }
//
// .twa-interrobang {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2049.svg"); }
//
// .twa-trade-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2122.svg"); }
//
// .twa-tm {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2122.svg"); }
//
// .twa-information-source {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2139.svg"); }
//
// .twa-left-right-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2194.svg"); }
//
// .twa-up-down-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2195.svg"); }
//
// .twa-arrow-up-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2195.svg"); }
//
// .twa-up-left-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2196.svg"); }
//
// .twa-arrow-upper-left {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2196.svg"); }
//
// .twa-up-right-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2197.svg"); }
//
// .twa-arrow-upper-right {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2197.svg"); }
//
// .twa-down-right-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2198.svg"); }
//
// .twa-arrow-lower-right {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2198.svg"); }
//
// .twa-down-left-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2199.svg"); }
//
// .twa-arrow-lower-left {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2199.svg"); }
//
// .twa-right-arrow-curving-left {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/21a9.svg"); }
//
// .twa-leftwards-arrow-with-hook {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/21a9.svg"); }
//
// .twa-left-arrow-curving-right {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/21aa.svg"); }
//
// .twa-arrow-right-hook {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/21aa.svg"); }
//
// .twa-hash {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23-20e3.svg"); }
//
// .twa-watch {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/231a.svg"); }
//
// .twa-hourglass {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/231b.svg"); }
//
// .twa-keyboard {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2328.svg"); }
//
// .twa-eject-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23cf.svg"); }
//
// .twa-fast-forword-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23e9.svg"); }
//
// .twa-fast-forward {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23e9.svg"); }
//
// .twa-fast-reverse-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23ea.svg"); }
//
// .twa-rewind {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23ea.svg"); }
//
// .twa-fast-up-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23eb.svg"); }
//
// .twa-arrow-double-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23eb.svg"); }
//
// .twa-fast-down-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23ec.svg"); }
//
// .twa-arrow-double-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23ec.svg"); }
//
// .twa-next-track-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23ed.svg"); }
//
// .twa-last-track-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23ee.svg"); }
//
// .twa-play-or-pause-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23ef.svg"); }
//
// .twa-alarm-clock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23f0.svg"); }
//
// .twa-stopwatch {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23f1.svg"); }
//
// .twa-timer-clock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23f2.svg"); }
//
// .twa-hourglass-with-flowing-sand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23f3.svg"); }
//
// .twa-hourglass-flowing-sand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23f3.svg"); }
//
// .twa-pause-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23f8.svg"); }
//
// .twa-stop-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23f9.svg"); }
//
// .twa-record-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/23fa.svg"); }
//
// .twa-circled-letter-m {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/24c2.svg"); }
//
// .twa-m {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/24c2.svg"); }
//
// .twa-black-small-square {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25aa.svg"); }
//
// .twa-white-small-square {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25ab.svg"); }
//
// .twa-play-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25b6.svg"); }
//
// .twa-arrow-forward {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25b6.svg"); }
//
// .twa-reverse-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25c0.svg"); }
//
// .twa-arrow-backward {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25c0.svg"); }
//
// .twa-white-medium-square {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25fb.svg"); }
//
// .twa-black-medium-square {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25fc.svg"); }
//
// .twa-white-medium-small-square {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25fd.svg"); }
//
// .twa-black-medium-small-square {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/25fe.svg"); }
//
// .twa-sun {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2600.svg"); }
//
.twa-sunny {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/2600.svg");
}
//
.twa-cloud {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/2601.svg");
}
//
.twa-umbrella {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/2602.svg");
}
//
// .twa-snowman {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2603.svg"); }
//
// .twa-comet {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2604.svg"); }
//
// .twa-telephone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/260e.svg"); }
//
// .twa-phone {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/260e.svg"); }
//
// .twa-ballot-box-with-check {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2611.svg"); }
//
// .twa-umbrella-with-rain-drops {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2614.svg"); }
//
// .twa-hot-beverage {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2615.svg"); }
//
// .twa-coffee {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2615.svg"); }
//
// .twa-shamrock {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2618.svg"); }
//
// .twa-white-up-pointing-index-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/261d-1f3fb.svg"); }
//
// .twa-white-up-pointing-index-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/261d-1f3fc.svg"); }
//
// .twa-white-up-pointing-index-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/261d-1f3fd.svg"); }
//
// .twa-white-up-pointing-index-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/261d-1f3fe.svg"); }
//
// .twa-white-up-pointing-index-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/261d-1f3ff.svg"); }
//
// .twa-index-pointing-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/261d.svg"); }
//
// .twa-point-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/261d.svg"); }
//
// .twa-skull-and-crossbones {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2620.svg"); }
//
// .twa-radioactive {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2622.svg"); }
//
// .twa-biohazard {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2623.svg"); }
//
// .twa-orthodox-cross {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2626.svg"); }
//
// .twa-star-and-crescent {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/262a.svg"); }
//
// .twa-peace-symbol {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/262e.svg"); }
//
// .twa-yin-yang {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/262f.svg"); }
//
// .twa-wheel-of-dharma {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2638.svg"); }
//
// .twa-frowning-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2639.svg"); }
//
// .twa-smiling-face {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/263a.svg"); }
//
// .twa-relaxed {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/263a.svg"); }
//
// .twa-aries {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2648.svg"); }
//
// .twa-taurus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2649.svg"); }
//
// .twa-gemini {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/264a.svg"); }
//
// .twa-cancer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/264b.svg"); }
//
// .twa-leo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/264c.svg"); }
//
// .twa-virgo {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/264d.svg"); }
//
// .twa-libra {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/264e.svg"); }
//
// .twa-scorpius {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/264f.svg"); }
//
// .twa-sagittarius {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2650.svg"); }
//
// .twa-capricorn {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2651.svg"); }
//
// .twa-aquarius {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2652.svg"); }
//
// .twa-pisces {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2653.svg"); }
//
// .twa-spade-suit {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2660.svg"); }
//
// .twa-spades {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2660.svg"); }
//
// .twa-club-suit {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2663.svg"); }
//
// .twa-clubs {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2663.svg"); }
//
// .twa-heart-suit {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2665.svg"); }
//
// .twa-hearts {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2665.svg"); }
//
// .twa-diamond-suit {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2666.svg"); }
//
// .twa-diamonds {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2666.svg"); }
//
// .twa-hot-springs {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2668.svg"); }
//
// .twa-hotsprings {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2668.svg"); }
//
// .twa-recycling-symbol {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/267b.svg"); }
//
// .twa-recycle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/267b.svg"); }
//
// .twa-wheelchair {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/267f.svg"); }
//
// .twa-hammer-and-pick {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2692.svg"); }
//
// .twa-anchor {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2693.svg"); }
//
// .twa-crossed-swords {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2694.svg"); }
//
// .twa-balance-scale {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2696.svg"); }
//
// .twa-alembic {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2697.svg"); }
//
// .twa-gear {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2699.svg"); }
//
// .twa-atom-symbol {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/269b.svg"); }
//
// .twa-fleur-de-lis {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/269c.svg"); }
//
// .twa-warning {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26a0.svg"); }
//
// .twa-high-voltage {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26a1.svg"); }
//
.twa-zap {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/26a1.svg");
}
//
// .twa-white-circle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26aa.svg"); }
//
// .twa-black-circle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26ab.svg"); }
//
// .twa-coffin {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26b0.svg"); }
//
// .twa-funeral-urn {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26b1.svg"); }
//
// .twa-soccer-ball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26bd.svg"); }
//
// .twa-soccer {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26bd.svg"); }
//
// .twa-baseball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26be.svg"); }
//
// .twa-snowman-without-snow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26c4.svg"); }
//
// .twa-sun-behind-cloud {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26c5.svg"); }
//
// .twa-partly-sunny {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26c5.svg"); }
//
// .twa-cloud-with-lightning-and-rain {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26c8.svg"); }
//
// .twa-ophiuchus {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26ce.svg"); }
//
// .twa-pick {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26cf.svg"); }
//
// .twa-helmet-with-white-cross {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26d1.svg"); }
//
// .twa-chains {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26d3.svg"); }
//
// .twa-no-entry {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26d4.svg"); }
//
// .twa-shinto-shrine {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26e9.svg"); }
//
// .twa-church {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26ea.svg"); }
//
// .twa-mountain {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f0.svg"); }
//
// .twa-umbrella-on-ground {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f1.svg"); }
//
// .twa-fountain {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f2.svg"); }
//
// .twa-flag-in-hole {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f3.svg"); }
//
// .twa-golf {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f3.svg"); }
//
// .twa-ferry {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f4.svg"); }
//
// .twa-sailboat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f5.svg"); }
//
// .twa-boat {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f5.svg"); }
//
// .twa-skier {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f7.svg"); }
//
// .twa-ice-skate {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f8.svg"); }
//
// .twa-person-with-ball-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f9-1f3fb.svg"); }
//
// .twa-person-with-ball-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f9-1f3fc.svg"); }
//
// .twa-person-with-ball-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f9-1f3fd.svg"); }
//
// .twa-person-with-ball-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f9-1f3fe.svg"); }
//
// .twa-person-with-ball-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f9-1f3ff.svg"); }
//
// .twa-person-with-ball {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26f9.svg"); }
//
// .twa-tent {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26fa.svg"); }
//
// .twa-fuel-pump {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26fd.svg"); }
//
// .twa-fuelpump {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/26fd.svg"); }
//
// .twa-scissors {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2702.svg"); }
//
// .twa-white-heavy-check-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2705.svg"); }
//
.twa-white-check-mark {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/2705.svg");
}
//
// .twa-airplane {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2708.svg"); }
//
// .twa-envelope {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2709.svg"); }
//
// .twa-email {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2709.svg"); }
//
// .twa-raised-fist-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270a-1f3fb.svg"); }
//
// .twa-raised-fist-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270a-1f3fc.svg"); }
//
// .twa-raised-fist-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270a-1f3fd.svg"); }
//
// .twa-raised-fist-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270a-1f3fe.svg"); }
//
// .twa-raised-fist-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270a-1f3ff.svg"); }
//
// .twa-raised-fist {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270a.svg"); }
//
.twa-fist {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/270a.svg");
}
//
// .twa-raised-hand-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270b-1f3fb.svg"); }
//
// .twa-raised-hand-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270b-1f3fc.svg"); }
//
// .twa-raised-hand-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270b-1f3fd.svg"); }
//
// .twa-raised-hand-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270b-1f3fe.svg"); }
//
// .twa-raised-hand-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270b-1f3ff.svg"); }
//
// .twa-raised-hand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270b.svg"); }
//
// .twa-hand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270b.svg"); }
//
// .twa-victory-hand-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270c-1f3fb.svg"); }
//
// .twa-victory-hand-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270c-1f3fc.svg"); }
//
// .twa-victory-hand-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270c-1f3fd.svg"); }
//
// .twa-victory-hand-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270c-1f3fe.svg"); }
//
// .twa-victory-hand-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270c-1f3ff.svg"); }
//
// .twa-victory-hand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270c.svg"); }
//
// .twa-v {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270c.svg"); }
//
// .twa-writing-hand-type-1-2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270d-1f3fb.svg"); }
//
// .twa-writing-hand-type-3 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270d-1f3fc.svg"); }
//
// .twa-writing-hand-type-4 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270d-1f3fd.svg"); }
//
// .twa-writing-hand-type-5 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270d-1f3fe.svg"); }
//
// .twa-writing-hand-type-6 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270d-1f3ff.svg"); }
//
// .twa-writing-hand {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270d.svg"); }
//
// .twa-pencil {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270f.svg"); }
//
// .twa-pencil2 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/270f.svg"); }
//
// .twa-black-nib {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2712.svg"); }
//
// .twa-heavy-check-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2714.svg"); }
//
// .twa-heavy-multiplication-x {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2716.svg"); }
//
// .twa-latin-cross {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/271d.svg"); }
//
// .twa-star-of-david {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2721.svg"); }
//
// .twa-sparkles {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2728.svg"); }
//
// .twa-eight-spoked-asterisk {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2733.svg"); }
//
// .twa-eight-pointed-star {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2734.svg"); }
//
// .twa-eight-pointed-black-star {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2734.svg"); }
//
// .twa-snowflake {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2744.svg"); }
//
// .twa-sparkle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2747.svg"); }
//
// .twa-cross-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/274c.svg"); }
//
.twa-x {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/274c.svg");
}
//
// .twa-cross-mark-button {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/274e.svg"); }
//
// .twa-negative-squared-cross-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/274e.svg"); }
//
// .twa-question-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2753.svg"); }
//
// .twa-question {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2753.svg"); }
//
// .twa-white-question-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2754.svg"); }
//
// .twa-grey-question {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2754.svg"); }
//
// .twa-white-exclamation-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2755.svg"); }
//
// .twa-grey-exclamation {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2755.svg"); }
//
// .twa-exclamation-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2757.svg"); }
//
// .twa-exclamation {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2757.svg"); }
//
// .twa-heavy-heart-exclamation-mark-ornament {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2763.svg"); }
//
// .twa-red-heart {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2764.svg"); }
//
.twa-heart {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/2764.svg");
}
//
// .twa-heavy-plus-sign {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2795.svg"); }
//
// .twa-heavy-minus-sign {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2796.svg"); }
//
// .twa-heavy-division-sign {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2797.svg"); }
//
// .twa-right-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/27a1.svg"); }
//
// .twa-arrow-right {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/27a1.svg"); }
//
// .twa-curly-loop {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/27b0.svg"); }
//
// .twa-double-curly-loop {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/27bf.svg"); }
//
// .twa-loop {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/27bf.svg"); }
//
// .twa-right-arrow-curving-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2934.svg"); }
//
// .twa-arrow-heading-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2934.svg"); }
//
// .twa-right-arrow-curving-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2935.svg"); }
//
// .twa-arrow-heading-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2935.svg"); }
//
// .twa-asterisk {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2a-20e3.svg"); }
//
// .twa-left-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b05.svg"); }
//
// .twa-arrow-left {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b05.svg"); }
//
// .twa-up-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b06.svg"); }
//
// .twa-arrow-up {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b06.svg"); }
//
// .twa-down-arrow {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b07.svg"); }
//
// .twa-arrow-down {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b07.svg"); }
//
// .twa-black-large-square {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b1b.svg"); }
//
// .twa-white-large-square {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b1c.svg"); }
//
// .twa-white-medium-star {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b50.svg"); }
//
.twa-star {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/2b50.svg");
}
//
// .twa-heavy-large-circle {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/2b55.svg"); }
//
.twa-o {
  background-image: url("../../../../wwwroot/fe/img/twitteremojis/2b55.svg");
}
//
// .twa-zero {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/30-20e3.svg"); }
//
// .twa-wavy-dash {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/3030.svg"); }
//
// .twa-part-alternation-mark {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/303d.svg"); }
//
// .twa-one {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/31-20e3.svg"); }
//
// .twa-two {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/32-20e3.svg"); }
//
// .twa-circled-congratulate-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/3297.svg"); }
//
// .twa-congratulations {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/3297.svg"); }
//
// .twa-circled-secret-ideograph {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/3299.svg"); }
//
// .twa-secret {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/3299.svg"); }
//
// .twa-three {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/33-20e3.svg"); }
//
// .twa-four {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/34-20e3.svg"); }
//
// .twa-five {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/35-20e3.svg"); }
//
// .twa-six {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/36-20e3.svg"); }
//
// .twa-seven {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/37-20e3.svg"); }
//
// .twa-eight {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/38-20e3.svg"); }
//
// .twa-nine {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/39-20e3.svg"); }
//
// .twa-copyright {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/a9.svg"); }
//
// .twa-registered {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/ae.svg"); }
//
// .twa-shibuya {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/e50a.svg"); }
//
// .twa-shibuya-109 {
//   background-image: url("https://twemoji.maxcdn.com/2/svg/e50a.svg"); }
