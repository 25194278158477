/* --------------------------------

Nucleo Mini Web Font - nucleoapp.com/
License - nucleoapp.com/license/
Created using IcoMoon - icomoon.io

-------------------------------- */
@import "variables.less";
@import "mixins.less";

@font-face {
  font-family: "Nucleo Mini";
  src: url("@{nc-font-path}/nucleo-mini.eot");
  src:
    url("@{nc-font-path}/nucleo-mini.eot") format("embedded-opentype"),
    url("@{nc-font-path}/nucleo-mini.woff2") format("woff2"),
    url("@{nc-font-path}/nucleo-mini.woff") format("woff"),
    url("@{nc-font-path}/nucleo-mini.ttf") format("truetype"),
    url("@{nc-font-path}/nucleo-mini.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*------------------------
	base class definition
-------------------------*/

.@{nc-css-prefix}-mini {
  display: inline-block;
  font: normal normal normal @nc-font-size-base / 1 "Nucleo Mini";
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/

.@{nc-css-prefix}-mini.lg {
  font-size: 1.33333333em;
  vertical-align: -16%;
}

.@{nc-css-prefix}-mini.x2 {
  font-size: 2em;
}

.@{nc-css-prefix}-mini.x3 {
  font-size: 3em;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.@{nc-css-prefix}-mini.square,
.@{nc-css-prefix}-mini.circle {
  padding: @nc-padding-width;
  vertical-align: -16%;
  background-color: @nc-background-color;
}

.@{nc-css-prefix}-mini.circle {
  border-radius: 50%;
}

/*------------------------
  list icons
-------------------------*/

.@{nc-css-prefix}-ul {
  padding-left: 0;
  margin-left: @nc-li-width;
  list-style-type: none;

  > li {
    position: relative;
  }

  > li > .@{nc-css-prefix}-mini {
    position: absolute;
    left: -@nc-li-width / 2 - 0.5em;
    top: (2em / 14);
    text-align: center;

    &.lg {
      top: 0;
      left: -@nc-li-width / 2 - (4em / 14);
    }

    &.circle,
    &.square {
      top: (2em / 14) - @nc-padding-width;
      left: (-@nc-li-width / 2 - 0.5em) - @nc-padding-width;
    }
  }
}

/*------------------------
  spinning icons
-------------------------*/

.@{nc-css-prefix}-mini.spin {
  -webkit-animation: nc-icon-spin 2s infinite linear;
  -moz-animation: nc-icon-spin 2s infinite linear;
  animation: nc-icon-spin 2s infinite linear;
}

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

.@{nc-css-prefix}-mini.rotate-90 {
  .nc-rotate(90deg, 1);
}

.@{nc-css-prefix}-mini.rotate-180 {
  .nc-rotate(180deg, 2);
}

.@{nc-css-prefix}-mini.rotate-270 {
  .nc-rotate(270deg, 3);
}

.@{nc-css-prefix}-mini.flip-y {
  .nc-flip(-1, 1, 0);
}
.@{nc-css-prefix}-mini.flip-x {
  .nc-flip(1, -1, 2);
}

@import "icons";
