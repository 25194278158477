each(range(0px, 80px, 1), {
  .mr-@{value} {
    margin-right: @value;
  }
});

@margin-top: auto 85px;

each(@margin-top, {
  .mt-@{value} {
    margin-top: @value;
  }
});

each(range(0px, 60px, 1), {
  .mt-@{value} {
    margin-top: @value;
  }
});

@margin-bottom: auto 135px;

each(@margin-bottom, {
  .mb-@{value} {
    margin-bottom: @value;
  }
});

each(range(0px, 34px, 1), {
  .mb-@{value} {
    margin-bottom: @value;
  }
});

each(range(1px, 80px, 1), {
  .ml-@{value} {
    margin-left: @value;
  }
});

each(range(0px, 32px, 1), {
  .m-@{value} {
    margin: @value;
  }
});

each(range(0px, 60px, 1), {
  .p-@{value} {
    padding: @value;
  }
});

each(range(0px, 16px, 1), {
  .px-@{value} {
    padding-left: @value;
    padding-right: @value;
  }
});

each(range(0px, 50px, 1), {
  .pl-@{value} {
    padding-left: @value;
  }
});

each(range(0px, 40px, 1), {
  .pt-@{value} {
    padding-top: @value;
  }
});

each(range(0px, 50px, 1), {
  .pb-@{value} {
    padding-bottom: @value;
  }
});

each(range(0px, 20px, 1), {
  .r-@{value} {
    right: @value;
  }
});

each(range(0px, 90px, 1), {
  .pr-@{value} {
    padding-right: @value;
  }
});

each(range(0px, 12px, 1), {
  .b-r-@{value} {
    border-radius: @value;
  }
});

@height: 10px, 20px, 28px, 34px, 35px, 36px, 38px, 40px, 45px, 50px, 58px, 62px,
  86px;

each(@height, {
  .h-@{value} {
    height: @value;
  }
});

@line-height: 14px, 20px, 36px;

each(@line-height, {
  .l-h-@{value} {
    line-height: @value;
  }
});

.lh-1 {
  line-height: 1;
}

@min-height: 10px, 34px, 360px;

each(@min-height, {
  .min-h-@{value} {
    min-height: @value;
  }
});

@width: 10px, 20px, 24px, 28px, 36px, 44px, 96px, 154px, 162px, 180px, 206px,
  225px, 256px, 310px, 320px, 400px, 536px, 680px;

each(@width, {
  .w-@{value} {
    width: @value;
  }
});

.w-full {
  width: 100%;
}

.w-fit {
  width: fit-content;
  width: -moz-fit-content;
}

.h-full {
  height: 100%;
}

.h-fit {
  height: fit-content;
  height: -moz-fit-content;
}

@max-width: 100px, 345px, 420px, 450px, 460px, 550px, 900px;

each(@max-width, {
  .max-w-@{value} {
    max-width: @value;
  }
});

@min-width: 36px, 40px, 51px, 162px;

each(@min-width, {
  .min-w-@{value} {
    min-width: @value;
  }
});

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

.no-margin {
  margin: 0;
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}

each(range(-15px, 10px, 1), {
  .l-@{value} {
    left: @value;
  }
});

each(range(1px, 10px, 1), {
  .t-@{value} {
    top: @value;
  }
});

@padding-y: 8px, 10px, 16px, 18px;

each(@padding-y, {
  .py-@{value} {
    padding-top: @value;
    padding-bottom: @value;
  }
});
