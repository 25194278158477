#nothing-found {
  &:extend(.centered-content);
  flex-direction: column;

  .context {
    text-align: center;

    .info {
      max-width: 360px;
    }
  }
}
