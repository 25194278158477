dropdown-actions-menu {
  display: inline-flex;

  .gh-ui-actions-dd {
    display: inline-flex;

    .toggle {
      border-radius: 3px;
      color: fade(@brand-dark, 30%);
      cursor: pointer;

      &:hover,
      &:focus-visible {
        color: @brand-jira;
        background: @brand-lighter-blue;
      }

      &:focus,
      &:focus-visible {
        outline: none;
      }

      &.disabled {
        opacity: 0.8;
        cursor: no-drop;

        &:hover {
          color: fade(@brand-dark, 30%);
          opacity: 0.5;
        }
      }
    }
  }
}

body {
  .dropdown-menu {
    &.gh-actions-menu {
      overflow: hidden;
      will-change: filter; // hides dropdown-menu shadow in safari

      .actions {
        .action {
          position: relative;
          padding: 10px 20px;
          line-height: 1.2em;
          font-size: 15px;
          cursor: pointer;
          white-space: nowrap;

          &:hover,
          &:focus,
          &:focus-visible {
            background: @brand-lighter-blue;
            color: @brand-dark;
          }

          &:focus,
          &:focus-visible {
            outline: none;
          }

          &.danger {
            color: @brand-danger;
          }

          &.disabled {
            opacity: 0.5;
            cursor: no-drop;

            &:hover {
              background: none;
            }
          }

          &.bordered-top::before,
          &.bordered-bottom::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background: @brand-border;
          }

          &.bordered-top {
            &::before {
              top: 0;
            }
          }

          &.bordered-bottom {
            &::after {
              bottom: 0;
            }
          }

          .icon {
            margin-right: 5px;
          }

          &.w-s-normal {
            .w-s-normal;
          }
        }
      }
    }

    &.gh-actions-hover-menu {
      .actions {
        .action {
          position: relative;
          padding: 10px 20px;
          line-height: 1.2em;
          font-size: 15px;
          cursor: pointer;
          white-space: nowrap;

          &:hover,
          &:focus,
          &:focus-visible {
            background: @brand-lighter-blue;
            color: @brand-dark;
          }

          &:focus,
          &:focus-visible {
            outline: none;
          }

          &.danger {
            color: @brand-danger;
          }

          &.disabled {
            opacity: 0.5;
            cursor: no-drop;

            &:hover {
              background: none;
            }
          }

          &.bordered-top::before,
          &.bordered-bottom::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background: @brand-border;
          }

          &.bordered-top {
            &::before {
              top: 0;
            }
          }

          &.bordered-bottom {
            &::after {
              bottom: 0;
            }
          }

          .icon {
            margin-right: 5px;
          }

          &.w-s-normal {
            .w-s-normal;
          }

          .gh-font-icon-arrow-right-small.arrow-selector {
            right: -30px;
          }

          .gh-font-icon-arrow-right-small.arrow-selector-spanish-portuguese {
            right: -70px;
          }

          .gh-font-icon-arrow-right-small.arrow-selector-french-italian {
            right: -50px;
          }
          .gh-font-icon-arrow-right-small {
            right: -80px;
          }
        }
      }
    }
  }
}
