button[gh-button] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI-SANS-SERIF", Roboto,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: @regular;
  cursor: pointer;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
  }

  .content-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: center;

    [class^="gh-font-icon-"],
    [class*=" gh-font-icon-"] {
      align-self: center;
      font-size: 16px;
    }
  }

  &.iconed {
    &:not(.link) {
      .content-wrapper {
        .leading-icon {
          margin-right: 4px;
        }
      }
    }

    &.link {
      .content-wrapper {
        .leading-icon {
          margin-right: 1px;
        }
      }
    }
  }

  &.tiny {
    height: 24px;
    padding: 0 18px;
    border-radius: 12px;

    .content-wrapper {
      .text {
        line-height: 18px;
        font-size: 13px;
        letter-spacing: 0.25px;
      }
    }

    &.dropdown-toggle {
      padding-left: 14px;
      padding-right: 6px;

      .content-wrapper {
        .gh-font-icon-arrow-down-small {
          margin-left: 8px;
        }
      }
    }

    &.iconed {
      padding-left: 10px;
    }
  }

  &.small {
    height: 30px;
    padding: 0 20px;
    border-radius: 15px;

    .content-wrapper {
      .text {
        line-height: 18px;
        font-size: 14px;
        letter-spacing: 0.3px;
      }
    }

    &.dropdown-toggle {
      padding-right: 10px;

      .content-wrapper {
        .gh-font-icon-arrow-down-small {
          margin-left: 10px;
        }
      }
    }

    &.iconed {
      padding-left: 12px;
    }
  }

  &.large {
    height: 38px;
    padding: 0 26px;
    border-radius: 19px;

    .content-wrapper {
      .text {
        line-height: 22px;
        font-size: 16px;
        letter-spacing: 0.5px;
      }
    }

    &.dropdown-toggle {
      padding-right: 12px;

      .content-wrapper {
        .gh-font-icon-arrow-down-small {
          margin-left: 12px;
        }
      }
    }

    &.iconed {
      padding-left: 18px;
    }
  }

  &.link {
    height: 24px;
    padding: 0 8px;
    background: transparent;
    border: 2px solid transparent;
    border-radius: 4px;
    font-weight: @regular;
    color: @blue-80-v3;

    &.iconed {
      padding-left: 2px;
    }

    .content-wrapper {
      .text {
        line-height: 18px;
        font-size: 13px;
      }
    }

    &:hover {
      color: @blue-100-v3;

      .content-wrapper {
        .text {
          text-decoration: underline;
        }
      }
    }

    &:focus {
      border-color: @blue-20-v3;
    }
  }

  &.primary {
    background: #0057d7;
    border: none;
    color: @brand-white;

    &:hover {
      background: #003a7b;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px #d3eaff;
    }

    &:disabled {
      background: #c0cbdd;
    }
  }

  &.secondary {
    background: @brand-white;
    border: 1px solid #81acff;
    color: #021421;

    &:hover {
      border-color: #003a7b;
      background: rgba(68, 85, 115, 0.08);
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px #d3eaff;
    }

    &:disabled {
      border-color: #c0cbdd;
      color: #7485a2;
    }
  }

  &.destructive {
    background: #da555d;
    border: none;
    color: @brand-white;

    &:hover {
      background: #b14055;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px rgba(218, 85, 93, 0.3);
    }

    &:disabled {
      background: #ffb1b1;
    }
  }
}
