#add-kr-label {
  padding: 12px;
  background: #d3eaff;
  border-radius: 10px;

  .gh-font-icon-info {
    color: #75a2f7;
    font-size: 18px;
    align-items: flex-start;
  }

  .add-kr-info {
    flex: 1;

    h5 {
      font-weight: @medium;
      font-size: 15px;
      line-height: 20px;
      color: @ink-100-v2;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      color: @ink-80-v2;
    }
  }

  .gh-b {
    &.oval {
      &.gh-royal-80-outline {
        height: 30px;
        font-weight: @medium;
        font-size: 14px;
        line-height: 16px;
        padding: 5px 20px;
        margin-right: 0;
        border-radius: 38px;
      }
    }
  }
}
