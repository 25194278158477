.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.grid {
  width: 50%;
  top: 125px;
  left: 0;
  overflow: auto;
  padding: 12px 20px;
  background: @brand-white;

  @media print {
    position: relative;
    top: 0;
    overflow: visible;
  }

  thead {
    border-bottom: 1px solid darken(@brand-light, 3%);
  }

  tr {
    border-top: 1px solid darken(@brand-light, 3%);

    &:first-of-type {
      border-top: none;
    }
  }

  .grid-filter {
    margin-bottom: 15px;
    height: 40px;

    .toggler {
      background: @brand-subtle;
      color: #fff;
      font-size: 14px;
      line-height: 30px;
      width: 24px;
      height: 24px;
      border-radius: 30px;
      float: left;
      text-align: center;
      margin-right: 10px;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }

    input {
      float: left;
      width: 330px;
      margin-top: -4px;
    }
  }

  table {
    width: 100%;
  }

  .mask {
    position: fixed;
    top: 144px;
    bottom: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    background: fade(@brand-dark, 20%);
    z-index: 5;
    display: none;

    .hover {
      text-align: center;
      position: absolute;
      width: 100%;
      top: 50%;
      margin-top: -80px;
      opacity: 0;
      text-transform: uppercase;
      .transition(0.1s);
      font-weight: @medium;
      color: fade(@brand-dark, 20%);

      .icon {
        margin-bottom: 20px;
        font-size: 80px;
      }
    }

    &:hover .hover {
      opacity: 1;
    }
  }

  &.masked {
    .mask {
      display: block;
    }
  }

  .no-items {
    text-align: center;
    margin-top: 50px;
    float: left;
    width: 100%;

    .main {
      color: lighten(@brand-dark, 65%);
      font-size: 130px;
    }

    h1 {
      font-size: 30px;
      margin-top: 40px;
      font-weight: @thin;
      color: lighten(@brand-dark, 47%);
    }

    h2 {
      font-size: 16px;
      line-height: 26px;
      font-weight: @light;
      margin-top: 20px;
      color: lighten(@brand-dark, 55%);
    }
  }

  th {
    .fw-500;
    text-align: left;
    font-size: 13px;
    line-height: 18px;
    padding: 10px 15px;
    vertical-align: top;
    color: #7485a2;

    div {
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &.sorted {
      font-weight: @bold;
      color: @brand-fog;

      div {
        &:after {
          margin-left: 5px;
          opacity: 0.5;
          content: "\e252";
          position: relative;
          top: 1px;
          display: inline-block;
          font-family: "Glyphicons Halflings";
          font-style: normal;
          font-size: 10px;
          font-weight: 400;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
        }

        &.up {
          &:after {
            content: "\e253";
          }
        }
      }
    }
  }
}

.grid-item {
  color: @brand-dark;

  .title {
    h1 {
      cursor: pointer;
    }
  }

  &:hover {
    background: saturate(fade(@brand-dark, 3%), 50%);
  }

  &.selected {
    background: saturate(fade(@brand-dark, 5%), 80%);

    td.icon {
      .icon {
        background: lighten(@brand-dark, 68%);
        color: fade(@brand-dark, 40%);
      }
    }
  }

  td {
    padding: 25px 15px 25px 15px;
    vertical-align: top;
    font-size: 13px;
    color: lighten(@brand-dark, 10%);

    &:last-child {
      border-right: none;
    }

    &.c-name {
      .title {
        position: relative;
      }

      .gh-badge,
      .goal-owner-name {
        margin-left: 26px;
      }
    }

    .drop-zone {
      position: absolute;
      left: -10px;
      top: -10px;
      bottom: -10px;
      right: -10px;
      background: fade(@brand-info, 20%);
      border: 1px dashed @brand-info;
      z-index: 2;
      border-radius: 5px;
      list-style: none;

      &.dndDragover {
        background: fade(@brand-primary, 20%);
        border-color: @brand-primary;
        box-shadow: 0 0 20px fade(@brand-primary, 30%);
      }
    }

    &.c-ownerId {
      padding: 25px 0px 25px 15px;
    }

    &.c-attainment {
      min-width: 10%;
    }

    &.c-description,
    &.c-textarea {
      min-width: 512px;
    }

    &.c-metricsCount {
      white-space: nowrap;
    }

    &.c-krs,
    &.c-krsAndChildObjs {
      min-width: 450px;
      padding-top: 13px;
    }

    &.c-confidence {
      min-width: 70px;
    }

    .krs-child-goals {
      font-size: 14px;

      .okr {
        position: relative;
        line-height: 18px;

        td {
          padding: 12px 0;
          border-right: 0;
          min-width: 0;

          &.okr-title-td {
            position: relative;
          }

          &.avatar-group {
            padding-left: 15px;
          }

          &.confidence-td {
            width: 100px;
            max-width: 100px;
            min-width: 100px;
            text-align: right;
            padding-left: 5px;
          }

          &.attainment-td {
            padding-top: 14px;
            width: 45px;
            min-width: 45px;
            max-width: 45px;
            text-align: right;
          }
        }

        .gh-b {
          position: absolute;
          right: 0;
          z-index: 2;
          padding: 1px 5px;
          line-height: 12px;
        }

        .drop-zone {
          top: 2px;
          bottom: 2px;
          left: -8px;
          right: 0;
        }

        .owner {
          display: inline-block;

          .picture {
            display: inline-block;
            width: 22px;
            height: 22px;
            z-index: 2;
            border-radius: 20px;

            img {
              width: 100%;
              border-radius: 3px;
            }
          }
        }

        .okr-name {
          font-size: 14px;
          line-height: 18px;
          padding-right: 10px;

          &:hover {
            cursor: pointer;
          }

          &.private {
            cursor: initial;
            opacity: 0.7;
          }
        }

        .text {
          color: darken(@brand-subtle, 10%);
          position: relative;
          display: block;

          &:hover {
            color: @brand-subtle;
          }
        }

        &:first-child td {
          border-top: 0;
        }

        .metric-confidence {
          text-align: right;
        }

        .okr-attainment {
          font-weight: @regular;
          color: @brand-dark;
          text-align: right;

          .only-color-dot {
            width: 10px;
            height: 10px;
            border-radius: 10px;
          }
        }
      }

      .dropdown-toggle {
        cursor: pointer;
      }

      .gh-m-1 {
        cursor: pointer;
      }

      confidence-preview {
        width: 100%;
        overflow: hidden;
      }
    }

    &.icon {
      width: 75px;
      position: relative;

      .icon {
        font-size: 24px;
        width: 40px;
        background: lighten(@brand-dark, 73%);
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 30px;
        color: fade(@brand-dark, 20%);
      }

      img {
        position: absolute;
        left: 38px;
        top: 37px;
        width: 24px;
        height: 24px;
        border-radius: 20px;
      }
    }

    .meta {
      font-size: 11px;
      color: fade(@brand-dark, 50%);
    }

    .custom-field {
      &.processing {
        position: relative;
        opacity: 0.6;

        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 2;
        }
      }

      .checkbox {
        .icon {
          width: 30px;
          height: 30px;
          background: @brand-primary;
          text-align: center;
          line-height: 30px;
          font-size: 13px;
          color: #fff;
          border-radius: 15px;

          & + .icon {
            display: none;
            background: @brand-subtle;
          }
        }

        &.negative {
          .icon {
            display: none;

            & + .icon {
              display: block;
            }
          }
        }
      }
    }

    &.goal-workflow-status {
      min-width: 40px;
      width: 40px;
      text-align: center;
    }
  }

  td[class^="c-designScore"] {
    width: 150px;
  }

  .title {
    font-weight: @light;
    min-width: 220px;
    color: lighten(@brand-dark, 10%);

    h1 {
      font-weight: @regular;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      word-break: break-word;

      &[ng-click] {
        cursor: pointer;
        color: saturate(lighten(@brand-dark, 20%), 20%);

        &:hover {
          color: saturate(lighten(@brand-dark, 30%), 30%);
        }
      }
    }

    h2 {
      font-family: Arial;
      font-size: 12px;
      line-height: 17px;
      margin-top: 3px;
      color: fade(@brand-dark, 40%);
      word-break: break-word;

      .gh-badge {
        line-height: 1.1em;
        top: -1px;
        position: relative;
        font-size: 0.9em;
        margin-right: 5px;
      }
    }
  }

  .gh-tags {
    margin-top: 10px;
    margin-left: 26px;

    .tag {
      .gh-tag-label {
        background: @brand-light;

        &:hover {
          background: @ink-20-v3;
          color: @brand-white;
          cursor: pointer;
        }
      }

      &.selected {
        .gh-tag-label {
          background: @blue-80-v3;
          color: @brand-white;

          &:hover {
            background: @blue-100-v3;
          }
        }
      }
    }

    &.pop {
      position: relative;
      z-index: 3;

      .tag {
        box-shadow: 0 2px 6px fade(@brand-jira, 25%);
      }
    }
  }

  .meta {
    min-width: 100px;
  }

  .responsible {
    width: 20%;
    line-height: 30px;
    font-weight: @light;
    font-size: 12px;
    color: fade(@brand-dark, 50%);
    font-family: Arial, sans-serif;
  }

  .confidence {
    width: 75px;
    color: @brand-grey;

    .confidence-bar {
      height: 3px;
      background: @brand-jira;
      border-radius: 12px;

      &.good {
        background: @brand-primary;
      }

      &.bad {
        background: @brand-warning;
      }
    }
  }

  .design-score {
    text-align: center;
    min-width: 30px;
    font-size: 17px;
    position: relative;
    font-weight: @medium;
    color: @brand-dark;
  }

  .attainment {
    min-width: 30px;
    font-size: 17px;
    position: relative;
    font-weight: @medium;
    color: @brand-dark;

    .numeric-attainment {
      gap: 8px;
    }

    .aggregate-progress {
      background: @brand-subtle;
      position: relative;
      padding: 0 6px;
      height: 20px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      border-radius: 13px;
      display: inline-block;
    }

    .only-color {
      width: 20px;
      height: 20px;
      border-radius: 15px;
      display: inline-block;
    }

    .only-color-aggregated {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: relative;
      margin-left: 5px;
      border-radius: 30px;
    }
  }

  .goal-workflow-status {
    font-size: 14px;
    color: #fff;

    .goal-status-icon {
      .icon-frame {
        font-size: 10px;
        display: inline-block;
        height: 20px;
        width: 20px;
        line-height: 20px;
        border-radius: 10px;

        &.draft {
          background: @brand-subtler;
        }

        &.in-review {
          background: @brand-lilac;
          font-size: 12px;
        }

        &.not-enough-reviewers {
          font-size: 11px;
          background: #e3541b;
        }

        &.approved {
          background: @brand-primary;
        }
      }
    }
  }

  .license-column {
    width: 20%;
  }

  .user-license-type {
    .license-icon {
      font-size: 12px;
    }

    .license-title {
      font-size: 14px;
      margin-left: 10px;
      padding: 0;
      word-break: break-word;
    }
  }
}

@media all and (max-width: 1360px) {
  .grid {
    padding: 20px;
  }

  .grid-item {
    td.title {
      width: auto;
    }
  }
}
