.form-email-verification {
  text-align: center;

  img {
    margin-bottom: 42px;
    margin-left: -4px;
  }

  h3 {
    font-family: "SF Display Pro", "Helvetica Neue", "SegoeUI", sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #0d232f;
    margin-bottom: 18px;
  }

  p {
    font-family: "SF Display Pro", "Helvetica Neue", "SegoeUI", sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: #5d6f8a;
    max-width: 290px;
    margin: 0 auto 30px;

    strong {
      margin-left: 5px;
    }
  }

  .letter-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.error {
      input {
        border: 1px solid @magenta-90;
      }
    }

    .separator {
      height: 1px;
      width: 8px;
      background-color: #5d6f8a;
      margin: 0 8px 0 2px;
    }

    input {
      display: inline-block;
      width: 32px;
      height: 34px;
      padding: 0;
      margin-right: 6px;
      border: 1px solid #c1d3e1;
      text-align: center;
      border-radius: 4px;
      font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;

      &:last-of-type {
        margin-right: 0;
      }

      &::placeholder {
        font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
        font-size: 14px;
        color: #c8d1df;
        text-transform: uppercase;
        opacity: 0;
        // transition: 0.3s all ease-in-out;
      }

      &:focus {
        border: 1px solid #37b9ff;
      }

      &:focus::placeholder {
        opacity: 1;
      }
    }
  }

  .error span {
    display: block;
    margin-top: 7px;
    font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
    font-size: 12px;
    color: @magenta-90;
    text-align: left;
  }

  button {
    display: flex;
    justify-content: center;
    height: 46px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 14px;
    border: 1px solid @blue-100;
    border-radius: 90px;
    background: @blue-100;
    color: #fff;
    align-items: center;
    font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
    font-size: 14px;
    transition: 0.3s all ease-in-out;

    &:disabled {
      border: 1px solid rgba(200, 209, 223, 0.5);
      color: @black-50;
      background: #fff;
    }
  } // end of button

  .link-blue {
    display: inline-block;
    color: @blue-100;
    cursor: pointer;
    font-size: 12px;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .resend-code {
    border-top: 1px solid rgba(200, 209, 223, 0.5);
    margin: 39px -40px -48px;
    padding-top: 25px;

    p {
      font-family: "SF Display Pro", "Helvetica Neue", "SegoeUI", sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: @brand-modal-grey-darker;

      span {
        margin-left: 5px;
      }
    }
  }
}

verify-email {
  .link-blue {
    color: @blue-100;
    margin-left: 5px;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .profile-email-verification {
    .verify,
    .verified {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;

      [class^="gh-font-icon-"],
      [class*="gh-font-icon-"] {
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }

  .verify {
    color: @blue-100;
  }

  .verified {
    color: @teal-200;
  }
}
