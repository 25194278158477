.gh-tags {
  &:after {
    content: "";
    width: 100%;
    clear: both;
    display: block;
  }

  .tag {
    margin: 0 6px 4px 0;
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;
    height: 24px;
    float: left;
    background: @brand-light;

    .gh-tag-label {
      padding: 0 7px 0 8px;
      background: @brand-light;
      color: @brand-dark;
      display: inline-block;
      float: left;
      font-weight: @medium;
      line-height: 23px;
      cursor: pointer;
      height: 24px;
      overflow: hidden;
      max-width: 385px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background: @brand-jira;
        color: #fff;
      }
    }

    .gh-remove-tag {
      display: inline-block;
      height: 24px;
      padding: 6px 9px 4px 8px;
      background: darken(@brand-light, 6%);
      font-size: 10px;
      line-height: 14px;

      &:hover {
        background: @brand-dark;
        cursor: pointer;
        color: #fff;
      }
    }
  }
}

tags-input {
  .tags {
    -webkit-appearance: none;
  }
}

.gh-tag {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 3px;
  border-radius: 3px;
  padding: 4px 9px;
  line-height: 16px;
  font-size: 14px;
  color: @brand-subtle;
  background: @brand-light;
  cursor: pointer;

  &:hover {
    background: darken(@brand-light, 10%);
  }

  .gh-delete-tag {
    display: inline-block;
    margin-left: 8px;
    font-size: 14px;
    .icon {
      font-size: 10px;
      opacity: 0.7;
    }

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }
}
