@import (reference) "../../../less/_variables.less";

#user-profile-panel {
  margin: 200px auto 0px auto;
  display: flex;
  flex-direction: row;
  width: 600px;
  background: @brand-white;
  box-shadow: 0px 0px 15px rgba(63, 87, 110, 0.3);
  border-radius: 7px;

  div.menu-container {
    background: #ecf1f6;
    width: 100%;
    max-width: 165px;
    border-radius: 7px 0px 0px 7px;

    div.heading {
      font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
      font-size: 11px;
      color: @black-70;
      font-weight: @mediumbold;
      font-style: normal;
      line-height: 12px;
      padding: 17px;
      text-align: left;
      text-transform: uppercase;
      border-bottom: 1px solid @ink-30;
    }

    .item li {
      margin-top: 0px;

      a {
        width: 100%;
        padding: 12px;
        font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: @semibold;
        line-height: 18px;
        color: @brand-grey2;
        border-bottom: 1px solid @ink-30;
        display: flex;
        align-items: center;

        gh-icon {
          margin-right: 10px;
          font-size: 16px;
        }
      }

      &:not(.active) a:hover {
        background: @brand-white;
        color: @ink-100;
      }

      &.active a {
        color: @ink-100;
        background-color: @brand-white;
      }
    }
  }

  .title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    width: 100%;
    max-width: 80%;
    padding-top: 32px;
    padding-bottom: 48px;
    padding-left: 24px;
    padding-right: 24px;

    h3 {
      text-align: center;
      font-size: 27px;
      font-weight: 600;
      line-height: 34px;
    }

    h2 {
      overflow-wrap: break-word;
    }

    h1 {
      font-size: 14px;
      font-weight: 400;
    }

    form {
      .form-group label {
        color: @brand-grey2;
        font-size: 12px;
        font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
        font-style: normal;
        font-weight: @semibold;
        line-height: 16px;
        text-align: left;
      }

      input.form-control {
        color: @ink-100;
        border-radius: 4px;
        padding: 8px;
        font-size: 14px;
        height: 38px;
        border: 1px solid @ink-40;

        &:read-only {
          color: @black-70;
        }
      }

      small.text-muted {
        font-weight: @semibold;
        color: @brand-danger;
        display: inline-block;
      }

      button[gh-button] {
        &.large .content-wrapper .text {
          font-size: 14px;
        }

        &.full-width {
          .content-wrapper .text {
            flex-grow: 1;
          }
          width: 100%;
        }
      }

      label[for="email"] {
        display: flex;
        justify-content: space-between;
      }
    }

    &.user-data {
      .edit-user-avatar {
        text-align: center;
        display: block;
        position: relative;

        &:hover {
          opacity: 0.6;
        }

        span.edit-icon {
          padding: 6px;
          color: @black-50;
          font-size: 19px;
          border-radius: 100%;
          position: absolute;
          top: 45px;
          right: 120px;
          background: @brand-white;
          box-shadow: 0 3px 7px @brand-border;
        }
      }

      h2 {
        font-family: "SF Pro Display", "Helvetica Neue", "SegoeUI", sans-serif;
        font-size: 27px;
        font-style: normal;
        font-weight: @mediumbold;
        line-height: 34px;
        letter-spacing: 0px;
        padding: 5px 0;
      }
    }

    &.user-password {
      span.notice {
        line-height: 20px;
        font-size: 14px;
        color: @ink-90 !important;
        margin-top: 15px;
      }

      div.form-container {
        position: relative;

        form {
          div.form-group {
            position: relative;
          }

          a {
            position: absolute;
            cursor: pointer;
            font-size: 16px;
            right: 23px;
            top: 34px;
            color: @brand-dark;
          }
        }

        div.password-rules-panel {
          display: none;
          visibility: hidden;
          position: absolute;
          width: 270px;
          border-radius: 7px;
          top: 7px;
          right: -281px;
          background: @brand-white;
          padding: 20px;
          box-shadow: 0 3px 7px @brand-border;

          &.visible {
            display: block;
            visibility: visible;
          }

          ul.rules {
            li {
              padding: 3px 0px;
              font-size: 12px;

              ul {
                margin-left: 25px;
              }

              span.password-icon {
                font-size: 12px;
                padding: 2px;
                color: @brand-white;
                border-radius: 100%;
                margin-right: 10px;
                display: none;
                visibility: hidden;

                &.visible {
                  display: inline-block;
                  visibility: visible;
                }

                &.gh-font-icon-check {
                  background: @brand-green;
                }

                &.gh-font-icon-close-medium {
                  background: @brand-danger;
                }
              }
            }
          }
        }
      }
    }

    &.user-language {
      .radio {
        margin-top: 20px;
        margin-bottom: 20px;

        input {
          width: 20px;
          height: 20px;
          margin-top: 0px;
        }
        label {
          span {
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
