.gh-box {
  border: 1px solid darken(@brand-light, 3%);
  border-radius: 6px;
  clear: both;
  width: 100%;

  .gh-box-section {
    padding: 25px 25px;
    border-top: 1px solid darken(@brand-light, 3%);
    position: relative;

    &.narrow {
      padding: 15px 25px;
    }

    &.first {
      border-top: 0;
    }

    .gh-box-section-title {
      font-size: 1em;
      font-weight: @medium;

      &.subtle {
        font-weight: @regular;
        font-style: italic;
      }
    }
  }
}
