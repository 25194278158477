@import (reference) "../../../less/_variables.less";
#first-okr-guide {
  .goals-guide {
    position: absolute;
    top: 50px;
    left: 80px;
    background-color: @blue-80-v2;
    width: 300px;
    border-radius: 5px;
    visibility: hidden;
    z-index: 2;

    &::before {
      content: " ";
      position: absolute;
      left: 34px;
      top: -10px;
      transform: rotate(90deg);

      border-right: 8px solid @blue-80-v2;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }

  .home-guide {
    position: absolute;
    top: 50px;
    left: 8px;
    background-color: @blue-80-v2;
    width: 300px;
    border-radius: 5px;
    visibility: hidden;

    &::before {
      content: " ";
      position: absolute;
      left: -7px;
      top: 18px;
      transform: rotate(-0deg);

      border-right: 8px solid @blue-80-v2;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }

  .guide-step {
    display: flex;
    align-items: flex-start;
    padding: 16px 20px;

    color: @grey-10;
    font-size: 11px;
    line-height: 15px;

    .title {
      font-weight: @bold;
    }

    .description {
      margin-bottom: 12px;
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        color: @brand-black;
      }
    }

    .gh-font-icon-close-medium {
      cursor: pointer;
      opacity: 0.7;
      font-size: 18px;
    }
  }
}
