.suggestion-tree {
  position: absolute;
  max-height: 280px;
  padding: 5px 0;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 7px 10px 0 rgba(9, 30, 66, 0.25);
  width: 100%;
  z-index: 999;
  background-color: @brand-white;
  overflow-y: auto;

  .session-row {
    width: 100%;

    .session-title {
      display: flex;
      color: @ink-50-v2;
      text-transform: uppercase;
      padding: 5px 10px;
      font-size: 11px;
      line-height: 13px;

      .session-name {
        margin-left: 3px;
      }
    }

    .goal-row {
      width: 100%;
      cursor: pointer;

      .main {
        width: 100%;
        padding: 0 10px;
        display: flex;
        align-items: center;
      }

      .data,
      .sub-row {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
      }

      .sub-row {
        padding: 0 10px;
      }

      .empty {
        height: 16px;
        min-width: 16px;
      }

      .left-side {
        display: flex;
        align-items: center;
        overflow: hidden;
      }

      .sub-row {
        padding-left: 45px;
        width: 100%;
      }

      .text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .selected {
        background: @black-10;
      }

      .gh-icon {
        min-width: 16px;
        margin-right: 5px;
      }
    }
  }
}
