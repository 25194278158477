@import (reference) "../../../../less/_variables.less";

dynamic-assignee-tooltip {
  position: absolute;
  min-width: 200px;
  padding-top: 4px;

  .arrow-up {
    position: relative;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid @ink-80-v2;
    left: 18px;
  }

  ul {
    background-color: @ink-80-v2;
    border-radius: 4px;
    overflow-y: auto;
    padding: 0 12px;
    max-height: 365px;
    width: 220px;

    li {
      margin: 11px auto;
      color: @brand-white;
    }
  }
}
