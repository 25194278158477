@import "./variables";

@font-face {
  font-family: "@{icomoon-font-family}";
  src: url("@{icomoon-font-path}/@{icomoon-font-family}.ttf?tk5qa2")
      format("truetype"),
    url("@{icomoon-font-path}/@{icomoon-font-family}.woff?tk5qa2")
      format("woff"),
    url("@{icomoon-font-path}/@{icomoon-font-family}.svg?tk5qa2#@{icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="gh-font-icon-"],
[class*=" gh-font-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "@{icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gh-font-icon-add-comment {
  &:before {
    content: @gh-font-icon-add-comment;
  }
}
.gh-font-icon-add-from-marketplace {
  &:before {
    content: @gh-font-icon-add-from-marketplace;
  }
}
.gh-font-icon-align-bottom {
  &:before {
    content: @gh-font-icon-align-bottom;
    color: #283d5c;
  }
}
.gh-font-icon-align-center {
  &:before {
    content: @gh-font-icon-align-center;
  }
}
.gh-font-icon-align-left {
  &:before {
    content: @gh-font-icon-align-left;
  }
}
.gh-font-icon-align-middle {
  &:before {
    content: @gh-font-icon-align-middle;
    color: #283d5c;
  }
}
.gh-font-icon-align-right {
  &:before {
    content: @gh-font-icon-align-right;
  }
}
.gh-font-icon-align-top {
  &:before {
    content: @gh-font-icon-align-top;
  }
}
.gh-font-icon-alignment-view {
  &:before {
    content: @gh-font-icon-alignment-view;
  }
}
.gh-font-icon-announcements {
  &:before {
    content: @gh-font-icon-announcements;
  }
}
.gh-font-icon-arrow-alt-right {
  &:before {
    content: @gh-font-icon-arrow-alt-right;
  }
}
.gh-font-icon-arrow-big {
  &:before {
    content: @gh-font-icon-arrow-big;
  }
}
.gh-font-icon-arrow-down-small {
  &:before {
    content: @gh-font-icon-arrow-down-small;
  }
}
.gh-font-icon-arrow-right-small {
  &:before {
    content: @gh-font-icon-arrow-right-small;
  }
}
.gh-font-icon-arrow-up-small {
  &:before {
    content: @gh-font-icon-arrow-up-small;
  }
}
.gh-font-icon-arrows-in {
  &:before {
    content: @gh-font-icon-arrows-in;
  }
}
.gh-font-icon-arrows-out {
  &:before {
    content: @gh-font-icon-arrows-out;
  }
}
.gh-font-icon-attachment {
  &:before {
    content: @gh-font-icon-attachment;
  }
}
.gh-font-icon-attention {
  &:before {
    content: @gh-font-icon-attention;
  }
}
.gh-font-icon-activity-history {
  &:before {
    content: @gh-font-icon-activity-history;
  }
}
.gh-font-icon-automation {
  &:before {
    content: @gh-font-icon-automation;
  }
}
.gh-font-icon-award {
  &:before {
    content: @gh-font-icon-award;
  }
}
.gh-font-icon-back {
  &:before {
    content: @gh-font-icon-back;
  }
}
.gh-font-icon-binocular {
  &:before {
    content: @gh-font-icon-binocular;
  }
}
.gh-font-icon-book {
  &:before {
    content: @gh-font-icon-book;
  }
}
.gh-font-icon-branding {
  &:before {
    content: @gh-font-icon-branding;
  }
}
.gh-font-icon-calendar {
  &:before {
    content: @gh-font-icon-calendar;
  }
}
.gh-font-icon-caution {
  &:before {
    content: @gh-font-icon-caution;
  }
}
.gh-font-icon-chart {
  &:before {
    content: @gh-font-icon-chart;
  }
}
.gh-font-icon-chat {
  &:before {
    content: @gh-font-icon-chat;
  }
}
.gh-font-icon-check-small {
  &:before {
    content: @gh-font-icon-check-small;
  }
}
.gh-font-icon-check {
  &:before {
    content: @gh-font-icon-check;
  }
}
.gh-font-icon-circle {
  &:before {
    content: @gh-font-icon-circle;
  }
}
.gh-font-icon-clock {
  &:before {
    content: @gh-font-icon-clock;
  }
}
.gh-font-icon-close-big {
  &:before {
    content: @gh-font-icon-close-big;
  }
}
.gh-font-icon-close-medium {
  &:before {
    content: @gh-font-icon-close-medium;
  }
}
.gh-font-icon-close-menu {
  &:before {
    content: @gh-font-icon-close-menu;
  }
}
.gh-font-icon-colapse-nav {
  &:before {
    content: @gh-font-icon-colapse-nav;
  }
}
.gh-font-icon-color-star-blackstroke .path1 {
  &:before {
    content: @gh-font-icon-color-star-blackstroke-path1;
  }
}
.gh-font-icon-color-star-blackstroke .path2 {
  &:before {
    content: @gh-font-icon-color-star-blackstroke-path2;
  }
}
.gh-font-icon-color-star-orangestroke .path1 {
  &:before {
    content: @gh-font-icon-color-star-orangestroke-path1;
  }
}
.gh-font-icon-color-star-orangestroke .path2 {
  &:before {
    content: @gh-font-icon-color-star-orangestroke-path2;
  }
}
.gh-font-icon-comments {
  &:before {
    content: @gh-font-icon-comments;
  }
}
.gh-font-icon-consulting {
  &:before {
    content: @gh-font-icon-consulting;
  }
}
.gh-font-icon-create-new {
  &:before {
    content: @gh-font-icon-create-new;
  }
}
.gh-font-icon-credit-card {
  &:before {
    content: @gh-font-icon-credit-card;
  }
}
.gh-font-icon-custom-field {
  &:before {
    content: @gh-font-icon-custom-field;
  }
}
.gh-font-icon-done {
  &:before {
    content: @gh-font-icon-done;
  }
}
.gh-font-icon-download {
  &:before {
    content: @gh-font-icon-download;
  }
}
.gh-font-icon-drag-drop {
  &:before {
    content: @gh-font-icon-drag-drop;
  }
}
.gh-font-icon-edit {
  &:before {
    content: @gh-font-icon-edit;
  }
}
.gh-font-icon-email {
  &:before {
    content: @gh-font-icon-email;
  }
}
.gh-font-icon-export-excel {
  &:before {
    content: @gh-font-icon-export-excel;
  }
}
.gh-font-icon-export {
  &:before {
    content: @gh-font-icon-export;
  }
}
.gh-font-icon-external-link {
  &:before {
    content: @gh-font-icon-external-link;
  }
}
.gh-font-icon-failed {
  &:before {
    content: @gh-font-icon-failed;
  }
}
.gh-font-icon-favorite {
  &:before {
    content: @gh-font-icon-favorite;
  }
}
.gh-font-icon-filter {
  &:before {
    content: @gh-font-icon-filter;
  }
}
.gh-font-icon-fit-to-screen {
  &:before {
    content: @gh-font-icon-fit-to-screen;
  }
}
.gh-font-icon-gh-chart {
  &:before {
    content: @gh-font-icon-gh-chart;
  }
}
.gh-font-icon-gh-pie {
  &:before {
    content: @gh-font-icon-gh-pie;
  }
}
.gh-font-icon-grid-view {
  &:before {
    content: @gh-font-icon-grid-view;
  }
}
.gh-font-icon-help {
  &:before {
    content: @gh-font-icon-help;
  }
}
.gh-font-icon-hidden {
  &:before {
    content: @gh-font-icon-hidden;
  }
}
.gh-font-icon-home {
  &:before {
    content: @gh-font-icon-home;
  }
}
.gh-font-icon-image {
  &:before {
    content: @gh-font-icon-image;
  }
}
.gh-font-icon-info {
  &:before {
    content: @gh-font-icon-info;
  }
}
.gh-font-icon-inline {
  &:before {
    content: @gh-font-icon-inline;
  }
}
.gh-font-icon-install {
  &:before {
    content: @gh-font-icon-install;
  }
}
.gh-font-icon-integrations {
  &:before {
    content: @gh-font-icon-integrations;
  }
}
.gh-font-icon-jira {
  &:before {
    content: @gh-font-icon-jira;
  }
}
.gh-font-icon-kanban {
  &:before {
    content: @gh-font-icon-kanban;
  }
}
.gh-font-icon-key {
  &:before {
    content: @gh-font-icon-key;
  }
}
.gh-font-icon-kr {
  &:before {
    content: @gh-font-icon-kr;
  }
}
.gh-font-icon-license {
  &:before {
    content: @gh-font-icon-license;
  }
}
.gh-font-icon-link {
  &:before {
    content: @gh-font-icon-link;
  }
}
.gh-font-icon-list-bullet {
  &:before {
    content: @gh-font-icon-list-bullet;
  }
}
.gh-font-icon-list-numbers {
  &:before {
    content: @gh-font-icon-list-numbers;
  }
}
.gh-font-icon-list-view {
  &:before {
    content: @gh-font-icon-list-view;
  }
}
.gh-font-icon-live-demo {
  &:before {
    content: @gh-font-icon-live-demo;
  }
}
.gh-font-icon-make-copy {
  &:before {
    content: @gh-font-icon-make-copy;
  }
}
.gh-font-icon-manage {
  &:before {
    content: @gh-font-icon-manage;
  }
}
.gh-font-icon-map {
  &:before {
    content: @gh-font-icon-map;
  }
}
.gh-font-icon-menu {
  &:before {
    content: @gh-font-icon-menu;
  }
}
.gh-font-icon-minus {
  &:before {
    content: @gh-font-icon-minus;
  }
}
.gh-font-icon-move {
  &:before {
    content: @gh-font-icon-move;
  }
}
.gh-font-icon-multiselect {
  &:before {
    content: @gh-font-icon-multiselect;
  }
}
.gh-font-icon-new-list {
  &:before {
    content: @gh-font-icon-new-list;
  }
}
.gh-font-icon-new-okr {
  &:before {
    content: @gh-font-icon-new-okr;
  }
}
.gh-font-icon-not-restricted {
  &:before {
    content: @gh-font-icon-not-restricted;
  }
}
.gh-font-icon-note {
  &:before {
    content: @gh-font-icon-note;
  }
}
.gh-font-icon-notifications {
  &:before {
    content: @gh-font-icon-notifications;
  }
}
.gh-font-icon-objective {
  &:before {
    content: @gh-font-icon-objective;
  }
}
.gh-font-icon-open-menu {
  &:before {
    content: @gh-font-icon-open-menu;
  }
}
.gh-font-icon-organization {
  &:before {
    content: @gh-font-icon-organization;
  }
}
.gh-font-icon-parent-child-view {
  &:before {
    content: @gh-font-icon-parent-child-view;
  }
}
.gh-font-icon-parent-view {
  &:before {
    content: @gh-font-icon-parent-view;
  }
}
.gh-font-icon-pdf {
  &:before {
    content: @gh-font-icon-pdf;
  }
}
.gh-font-icon-performance {
  &:before {
    content: @gh-font-icon-performance;
  }
}
.gh-font-icon-pie {
  &:before {
    content: @gh-font-icon-pie;
  }
}
.gh-font-icon-plus {
  &:before {
    content: @gh-font-icon-plus;
  }
}
.gh-font-icon-process {
  &:before {
    content: @gh-font-icon-process;
  }
}
.gh-font-icon-public {
  &:before {
    content: @gh-font-icon-public;
  }
}
.gh-font-icon-reaction {
  &:before {
    content: @gh-font-icon-reaction;
  }
}
.gh-font-icon-recenter {
  &:before {
    content: @gh-font-icon-recenter;
  }
}
.gh-font-icon-refresh {
  &:before {
    content: @gh-font-icon-refresh;
  }
}
.gh-font-icon-restricted {
  &:before {
    content: @gh-font-icon-restricted;
  }
}
.gh-font-icon-scroll {
  &:before {
    content: @gh-font-icon-scroll;
  }
}
.gh-font-icon-search {
  &:before {
    content: @gh-font-icon-search;
  }
}
.gh-font-icon-security {
  &:before {
    content: @gh-font-icon-security;
  }
}
.gh-font-icon-sessions {
  &:before {
    content: @gh-font-icon-sessions;
  }
}
.gh-font-icon-share {
  &:before {
    content: @gh-font-icon-share;
  }
}
.gh-font-icon-slack {
  &:before {
    content: @gh-font-icon-slack;
  }
}
.gh-font-icon-sort {
  &:before {
    content: @gh-font-icon-sort;
  }
}
.gh-font-icon-sql {
  &:before {
    content: @gh-font-icon-sql;
  }
}
.gh-font-icon-t-bold {
  &:before {
    content: @gh-font-icon-t-bold;
  }
}
.gh-font-icon-t-italic {
  &:before {
    content: @gh-font-icon-t-italic;
  }
}
.gh-font-icon-t-strike {
  &:before {
    content: @gh-font-icon-t-strike;
  }
}
.gh-font-icon-t-underlined {
  &:before {
    content: @gh-font-icon-t-underlined;
  }
}
.gh-font-icon-t {
  &:before {
    content: @gh-font-icon-t;
  }
}
.gh-font-icon-table {
  &:before {
    content: @gh-font-icon-table;
  }
}
.gh-font-icon-tag {
  &:before {
    content: @gh-font-icon-tag;
  }
}
.gh-font-icon-target {
  &:before {
    content: @gh-font-icon-target;
  }
}
.gh-font-icon-task {
  &:before {
    content: @gh-font-icon-task;
  }
}
.gh-font-icon-trash-bin {
  &:before {
    content: @gh-font-icon-trash-bin;
  }
}
.gh-font-icon-upload {
  &:before {
    content: @gh-font-icon-upload;
  }
}
.gh-font-icon-user {
  &:before {
    content: @gh-font-icon-user;
  }
}
.gh-font-icon-users-group {
  &:before {
    content: @gh-font-icon-users-group;
  }
}
.gh-font-icon-verification {
  &:before {
    content: @gh-font-icon-verification;
  }
}
.gh-font-icon-view-only {
  &:before {
    content: @gh-font-icon-view-only;
  }
}
.gh-font-icon-whiteboards {
  &:before {
    content: @gh-font-icon-whiteboards;
  }
}
.gh-font-icon-wizard {
  &:before {
    content: @gh-font-icon-wizard;
  }
}
.gh-font-icon-workday {
  &:before {
    content: @gh-font-icon-workday;
  }
}
