#user-detail-view {
  .details-container {
    .title-section {
      h1 {
        margin-left: 11px;
      }
      .user-email {
        color: @brand-black;
      }
    }
  }

  .roles {
    border-top: 1px solid @brand-border;
    padding: 21px 90px 0px 50px;
    margin-top: 32px;
  }

  .role-indicators {
    padding: 0 90px 0 50px;
  }
}
