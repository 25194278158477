.gh-two-columns-screen {
  .gh-screen-title {
    font-size: 1.6em;
    font-weight: @regular;
    color: @brand-dark;
    margin-bottom: 20px;
  }

  .gh-screen-description {
    margin-bottom: 30px;
    font-size: 1.1em;
    color: fade(@brand-dark, 65%);
  }

  .gh-two-column {
    .gh-title-col {
      font-size: 1.2em;

      &.required {
        &:before {
          position: absolute;
          left: -20px;
          top: -4px;
          font-size: 25px;
          content: "*";
          color: @brand-jira;
        }
      }
    }

    .gh-content-col {
      &.multiple-users {
        .users-list {
          .users-wrapper {
            margin-bottom: 15px;
            border: 2px solid @brand-border;
            border-radius: 10px;

            .single-user {
              position: relative;
              padding: 10px 45px 10px 45px;
              min-height: 40px;
              width: 100%;
              border-bottom: 1px solid @brand-border;

              .gh-modal-close-button {
                top: 12px;
                right: 15px;

                .icon {
                  font-weight: @ultrabold;
                }
              }

              &:last-child {
                border-bottom: 0;
              }

              &.fake {
                display: flex;
                gap: 5px;
              }
            }
          }
        }

        .select-users {
          padding-left: 15px;
          color: @brand-jira;
          cursor: pointer;

          &:hover {
            color: @brand-info;
            text-decoration: underline;
          }
        }
      }
    }

    .gh-actions-group {
      padding-top: 40px;
      margin-top: 40px;
      border-top: 2px solid @brand-border;
    }
  }
}
