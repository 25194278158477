body.hide-left-nav {
  #kpis {
    #kpi-table {
      left: calc(var(--left-nav-hidden-width) + 10px);
      .border-left,
      .border-top,
      .border-bottom,
      .mask-bottom {
        left: calc(var(--left-nav-hidden-width) + 10px);
      }
    }
  }

  @media all and (max-width: 1520px) {
    #api-tokens {
      max-width: 900px;
    }

    #billing-configuration {
      width: 900px;
    }
  }

  @media all and (max-width: 1420px) {
    #api-tokens {
      max-width: 800px;
    }

    #billing-configuration {
      width: 800px;
    }

    #insightboards {
      .card {
        width: 22%;
      }
    }
  }

  @media all and (max-width: 1320px) {
    #api-tokens {
      max-width: 700px;
    }

    #billing-configuration {
      width: 700px;
    }
  }

  @media all and (max-width: 1200px) {
    #insightboards {
      .card {
        width: 30%;
      }
    }
  }

  @media all and (max-width: 1000px) {
    #insightboards {
      .card {
        width: 47%;
      }
    }
  }

  @media all and (max-width: 576px) {
    #insightboards {
      .card {
        width: 100%;
      }
    }
  }
}
