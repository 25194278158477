permission-principal {
  .permission-principal-wrapper {
    width: 393px;
    height: 38px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.no-edit-permissions {
      margin-bottom: 4px;
      .principal-wrapper {
        width: 372px;
      }
    }
    .indicator-dot {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      height: 10px;
      width: 10px;
      background-color: @blue-40-v2;
      border-radius: 50%;
    }

    .principal-wrapper {
      width: 330px;
      height: 38px;
      border: 1px solid @grey-60-v2;
      box-shadow: 0px 1px 1px rgba(90, 127, 158, 0.1);
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info-wrapper {
        width: 291px;
        height: 100%;
        padding-left: 10px;
        display: flex;
        align-items: center;

        .principal-name {
          margin-left: 6px;
        }

        ui-assignee {
          max-width: 280px;
        }

        .team-with-subteams-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          ui-assignee {
            max-width: 180px;
          }

          .subteams-text {
            padding-left: 5px;
            min-width: 100px;
          }
        }
      }

      .team-info-wrapper {
        width: 39px;
        height: 36px;
        text-align: center;

        .gh-font-icon-users-group {
          display: inline-block;
          line-height: 38px;
          font-size: 16px;
          font-weight: @regular;
          color: @brand-dark;
          cursor: pointer;
        }

        .dropdown {
          border-left: 1px solid @grey-60-v2;
          height: 100%;
          width: 100%;

          .dropdown-toggle {
            height: 100%;
            width: 100%;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          .teams-and-subteams-tooltip {
            &.ink-80-tooltip {
              .tooltip-inner {
                white-space: nowrap;
              }
            }
          }

          &.open {
            .dropdown-toggle {
              background-color: @grey-10-v3;
            }

            .teams-and-subteams-tooltip {
              display: none;
            }
          }
        }

        .open > .dropdown-menu {
          width: 330px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 12px 8px 12px 12px;
          gap: 8px;
          box-shadow:
            0px 5px 10px rgba(9, 30, 66, 0.25),
            0px 0px 1px rgba(63, 87, 110, 0.31);
          border-radius: 4px;

          .included-subteams {
            position: relative;
            display: flex;
            align-items: center;

            .checkbox-wrapper {
              height: 100%;
              display: flex;

              input[type="checkbox"] {
                margin-top: 0;
              }
            }

            label {
              position: relative;
              padding-left: 6px;
              margin-bottom: 0;
              line-height: 20px;
              font-size: 14px;
              font-weight: @regular;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }

          .who {
            color: @link;
            display: inline-block;
            cursor: pointer;
          }
        }
      }
    }
  }

  .default-settings-wrapper {
    width: 38px;
    height: 100%;
    text-align: center;

    .gh-font-icon-key {
      display: inline-block;
      line-height: 36px;
      font-size: 16px;
      font-weight: @regular;
      color: @brand-dark;
      cursor: pointer;
    }

    .dropdown {
      .dropdown-toggle {
        border: 1px solid @grey-60-v2;
        box-shadow: 0px 1px 1px rgba(90, 127, 158, 0.1);
        border-radius: 4px;
      }

      .default-settings-tooltip {
        &.ink-80-tooltip {
          .tooltip-inner {
            white-space: nowrap;
          }
        }
      }

      &.open {
        .dropdown-toggle {
          background-color: @grey-10-v3;
        }

        .default-settings-tooltip {
          display: none;
        }
      }
    }

    .open > .dropdown-menu {
      width: 202px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 30px 16px 16px;
      gap: 8px;
      box-shadow:
        0px 5px 10px rgba(9, 30, 66, 0.25),
        0px 0px 1px rgba(63, 87, 110, 0.31);
      border-radius: 4px;

      .default-setting-name {
        position: relative;

        label {
          position: relative;
          padding-left: 22px;
          line-height: 18px;
          font-size: 14px;
          font-weight: @medium;

          input {
            position: absolute;
            margin: 0;
            padding: 0;
            left: 4px;
            top: 3px;
          }
        }
      }

      .who {
        color: @link;
        display: inline-block;
        padding-left: 4px;
        cursor: pointer;
      }
    }
  }

  .permission-remove-wrapper {
    height: 100%;
    text-align: center;
    min-width: 17px;

    .gh-font-icon-trash-bin {
      display: inline-block;
      line-height: 38px;
      font-size: 1.2em;
      color: @ink-50-v2 !important;
      cursor: pointer;

      &:hover {
        color: @ink-70-v2 !important;
      }
    }
  }
}
