.user-full-name .deactivated-span {
  opacity: 0.6;
}

.user-full-name.deactivated-label {
  display: inline-flex;
  flex-direction: column;
  color: @ink-100-v3;
  min-width: 0;
}

.user-full-name {
  overflow-wrap: break-word;
}

.deactivated-label-container {
  display: flex;
  align-items: center;
  padding: 4px 0px;
}
