.snackbar-container {
  position: fixed;
  right: 20px;
  top: 50px;
  z-index: 99999;

  &.center {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottom {
    top: auto;
    bottom: 50px;
  }

  .snackbar {
    display: flex;
    align-items: center;
    clear: both;
    visibility: visible;
    overflow: visible;
    height: auto;
    min-width: 380px;
    max-width: 568px;
    background: @brand-white;
    border-left: 7px solid @teal-100;
    border-radius: 4px;
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
    color: @ink-100;
    font-family: "Helvetica Neue", "SegoeUI", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: @mediumbold;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-align: left;
    padding: 15px 20px 15px 20px;
    margin-bottom: 20px;

    .gh-modal-close-button {
      position: initial;
    }

    .gh-font-icon-verification {
      margin-right: 12px;
      font-size: 24px;
    }

    .snackbar-content {
      flex-grow: 1;
    }

    .snackbar-action {
      cursor: pointer;
      color: @brand-link;
      margin-top: 20px;
    }
  }

  &.info {
    @paddingForInfoToast: 12px;

    &.has-action {
      // Moves padding from the snackbar to the action button so as to have the separator (border-left) be full height
      .snackbar {
        padding-top: 0;
        padding-bottom: 0;
      }

      .snackbar-action {
        padding-top: @paddingForInfoToast;
        padding-bottom: @paddingForInfoToast;
      }
    }

    .snackbar {
      display: flex;
      align-items: center;
      min-width: 200px;
      padding: @paddingForInfoToast;
      border: none;
      border-radius: 8px;
      color: @brand-white;
      background-color: @blue-100-v3;
      font-weight: 400;

      .gh-font-icon-info {
        font-size: 24px;
        margin-right: @paddingForInfoToast;
      }

      .snackbar-content {
        padding-right: @paddingForInfoToast;
      }

      .snackbar-action {
        color: @brand-white;
        opacity: 1;
        margin-top: 0;
        margin-left: auto;
        padding-left: @paddingForInfoToast;
        font-weight: 500;
        border-left: 1px solid @blue-80-v3;
      }
    }
  }

  &.success {
    .snackbar-before {
      content: "";
      border-top: 1px solid @grey-10;
    }

    .snackbar {
      background: @teal-80-v2;
      border-left: none;
      color: @brand-white;
      font-style: normal;
      font-weight: @regular;
      min-width: auto;
      font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;

      .snackbar-action {
        color: @brand-white;
        display: inline-block;
        font-size: 18px;
        margin: 0 5px;
      }
    }

    .gh-modal-close-button {
      display: flex;
      align-items: center;

      .icon {
        font-size: 18px;
        margin-left: 5px;
      }
    }
  }
}
