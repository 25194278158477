.gh-empty-module {
  text-align: center;
  margin: 0 auto;

  .icon {
    font-size: 120px;
    color: @grey-10-v3;
    margin-bottom: 20px;
  }

  .private-okr-error {
    margin-top: 20%;
    margin-bottom: 20px;
  }

  .gh-empty-module-title {
    font-weight: @medium;
    letter-spacing: 0.01em;
    font-size: 1.6em;
    max-width: 420px;
    position: relative;
    margin: 0 auto 10px auto;
    color: lighten(desaturate(@brand-dark, 8%), 25%);
  }
  .gh-empty-module-action {
    font-weight: @regular;
    letter-spacing: 0.01em;
    font-size: 1.4em;
    max-width: 420px;
    position: relative;
    margin: 0 auto 10px auto;

    .action-link {
      color: @brand-jira;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .moving-session {
    padding-left: 40px;
    padding-right: 40px;
    color: lighten(desaturate(@brand-dark, 8%), 25%);
    span {
      font-weight: @bold;
    }
  }

  a.action {
    font-size: 1.4em;
    cursor: pointer;
    margin-top: 10px;
  }

  p {
    font-size: 1.2em;
    color: lighten(desaturate(@brand-subtle, 8%), 2%);
    max-width: 460px;
    margin: 0 auto;
    word-break: break-word;
  }

  .new-okr {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .msg {
      z-index: 1;
      margin-top: 15px;
      line-height: 20px;
      font-size: 14px;
      color: @ink-90;
    }

    button[gh-button] {
      z-index: 1;
    }
  }
}

.gh-confirm {
  .gh-empty-module {
    p {
      margin-bottom: 10px;

      &.pre-wrap {
        margin-bottom: 0;
        color: darken(@brand-subtle, 20%);
        font-weight: @ultrabold;
      }
    }

    ul {
      width: 60%;
      margin: 0 auto 10px auto;
      text-align: left;
      list-style: disc;

      li {
        font-size: 1.1em;
        color: lighten(desaturate(@brand-subtle, 8%), 2%);
      }
    }
  }
}

.gh-modal-title {
  font-size: @font-size-large;
  margin-bottom: 15px;
}

.gh-empty-row {
  position: relative;
  margin: 20px 0;
  font-size: 1.3em;
  padding-left: 80px;
  clear: both;
  height: 80px;

  &.small {
    padding-left: 55px;

    .icon {
      font-size: 40px;
      top: 15px;
    }

    p {
      font-size: 0.9em;
    }
  }

  .message {
    float: left;
    margin-right: 30px;
    color: fade(@brand-subtle, 60%);

    p {
      font-weight: @regular;
      color: fade(@brand-subtle, 60%);
      max-width: 420px;
      padding: 10px 0 5px 0;
      margin: 0;
    }

    a {
      font-weight: @medium;
      cursor: pointer;
      font-size: 0.9em;
    }
  }

  .icon {
    font-size: 65px;
    color: fade(@brand-subtle, 15%);
    margin-bottom: 20px;
    position: absolute;
    left: 0;
    top: 0;
  }
}
