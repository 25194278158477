@font-face {
  font-family: "Aeonik-Medium";
  src: url("../../../wwwroot/fe/fonts/login/aeonik-medium.woff2")
    format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Aeonik";
  src: url("../../../wwwroot/fe/fonts/login/aeonik-regular.woff2")
    format("woff2");
  font-display: swap;
}
