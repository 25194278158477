.gh-timeframe {
  clear: both;
  position: relative;
  padding: 10px 18px;
  border: 3px solid #eee;
  border-radius: 6px;
  .transition(0.3s);

  &:hover {
    border-color: saturate(darken(@brand-light, 15%), 20%);
  }

  &:after {
    clear: both;
    display: block;
    content: "";
    width: 100%;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    cursor: pointer;
    height: 100%;
    opacity: 0;
  }

  .label {
    font-weight: @regular;
    color: @brand-subtle;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  .date {
    font-weight: @medium;
    font-size: 14px;
  }

  .sep {
    position: absolute;
    left: 50%;
    margin-left: -15px;
    background: fade(@brand-subtle, 30%);
    top: 0;
    height: 100%;
    width: 1px;
  }

  .start-date {
    float: left;
    width: 50%;
  }

  .end-date {
    float: left;
    width: 50%;
  }
}
