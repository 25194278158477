.gh-grid {
  margin-bottom: 30px;
  border-bottom: 1px solid @brand-lighter-grey;

  .new-objective {
    text-align: right;
  }

  &.workflow-grid {
    .gh-cell {
      &:nth-child(1) {
        width: 25%;
      }

      &:nth-child(2) {
        width: 10%;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 25%;
      }

      &:nth-child(5) {
        width: 25%;
      }
    }

    .gh-row-header {
      -webkit-user-select: none;
      user-select: none;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.gh-row {
  border-top: 1px solid @brand-lighter-grey;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  position: relative;
  height: 39px;

  &.collapsed {
    overflow: hidden;
    .transition(0.2s);
    background: lighten(@brand-warning, 25%);
    height: 0px;
    border: 0;
  }

  .gh-row-columns {
    display: flex;
  }

  .gh-row-actions {
    position: absolute;
    right: 5px;
    top: 10px;
  }

  &:hover {
    background: lighten(@brand-light, 4%);
  }

  &.gh-row-header {
    color: @brand-subtler;
  }

  &.more {
    border-top: 0;

    &:hover {
      background: none;
    }

    .gh-cell {
      height: 25px;
      line-height: 18px;
      padding-left: 30px;
      color: @brand-subtler;

      .icon {
        font-size: 9px;
      }

      &:hover {
        cursor: pointer;
        color: @brand-dark;
      }
    }
  }
}

@ghCellOffset: 8px;
.gh-cell {
  height: 38px;
  line-height: 38px;
  border-right: 1px solid @brand-lighter-grey;
  padding: 0 @ghCellOffset;
  position: relative;

  &.pl-04 {
    padding-left: 4px;
  }

  .gh-b {
    letter-spacing: 0;
    font-weight: @medium;

    &.gh-b-border {
      border-width: 1px;

      &.gh-xs {
        padding: 5px 6px;
      }
    }
  }

  &.free-height {
    height: auto;
    white-space: nowrap;
  }

  .clip-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 38px;
  }

  &.focusable {
    &:focus,
    &:active {
      background: lighten(@brand-info, 36%);
      outline: none;
      border: 1px solid lighten(@brand-info, 16%);
      line-height: 37px;
      padding-left: 29px;

      .grid-icon {
        left: 5px;
      }
    }
  }

  .gh-b {
    line-height: 12px;
    border-radius: 20px;

    &:hover {
      color: #fff;
    }

    &.gh-b-border {
      &:hover {
        color: @brand-dark;
      }
    }
  }

  a {
    color: @brand-dark;

    &:hover {
      color: @brand-jira;
    }
  }

  &.title {
    font-weight: @semibold;
    font-size: 15px;
  }

  &:last-child {
    border-right: 0;
    padding-right: 40px;
  }

  .grid-input {
    width: 100%;
    border: 0;
    padding-left: 6px;
    outline: none;
    position: absolute;
    background: transparent;
    left: 0;
    top: 0;
    height: 38px;

    &:focus,
    &:active {
      background: lighten(@brand-info, 36%);

      &::placeholder {
        color: desaturate(lighten(@brand-info, 20%), 25%);
      }

      &:-ms-input-placeholder {
        color: desaturate(lighten(@brand-info, 20%), 25%);
      }

      &::-ms-input-placeholder {
        color: desaturate(lighten(@brand-info, 20%), 25%);
      }
    }

    &::placeholder {
      color: darken(@brand-light, 5%);
    }

    &:-ms-input-placeholder {
      color: darken(@brand-light, 5%);
    }

    &::-ms-input-placeholder {
      color: darken(@brand-light, 5%);
    }
  }

  &.with-icon {
    padding-left: 30px;

    .open {
      width: 52px;
      color: @brand-subtle;
      float: right;
      font-size: 0.8em;
      display: none;

      span {
        font-size: 0.8em;
        margin-left: 0.5em;
      }
    }

    .name {
      height: 100%;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        .grid-input {
          border: 1px solid @brand-jira;
        }

        .open {
          display: inline;
        }
      }

      .grid-input {
        position: unset;
        width: 77%;
        flex-grow: 0.2;
        padding: 0px 5px 0px 2px;
        border-radius: 4px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid @brand-white;

        &:hover,
        &:active,
        &:focus {
          border: 1px solid @brand-jira;

          + .open {
            display: none;
          }
        }

        &:active,
        &:focus {
          width: 100%;
        }
      }
    }

    .grid-icon {
      position: absolute;
      left: 6px;
      z-index: 2;
      top: 3px;
    }

    &.ident-1 {
      padding-left: 55px;

      .grid-icon {
        left: 30px;
      }
    }
  }

  .clip-text {
    &.jira-issue-link {
      a {
        color: @brand-jira;
      }
    }
  }

  &.attainment {
    padding-right: 60px;
  }

  &.ident-1 {
    padding-left: 40px;
  }
}
