.confidence-badge {
  display: inline-block;

  &.confidenceType-text {
    color: #fff !important;
    border-radius: 12px;
    padding: 2px 7px;
    font-weight: @bold;
    font-size: 9px;
    font-family: Arial;
    line-height: 12px;
    text-transform: uppercase;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.confidenceType-numeric {
    background: none !important;
  }
}

#color-scale {
  .scale {
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-left: 80px;
    position: relative;
    border-bottom: 1px solid @brand-light;

    &:last-of-type {
      border-bottom: 0;
    }

    .delete {
      position: absolute;
      right: 10px;
      font-size: 16px;
      color: @brand-grey;
      top: 10px;

      &:hover {
        color: @brand-danger;
        cursor: pointer;
      }
    }

    .dropdown {
      position: absolute;
      left: 0;
      top: 0;

      .dropdown-menu {
        &.dropdown-menu-emoji {
          width: 346px;
        }

        .emoji {
          margin: 9px;
        }
      }

      .dropdown-toggle {
        width: 40px;
        height: 40px;
        position: relative;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        &:hover {
          cursor: pointer;
          .arrow {
            opacity: 0.9;
          }
        }

        .arrow {
          opacity: 0.5;
          position: absolute;
          width: 25px;
          left: 40px;
          top: 0;
          height: 100%;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .icon {
          position: absolute;
          left: 47px;
          top: 15px;
          color: #fff;
        }

        // used in confidence settings screen
        &.gh-input-wrapper {
          width: 170px;

          .gh-input-text {
            &.gh-input-nested {
              width: 130px;
              height: 40px;
              line-height: 40px;
              background: none;
              color: @brand-light;
              border: 0;
              font-weight: @medium;
              border-right: 1px solid fade(#fff, 50%);
              border-radius: 0;
            }
          }

          .arrow {
            left: 170px;
          }

          .icon {
            left: 177px;
          }
        }

        // emoji dropdown toggle class
        &.emoji-toggle {
          background: @brand-subtler;

          .arrow {
            background: darken(@brand-dark, 15%);
          }
        }

        .emoji-inline {
          position: absolute;
          top: 2px;
          left: 2px;
        }
      }

      .colors {
        width: 420px;
        padding: 12px;
        display: flex;
        flex-flow: row wrap;
      }

      .color {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        margin: 6px;
        flex: 0 1 calc(11% - 12px);

        .transition(0.1s);

        &.selected {
          box-shadow:
            0 0 0 2px @grey-10-v2,
            0 0 0 4px @blue-30-v2;
        }

        &:hover {
          box-shadow:
            0 0 0 2px @grey-10-v2,
            0 0 0 3px @grey-100-v2;
          cursor: pointer;
        }
      }

      .emoji {
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }

    .value {
      padding: 3px 0;

      .title {
        font-size: 0.9em;
        color: @brand-grey;
      }

      .text {
        font-size: 1.2em;
      }

      &.confidence-type-text {
        padding-left: 133px;
      }
    }
  }
}

.emoji-toggle {
  .twa-xxl {
    height: 2.5em;
    width: 2.5em;
    background-size: 2.5em 2.5em;
  }
}
