.o-hidden {
  overflow: hidden;
}

.hov-cursor-g {
  &:hover {
    cursor: grab;
  }
}

.hov-cursor-p {
  &:hover {
    cursor: pointer;
  }
}

.hov-cursor-h {
  &:hover {
    cursor: help;
  }
}

.hov-cursor-na {
  &:hover {
    cursor: not-allowed;
  }
}

.hov-cursor-i {
  &:hover {
    cursor: initial;
  }
}

.no-pointer-events {
  pointer-events: none;
}
