#edit-user-names-form {
  .form {
    .gh-f-title-medium {
      font-size: 1.9em;
      font-weight: @bold;
      color: @brand-black;
    }

    .email {
      font-family: SF Pro Display;
      font-size: 12px;
      line-height: 14px;
      color: #536178;
    }

    editable-textarea {
      &.first {
        margin-top: 7px;
      }
    }

    .gh-fg {
      border: none;
      &.user-names {
        .gh-fg-title {
          margin-bottom: 5px;
          margin-top: 25px;
          width: 42%;
          display: inline-block;

          &:first-child {
            margin-top: 0px;
          }
        }

        .gh-input-text {
          width: 58%;
          display: inline-block;
        }
      }
    }
  }
}
