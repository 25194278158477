subteams-tree {
  .branch {
    margin-top: 12px;

    &.top-level {
      margin-top: 0;
    }

    .leading-node {
      .expand-collapse {
        color: @ink-60-v3;

        &:not(.empty) {
          cursor: pointer;
        }

        &.empty {
          &.medium {
            height: 16px;
            min-width: 16px;
            max-width: 16px;
          }
        }
      }
    }
  }
}
