@import (reference) "../../less/_variables.less";

.feed-comment-sidebar-modal {
  .modal-dialog {
    max-width: 550px;

    .modal-content {
      padding: 10px 50px 50px 50px;
    }
  }
}

#feed {
  padding: 0px 130px 93px;
  background: @brand-white;

  .empty-screen {
    max-width: 1021px;
    margin: auto;
    padding-top: 150px;

    .text-wrapper {
      width: 37%;
      margin-top: 60px;
      margin-right: 56px;
      word-break: break-word;

      h1 {
        font-weight: @mediumbold;
        padding-bottom: 27px;
        color: @ink-100-v2;
      }

      p {
        color: @ink-70-v2;
      }
    }

    img {
      width: 627px;
      max-width: 62%;
      height: auto;
      object-fit: contain;
    }
  }

  .feed-item-skeleton {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: @brand-white;

    .feed-item {
      width: 100%;
      @item-max-width: 587px;
      max-width: @item-max-width;

      &:first-of-type {
        margin-top: 10px;
      }

      &:not(:first-of-type) {
        margin-top: 31px;
      }

      .item-frame {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: fit-content;
        border: 1px solid @grey-10-v3;
        border-radius: 10px;
        padding: 2px; // Compensate for the extra thick border of the active element
        z-index: 1;

        &.active {
          border-width: 3px;
          padding: 0;
        }

        .celebration {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          .confetti-fast,
          .confetti-slow {
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("../../../../wwwroot/fe/img/celebration.png")
              no-repeat 0 0;
            background-size: cover;
            opacity: 0;
          }
        }

        &.successful-kr {
          background-color: @purple-10-v2;
          border: none;
          padding: 3px; // Compensate for the border of the active element

          &.active {
            border: 3px solid @purple-20-v2;
            padding: 0;
          }

          .confidence-level .confidenceType-text {
            background-color: @purple-100-v2;
          }

          .item-footer {
            border-color: @purple-20-v2;
          }
        }

        .item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 17px 29px 0;
          z-index: 2;

          .item-owner-info {
            display: flex;

            .assignee-details-popover-trigger .names:hover {
              cursor: pointer;
            }

            .owner-data {
              display: flex;
              flex-direction: column;
              align-self: center;
              flex-wrap: wrap;
              .names {
                font-weight: @mediumbold;
                color: @ink-90-v2;
              }

              .additional-info {
                display: flex;
                align-items: center;

                .team {
                  color: @ink-60-v3;
                  margin-right: 6.5px;
                }

                .time-created {
                  font-weight: @medium;
                  color: @ink-10-v3;

                  &::before {
                    content: "\2022";
                    display: inline-block;
                    margin: 0 4px;
                  }
                }
              }
            }
          }
          .confidence-level {
            display: flex;
            align-items: center;
            width: fit-content;
            height: 28px;
            color: @brand-white;

            confidence-preview {
              height: 100%;
            }

            .confidence-badge.confidenceType-text {
              padding: 2.5px 18px;
              border-radius: 6px;
              font-size: 14px;
              line-height: 23px;
              font-weight: @medium;
              text-transform: none;
            }
          }
        }

        .item-body {
          width: 100%;
          padding: 26px 30px;
          z-index: inherit;

          .kr-title {
            display: flex;
            align-items: flex-start;
            margin-bottom: 14px;

            .gh-icon {
              min-width: 16px;
              margin-top: 3px;
            }

            .objective-tooltip {
              display: flex;
              align-items: top;

              .gh-icon {
                display: flex;
                margin: 3px 0;
              }

              .obj-name {
                display: flex;
                margin-left: 6px;
                align-self: center;
                width: max-content;
                max-width: 252px;
                line-height: 16px;
                font-size: 13px;
              }
            }

            .tooltip div {
              text-align: left;
            }

            .title {
              margin-left: 7px;
              font-weight: @medium;
              color: @ink-100;
              word-break: break-word;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .kr-comment {
            margin-top: 14px;
            color: @ink-100;
            word-break: break-word;
          }

          .kr-progress-bar {
            position: relative;
            width: 100%;
            height: 12px;
            background-color: @grey-10-v3;
            border-radius: 100px;

            .current-progress {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              position: relative;
              height: 100%;
              min-width: 12px;
              border-radius: 100px;

              &:after {
                content: "";
                width: 6px;
                height: 6px;
                margin-right: 3px;
                transform: rotate(45deg);
                background-color: @brand-white;
              }
            }
          }

          .exceptional {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 2px;
            right: 31%;
            border-radius: 50%;
            background-color: @brand-white;
            z-index: 1;
          }

          .kr-progress-bar-values {
            display: flex;
            justify-content: flex-end;
            position: relative;
            height: 19px;
            margin-top: 2px;
            color: @ink-100;
            font-weight: @mediumbold;

            .progress-bar-target-value {
              margin-right: 31%;
              color: @ink-40-v3;
            }

            .progress-bar-current-value {
              position: absolute;
              .current-percent-value {
                margin-left: 1px;
                color: @ink-40-v3;
              }
            }

            &.attainment-over-hundred {
              .progress-bar-target-value {
                margin-right: 0;
              }

              .progress-bar-current-value {
                position: static;
                margin-left: 3% !important;
                min-width: 28%;
                text-align: end;
              }
            }
          }
        }
        .item-footer {
          padding-top: 13px;
          margin: 0 30px 13px;
          border-top: 1px solid @grey-10-v3;
          z-index: 1;

          .feed-meta-container {
            display: flex;
            align-items: center;
          }
        }
      }

      .item-last-comment {
        max-width: @item-max-width;
        padding-left: 22px;
        border-bottom: 1px solid @grey-10-v3;
      }
    }
  }
}

@keyframes rotate-mask {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
