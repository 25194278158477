#how-to-start {
  .how-to-start-wrapper {
    max-width: 660px;
    margin: 0 auto;

    .how-to-start-title {
      font-size: 30px;
      line-height: 34px;
      font-weight: @bold;
      margin-bottom: 10px;
      color: @ink-100-v2;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .how-to-start-content {
      .how-to-start-card {
        position: relative;
        padding: 10px 0px 20px 20px;
        border: 2px solid @grey-10;
        border-radius: 25px;
        width: 50%;

        display: flex;
        flex-direction: row;

        &:nth-child(1) {
          background-color: @grey-5;
        }

        .card-text-width {
          width: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 10px;
        }

        .card-img {
          position: absolute;
          height: 130px;
          right: 0;
          width: 30%;
          overflow-x: hidden;
          border-top-right-radius: 15px;

          img {
            height: 130px;
            position: absolute;
            right: -5px;
            top: -5px;
          }
        }

        h2 {
          font-family: "SF Pro Display", Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          width: 110%;
          font-weight: @bold;
          font-size: 20px;
          line-height: 26px;
          color: @ink-80-v2;
          letter-spacing: 0.005em;
          margin-bottom: 10px;
        }

        p {
          font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          color: @ink-70-v2;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
