@import (reference) "../../../../less/_variables.less";

.grid-wrapper {
  border: 1px solid @grey-40-v2;
  border-radius: 5px;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  width: fit-content;

  empty-filter-results {
    margin-top: 145px;
  }

  .no-events {
    pointer-events: none;
  }

  #non-frozen-cols-wrapper {
    right: 0;
    overflow: visible;
    z-index: 2;
    display: grid;

    // helps position the frozen last column to the right (else won't work if columns are very wide and window is small)
    width: fit-content;
  }

  .grid-header {
    position: sticky;
    top: 0;
    z-index: 9;
    border-bottom: 1px solid @grey-40-v2;
    background-color: @brand-white;
  }

  #pane-divider {
    z-index: 11;

    .drag-handle {
      transform: translate(-50%, -50%);
      margin-left: 1px;
      border-radius: 10px;
      width: 6px;
      height: 26px;
      background: @royal-80;
      position: absolute;
      z-index: 4;
    }

    .line {
      height: 100%;
      width: 2px;
      background: @ink-60-v2;
    }

    &:hover {
      top: 0;
      opacity: 1;
      cursor: grab;

      .line,
      .drag-handle {
        opacity: 1;
      }
    }
  }

  #divider-snap {
    z-index: 10;
    .line {
      height: 100%;
      width: 2px;
      background: @purple-90;
    }
  }

  #pane-divider,
  #divider-snap {
    position: absolute;
    top: 32px;
    bottom: 0;
    width: 6px;
    opacity: 1;
    overflow: visible;
  }

  #grid-container {
    position: relative;
    background: @brand-white;

    &.reloading .grid-col:not(.grid-col-header),
    .grid-col.new {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      &:after {
        content: "";
        position: absolute;
        left: 15px;
        top: 10px;
        right: 15px;
        height: 14px;
        z-index: 3;
        background: @brand-light;
        border-radius: 5px;
      }
    }

    .grid-col-header {
      padding: 0px 16px;
      p {
        margin: 0;
        letter-spacing: 0em;
        text-align: left;
        color: @ink-60-v2;
        font-weight: @regular;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .grid-row-resize {
      display: flex;
      align-items: center;

      .resize-holder {
        height: 0px;
        position: relative;

        @media screen and (max-width: 1536px) {
          max-width: 450px;
        }

        @media screen and (max-width: 1280px) {
          max-width: 350px;
        }

        @media screen and (max-width: 768px) {
          max-width: 250px;
        }

        // handle - resize
        .col-drag-handle {
          width: 6px;
          height: 45.1px;
          position: absolute;
          left: 100%;
          margin-left: -3px;
          top: 0;
          opacity: 0;
          background: darken(@list-cell-border, 5%);
          z-index: 5 !important;
          cursor: ew-resize;

          &:hover,
            // class is coming from angular-drag-drop
            &.drag-container-active {
            background: @blue-80-v2;
            opacity: 1;

            .vl {
              display: block;
            }
          }

          .vl {
            position: absolute;
            left: 0;
            display: none;
            background: @blue-10-v3;
            width: 6px;
            top: 100%;
          }
        }
      }

      .resize-holder:last-of-type {
        .col-drag-handle {
          margin-left: -6px;
        }
      }
    }

    .frozen-cols-wrapper {
      position: sticky;
      left: 0;
      z-index: 3;
      border-right-style: solid;
      border-color: inherit;
      border-width: 1px;
      box-shadow: 4px 0 0 0 transparent;
      transition-property: border-color, box-shadow;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      transition-delay: 0s;

      &.last {
        right: 0;
        border-right-style: none;

        .grid-cell {
          overflow: unset;
        }

        .grid-row {
          border-left: 1px solid @grey-10;
        }
      }
    }

    .grid-col {
      &.disabled {
        pointer-events: none;
      }
    }

    .grid-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid @grey-40-v2;

      &.grid-row-header {
        &:hover {
          background: none;
        }
      }

      &.disabled {
        cursor: not-allowed;

        .grid-col {
          pointer-events: none;
        }
      }

      .grid-cell {
        display: flex;
        flex-grow: 1;
        align-self: stretch;
        align-items: center;
        overflow: hidden;

        gh-row-cell {
          display: contents;
        }
      }
    }

    .grid-row:last-of-type {
      border-bottom: none;
    }

    .grid-col {
      background-color: @brand-white;
      min-height: 44px;
      display: flex;
      flex-grow: 1;
      align-items: center;
      font-size: 13px;

      @media screen and (max-width: 1536px) {
        max-width: 450px;
      }

      @media screen and (max-width: 1280px) {
        max-width: 350px;
      }

      @media screen and (max-width: 768px) {
        max-width: 250px;
      }
    }

    .row-hovered {
      .grid-col {
        background-color: @grey-5;
      }
    }
  }
}
