roles-selector {
  width: 100%;

  .active-b {
    border: 1px solid @ink-50;
    box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);
  }

  .mr-9 {
    margin-right: 9px;
  }

  input.gh-input-text:read-only {
    font-size: 1em;
    color: @ink-100-v2;
    background-color: @grey-5;
    border: 1px solid @grey-20;
    box-shadow: inset 0px 1px 2px rgba(90, 127, 158, 0.2);
  }

  tags-input.roles-input-container {
    width: 100%;

    .clickable {
      cursor: pointer;
    }

    .autocomplete {
      padding: 0;
      border: 0;
      border-radius: 3px;
      box-shadow:
        0px 2px 15px rgba(9, 30, 66, 0.25),
        0px 0px 1px rgba(63, 87, 110, 0.31);
      width: 70%;

      .suggestion-list {
        border-radius: 3px;

        .suggestion-item {
          ng-include {
            .centered-vert;
            height: 23px;
            font-size: 14px;
            color: @ink-100;

            span {
              .ellipsis;
            }

            em {
              font: inherit;
              background: transparent;
              .fw-700;
            }
          }

          &.selected {
            background: @black-10;

            em {
              color: initial;
              background-color: transparent;
            }
          }

          &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }

          span.disabled {
            margin-left: 4px;
            text-transform: lowercase;
          }
        }
      }

      .actions {
        border-top: 1px solid @ink-20;
        border-bottom: 1px solid @ink-20;

        li {
          .fw-500;
          padding: 5px 10px;
          color: @blue-100;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .host {
      margin-top: 0;
      margin-bottom: 0;

      .tags {
        border-radius: 4px;
        font-size: 14px;
        color: @ink-90;

        &:hover {
          .active-b;
        }

        .tag-list {
          .tag-item {
            padding: 1px;
            margin: 4px;
            padding-right: 10px;
            border: 0;
            border-radius: 20px;
            background: @black-10;
            line-height: 24px;
            font-size: 14px;
            color: @ink-100;

            white-space: nowrap;
            text-overflow: ellipsis;

            ng-include {
              .d-flex;

              .gh-font-icon-close-medium {
                display: none;
              }
            }
          }
        }

        &.focused {
          .tag-list {
            .tag-item {
              margin: 3px;
              ng-include {
                .gh-font-icon-close-medium {
                  display: initial;
                  color: @ink-80;
                  margin-left: 8px;
                  align-self: center;

                  &:hover,
                  &:active,
                  &:focus {
                    color: @ink-90;
                  }

                  &.remove {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // if the input is disabled
  tags-input[disabled] {
    .host {
      .tags {
        background-color: inherit;

        .tag-item {
          opacity: 1;
        }

        .input {
          background-color: inherit;
        }

        &:hover {
          border: 1px solid transparent;
          box-shadow: none;
        }
      }
    }
  }
}
