.card {
  display: block;
  float: left;
  width: 280px;
  height: 230px;
  border-radius: 2px;
  margin: 0 @s @s 0;
  background: #fff url(../../../../wwwroot/fe/img/dashboards_bg.png) 2px bottom
    no-repeat;
  padding: 20px 25px 40px;
  position: relative;
  border: 1px solid darken(@brand-light, 4%);
  border-top: 3px solid @brand-primary;
  overflow: hidden;

  .stamp {
    position: absolute;
    top: 5px;
    border-radius: 4px;
    left: 25px;
    padding: 3px 7px;
    display: block;
    font-size: 11px;
    letter-spacing: 0px;
    font-weight: @medium;
    color: #fff;
    background: @brand-primary;
  }

  &.expired {
    opacity: 0.7;
    border-top-color: #dcdee2;
    .stamp {
      background: #dcdee2;
      color: #fff;
    }

    .attainment {
      color: #ccc;
    }
  }

  &.warning {
    border-top-color: @brand-warning;
    .stamp {
      background: @brand-warning;
      color: #fff;
    }
  }

  &.accent {
    border-top-color: @brand-jira;
    .stamp {
      background: @brand-jira;
      color: #fff;
    }
  }
  &.user {
    text-align: center;

    .tag {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      .text {
        line-height: 17px;
        padding: 3px 10px;
        background: @brand-primary;
        color: #fff;
        font-size: 10px;
        text-transform: uppercase;
        display: inline-block;
        white-space: nowrap;
      }
    }
  }

  .button {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
    text-align: center;

    .btn {
      display: inline-block;
      padding: 5px 10px;
      font-size: 11px;
      font-weight: @medium;
    }
  }

  &.selectable {
    cursor: pointer;
  }

  &.wider {
    width: 245px;
    height: 340px;
  }

  &.small {
    padding-top: 60px;
    height: 170px;
    width: 210px;
    padding-bottom: 60px;
    background-image: none;
  }

  &.medium {
    padding-top: 60px;
    height: 220px;
    padding-bottom: 60px;
    background-image: none;
  }

  &.isSelected {
    transform: scale(0.96);
  }

  .multiselect {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-size: 13px;

    .icon {
      display: none;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    &.isSelected {
      background: @brand-primary;

      .icon {
        display: block;
      }
    }

    &.isSelectable {
      border: 1px dashed #aaa;
    }
  }

  .attainment {
    position: absolute;
    color: fade(@brand-primary, 70%);
    left: 25px;
    top: 78px;
    font-weight: @thin;
    font-size: 28px;
  }

  .responsible {
    padding-left: 50px;
    position: absolute;
    left: 25px;
    bottom: 25px;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    span {
      font-size: 11px;
      opacity: 0.4;
    }

    div {
      font-weight: @regular;
      color: @brand-dark;
      font-size: 13px;
    }

    img {
      position: absolute;
      left: 0;
      width: 0;
      width: 40px;
      border-radius: 30px;
    }
  }

  .logos {
    font-size: 12px;
    font-style: italic;
    margin-top: 10px;
    color: fade(@brand-dark, 60%);

    img {
      width: 45px;
    }

    .logo {
      display: block;
      float: left;
      margin-right: 10px;
      .transition(0.2s);

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &.syncing {
    border-color: @brand-info;

    .status {
      opacity: 1;
    }
  }

  &.problem {
    border-color: @brand-danger;

    .tag {
      .text {
        background: @brand-danger;
      }
    }
  }

  &.add-new {
    background: none;
    border: 1px dashed darken(@brand-light, 6%);
    opacity: 1;

    .title {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      top: 30px;
      color: @brand-subtle;
      opacity: 1;
      text-align: center;
    }

    .leading-icon {
      left: 90px;
      color: darken(@brand-light, 4%);
      top: 40px;
      opacity: 1;
      font-size: 30px;
    }

    &:hover {
      border: 1px dashed darken(@brand-light, 16%);
    }
  }

  &.not-connected {
    background: @brand-dark;
    border-color: lighten(@brand-dark, 8%);

    .note {
      color: fade(#fff, 80%);
    }

    .text {
      span {
        background: lighten(@brand-dark, 8%);
      }
    }

    .leading-icon {
      color: #fff;
    }

    .title,
    a.title {
      color: #fff;
    }

    a.title {
      &:hover {
        color: @brand-primary;
      }
      &.disable-hover {
        &:hover {
          color: #fff;
        }
      }
    }

    .status {
      color: fade(#fff, 40%);
    }

    .description {
      color: fade(#fff, 50%);
    }

    .options {
      color: fade(#fff, 60%);

      &.close {
        color: fade(@brand-dark, 60%);
      }
    }

    .options-menu {
      background: @brand-dark;

      ul {
        li {
          color: fade(#fff, 80%);
        }
      }
    }
  }

  .status {
    position: absolute;
    color: @grey-20;
    left: 25px;
    top: 90px;
    font-weight: @regular;
    text-transform: capitalize;

    &:before {
      opacity: 0;
      .transition(0.3s);
      background: rgba(255, 255, 255, 0)
        url(../../../../wwwroot/fe/img/ring-alt.svg) center bottom no-repeat;
      background-size: 100%;
      width: 40px;
      height: 40px;
      top: -60px;
      left: 0px;
      position: absolute;
      content: "";
    }
  }

  &.syncing {
    .status {
      &:before {
        opacity: 1;
      }
    }
  }

  .options {
    position: absolute;
    right: 8px;
    top: 15px;
    font-size: 1.2em;
    color: fade(@brand-dark, 60%);

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    &.close-options {
      z-index: 3;
    }
  }

  .options-menu {
    position: absolute;
    right: 0;
    top: -130%;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: #fff;
    padding: 15px;
    .transition(0.15s);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    opacity: 0;

    .note {
      text-align: center;
      font-size: 14px;
      margin-top: 40px;
      line-height: 24px;
      font-weight: @light;
    }

    &.open {
      top: 0;
      opacity: 1;

      ul {
        li {
          margin-top: -20px;
        }
      }
    }

    ul {
      margin-top: 10px;
      text-align: center;

      li {
        height: 65px;
        margin: 0 auto 30px auto;
        position: relative;
        display: block;
        width: 105px;
        transition:
          0.6s ease-out margin,
          0.2s ease-out color;
        text-align: center;
        font-size: 13px;
        color: fade(@brand-dark, 40%);
        font-weight: @regular;

        a {
          color: fade(@brand-dark, 40%);
        }

        &:hover {
          cursor: pointer;
        }

        &.red:hover {
          color: #f98484;
          a {
            color: #f98484;
          }
        }

        &.blue:hover {
          color: #74c4fe;
          a {
            color: #74c4fe;
          }
        }

        &.green:hover {
          color: @brand-primary;
          a {
            color: @brand-primary;
          }
        }

        .icon {
          font-size: 24px;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          display: block;
          text-align: center;
          line-height: 50px;
        }
      }
    }
  }

  &.processing {
    .leading-icon {
      display: none;
    }

    gh-indicator {
      position: absolute;
      left: @s;
      top: 120px;
    }

    &.deleting {
      .title,
      .description,
      .logos,
      .status,
      .responsible,
      .attainment {
        display: none;
      }
    }
  }

  .leading-icon {
    font-size: 180px;
    position: absolute;
    left: -40px;
    top: -50px;
    color: @brand-dark;
    opacity: 0.04;
  }

  .title {
    max-height: 46px;
    font-size: 16px;
    line-height: 23px;
    font-weight: @medium;
    color: @brand-dark;
    margin-bottom: 20px;
    display: block;
    position: relative;
    overflow: hidden;

    &.role-title {
      margin-bottom: 8px;
    }
  }

  .role-description {
    word-break: break-word;
  }

  a.title {
    &:hover {
      cursor: pointer;
      color: @brand-primary;
    }
  }

  a.title {
    &:hover {
      cursor: pointer;
      color: @brand-primary;
    }
    &.disable-hover {
      &:hover {
        cursor: default;
        color: inherit;
      }
    }
  }

  .permission {
    position: absolute;
    background: darken(@brand-warning, 15%);
    left: 100%;
    margin-left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    text-transform: none;
    letter-spacing: 0;
    white-space: nowrap;
  }

  .description {
    position: relative;
    font-size: 12px;
    font-family: Arial;
    color: fade(@brand-dark, 40%);
    font-weight: @light;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;

    strong {
      font-weight: @regular;
      color: fade(@brand-dark, 70%);
    }
  }
}
