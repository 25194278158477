@import (inline, less)
  "../../../../../../node_modules/ng-tags-input/build/ng-tags-input.css";

tags-input .tags input:disabled {
  background-color: inherit;
}

tags-input.cannot-add-tags .tags,
tags-input[disabled] .tags {
  background-color: inherit;
  cursor: default;
}

tags-input[disabled] .remove-button {
  visibility: hidden;
}

.allow-changing-invited-user-role input {
  display: none;
}

.tag-list,
.tag-item {
  max-width: 100%;
}
