included-in-permission {
  overflow: hidden;
  line-height: 18px;
  font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;

  .section {
    &.users {
      .body {
        max-height: 148px;

        .user {
          margin-top: 12px;

          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }

    &.subteams {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid @grey-40-v2;

      .body {
        max-height: 244px;
      }
    }

    .header {
      .title {
        white-space: nowrap;
        font-weight: @medium;
        color: @ink-20-v3;
      }
    }

    .body {
      overflow-y: auto;
    }

    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: @regular;
      color: @ink-100-v3;
    }
  }
}

.modal {
  &.included-in-permission {
    .modal-dialog {
      .modal-content {
        min-height: 240px;
        padding: 50px;
      }
    }
  }
}
