#account-suspended {
  position: relative;
  margin: 0 10px;
  border-radius: 10px;
  color: lighten(@brand-dark, 15%);

  .plan-info-table {
    min-width: 300px;
    height: 150px;

    td {
      border: 1px solid @brand-dark;
      font-size: 18px;
      padding: 0 10px;

      a {
        font-size: 0.8em;
        margin: 5px;
      }
    }
  }

  .emoticon {
    text-align: center;
    height: 125px;
  }

  h3 {
    font-weight: @bold;
    font-size: 2.4em;
    color: #000;
    line-height: 1.4em;
    margin-bottom: 5px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  h4 {
    line-height: 1.4em;
    font-size: 1.3em;
    color: #000;
  }

  .happy-face {
    font-size: 100px;
    color: @brand-light;
  }

  .logout-button {
    text-align: center;
  }

  .modal {
    width: 700px;
    height: 40px;
    display: block;
    overflow: hidden;
    top: 100%;

    &.account-switched {
      margin-top: 30px;
    }
  }

  p {
    font-size: 1.3em;
    line-height: 1.4em;
    margin-bottom: 30px;
    text-align: center;

    &.questions_contact_us {
      font-size: 1.1em;

      a {
        font-weight: bold;
        color: @blue-80-v2;
      }
    }

    &.questions-suspended {
      margin-top: 10px;
    }
  }
}

#logo-resolve {
  width: 140px;
  line-height: 50px;
  position: relative;
  margin: 40px auto;
  height: 50px;
  color: #333;
  font-size: 22px;
  background: url(../../../../wwwroot/fe/img/logo.png) no-repeat left top;
  background-size: auto 100%;
  padding-left: 50px;
}

#login-panel {
  width: 440px;
  position: relative;
  background: #fff;
  padding: 60px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 14px 80px rgba(0, 0, 0, 0.2);
}

#select-username {
  #not-selected-user {
    border-top: 1px solid #eee;
    padding: 20px 0 0;
    margin: 30px 0 0;
    font-size: 13px;

    a {
      color: @brand-subtle;

      &:hover {
        cursor: pointer;
        color: @brand-primary;
      }
    }
  }

  input.form-control {
    box-shadow: none;
    margin: 5px 0;
    padding: 10px 10px 8px 15px;
    border: 0;
    border: 3px solid #eee;
    font-weight: @brand-light;
    font-size: 15px;
    height: 50px;
    border-radius: 4px;

    &:hover {
      border-color: darken(@brand-light, 10%);
    }

    &:active,
    &:focus {
      border-color: @brand-jira;
    }
  }

  .agreement {
    margin-top: 30px !important;

    label {
      font-size: 13px;
      font-weight: 400;
      color: @brand-subtle;

      a {
        color: @brand-jira;
      }
    }

    .form-control {
      width: auto;
      height: auto;
      float: left;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .btn {
    margin-top: 40px;
    font-weight: @medium;
    text-transform: uppercase;
  }

  h3 {
    font-weight: @regular;
    font-size: 24px;
    color: @brand-dark;
    margin-bottom: 15px;

    & + p {
      font-size: 14px;
      font-weight: @regular;
      color: @brand-grey;
      margin-bottom: 30px;
    }
  }

  .help-block {
    font-size: 13px;
    color: @brand-danger;
  }

  .form-group {
    padding: 0;
    margin: 8px 0 4px 0;

    &.domain {
      position: relative;

      .form-control {
        padding-left: 58px;
        text-align: right;
        padding-right: 109px;
      }

      &:before {
        font-size: 15px;
        content: "https://";
        display: block;
        position: absolute;
        left: 11px;
        top: 16px;
        line-height: 20px;
      }

      &:after {
        font-size: 15px;
        content: ".gtmhub.com";
        display: block;
        position: absolute;
        right: 21px;
        top: 16px;
        line-height: 20px;
      }

      &.us-dc,
      &.us2-dc,
      &.as-dc,
      &.af-dc,
      &.sa-dc,
      &.au-dc {
        .form-control {
          padding-right: 128px;
        }
      }

      &.us-dc {
        &:after {
          content: ".us.gtmhub.com";
        }
      }
      &.us2-dc {
        &:after {
          content: ".us2.gtmhub.com";
        }
      }
      &.as-dc {
        &:after {
          content: ".as.gtmhub.com";
        }
      }
      &.af-dc {
        &:after {
          content: ".af.gtmhub.com";
        }
      }
      &.sa-dc {
        &:after {
          content: ".sa.gtmhub.com";
        }
      }
      &.au-dc {
        &:after {
          content: ".au.gtmhub.com";
        }
      }
    }
  }
}
