#error-messaage {
  max-width: 764px;
  margin: 25px auto;
  padding: 0px 40px 47px;
  background: #fff;
  border: 1px solid @brand-light;
  box-shadow: 0px 4px 10px rgba(63, 87, 110, 0.3);
  border-radius: 7px;
  text-align: center;

  h1 {
    font-size: 30px;
    font-weight: @bold;
    line-height: 34px;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 16px;
    line-height: 22px;
    font-weight: @regular;
    color: @ink-50-v2;

    a {
      color: @brand-jira;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  p {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 23px;
    line-height: 27px;
    font-weight: @regular;
    color: @brand-subtle;
  }

  .icon {
    font-size: 130px;
    color: fade(@brand-dark, 10%);
    margin: 25px 0 14px;
  }

  .alert {
    margin: 30px 0;
    display: inline-block;
  }
}

#error-contact {
  max-width: 764px;
  position: relative;
  margin: 16px auto;
  text-align: center;
  color: @ink-50-v2;
  font-family: "SF Pro Text", "Helvetica Neue", "SegoeUI", sans-serif;
  font-size: 13px;
  line-height: 18px;

  a {
    color: @blue-80-v2;
    cursor: pointer;
    font-weight: @bold;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

#error-logout {
  max-width: 800px;
  margin: 30px auto 10px auto;
  text-align: center;
  opacity: 0.7;
  color: @brand-subtle;
  font-size: 16px;
}

#error-stack {
  max-width: 800px;
  margin: 20px auto;

  .title {
    text-align: center;
    color: @brand-subtle;

    .icon {
      margin-left: 5px;
      font-size: 11px;
    }

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  pre {
    padding: 10px;
    color: @brand-dark;
    border: 1px solid darken(@brand-light, 5%);
    background: #fff;
    margin-top: 20px;
    border-radius: 5px;
    overflow: scroll;
  }
}

.modal #no-permissions {
  margin-top: 15px;

  h1 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 10px;
  }

  h2 {
    font-size: 16px;
    line-height: 26px;
  }
}

#no-permissions {
  text-align: center;
  margin-top: 120px;

  h1 {
    font-size: 2em;
    line-height: 1.4em;
    color: @brand-dark;
    font-weight: @bold;
  }

  h2 {
    font-size: 1.3em;
    font-weight: @regular;
    line-height: 1.4em;
    color: fade(@brand-dark, 50%);
  }

  .icon {
    font-size: 70px;
    margin-bottom: 40px;
    line-height: 150px;
    width: 150px;
    height: 150px;
    color: #fff;
    background: @brand-danger;
    border-radius: 80px;
  }
}

.typeahead-assignee.role .icon {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}
