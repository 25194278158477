gh-attachment {
  -webkit-font-smoothing: antialiased;

  .existing,
  .uploading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 180px;
    height: 50px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid @brand-border;
    border-radius: 7px;
  }

  .name,
  .size {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .existing {
    padding: 7px 15px 7px 35px;

    .file-type-icon {
      position: absolute;
      left: 10px;
      top: 8px;
      width: 16px;
      height: 16px;

      img {
        vertical-align: top;
      }
    }

    .name {
      padding-right: 5px;
      font-size: 12px;
      color: @blue-100;
    }

    .size {
      font-size: 11px;
      color: @black-70;
    }

    dropdown-actions-menu {
      position: absolute;
      top: 5px !important; // important due to styles of views holding the component
      right: 5px !important;

      .dropdown-menu {
        min-width: 90px;
      }
    }

    &.deleting {
      opacity: 0.7;

      dropdown-actions-menu {
        display: none;
      }
    }

    &.image-thumbnail {
      width: 80px;
      padding-left: 15px;
      cursor: pointer;

      .image-frame {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 6px;
      }

      &:hover {
        background: @brand-dark;

        .image-frame {
          opacity: 0.1;
        }

        .name {
          color: @brand-white;
        }

        .size {
          color: lighten(@brand-light, 2%);
        }

        dropdown-actions-menu {
          .toggle {
            color: lighten(@ink-40, 5%);

            &:hover {
              color: @brand-jira;
            }
          }
        }
      }
    }
  }

  .uploading {
    padding: 12px 15px 7px;

    .upload-progress,
    .bar {
      height: 7px;
      border-radius: 4px;
    }

    .upload-progress {
      width: 100%;
      background: @black-20;

      .bar {
        width: 0px;
        max-width: 100%;
        background: @teal-90;
      }
    }

    .name {
      font-size: 11px;
      color: @ink-80;
      font-weight: @light;
    }
  }
}
