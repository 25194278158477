.gh-b {
  padding: 10px 25px 10px 25px;
  position: relative;
  font-size: 1.1em;
  letter-spacing: 0.03em;
  border-radius: 5px;
  border: 2px solid transparent;
  .transition(0.2s);
  margin-right: 10px;
  display: inline-block;
  white-space: nowrap;

  &.gh-sm {
    padding: 5px 15px;
    font-size: 1em;
  }

  &.gh-xs {
    padding: 3px 6px;
    font-size: 0.9em;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    filter: grayscale();
    opacity: 0.7;
    cursor: stop;
  }

  &.gh-b-border {
    background: none;
    border: 2px solid @brand-light;
    color: @brand-subtle;
    font-weight: @medium;

    &:hover {
      border-color: darken(@brand-light, 10%);
    }
  }

  &.gh-prime {
    color: #fff;
    background: @brand-primary;

    &:hover {
      background: lighten(saturate(@brand-primary, 3%), 3%);
    }

    &:focus {
      border: 2px solid lighten(saturate(@brand-primary, 13%), 13%);
    }
  }

  &.gh-accent {
    color: #fff;
    background: @brand-jira;

    &:hover {
      background: lighten(saturate(@brand-jira, 3%), 3%);
    }

    &:focus,
    &:active {
      border: 3px solid lighten(saturate(@brand-jira, 13%), 13%);
      box-shadow: 0 0 20px fade(@brand-jira, 40%);
    }
  }

  &.gh-dark {
    color: #fff;
    background: @brand-dark;

    &:hover {
      background: lighten(saturate(@brand-dark, 3%), 3%);
    }

    &:focus,
    &:active {
      border: 3px solid lighten(saturate(@brand-dark, 13%), 13%);
      box-shadow: 0 0 20px fade(@brand-dark, 40%);
    }
  }

  &.gh-danger {
    color: #fff;
    background: @brand-danger;

    &:hover {
      background: lighten(saturate(@brand-danger, 3%), 3%);
    }

    &:focus {
      border: 3px solid lighten(saturate(@brand-danger, 13%), 13%);
    }
  }

  &.gh-warning {
    color: #fff;
    background: @brand-warning;

    &:hover {
      background: lighten(saturate(@brand-warning, 3%), 3%);
    }

    &:focus {
      border: 3px solid lighten(saturate(@brand-warning, 13%), 13%);
    }
  }

  &.gh-subtle {
    color: #fff;
    background: @brand-subtle;

    &:hover {
      background: lighten(saturate(@brand-subtle, 3%), 3%);
    }

    &:focus {
      border: 3px solid saturate(@brand-subtle, 13%);
    }
  }

  &.gh-lilac {
    color: #fff;
    background: @brand-lilac;

    &:hover {
      background: lighten(saturate(@brand-lilac, 3%), 3%);
    }

    &:focus {
      border: 3px solid saturate(@brand-lilac, 13%);
    }
  }

  &.gh-navy {
    padding: 11px 25px 11px 25px;
    border: 2px solid #595596;
    color: @brand-subtle;
    background: @brand-navy;

    &:hover {
      background: lighten(saturate(@brand-navy, 3%), 3%);
    }

    &:focus {
      border: 3px solid saturate(@brand-navy, 13%);
    }

    &.gh-sm {
      padding: 6px 15px;
    }

    &.gh-xs {
      padding: 4px 6px;
    }
  }

  .icon {
    margin-left: -3px;
    margin-right: 15px;
  }

  &.disabled,
  &:disabled {
    opacity: 0.35;
    cursor: not-allowed;
    filter: none;
  }

  &.gh-b-scr-med {
    @media all and (max-width: @screen-width-med) {
      font-size: 1em;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.gh-b {
  &.oval {
    height: 30px;
    padding: 6px 18px;
    border-radius: 15px;
    font-weight: @medium;
    font-size: 13px;
    line-height: 14px;

    &.basic {
      background: @brand-white;
      box-shadow:
        0px 0px 1px @blue-200,
        0px 1px 2px rgba(119, 138, 156, 0.3);

      &:hover {
        box-shadow:
          0px 0px 2px @blue-200,
          0px 1px 2px rgba(119, 138, 156, 0.3);
      }

      &:focus {
        border: 1.5px solid @blue-80;
        box-shadow: 0px 1px 2px rgba(119, 138, 156, 0.3);
      }

      &:active {
        box-shadow:
          0px 0px 1px @blue-200,
          inset 0px 0px 3px rgba(0, 97, 193, 0.4);
      }

      &:disabled {
        opacity: 0.4;
        box-shadow:
          0px 0px 1px @blue-200,
          0px 1px 2px rgba(119, 138, 156, 0.3);
      }
    }

    &.gh-xs {
      //- TODO: Remove color from this class
      color: @ink-80;
      height: auto;
      padding: 2px 6px;
      font-size: 11px;
    }

    &.gh-lilac {
      color: @brand-white;
      background: @purple-100;

      &:hover {
        background: lighten(saturate(@purple-100, 3%), 3%);
      }

      &:focus {
        border: 3px solid saturate(@purple-100, 13%);
      }
    }

    &.no-mr {
      margin-right: 0;
    }

    &.gh-blue-80 {
      color: @brand-white;
      background: #0057d7;

      &:hover {
        background: lighten(saturate(#0057d7, 3%), 3%);
      }
    }

    &.gh-royal-80-outline {
      color: @brand-black;
      background: @brand-white;
      border: 1px solid @royal-80;

      &:hover {
        color: lighten(saturate(@brand-black, 3%), 3%);
      }
    }
  }
}
